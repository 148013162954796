export const types = {
  ORDERS_LOAD_REQUEST    : 'userOrsers/ORDERS_LOAD_REQUEST',
  ORDERS_LOAD_COMPLETED  : 'userOrders/ORDERS_LOAD_COMPLETED',
  ORDERS_LOAD_FAILED     : 'userOrders/ORDERS_LOAD_FAILED',

  ORDERS_ADD_REQUEST     : 'userOrsers/ORDERS_ADD_REQUEST',
  ORDERS_ADD_COMPLETED   : 'userOrders/ORDERS_ADD_COMPLETED',
  ORDERS_ADD_FAILED      : 'userOrders/ORDERS_ADD_FAILED',

  INVOICE_LOAD_REQUEST   : 'userOrsers/INVOICE_LOAD_REQUEST',
  INVOICE_LOAD_COMPLETED : 'userOrders/INVOICE_LOAD_COMPLETED',
  INVOICE_LOAD_FAILED    : 'userOrders/INVOICE_LOAD_FAILED'
}

const initialState = {
  user_orders       : [],
  need_pay_order    : false,
  add_single_result : 0
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ORDERS_LOAD_REQUEST:
    case types.ORDERS_LOAD_FAILED:
    case types.ORDERS_ADD_REQUEST:
    case types.INVOICE_LOAD_FAILED:
      return { ...initialState };

    case types.ORDERS_LOAD_COMPLETED:
    let user_orders = [...action.data] || [],
      isNeedPay = false;

      for (let i = 0; i < user_orders.length; i++) {
        user_orders[i].created_at = user_orders[i].created_at.slice(0, -9);

        if (user_orders[i].status === 0) {
          isNeedPay = true;
        }
      }

      return { ...state, user_orders: user_orders, need_pay_order: isNeedPay };

    case types.ORDERS_ADD_COMPLETED:
      return { ...state, add_single_result: 1 };

    case types.ORDERS_ADD_FAILED:
      return { ...initialState, add_single_result: 2 };

    case types.INVOICE_LOAD_REQUEST:
    case types.INVOICE_LOAD_COMPLETED:
      return { ...state };

    default:
      return state;
  }
}

export const actions = {
  loadUserOrdersRequest: function () {
    return { type: types.ORDERS_LOAD_REQUEST };
  },
  loadUserOrdersCompleted: function(data) {
    return { type: types.ORDERS_LOAD_COMPLETED, data: data };
  },
  loadUserOrdersFailed: function(error) {
    return { type: types.ORDERS_LOAD_FAILED, error: error };
  },
  addUserOrderRequest: function(list) {
    return { type: types.ORDERS_ADD_REQUEST, list: list };
  },
  addUserOrderCompleted: function(data) {
    return { type: types.ORDERS_ADD_COMPLETED, data: data };
  },
  addUserOrderFailed: function(error) {
    return { type: types.ORDERS_ADD_FAILED, error: error };
  },
  loadInvoiceDataRequest: function(orderID) {
    return { type: types.INVOICE_LOAD_REQUEST, orderID: orderID };
  },
  loadInvoiceDataCompleted: function(data) {
    return { type: types.INVOICE_LOAD_COMPLETED, data: data };
  },
  loadInvoiceDataFailed: function(error) {
    return { type: types.INVOICE_LOAD_FAILED, error: error };
  }
}
