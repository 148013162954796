import { call, put, takeLatest } from 'redux-saga/effects'
import validator from 'validator'

import { SingleAiConsultantAPIs } from 'api'
import { types, actions } from '../modules/SingleAiConsultant'


const { AnswerGetAPI } = SingleAiConsultantAPIs;

function* watchAiChatAnswerGetRequest() {
  yield takeLatest(types.ANSWER_GET_REQUEST, getAiChatAnswerFlow);
}

function* getAiChatAnswerFlow(action) {
  const question = action.question;
  const date = action.date;
  const providers = action.providers;

  try {
    const response = yield call(AnswerGetAPI, question, date, providers);
    const responseData = response.data;

    if (responseData.status === 'success') {
      let result = responseData.data.text;

      if (!validator.isJSON(result)) {
        result = result.replaceAll('```', '');
      }

      yield put(actions.getAnswerSuccess(result, question));
    } else {
      console.log(
        'getAiChatAnswer response error: ',
        responseData.error.message[0]
      );

      yield put(actions.getAnswerError(question));
    }
  } catch (err) {
    console.log('getAiChatAnswer catch error: ', err);

    yield put(actions.getAnswerError(question));
  }
}


export default [ watchAiChatAnswerGetRequest() ]
