import axios from 'axios'


const url = process.env.REACT_APP_STOCK_DOMAIN;
const BubbleDataLoadAPI = (stockCode) => axios.get(
  `${url}/data_fetch/api/StockComparisonBubble/${stockCode}`,
  { headers: { Accept: 'application/json' } }
);
const ReutersEstimateAPI = (api, stockCode) => axios.get(
  `${url}/data_fetch/api/ReutersSmartEstimate_${api}_Comparison/${stockCode}`,
  { headers: { Accept: 'application/json' } }
);
const SingleStockComparisonLoadAPI = (stockCode) => axios.get(
  `${url}/data_fetch/api/StockComparison/${stockCode}`,
  { headers: { Accept: 'application/json' } }
);


export default {
  BubbleDataLoadAPI,
  ReutersEstimateAPI,
  SingleStockComparisonLoadAPI,
}
