export const types = {
    REQUEST : 'contact/REQUEST',
    SUCCESS : 'contact/SUCCESS',
    ERROR   : 'contact/ERROR',
    RESET   : 'contact/RESET',
}

const initialState = { sent: null, send_error: null }

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.SUCCESS:
            return { ...state, sent: true };

        case types.ERROR:
            return { ...state, send_error: true };

        case types.RESET:
            return { ...state, sent: null, send_error: null };

        default:
            return state;
    }
}

export const actions = {
    sendRequest: function(data) {
        return { type: types.REQUEST, data: data };
    },
    sendSuccess: function(data) {
        return { type: types.SUCCESS, data: data };
    },
    sendError: function(error) {
        return { type: types.ERROR, error: error };
    },
    resetStatus: function(error) {
        return { type: types.RESET };
    },
}
