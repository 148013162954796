import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/UserProducts'
import { UserProductsAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


function responseHandler(response) {
    return response.data;
}

function * watchUserProductsLoadRequest() {
    yield takeLatest(types.PRODUCTS_LOAD_REQUEST, loadUserProductsFlow);
}

function * loadUserProductsFlow(action) {
    try {
        const response = yield call(UserProductsAPIs.UserProductsLoadAPI, action.data),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadUserProductsCompleted(responseData.data));
        } else {
            yield put(actions.loadUserProductsFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadUserProductsFailed(error));
    }
}

function * watchUserProductsCancelRequest() {
    yield takeLatest(types.PRODUCTS_CANCEL_REQUEST, userProductsCancelFlow);
}

function * userProductsCancelFlow(action) {
    try {
        const response = yield call(UserProductsAPIs.UserProductCancelAPI, action.id),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.cancelUserProductCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.cancelUserProductFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.cancelUserProductFailed(error));
    }
}

export default [ watchUserProductsLoadRequest(), watchUserProductsCancelRequest() ]
