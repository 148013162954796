import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const UserDataAPI = () => axios.get(`${config.api.APP_API_DOMAIN}/user/info`, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
    }
  }),
  UserDataUpdateAPI = (data) => axios.put(`${config.api.APP_API_DOMAIN}/user/info`, data, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
    }
  }),
  UserMailSubscriptAPI = (formData) => axios.post(`${config.api.APP_API_DOMAIN}/subscript`, formData, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
    }
  }),
  UserMailSubscriptCancelAPI = (formData) => axios.post(`${config.api.APP_API_DOMAIN}/subscript/cancel`, formData, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
    }
  })


export default {
  UserDataAPI,
  UserDataUpdateAPI,
  UserMailSubscriptAPI,
  UserMailSubscriptCancelAPI
}
