import React from 'react'
import { Link } from 'react-router-dom'
import { Message } from 'components'


export class LogInOutHandler extends React.PureComponent {
  LogOutHandler = (e) => {
    e.preventDefault();

    Message.Box(
      { title: '登出', content: '是否確定登出？', buttons: '[否][是]' },
      (ButtonPressed) => {
        if (ButtonPressed === '是') { this.props.onLogout(); }
      }
    );
  }

  render() {
    const { authenticated, isUserInfoLoaded, user_info } = this.props;
    const isLogined = authenticated && isUserInfoLoaded && user_info
        && user_info.nickname;
    const loginComponent = (
      <Link
        className='auth-btn login ua-btn default w-100'
        title='Log In'
        href='/login-page'
        to='/login-page'
      >
        登入
      </Link>
    );
    const logoutComponent = (
      <a
        className='auth-btn logout ua-btn outline text'
        onClick={this.LogOutHandler}
        title='Log Out'
        href='#logout'
      >
        登出
      </a>
    );

    return (
      <div className={`auth-btn-container text-center
          ${!isLogined ? 'padding-right-12' : ''}`}>
        {isLogined ? logoutComponent : loginComponent}
      </div>
    );
  }
}
