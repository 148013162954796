export const types = { SET : 'pagination/SET' }

const initialState = { pager : {} }


export default function reducer(state = initialState, action = {}) {
  return (action.type === types.SET) ? { ...state,  pager: { ...state.pager, [action.id]: action.currentPage } } : state;
}

export const actions = {
  setPager: function(id, currentPage) {
    return { type: types.SET, id: id, currentPage : currentPage };
  }
}
