import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const UserOrdersLoadAPI = () => axios.get(`${config.api.APP_API_DOMAIN}/user/orders`,{
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  }),
  UserOrdersAddAPI = (list) => axios.post(`${config.api.APP_API_DOMAIN}/user/orders`, list, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  }),
  InvoiceDataLoadAPI = (orderID) => axios.get(`${config.api.APP_API_DOMAIN}/user/invoices/${orderID}`, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  })


export default { UserOrdersLoadAPI, UserOrdersAddAPI, InvoiceDataLoadAPI }
