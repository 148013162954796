import React from 'react'
import { Pentagon, Octagon } from './containers'


export function UAD3(props) {
  switch (props.options.type) {
    case 'pentagon':
      return <Pentagon { ...props } />;

    case 'octagon':
      return <Octagon { ...props } />;

    default:

  }
}
