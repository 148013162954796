import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/ColourTheme'
import { ColourThemeHandler as component } from '../components'


const mapStateToProps = (state) => ({
  colour_theme: state.ColourTheme.colour_theme,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onColourThemeSet: (oldTheme, newTheme) =>
      dispatch(actions.setColourThem(oldTheme, newTheme)),
});
const ColourThemeHandler = connect(mapStateToProps, mapDispatchToProps)(component);


export { ColourThemeHandler }
