import React from 'react'
import { Link } from 'react-router-dom'
import packageJson from '../../../../../package.json'

import 'assets/sass/Layout/footer.scss'


const legal = [
  { key: 'terms-cons', title: '服務條款', url: '/service' },
  { key: 'ua-disclaimer', title: '免責聲明', url: '/disclaimer' },
  { key: 'privacy-policy', title: '隱私權政策', url: '/privacypolicy' },
]

export class Footer extends React.PureComponent {
  render() {
    return (
      <footer className='page-footer text-center'>
        <div className='page-footer-declaration'>
          優分析 UAnalyze 特別聲明：本站各項分析數據僅供參考。
          投資必有風險，投資人須謹慎、理性地判讀各種市場資訊妥善決策。
        </div>

        <div className='page-footer-nav'>
          {legal.map((item, idx) => {
            const { key, title, url } = item;

            return (
              <span key={idx}>
                <Link className={key} to={url}>{title}</Link>

                {(this.props.isMobile && idx === legal.length - 1)
                  ?
                  <br/>
                  :
                  <span className='margin-horizontal-5'>|</span>}
              </span>
            );
          })}

          <a
            className='facebook'
            target='_blank'
            href='https://www.facebook.com/UAnalyze'
            rel='nofollow noopener noreferrer'
          >
            Facebook
          </a>

          <span className='margin-horizontal-5'>|</span>

          <a
            className='forum'
            target='_blank'
            href='https://forum.uanalyze.com.tw'
            rel='nofollow noopener noreferrer'
          >
            討論區
          </a>

          <span className='margin-horizontal-5'>|</span>

          <Link className='contact-us' to='/contact-us'>聯絡我們</Link>
        </div>

        <div className='copyright'>
          <div className='tax-id'>統一編號：42612122</div>

          <div className='margin-top-5'>© 2018 優分析 UAnalyze 商拓財經有限公司 v.{packageJson.version}</div>
        </div>
      </footer>
    );
  }
}
