const _comma = (number) => ((number !== null)
	?
	(!isNaN(number)
		?
		number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		:
		number)
	:
	'');

const comma = (number) => (
	Array.isArray(number) ? number.map((d) => _comma(d)).join('~') : _comma(number)
);
const floatFormatter = (value, exponent) => {
	if (typeof Number(value) !== 'number'
			|| (typeof value === 'string' && value.length === 0)) { return null; }

	const number = Number(value);
	const size = Math.pow(10, exponent);

	return Math.round(number * size) / size;
};
const getUnitNumber = (
	number,
	decimal = false,
	useUnits = [ 0, 3, 4, 6, 8, 10, 12, 14 ]
) => {
	const unitMap = [
		'', // 0
		'十', // 1
		'百', // 2
		'千', // 3
		'萬', // 4
		'十萬', // 5
		'百萬', // 6
		'千萬', // 7
		'億', // 8
		'十億', // 9
		'百億', // 10
		'千億', // 11
		'兆', // 12
		'十兆', // 13
		'百兆', // 14
		'千兆', // 15
	];

	let data = parseFloat(number);
	let i;
	let pow = 1;
	let precision = 3;

	for (i = 0; i < useUnits.length; i++) {
		pow = Math.pow(10, useUnits[i]);

		if (i !== 0) { precision = useUnits[i] - useUnits[i - 1]; }

		if ((data / pow) < Math.pow(10, precision)) {
			if (i === 0) { precision = (useUnits[i] > 2) ? 1 : 2; }

			return floatFormatter(data / pow, (decimal !== false) ? decimal
					: ((useUnits[i] > 2) ? 1 : 2)) + unitMap[useUnits[i]];
		}
	}

	if (i === 0) {
		return floatFormatter(data / pow, (decimal !== false) ? decimal
				: ((useUnits[i] > 2) ? 1 : 2)) + unitMap[useUnits[i]];
	}

	return floatFormatter(data / pow, (decimal !== false) ? decimal
			: ((useUnits[i] > 2) ? 1 : 2)) + unitMap[useUnits[i - 1]];
}


export default { comma, floatFormatter, getUnitNumber }
