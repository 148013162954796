import React from 'react'
import validator from 'validator'
import moment from 'moment'
import _ from 'lodash'


const blackList = [ 'stock_title', 'uae10111_cp' /* news */ ];
const bytes = (str) => {
  let len = 0;

  if (str && !(isNaN(str) && validator.isURL(str))) {
    for (let i = 0; i < str.length; i++) {
      len += (str.charCodeAt(i) < 256) ? 13 : 16;
    }
  }

  return len;
};

const getColumnArray = (
  type,
  singleKey,
  arrData,
  model,

  arrHeader,
  arrAccessor,
  arrCell,
  arrMinWidth
) => {
  let columns = [];

  for (let i = 0; i < arrHeader.length; i++) {
    const header = arrHeader[i];
    const accessor = arrAccessor[i];
    const cell = arrCell[i];
    const minWidth = arrMinWidth ? arrMinWidth[i] : (accessor === 'compare')
        ? 50 : 100;
    const data = (arrData && _.size(arrData)) ? arrData[0][accessor] : null;
    const sortable = type !== 'user' && (blackList.includes(accessor)
        || (type === 'account_single' && i === 0) || typeof data !== 'object');

    columns.push({
      Header: (props) => {
        return (sortable && header !== '比較')
          ?
          <span
            className='data-table-header sort'
            data-id={singleKey}
            data-title={accessor}
          >
            {header}
          </span>
          :
          header;
      },

      Cell: cell,

      accessor: accessor,
      minWidth: minWidth,
      resizable: false,
      sortable: sortable,

      filterMethod : (filter, row) => {
        const id = filter.id;
        const value = row[id];
        const filterValue = filter.value;

        switch (id) {
          case 'ua1320015_cp':
          case 'ua1320017_cp':
          case 'ua70037_cp':
          case 'ua70069_cp':
            switch (filterValue) {
              case 'y': return value === '是';

              case 'n': return value === '否';

              default: return true;
            }

            case 'ua50017_cp':
              switch (filterValue) {
                case '0.75': return value < 0.75;

                case '0.75 - 1.2': return value >= 0.75 && value < 1.2;

                case '1.2': return value >= 1.2;

                default: return true;
              }

              case 'ua50228_cp':
              case 'ua50230_cp':
              case 'ua60282_cp':
              case 'ua70273_cp':
              case 'ua70300_cp':
              case 'ua70301_cp':
              case 'ua80040_cp':
                switch (filterValue) {
                  case 'grow': return value === '成長';

                  case 'steady': return value === '持平';

                  case 'decline': return value === '衰退';

                  case 'q_1': return value === 'Q1谷底後趨勢向上';

                  case 'q_2': return value === 'Q2谷底後趨勢向上';

                  case 'q_3': return value === 'Q3谷底後趨勢向上';

                  case 'q_4': return value === 'Q4谷底後趨勢向上';

                  case 'sharp_rise': return value === '大幅向上';

                  case 'rise': return value === '向上';

                  case 'drop': return value === '向下';

                  case 'sharp_drop': return value === '大幅向下';

                  case 'high': return value === '庫存偏高';

                  case 'sound': return value === '庫存健康';

                  case 'low': return value === '庫存偏低';

                  case 'cheap': return value === '便宜';

                  case 'normal': return value === '適中';

                  default: return true;
                }

              case 'ua50229_cp':
                if (filterValue === 'all') {
                  return true;
                } else {
                  const dateTime = moment(value, 'YYYY/M/D');
                  const mo = Number(filterValue);

                  return moment().isSameOrBefore(dateTime.add(mo, 'months'));
                }

              case 'ua50078_cp':
                switch (filterValue) {
                  case '0.8': return value < 0.8 && value !== null;

                  case '0.8 - 1.2': return value >= 0.8 && value < 1.2;

                  case '1.2': return value >= 1.2;

                  default: return true;
                }

              case 'ua50018_cp':
              case 'ua60132_cp':
              case 'ua60213_cp':
              case 'ua60245_cp':
              case 'ua60297_cp':
              case 'ua60299_cp':
              case 'ua60302_cp':
              case 'ua70030_cp':
              case 'ua70034_cp':
              case 'ua70263_cp': // 近12月營收合計年增率
              case 'ua70268_cp': // 累計營收超法人預期(%)
              case 'ua70307_cp':
              case 'ua90247_xbrl':
                switch (filterValue) {
                  case '0 - 10': return value >= 0 && value < 10;

                  case '10 - 15': return value >= 10 && value < 15;

                  case '15 - 20': return value >= 15 && value < 20;

                  case '10 - 25': return value >= 10 && value < 25;

                  case '25 - 50': return value >= 25 && value < 50;

                  case '50':
                  case '40':
                  case '20':
                  case '10':
                  case '5':
                  case '3':
                  case '1':
                    return value >= Number(filterValue);

                  case '0': return value > 0;

                  case '-0':
                  case '-1':
                  case '-3':
                  case '-5':
                  case '-10':
                  case '-20':
                    const abs = Number(filterValue.replace('-', ''));

                    let result = value !== '' && value !== null &&
                        value !== undefined;
                    result = result && value <= (!abs ? 0 : -(abs));

                    return result;

                  default: return true;
                }

              case 'ua60281_cp': // 條件五：財報公佈距今幾天
              case 'ua70270_cp': // 條件一：月營收公佈距今幾天
                if (filterValue === 'all') {
                  return true;
                } else {
                  const arr = filterValue.split(' 天');

                  return ((typeof value === 'string' && value.length === 0)
                      || value === null || value === undefined)
                    ?
                    false
                    :
                    Number(value) <= Number(arr[0]);
                }

              case 'ua80010_cp': // 最新股價(元)
                if (model === 'A0006_5SingleRankings'
                    || model === 'A0006_6SingleRankings') {
                  switch (filterValue) {
                    case '50': return value < 50;

                    case '50 - 100': return value >= 50 && value < 100;

                    case '100 - 200': return value >= 100 && value < 200;

                    case '200': return value >= 200;

                    default: return true;
                  }
                } else if (model === 'A0014_2SingleRankings'
                    || model === 'A0014_3SingleRankings'
                    || model === 'A0014_4SingleRankings'
                    || model === 'A0014_5SingleRankings') {
                  switch (filterValue) {
                    case '20': return value < 20;

                    case '20 - 50': return value >= 20 && value < 50;

                    case '50 - 100': return value >= 50 && value < 100;

                    case '100': return value >= 100;

                    default: return true;
                  }
                }

                return true;

              case 'ua80012_cp':
                if (model === 'A0006_5SingleRankings'
                    || model === 'A0006_6SingleRankings') {
                  let lower = 8;
                  let upper = 12;

                  switch (filterValue) {
                    case '12 - 15':
                      lower = 12;
                      upper = 15;

                      break;

                    case '15 - 20':
                      lower = 15;
                      upper = 20;

                      break;

                    case '20':
                      lower = 20;
                      upper = 20;

                      break;

                    default:

                  }

                  if (lower === upper) { return parseFloat(value) >= upper; }

                  return parseFloat(value) >= lower && parseFloat(value) <= upper;
                } else {
                  return parseFloat(value) <= parseInt(filterValue);
                }

              case 'ua80050_cp':
                switch (filterValue) {
                  case '0.8': return value < 0.8;

                  case '0.8 - 1.2': return value >= 0.8 && value < 1.2;

                  case '1.2': return value >= 1.2;

                  default: return true;
                }

              case 'ua80083_cp':
              case 'ua80084_cp': // 投信連續買/賣紀錄
                return (filterValue === 'all') ? true
                    : parseFloat(value) >= parseInt(filterValue);

              case 'ua80100_cp':
                switch (filterValue) {
                  case 'under 10': return value <= -10;

                  case 'under 0 - 10': return value <= 0 && value > -10;

                  case 'over 0 - 10': return value >= 0 && value < 10;

                  case 'over 10': return value >= 10;

                  default: return true;
                }

              default:
                return filterValue === 'all'
                    || parseFloat(value) >= parseInt(filterValue);
            }
          },
          sortMethod : (a, b) => {
            switch (accessor) {
              case 'ua10010_cp':
              case 'ua1340003_cp':
                a = (a === null || a === undefined || a === ''
                    || (a && _.size(a) === 0)) ? -Infinity
                    : parseInt(a.replace(/\D/g, ''));
                b = (b === null || b === undefined || b === ''
                    || (b && _.size(b) === 0)) ? -Infinity
                    : parseInt(b.replace(/\D/g, ''));

                break;

              default:
            }

            if (a === b) { return 0; }

            return (a > b) ? 1 : -1;
          }
      })
  }

  return columns;
};
const getColumnWidth = (rows, accessor, header, isMobile) => {
  let cellLength = Math.max(...rows.map((row) => bytes(`${row[accessor]}` || '')));
  // compare header length
  cellLength = (cellLength > bytes(header)) ? cellLength : bytes(header) + 10;

  return (accessor === 'compare') ? 50 : (!isMobile && accessor === 'stock_title')
      ? cellLength + 60 : (cellLength < 100) ? 100 : cellLength;
};


export { getColumnArray, getColumnWidth }
