import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { MenuChildren } from '../MenuChildren'


const MenuContainer = ({ children }) => (
  <div className="menu-item-parent padding-top-10">{children}</div>
);
const SortableMenuContainer = SortableContainer(
  ({ children }) => <MenuContainer>{children}</MenuContainer>
);
const SortableMenuItem = SortableElement(MenuChildren);


export { MenuContainer, SortableMenuContainer, SortableMenuItem }
