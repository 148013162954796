export const types = {
  LIST_LOAD_REQUEST     : 'product/LIST_LOAD_REQUEST',
  LIST_LOAD_COMPLETED   : 'product/LIST_LOAD_COMPLETED',
  LIST_LOAD_FAILED      : 'product/LIST_LOAD_FAILED',

  DETAIL_LOAD_REQUEST   : 'product/DETAIL_LOAD_REQUEST',
  DETAIL_LOAD_COMPLETED : 'product/DETAIL_LOAD_COMPLETED',
  DETAIL_LOAD_FAILED    : 'product/DETAIL_LOAD_FAILED',

  CURRENT_CLEAR         : 'product/CURRENT_CLEAR',
  CURRENT_SET           : 'product/CURRENT_SET',
  TAG_SELECT            : 'product/TAG_SELECT'
}

const initialState = {
  products        : [],
  loaded          : false,
  current_product : null,
  load_error      : false,
  selected_tags    : [ 'all' ]
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LIST_LOAD_REQUEST:
      return { ...state, loaded: false, load_error: false };

    case types.LIST_LOAD_COMPLETED:
     return { ...state, products: action.data, loaded: true, load_error: false };

    case types.LIST_LOAD_FAILED:
      return { ...state, loaded: false, load_error: true };

    case types.DETAIL_LOAD_REQUEST:
      return { ...state, load_error: false };

    case types.DETAIL_LOAD_COMPLETED:
      return { ...state, current_product: { ...action.data }, load_error: false };

    case types.DETAIL_LOAD_FAILED:
      return { ...state, current_product: null, load_error: true };

    case types.CURRENT_CLEAR:
      return { ...state, current_product: null, load_error: false };

    case types.CURRENT_SET:
      return { ...state, current_product: { ...action.data } };

    case types.TAG_SELECT:
      return { ...state, current_product: null, selected_tags: action.tag };

    default:
      return state;
  }
}

export const actions = {
  loadListRequest: () => ({ type: types.LIST_LOAD_REQUEST }),
  loadListCompleted: (data) => ({
    type: types.LIST_LOAD_COMPLETED,
    data: data
  }),
  loadListFailed: (error) => ({
    type: types.LIST_LOAD_FAILED,
    error: error
  }),

  loadDetailRequest: (id) => ({
    type: types.DETAIL_LOAD_REQUEST,
    id: id
  }),
  loadDetailCompleted: (data) => ({
    type: types.DETAIL_LOAD_COMPLETED,
    data: data
  }),
  loadDetailFailed: (error) => ({
    type: types.DETAIL_LOAD_FAILED,
    error: error
  }),

  onCurrentProductClear: () => ({ type: types.CURRENT_CLEAR }),
  setCurrentProduct: (product) => ({
    type: types.CURRENT_SET,
    data: product
  }),

  setTag: (tag) => ({
    type: types.TAG_SELECT,
    tag: tag
  })
}
