export const types = {
  LOAD_REQUEST             : 'profile/LOAD_REQUEST',
  LOAD_COMPLETED           : 'profile/LOAD_COMPLETED',
  LOAD_FAILED              : 'profile/LOAD_FAILED',

  UPDATE_SET               : 'profile/UPDATE_SET',
  UPDATE_REQUEST           : 'profile/UPDATE_REQUEST',
  UPDATE_SUCCESS           : 'profile/UPDATE_SUCCESS',
  UPDATE_ERROR             : 'profile/UPDATE_ERROR',

  SUBSCRIPT_REQUEST        : 'profile/SUBSCRIPT_REQUEST',
  SUBSCRIPT_SUCCESS        : 'profile/SUBSCRIPT_SUCCESS',
  SUBSCRIPT_ERROR          : 'profile/SUBSCRIPT_ERROR',

  SUBSCRIPT_CANCEL_REQUEST : 'profile/SUBSCRIPT_CANCEL_REQUEST',
  SUBSCRIPT_CANCEL_SUCCESS : 'profile/SUBSCRIPT_CANCEL_SUCCESS',
  SUBSCRIPT_CANCEL_ERROR   : 'profile/SUBSCRIPT_CANCEL_ERROR'
}

const initialState = {
  user_info      : false,
  user_info_temp : {},
  loaded         : false,
  subscription   : false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
    case types.LOAD_FAILED:
      return { ...state, loaded: false };

    case types.LOAD_COMPLETED:
      return { ...state, user_info: action.data, subscription: action.data.subscription, loaded: true };

    case types.UPDATE_REQUEST:
    case types.UPDATE_ERROR:
    case types.SUBSCRIPT_REQUEST:
    case types.SUBSCRIPT_ERROR:
    case types.SUBSCRIPT_CANCEL_REQUEST:
    case types.SUBSCRIPT_CANCEL_ERROR:
      return { ...state };

    case types.UPDATE_SET:
      return {
        ...state,
        user_info_temp: action.data
      };

    case types.UPDATE_SUCCESS:
      return {
        ...state,
        user_info: {
          ...state.user_info,
          ...state.user_info_temp
        }
      };

    case types.SUBSCRIPT_SUCCESS:
      let subscription = action.subscript.subscription;

      return { ...state, subscription: subscription };

    case types.SUBSCRIPT_CANCEL_SUCCESS:
      let subscriptionCancel = action.subscript.subscription;

      return { ...state, subscription: subscriptionCancel };

    default:
      return state;
  }
}

export const actions = {
  loadUserRequest: function () {
    return { type: types.LOAD_REQUEST };
  },
  loadUserCompleted: function(data) {
    return { type: types.LOAD_COMPLETED, data: data };
  },
  loadUserFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },

  updateUserSet: function(data) {
    return { type: types.UPDATE_SET, data: data };
  },
  updateUserRequest: function(data) {
    return { type: types.UPDATE_REQUEST, data: data };
  },
  updateUserSuccess: function(data) {
      return { type: types.UPDATE_SUCCESS, data: data };
  },
  updateUserError: function(error) {
      return { type: types.UPDATE_ERROR, error: error };
  },

  subscriptRequest: function () {
    return { type: types.SUBSCRIPT_REQUEST };
  },
  subscriptSuccess: function(subscript) {
    return { type: types.SUBSCRIPT_SUCCESS, subscript: subscript };
  },
  subscriptError: function(error) {
    return { type: types.SUBSCRIPT_ERROR, error: error };
  },
  cancelSubscriptRequest: function () {
    return { type: types.SUBSCRIPT_CANCEL_REQUEST };
  },
  cancelSubscriptSuccess: function(subscript) {
    return { type: types.SUBSCRIPT_CANCEL_SUCCESS, subscript: subscript };
  },
  cancelSubscriptError: function(error) {
    return { type: types.SUBSCRIPT_CANCEL_ERROR, error: error };
  }
}
