import { findAndReplace } from 'mdast-util-find-and-replace'
import { u } from 'unist-builder'


const RemarkColorWord = () => {
  const replacements = [
    [
      /\[\[(.*?)\]\]/g,
      (text, value) => u('element', { tagName: 'span', properties: { className: ['remark-red'] } }, [
        u('text', value)
      ])
    ],
    [
      /<<(.*?)>>/g,
      (text, value) => u('element', { tagName: 'span', properties: { className: ['remark-green'] } }, [
        u('text', value)
      ])
    ],
    [
      /^(結論|總結)$/g,
      (text) => u('element', { tagName: 'div', properties: { className: ['hr'] } }, [
        u('text', '結論')
      ])
    ],
  ];

  return (tree) => findAndReplace(tree, replacements);
}


export default RemarkColorWord
