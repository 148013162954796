import { Cookies } from 'utils'


export const types = { COLOUR_THEME_SET: 'css/COLOUR_THEME_SET' }

const colourTheme = Cookies.get("colour_theme");
const initialState = { colour_theme: colourTheme ? colourTheme : 'dark' };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COLOUR_THEME_SET:
      Cookies.set(
        'colour_theme',
        action.colour_theme,
        {
          path: '/',
          maxAge: 60 * 60 * 24 * 31 * 12,
          sameSite: 'Lax',
          secure: true,
        }
      );

      return { ...state, colour_theme: action.colour_theme };

    default:
      return state;
  }
}

export const actions = {
  setColourThem: function(oldTheme, newTheme) {
    return {
      type: types.COLOUR_THEME_SET, colour_theme: newTheme
    };
  },
}
