import { DateTimeHandler } from 'utils'


export const types = {
  QUESTIONS_GET_REQUEST: 'singleAiReport/QUESTIONS_GET_REQUEST',
  QUESTIONS_GET_SUCCESS: 'singleAiReport/QUESTIONS_GET_SUCCESS',
  QUESTIONS_GET_ERROR: 'singleAiReport/QUESTIONS_GET_ERROR',

  QUESTIONS_SET: 'singleAiReport/QUESTIONS_SET',
  // get a reply to a specific question
  QUESTION_SET: 'singleAiReport/QUESTION_SET',
  // ANSWERING_STOP: 'singleAiReport/ANSWERING_STOP',
  DAY_TYPE_SET: 'singleAiReport/DAY_TYPE_SET',

  ANSWER_GET_REQUEST: 'singleAiReport/ANSWER_GET_REQUEST',
  ANSWER_GET_SUCCESS: 'singleAiReport/ANSWER_GET_SUCCESS',
  ANSWER_GET_ERROR: 'singleAiReport/ANSWER_GET_ERROR',

  SOURCE_GET_REQUEST: 'singleAiReport/SOURCE_GET_REQUEST',
  SOURCE_GET_SUCCESS: 'singleAiReport/SOURCE_GET_SUCCESS',
  SOURCE_GET_ERROR: 'singleAiReport/SOURCE_GET_ERROR',
  SOURCE_SET: 'singleAiReport/SOURCE_SET',
  SOURCE_RESET: 'singleAiReport/SOURCE_RESET',

  RESET: 'singleAiReport/RESET',
}

const KEYS = [ 'display', 'question', 'highchart' ];
const initialState = {
  day_type: {},
  loading: {},
  qa: {},
  questions: {},
  source: null,
};
const getDays = (days, dayType) => {
  const numDay = Number(days);

  switch (dayType) {
    case 'day': return numDay <= 1;

    case 'week': return numDay >= 4 && numDay <= 7;

    case 'month': return numDay >= 20 && numDay <= 30;

    default: return false;
  }
};
const getDayData = (dataUnfiltered, dayType) => {
  let result = null;

  const dayData = dataUnfiltered.filter((item) => {
    const result = getDays(item.days, dayType);

    return result;
  });

  if (dayData.length > 0) {
    const { answer, source } = dayData[0];

    result = { ...dayData[0], text: { answer: answer, source: source } };
  } else {
    result = { ...dataUnfiltered[0], text: { answer: '尚無訊息', source: null } };
  }

  return result;
};
const getAnswer = (data, questions, dayType = null) => {
  const { account, sub_account } = data;

  let answer = {
    account: account,
    sub_account: sub_account,
    prompt: sub_account,
  };
  let dayTypeUpdated = dayType;

  const questionfiltered = questions
    .filter((item) => (item.account === account));

  if (questionfiltered.length > 0) {
    const subfiltered = questionfiltered[0].sub_account
      .filter((item) => (item.label === sub_account));

    if (subfiltered.length > 0 && subfiltered[0].data) {
      const subData = subfiltered[0].data;
      const lastest = Math.min(...subData.map((item) => (item.days)));

      if (!dayTypeUpdated) {
        dayTypeUpdated = DateTimeHandler.getDayType(lastest);
      }

      const dayData = getDayData(subData, dayTypeUpdated);

      if (dayData) { answer = { ...answer, ...dayData }; }
    }
  }

  return { answer: answer, day_type: dayTypeUpdated };
}

export default function reducer(state = initialState, action = {}) {
  let loadingUpdated = { ...state.loading };
  let questionsUpdated = { ...state.questions };
  let qaUpdated = { ...state.qa };
  let dayTypeUpdated = { ...state.day_type };

  switch (action.type) {
    case types.QUESTIONS_GET_REQUEST:
    case types.ANSWER_GET_REQUEST:
    case types.SOURCE_GET_REQUEST:
      loadingUpdated[action.subcategory] = true;

      return { ...state, loading: loadingUpdated };

    case types.QUESTIONS_GET_SUCCESS:
      loadingUpdated[action.subcategory] = false;

      const dataUpdated = action.data.questions
        ?
        [ ...action.data.questions ].map((itemOutter, idxOutter) => ({
          order: idxOutter,
          account: itemOutter.label,
          sub_account: itemOutter.children.map((itemInner, idxInner) => ({
            ...itemInner, order: idxInner,
          })),
        }))
        :
        [];

      questionsUpdated[action.subcategory] = dataUpdated;

      if (dataUpdated.length > 0) {
        for (let i = 0; i < dataUpdated.length; i++) {
          if (dataUpdated[i].sub_account[0]) {
            qaUpdated[action.subcategory] = {
              order: 0,
              prompt: dataUpdated[i].sub_account[0].label,
              account: dataUpdated[i].account,
              sub_account: dataUpdated[i].sub_account[0].label,
              isDefault: true,
            };

            break;
          }
        }
      }

      return {
        ...state,
        loading: loadingUpdated,
        questions: questionsUpdated,
        qa: qaUpdated,
      };

    case types.QUESTIONS_GET_ERROR:
    case types.SOURCE_GET_ERROR:
      loadingUpdated[action.subcategory] = false;

      return { ...state, loading: loadingUpdated };

    case types.QUESTIONS_SET:
      questionsUpdated[action.subcategory] = action.data;

      for (let i = 0; i < action.data.length; i++) {
        if (!action.data[i].sub_account[0]
            || action.data[i].sub_account[0].data.length === 0) { continue; }

        const arrData = action.data[i].sub_account[0].data
          .sort((a, b) => (a.days - b.days));
        const firstDayData = arrData[0];
        const dayTypeFirstDay = DateTimeHandler.getDayType(firstDayData.days);

        qaUpdated[action.subcategory] = {
          ...firstDayData,
          account: action.data[i].account,
          sub_account: firstDayData.question,
          prompt: firstDayData.question,
          text: { answer: firstDayData.answer, source: firstDayData.source },
          isDefault: true,
        };

        if (dayTypeFirstDay !== dayTypeUpdated[action.subcategory]) {
          dayTypeUpdated[action.subcategory] = dayTypeFirstDay;
        }

        break;
      }

      return {
        ...state,
        questions: questionsUpdated,
        qa: qaUpdated,
        day_type: dayTypeUpdated,
      };

    case types.QUESTION_SET:
      const { answer, day_type } = getAnswer(
        action.data,
        questionsUpdated[action.subcategory]
      );

      qaUpdated[action.subcategory] = answer;

      const questionSetStateUpdated = { ...state, qa: qaUpdated };

      if (day_type !== dayTypeUpdated[action.subcategory]) {
        dayTypeUpdated[action.subcategory] = day_type;
        questionSetStateUpdated.day_type = dayTypeUpdated;
      }

      return questionSetStateUpdated;

    case types.DAY_TYPE_SET:
      dayTypeUpdated[action.subcategory] = action.day_type;
      qaUpdated[action.subcategory] = getAnswer(
        qaUpdated[action.subcategory],
        questionsUpdated[action.subcategory],
        dayTypeUpdated[action.subcategory]
      ).answer;

      return { ...state, qa: qaUpdated, day_type: dayTypeUpdated };

    // case types.ANSWERING_STOP:
    //   loadingUpdated[action.qa_type] = false;
    //
    //   return { ...state, qa: {}, loading: loadingUpdated };

    case types.ANSWER_GET_SUCCESS:
    case types.ANSWER_GET_ERROR:
    case types.RESET:
      const { data, question, subcategory } = action;

      let text = '暫無訊息';

      if (data) {
        const arr = data.split('\n\n');

        text = '找不到相關訊息';

        if (!data.includes('找不到相關訊息')) {
          text = {
            conclusion: [],
            display: [],
            highchart: {},
            question: [],
          };

          for (let i = 0; i < arr.length; i++) {
            let temp = arr[i];

            for (let j = 0; j < KEYS.length; j++) {
              const key = KEYS[j];

              if (!temp.includes(key)) { continue; }

              temp = temp.replaceAll('```', '');

              let idxStart = temp.indexOf('[');
              let idxEnd = temp.indexOf(']');

              if (temp.includes('highchart')) {
                idxStart = temp.indexOf('highchart') + 9;
                idxEnd = temp.length - 1;
                let cleanHighchartText = temp.substr(idxStart, idxEnd);

                if(cleanHighchartText.includes('highchart')){
                  idxStart = idxStart +cleanHighchartText.indexOf('highchart') + 9;
                }
              }
              temp = temp.substr(idxStart, idxEnd);
              temp = JSON.parse(temp);
              text[key] = temp;
            }
          }

          text.conclusion = arr.filter((item) => {
            let isConclusion = true;

            for (let i = 0; i < KEYS.length; i++) {
              isConclusion = !item.includes(KEYS[i]);

              if (!isConclusion) { return isConclusion; }
            }

            return isConclusion;
          });
        }
      }

      if (loadingUpdated[subcategory]) {
        qaUpdated[subcategory] = {
          ...qaUpdated[subcategory],
          prompt: question,
          text: text,
        };
      } else {
        qaUpdated = {};
      }

      loadingUpdated[subcategory] = false;

      return {
        ...state,
        loading: loadingUpdated,
        questions: questionsUpdated,
        qa: qaUpdated,
      };

    case types.SOURCE_GET_SUCCESS:
    case types.SOURCE_SET:
      return {
        ...state,
        source: (action.data && action.data.length > 0) ? action.data : '無內文',
      };

    case types.SOURCE_RESET:
      return { ...state, source: null };

    default:
      return state;
  }
}

export const actions = {
  getQuestionsRequest: function (subcategory) {
    return { type: types.QUESTIONS_GET_REQUEST, subcategory: subcategory };
  },
  getQuestionsSuccess: function(data, subcategory, question = null) {
    return {
      type: types.QUESTIONS_GET_SUCCESS,
      data: data,
      subcategory: subcategory,
    };
  },
  getQuestionsError: function(subcategory) {
    return { type: types.QUESTIONS_GET_ERROR, subcategory: subcategory };
  },

  setQuestions: function (data, subcategory) {
    return {
      type: types.QUESTIONS_SET,
      data: data,
      subcategory: subcategory,
    };
  },
  setQuestion: function (data, subcategory) {
    return {
      type: types.QUESTION_SET,
      data: data,
      subcategory: subcategory,
    };
  },
  setDayType: function (dayType, subcategory) {
    return {
      type: types.DAY_TYPE_SET,
      day_type: dayType,
      subcategory: subcategory,
    };
  },

  // stopAnswering: function (category) {
  //   return { type: types.ANSWERING_STOP };
  // },

  getAnswerRequest: function (data, subcategory) {
    return {
      type: types.ANSWER_GET_REQUEST,
      data: data,
      subcategory: subcategory,
    };
  },
  getAnswerSuccess: function(data, question, subcategory) {
    return {
      type: types.ANSWER_GET_SUCCESS,
      data: data,
      question: question,
      subcategory: subcategory,
    };
  },
  getAnswerError: function(subcategory) {
    return { type: types.ANSWER_GET_ERROR, subcategory: subcategory };
  },

  getSourceRequest: function (data) {
    return { type: types.SOURCE_GET_REQUEST, data: data };
  },
  getSourceSuccess: function(data) {
    return { type: types.SOURCE_GET_SUCCESS, data: data };
  },
  getSourceError: function() { return { type: types.SOURCE_GET_ERROR }; },
  setSource: function(data) { return { type: types.SOURCE_SET, data: data }; },
  resetSource: function() { return { type: types.SOURCE_RESET }; },

  reset: function(subcategory) {
    return { type: types.RESET, subcategory: subcategory };
  },
}
