import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/SingleCustomSettings'
import { SingleCustomSettingsAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


function responseHandler(response) {
    return response.data;
}

function * watchSingleCustomSettingsLoadRequest() {
    yield takeEvery(types.LOAD_REQUEST, loadSingleCustomSettingsFlow);
}

function * loadSingleCustomSettingsFlow(action) {
    try {
        const response = yield call(SingleCustomSettingsAPIs.SingleCustomSettingsLoadAPI, action.api_name),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadSingleCustomSettingsCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadSingleCustomSettingsFailed(responseData.error));
        }
    }
    catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loadSingleCustomSettingsFailed(error));
    }
}

function * watchSingleCustomSettingsUpdateRequest() {
    yield takeLatest(types.UPDATE_REQUEST, updateSingleCustomSettingsFlow);
}

function * updateSingleCustomSettingsFlow(action) {
    try {
        const response = yield call(SingleCustomSettingsAPIs.SingleCustomSettingsUpdateAPI, action.api_name, action.data, action.stock_code),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.updateSingleCustomSettingsCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.updateSingleCustomSettingsFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.updateSingleCustomSettingsFailed(error));
    }
}


export default [
    watchSingleCustomSettingsLoadRequest(),
    watchSingleCustomSettingsUpdateRequest()
]
