import axios from 'axios'


const AnswerGetAPI = (question, date, providers) => axios.post(
  `${process.env.REACT_APP_AI_HOST}/api/ai/chat`,
  {q:question, date, providers, flush:true},
  { headers: { Accept: 'application/json' } }
)


export default { AnswerGetAPI }
