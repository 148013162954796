import React from 'react'
import { isEqual as _isEqual } from 'lodash'
import { MenuParents } from './MenuParents'


export class Menu extends React.PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const {
      menu,
      collection_labs,
      free_labs,
			my_labs,
			portfolio_labs,
      labsLoaded,
      labEditProcess,
      authenticated,
      onLabListLoadRequest,
      onSideMenuSet,
    } = this.props;

    if (authenticated && prevProps.labsLoaded && !labsLoaded) {
      onLabListLoadRequest();
    }

    if ((!_isEqual(prevProps.free_labs, free_labs)
        || !_isEqual(prevProps.collection_labs, collection_labs)
        || !_isEqual(prevProps.my_labs, my_labs)
        || !_isEqual(prevProps.portfolio_labs, portfolio_labs)) && authenticated) {
      const menuUpdated = menu.map((parent) => {
        const parentUpdated = { ...parent };

        switch (parent.section) {
          case 'freebie':
    				parentUpdated.items = [ ...free_labs ];

            break;

          case 'my-collection':
            parentUpdated.items = [ ...collection_labs ];
            parentUpdated.uncollapse = true;

            break;

          case 'my-lab':
            parentUpdated.items = [ ...my_labs ];
            parentUpdated.uncollapse = true;

            break;

          case 'portfolio':
            parentUpdated.items = [ ...portfolio_labs ];

            break;

          default:

        }

  			return parentUpdated;
  		});

      onSideMenuSet(menuUpdated);
    }

    if (prevProps.labEditProcess !== labEditProcess
        && labEditProcess === 'success') { onLabListLoadRequest(); }
  }

  render() {
    const { menu, labsLoaded, authenticated } = this.props;

    return (
      <nav id='side-menu-nav' className='side-menu-nav w-100'>
        <div className='menu-list padding-0'>
          {(!authenticated || labsLoaded)
            ?
            menu.map((parent, idx) => (
              <MenuParents idxParent={idx + 1} parent={parent} key={idx} />
            ))
            :
            null}
        </div>
      </nav>
    );
  }
}
