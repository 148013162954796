import React from 'react'
import Dropzone from 'react-dropzone'
import LazyLoad from 'react-lazyload'


export default class DropzoneContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { rejected: false };
  }

  onDrop = (acceptedFiles) => {
    acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));

    this.props.onDropGet(acceptedFiles);
  }

  onDropRejected = (files, e) => {
    this.setState({ rejected: true });
  }

  onRemove = (e) => {
    e.stopPropagation();

    this.setState({ rejected: false });
  }

  onDropGet = (e) => {
    this.props.onDropGet([]);
  }

  render() {
    const rejected = this.state.rejected,
      { files, className } = this.props,
      txt = '圖片大小請不要超過 1MB';

    return (
      <div className={`${className || ''} dropzone-container`} onClick={rejected ? this.onRemove : null}>
        {(files && files.length)
          ?
          <div className="preview">
            <LazyLoad height="100%" once={true}>
              <img
                className='preview-img'
                src={files[0].preview}
                alt='uploaded-img'
              />
            </LazyLoad>
            {/* remove dropped img */}
            <i className="far fa-times-circle remove-files" aria-hidden="true" onClick={this.onDropGet}/>
          </div>
          :
          rejected
            ?
            <h1 className="text-center">{txt}</h1>
            :
            <Dropzone
              accept         = {[ 'image/jpeg', 'image/png' ]}
              maxSize        = {1000000}
              multiple       = {false}
              onDrop         = {this.onDrop}
              onDropRejected = {this.onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone text-center" { ...getRootProps() }>
                  <input { ...getInputProps() }/>

                  <h1>點擊選取檔案</h1>

                  <h4>(電腦環境下，可直接拖曳圖片上傳)</h4>

                  <span className="display-block">
                    <i className="txt-red elegant elegant-eleganticons-36"/>

                    <h4 className="display-inline-block">{txt}</h4>
                  </span>
                </div>
              )}
            </Dropzone>}
      </div>
    );
  }
}
