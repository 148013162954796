import { call, put, takeLatest } from 'redux-saga/effects'
import { SingleAiPromptAPIs } from 'api'
import { types, actions } from '../modules/SingleAiPrompt'


const { AnswerGetAPI } = SingleAiPromptAPIs;

function* watchAiPromptQuestionHandleRequest() {
  yield takeLatest(types.QUESTION_HANDLE_REQUEST, handleAiPromptQuestionFlow);
}

function* handleAiPromptQuestionFlow(action) {
  const { data_type, data } = action;

  try {
    const response = yield call(AnswerGetAPI, data);
    const responseData = response.data;

    if (responseData.status === 'OK') {
      yield put(actions.handleQuestionSuccess(data_type, responseData.data));
    } else {
      console.log(
        'handleAiPromptQuestionFlow response error: ',
        responseData.error ?? 'ERROE'
      );

      yield put(actions.handleQuestionError(data_type, responseData.error));
    }
  } catch (err) {
    console.log('handleAiPromptQuestionFlow catch error: ', err);

    yield put(actions.handleQuestionError(data_type, err));
  }
}


export default [ watchAiPromptQuestionHandleRequest() ]
