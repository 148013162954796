import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'


const SortableSingleContainer = SortableContainer(({ children }) => (
  <div>{children}</div>
));


export { SortableSingleContainer }
