import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions } from 'appRedux/modules/Layout'
import { actions as actionsAuth } from 'appRedux/modules/Auth'
import { actions as actionsProduct } from 'appRedux/modules/Product'
import { actions as actionsSideMenu } from 'appRedux/modules/SideMenu'
import { Layout as component } from './Layout'


const mapStateToProps = (state, ownProps) => ({
    ...state.Layout,
    authenticated: state.Auth.authenticated,
    colour_theme: state.ColourTheme.colour_theme,
		products_loaded: state.Product.loaded,
    side_menu_hidden: state.SideMenu.side_menu_hidden,
  });
const mapDispatchToProps = (dispatch, ownProps) => ({
  onRedirectPathSet        : (path) => dispatch(actionsAuth.setRedirectPath(path)),
  onWindowSizeSet 	       : (width, height) =>
      dispatch(actions.setWindowSize(width, height)),
  onIsMobileSet  	         : (isMobile) => dispatch(actions.setMobile(isMobile)),
  onProductListLoadRequest : () => dispatch(actionsProduct.loadListRequest()),
  onSideMenuHide           : (sideMenuHidden) =>
      dispatch(actionsSideMenu.hideSideMenu(sideMenuHidden))
});
const Layout = withRouter(connect(mapStateToProps, mapDispatchToProps)(component));


export { Layout }
