import { isAuthenticated, isNotAuthenticated, isAuthenticatedShow } from './AuthRedirect'
import { actions } from 'appRedux/modules/Auth'
import { connect } from 'react-redux'


const mapStateToProps = state => ({
		accessToken      : state.Auth.access_token,
		authenticated  : state.Auth.authenticated,

		isVerified       : state.Auth.verified,
		redirectPath     : state.Auth.redirect_path,
		setPassword      : state.Auth.set_password
	}),
	mapStateToProps2    = state => ({
		authenticated	 : state.Auth.authenticated,

		isVerified			 : state.Auth.verified
	}),
	mapDispatchToProps  = (dispatch) => ({ onRedirectPathSet: (path) => dispatch(actions.setRedirectPath(path)) }),
	isAuth              = (componenet) => (connect(mapStateToProps, mapDispatchToProps)(isAuthenticated(componenet))),
	isNotAuth           = (componenet) => (connect(mapStateToProps)(isNotAuthenticated(componenet))),
	isAuthShow          = (componenet) => (connect(mapStateToProps)(isAuthenticatedShow(componenet))),
	AuthProps           = (componenet) => (connect(mapStateToProps2)(componenet));


export default {
	isAuth,
	isNotAuth,
	isAuthShow,
	AuthProps
}
