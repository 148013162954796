import { connect } from 'react-redux'
import { actions as actionsForm } from 'appRedux/modules/Form'
import { actions as actionsValidator } from 'appRedux/modules/Validator'


const mapStateToProps = (state, ownProps) => ({
		formData: (ownProps.formID in state.Form) ? state.Form[ownProps.formID].form_data : {},
		formError: (ownProps.formID in state.Validator) ? state.Validator[ownProps.formID].form_error : true,
	}),
	mapDispatchToProps  = (dispatch, ownProps) => ({
		onFormDataChange : (key, value) => dispatch(actionsForm.set(ownProps.formID, key, value)),
 		onFormClear			 : () => {
 			dispatch(actionsForm.clear(ownProps.formID));
 			dispatch(actionsValidator.clear(ownProps.formID));
 		},
 		onFormError			 : () => dispatch(actionsValidator.show(ownProps.formID)),
	}),
	ConnectForm 				= (container) => connect(mapStateToProps, mapDispatchToProps)(container)


export default ConnectForm
