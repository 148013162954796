export const types = {
    SET: 'singleInputChart/SET',
    RESET: 'singleInputChart/RESET'
}

const initialState = {
    input_props: {},
    is_initial : {}
}

export default function reducer(state = initialState, action = {}) {
    const inputs = { ...state.input_props },
        isInitial = { ...state.is_initial }

    switch (action.type) {
        case types.SET :
            const { stock_code, data } = action;

            isInitial[stock_code] = inputs[stock_code] === undefined;
            inputs[stock_code] = data;

            return {
                ...state,
                input_props: inputs,
                is_initial: isInitial
            };

         case types.RESET :
           const stockCode = action.stock_code;

           inputs[stockCode] = {};
           isInitial[stockCode] = true;

           return {
             ...state,
             inputs: inputs,
             is_initial: isInitial
           };

        default:
            return state;
    }
}

export const actions = {
    set: function(stockCode, data) {
        return {
          type       : types.SET,
          stock_code : stockCode,
          data       : data
        };
    },
    reset: function(id) {
        return { type: types.RESET };
    }
}
