export const types = {
  CHECK               : 'portfolio/CHECK',
  CHANGE              : 'portfolio/CHANGE',

  LOAD_REQUEST        : 'portfolio/LOAD_REQUEST',
  LOAD_COMPLETED      : 'portfolio/LOAD_COMPLETED',
  LOAD_FAILED         : 'portfolio/LOAD_FAILED',

  CREATE_REQUEST      : 'portfolio/CREATE_REQUEST',
  CREATE_COMPLETED    : 'portfolio/CREATE_COMPLETED',
  CREATE_FAILED       : 'portfolio/CREATE_FAILED',

  UPDATE_REQUEST      : 'portfolio/UPDATE_REQUEST',
  UPDATE_COMPLETED    : 'portfolio/UPDATE_COMPLETED',
  UPDATE_FAILED       : 'portfolio/UPDATE_FAILED',

  DELETE_REQUEST      : 'portfolio/DELETE_REQUEST',
  DELETE_COMPLETED    : 'portfolio/DELETE_COMPLETED',
  DELETE_FAILED       : 'portfolio/DELETE_FAILED',

  GET_STOCK_REQUEST   : 'portfolio/GET_STOCK_REQUEST',
  GET_STOCK_COMPLETED : 'portfolio/GET_STOCK_COMPLETED',
  GET_STOCK_FAILED    : 'portfolio/GET_STOCK_FAILED',
}

const initialState = {
  show : false,
  stock_error : false,
  same_stock : false,
  stock_data_array: {},
  content : [],
  show_items : [{id: 0, show: false}],
  new_obj : null,
  icon_show : true,
  stock_info : [],
  stock : {stock_code:'1101', stock_name:'台泥'}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
    case types.LOAD_FAILED:
    case types.CREATE_REQUEST:
    case types.CREATE_FAILED:
    case types.UPDATE_REQUEST:
    case types.UPDATE_FAILED:
    case types.DELETE_REQUEST:
    case types.DELETE_FAILED:
    case types.GET_STOCK_REQUEST:
    case types.GET_STOCK_FAILED:
      return { ...state };

    case types.LOAD_COMPLETED :
      let item = [ { id: 0, show: false } ];

      action.data.map((data) => (item.push({ id: data.id, show: false })));

      return { ...state, content: [ ...action.data ], show_items: item };

    case types.CREATE_COMPLETED:
    case types.UPDATE_COMPLETED:
    case types.DELETE_COMPLETED:
      return { ...state, new_obj: action.data };

    case types.GET_STOCK_COMPLETED:
      return { ...state, stock_info: action.data };

    case types.CHECK :
      let check = false;

      if (state.content.filter((item) => (item.stock_code.indexOf(action.stock.stock_code) > -1)).length) {
        check = true;
      }

      return { ...state, stock_error: action.checkStock, same_stock: check, stock: { ...action.stock }};

    case types.CHANGE :
      let result = [ ...state.show_items ];

      for (let key in result) {
        if (result[key].id === action.id) {
          result[key].show = !result[key].show;
        }
      }

      return { ...state, show_items: result };

    default:
      return state;
  }
}

export const actions = {
  checkHandler: function(stock, checkStock) {
    return { type: types.CHECK, checkStock: checkStock, stock: stock };
  },
  changeHandler: function(id) {
    return { type: types.CHANGE, id: id };
  },

  loadRequest: function(id) {
    return { type: types.LOAD_REQUEST, id: id };
  },
  loadCompleted: function(data) {
    return { type: types.LOAD_COMPLETED, data: data };
  },
  loadFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },

  createRequest: function(new_data) {
    return { type: types.CREATE_REQUEST, new_data: new_data };
  },
  createCompleted: function(data) {
    return { type: types.CREATE_COMPLETED, data: data};
  },
  createFailed: function(error) {
    return { type: types.CREATE_FAILED, error: error };
  },

  updateRequest: function(labID, updated_data) {
    return { type: types.UPDATE_REQUEST, labID: labID, updated_data: updated_data };
  },
  updateCompleted: function(data) {
    return { type: types.UPDATE_COMPLETED, data: data };
  },
  updateFailed: function(error) {
    return { type: types.UPDATE_FAILED, error: error };
  },

  deleteRequest: function(id) {
    return { type: types.DELETE_REQUEST, id: id };
  },
  deleteCompleted: function(data) {
    return { type: types.DELETE_COMPLETED, data: data };
  },
  deleteFailed: function(error) {
    return { type: types.DELETE_FAILED, error: error };
  },

  getStockRequest: function(stocks) {
    return { type: types.GET_STOCK_REQUEST, stocks: stocks };
  },
  getStockCompleted: function(data) {
    return { type: types.GET_STOCK_COMPLETED, data: data };
  },
  getStockFailed: function(error) {
    return { type: types.GET_STOCK_FAILED, error: error };
  },
}
