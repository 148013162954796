import api from './api'
import app from './app'
import apple from './apple'
import currency from './currency'
import dashboard from './dashboard'
import facebook from './facebook'
import google from './google'
import lab from './lab'
import product from './product'
import stock from './stock'


export default {
	api,
	app,
	apple,
	currency,
	dashboard,
	facebook,
	google,
	lab,
	product,
	stock,
}
