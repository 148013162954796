import React from 'react'
import 'assets/sass/utils/tooltip.scss'

export class Tooltip extends React.PureComponent {
  onVisibility = (e) => {
    e.stopPropagation();

    this.props.onTooltipsToggle(e.currentTarget.id);
  }

  componentDidMount() {
    const { id, onTooltipsHide } = this.props;

    onTooltipsHide(id);
  }

  render() {
    const {
      id,
      containerClass,
      className,
      icon,
      text,
      content,
      tooltips,
    } = this.props;

    return (
      <div className={`${containerClass || ''} display-inline-block padding-left-5`}>
        <div
          id={id}
          className={`${className || ''} ua-tooltip-trigger cursor-pointer`}
          href={`#${id}`}
          onClick={this.onVisibility}
        >
          {tooltips[id] && (
            <div
              className='tooltip-backdrop ua-backdrop'
              onClick={this.onVisibility}
            />
          )}

          <i className={`elegant elegant-eleganticons-${icon || '190'}`}/>

          {text && text}
        </div>

        {tooltips[id] ? content : null}
      </div>
    );
  }
}
