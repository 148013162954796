import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const LoginAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/auth/token`,
  data,
  { headers: { Accept: 'application/json' } }
);
const AppleLoginAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/auth/apple`,
  data,
  { headers: { Accept: 'application/json' } }
);
const FacebookLoginAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/auth/facebook`,
  data,
  { headers: { Accept: 'application/json' } }
);
const GoogleLoginAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/auth/google`,
  data,
  { headers: { Accept: 'application/json' } }
);
const RegisterAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/register`,
  data,
  { headers: { Accept: 'application/json' } }
);
const SendVerifiedEmailAPI = () => axios.post(
  `${config.api.APP_API_DOMAIN}/auth/email`,
  {},
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const RefreshTokenAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/auth/token/refresh`,
  data,
  { headers: { Accept: 'application/json' } }
);


export default {
	LoginAPI,
  AppleLoginAPI,
	FacebookLoginAPI,
	GoogleLoginAPI,
	RegisterAPI,
	SendVerifiedEmailAPI,
	RefreshTokenAPI,
}
