import { googleLogout } from '@react-oauth/google'
import { Cookies } from 'utils'


export const types = {
  LOGIN_REQUEST               : 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS               : 'auth/LOGIN_SUCCESS',
  LOGIN_ERROR                 : 'auth/LOGIN_ERROR',

  APPLE_LOGIN_REQUEST         : 'auth/APPLE_LOGIN_REQUEST',
  FACEBOOK_LOGIN_REQUEST      : 'auth/FACEBOOK_LOGIN_REQUEST',
  GOOGLE_LOGIN_REQUEST        : 'auth/GOOGLE_LOGIN_REQUEST',

  REFRESH_TOKEN_REQUEST       : 'auth/REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS       : 'auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR         : 'auth/REFRESH_TOKEN_ERROR',

  REGISTER_REQUEST            : 'auth/REGISTER_REQUEST',
  REGISTER_SUCCESS            : 'auth/REGISTER_SUCCESS',
  REGISTER_ERROR              : 'auth/REGISTER_ERROR',

  VERIFIED_EMAIL_SEND_REQUEST : 'auth/VERIFIED_EMAIL_SEND_REQUEST',
  VERIFIED_EMAIL_SEND_SUCCESS : 'auth/VERIFIED_EMAIL_SEND_SUCCESS',
  VERIFIED_EMAIL_SEND_ERROR   : 'auth/VERIFIED_EMAIL_SEND_ERROR',

  LOGOUT                      : 'auth/LOGOUT',

  REREDIRECT_PATH_SET         : 'auth/REREDIRECT_PATH_SET',

  PASSWORD_SET_REQUEST        : 'auth/PASSWORD_SET_REQUEST',
  PASSWORD_SET_SUCCESS        : 'auth/PASSWORD_SET_SUCCESS',
  PASSWORD_SET_ERROR          : 'auth/PASSWORD_SET_ERROR',
}

const initialState = {
  authenticated: false,
  authenticating: false,
  access_token     : null,
  expires_in       : null,
  redirect_path    : Cookies.get('redirect_path'),
  refresh_token    : null,
  set_password     : false,
  token_type       : null,
  password_set     : false,
  password_setting : false,
  verified         : false,
};
const COOKIES = [
  'access_token',
  'expires_in',
  'refresh_token',
  'set_password',
  'token_type',
];
const setAuthCookies = (data) => {
  for (let i = 0; i < COOKIES.length; i++) {
    const key = COOKIES[i];

    Cookies.set(
      key,
      data[key],
      { path: '/', maxAge: 60 * 60 * 24 * 31, sameSite: 'Lax', secure: true }
    );
  }
};
const removeAuthCookies = (data) => {
  for (let i = 0; i < COOKIES.length; i++) {
    const key = COOKIES[i];

    Cookies.remove(key, { path: '/' });
  }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
    case types.REGISTER_REQUEST:
      return { ...state, authenticated: true };

    case types.LOGIN_SUCCESS:
      setAuthCookies(action.data);

      return {
        ...state,
        ...action.data,
        set_password: action.data.set_password === 1,
        authenticated: true,
        authenticating: false,
      };

    case types.LOGIN_ERROR:
      return { ...initialState };

    case types.APPLE_LOGIN_REQUEST:
    case types.FACEBOOK_LOGIN_REQUEST:
    case types.GOOGLE_LOGIN_REQUEST:
      return { ...initialState, authenticated: true };

    case types.REGISTER_SUCCESS:
      setAuthCookies(action.data);

      return {
        ...state,
        ...action.data,
        set_password: action.data.set_password === 1,
        authenticated: false,
        authenticating: false,
      };

    case types.REGISTER_ERROR:
      return { ...state, result: action.error };

    case types.VERIFIED_EMAIL_SEND_REQUEST:
    case types.VERIFIED_EMAIL_SEND_SUCCESS:
    case types.VERIFIED_EMAIL_SEND_ERROR:
    case types.REFRESH_TOKEN_REQUEST:
    case types.REFRESH_TOKEN_ERROR:
    case types.PASSWORD_SET_ERROR:
      return {
        ...state,
        authenticating: action.type === types.REFRESH_TOKEN_REQUEST,
      };

    case types.REFRESH_TOKEN_SUCCESS:
      setAuthCookies(action.data);

      return {
        ...state,
        ...action.data,
        authenticated: true,
        authenticating: false,
      };

    case types.LOGOUT:
      googleLogout();

      removeAuthCookies();

      return { ...initialState };

    case types.REREDIRECT_PATH_SET:
      Cookies.set(
        'redirect_path',
        action.path,
        { path: '/', maxAge:60 * 60 * 24 * 1, sameSite: 'Lax', secure: true }
      );

      return { ...state, redirect_path: action.path };

    case types.PASSWORD_SET_REQUEST:
      return { ...state, password_setting: true };

    case types.PASSWORD_SET_SUCCESS:
      return {
        ...state,
        set_password: action.data.set === 1,
        password_set: true,
        password_setting: false,
      };

    default:
      const stateUpdated = { ...state };

      for (let i = 0; i < COOKIES.length; i++) {
        const key = COOKIES[i];

        stateUpdated[key] = Cookies.get(key);
      }

      stateUpdated.authenticated = typeof stateUpdated.access_token !== 'undefined';
      stateUpdated.set_password = stateUpdated.set_password === '1';
      stateUpdated.redirect_path = Cookies.get('redirect_path');

      return stateUpdated;
  }
}

export const actions = {
    loginRequest: function(data) {
        return { type: types.LOGIN_REQUEST, data: data };
    },
    loginSuccess: function(data) {
        return { type: types.LOGIN_SUCCESS, data: data };
    },
    loginError: function(error) {
        return { type: types.LOGIN_ERROR, error: error };
    },

    appleLoginRequest: function(data) {
        return { type: types.APPLE_LOGIN_REQUEST, data: data };
    },
    facebookLoginRequest: function(data) {
        return { type: types.FACEBOOK_LOGIN_REQUEST, data: data };
    },
    googleLoginRequest: function(data) {
        return { type: types.GOOGLE_LOGIN_REQUEST, data: data };
    },

    registerRequest: function(data) {
        return { type: types.REGISTER_REQUEST, data: data };
    },
    registerSuccess: function(data) {
        return { type: types.REGISTER_SUCCESS, data: data };
    },
    registerError: function(error) {
        return { type: types.REGISTER_ERROR, error: error };
    },

    sendVerifiedEmail: function () {
        return { type: types.VERIFIED_EMAIL_SEND_REQUEST };
    },
    sendVerifiedEmailSuccess: function(data) {
        return { type: types.VERIFIED_EMAIL_SEND_SUCCESS, data: data };
    },
    sendVerifiedEmailError: function(error) {
        return { type: types.VERIFIED_EMAIL_SEND_ERROR, error: error };
    },

    refreshTokenRequest: function(data) {
        return { type: types.REFRESH_TOKEN_REQUEST, data: data };
    },
    refreshTokenSuccess: function(data) {
        return { type: types.REFRESH_TOKEN_SUCCESS, data: data };
    },
    refreshTokenError: function(error) {
        return { type: types.REFRESH_TOKEN_ERROR, error: error };
    },

    setRedirectPath: function(path) {
        return { type: types.REREDIRECT_PATH_SET, path: path };
    },

    logout: function () {
        return { type: types.LOGOUT };
    },

    setPasswordRequest: function(data, noAuth) {
        return { type: types.PASSWORD_SET_REQUEST, data: data };
    },
    setPasswordSuccess: function(data) {
        return { type: types.PASSWORD_SET_SUCCESS, data: data };
    },
    setPasswordError: function(error) {
        return { type: types.PASSWORD_SET_ERROR, error: error };
    }
}
