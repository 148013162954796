import React, { useEffect, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'


const SuggestionList = ({ suggestions, getItemProps, renderSuggestion, containerProps, highlightedSuggestion, searchType, ...props }) => {
  const selectedRef = useRef();
  const listRef = useRef();

  const Row = ({ index, style }) => {
    const suggestion = suggestions[index];
    const itemProps = getItemProps ? getItemProps({ sectionIndex:0, itemIndex:index }) : null;
    const isSelected = (highlightedSuggestion.stock_code && highlightedSuggestion.stock_code === suggestion.stock_code)
                        || highlightedSuggestion === suggestion;
    
    const onClick = (e)=>{
      if(itemProps){
        itemProps.onClick(e)
      }
    }

    return (
      <div
        onMouseDown={onClick}
        role="option"
        className={`react-autosuggest__suggestion${isSelected ? " react-autosuggest__suggestion--isSelected" : ""}${index===0 ? ' react-autosuggest__suggestion--first':''}`}
        style={{ ...style}}
        data-suggestion-index={index}
        data-section-index={0}
        id={`react-autowhatever-stock-container--item-${index}`}
        aria-selected={isSelected ? "true" : "false"}
        ref={isSelected ? selectedRef : null}
      >
        {renderSuggestion(suggestion, props)}
      </div>
    );
  };
  
  useEffect(()=>{
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }else{
      listRef.current.scrollToItem(0); 
    }
  },[selectedRef.current])

  return suggestions.length ? (
    <List
      ref={listRef}
      className="react-autosuggest__suggestions-list"
      role="listbox"
      height={200}
      itemCount={suggestions.length}
      itemSize={searchType === 'stock' ? 50 : 40 }
      style={{
        position:'fixed',
        left: 'auto'}}
    >
      {Row}
    </List>
  ):null;
};


export { SuggestionList }
