import React from 'react'
import { Link } from 'react-router-dom'
import defaultLabImage from 'assets/img/default/default-user.png'


export class UserInfo extends React.PureComponent {
  render() {
    const {
      is_mobile,
      userAvatar,
      user_info,
      notifications,
      path,
      onSideMenuHide
    } = this.props;
    const notice = notifications ? notifications.filter((item) => (
      item.status === 0
    )).length : 0;

    return (
      <div className='login-info'>
        <Link to='/profile' onClick={() => onSideMenuHide(is_mobile)}>
          <div
            className={`${!userAvatar ? 'opacity-80' : ''} frame
              display-inline-block ua-box-shadow img-circle`}
            style={{
              backgroundImage: `url(${userAvatar ? userAvatar : defaultLabImage})`,
            }}
          />

          <span className='user-info display-inline-block'>
            <h4 className={`user-nickname txt-dark-grey txt-upper-case
                ${(path === '/profile') ? 'active' : ''}`}>
              {(user_info && user_info.nickname) ? user_info.nickname : ''}
            </h4>

            <small>
              <i className='elegant elegant-eleganticons-75'/>

              <span className={(path === '/profile') ? 'active' : ''}>
                帳戶和訂閱
              </span>
            </small>

            {(notice > 0) ? <span className='user-notice text-center'>{notice}</span>
                : null}
          </span>
        </Link>
      </div>
    );
  }
}
