import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import ReactGA  from 'react-ga4'
// import TagManager from 'react-gtm-module'

import config from 'config'
import RootRoutes from './routes'
import { CSSHandler, DeviceHandler } from './utils'


class App extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		let pathname = this.props.location.pathname;

		if (prevProps.location.pathname !== pathname) {
			if (pathname.includes('//')) {
				pathname = pathname.replace('//', '');

				window.location.replace(pathname);
			}

			ReactGA.send({
				hitType : 'pageview',
				page 		: pathname,
				title 	: pathname,
			});

			ReactPixel.pageView();
		}
	}

	componentDidMount() {
		CSSHandler.OSHandler();
		DeviceHandler.inAppBrowserDetect();

		if (config.google.GOOGLE_GA_MEASUREMENT_ID) {
			ReactGA.initialize(config.google.GOOGLE_GA_MEASUREMENT_ID);
		}
		// TagManager.initialize({ gtmId: config.google.GOOGLE_GTM_ID });
		ReactPixel.init(config.facebook.FACEBOOK_PIXEL_ID);

		let pathname = this.props.location.pathname;

		if (pathname.includes('//')) {
			pathname = pathname.replace('//', '');

			window.location.replace(pathname);
		}

		ReactGA.send({
			hitType : 'pageview',
			page 		: pathname,
			title 	: 'Initialised',
		});
		ReactPixel.pageView();
	}

	render() {
		return <RootRoutes />;
	}
}


export default withRouter(App)
