import axios from 'axios'
import { Cookies } from 'utils'


const url = process.env.REACT_APP_API_DOMAIN;
const LabDataLoadAPI = (lab) => axios.get(
  `${url}/user/laboratories/${lab}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const FreeLabDataLoadAPI = (lab) => axios.get(
  `${url}/laboratories/${lab}`, { headers: { Accept: 'application/json' } }
);
const LabAffiliatedDataLoadAPI = (lab, affiliated) => axios.get(
  `${url}/user/laboratories/${lab}/affiliates/${affiliated}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);


export default { LabDataLoadAPI, LabAffiliatedDataLoadAPI, FreeLabDataLoadAPI }
