import React, { useState } from 'react';
import data from './data';
import func from './func';

const ShortcutKey = ({ id, lab }) => {
    const [ currHover, setCurrHover ] = useState(null);
    const shortcut = data[lab.pathname]?.find(i=>i.id === id)?.key;

    const handleMouseEnter = key => () => {
        setCurrHover(key);
    };

    const handleMouseLeave = () => {
        setCurrHover(null);
    };
    
    const shortcutMap = (i, key) => {
        const { modifierkey, primaryKey } = func.parseKeyCombination(i);
        const isCombo = modifierkey !== "F";

        return (
            <div 
                className={`shortcutKey-k--${func.getModifierkeyName(modifierkey)}`} 
                key={key}
                onMouseEnter={handleMouseEnter(key)}
                onMouseLeave={handleMouseLeave}
            >
                {i}
                {currHover === key && 
                    <div className="shortcutKey-hint">{isCombo && func.getModifierkeyName(modifierkey)}{isCombo && "+"}{primaryKey}</div>
                }
            </div>
        );
    };

    return (
        shortcut ? <div className="shortcutKey">{shortcut.map(shortcutMap)}</div> : null
    )
}

export default ShortcutKey;