import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/Validator'
import Validator from './Validator'


const mapStateToProps = (state, ownProps) => ({
		show_error: (ownProps.formID in state.Validator) ? state.Validator[ownProps.formID].show : false,
		init: (ownProps.formID in state.Validator) ? state.Validator[ownProps.formID].init : true
	}),
	mapDispatchToProps = (dispatch, ownProps) => ({
		onErrorSet	: (key, error) => dispatch(actions.set(ownProps.formID, key, error)),
		onErrorShow : () => dispatch(actions.show(ownProps.formID)),
		onRemove		: (key) => dispatch(actions.remove(ownProps.formID, key))
	})


export default connect(mapStateToProps, mapDispatchToProps)(Validator)
