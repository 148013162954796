export const types = {
  LOAD_REQUEST   : 'userNotifications/LOAD_REQUEST',
  LOAD_COMPLETED : 'userNotifications/LOAD_COMPLETED',
  LOAD_FAILED    : 'userNotifications/LOAD_FAILED',
  READ_REQUEST   : 'userNotifications/READ_REQUEST',
  READ_COMPLETED : 'userNotifications/READ_COMPLETED',
  READ_FAILED    : 'userNotifications/READ_FAILED'
}

const initialState = {
  user_notifications : [],
  new_notification   : false,
  loaded             : false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
    case types.LOAD_FAILED:
      return { ...initialState };

    case types.LOAD_COMPLETED:
      let notifications = [...action.data] || [],
          isNewNotification = false;

      for (let i = 0; i<notifications.length; i++) {
        if (notifications[i].status === 0) {
          isNewNotification = true;

          break;
        }
      }

      return { ...state, user_notifications: action.data, new_notification: isNewNotification, loaded: true };

    case types.READ_REQUEST:
      return state;
    case types.READ_COMPLETED:
      const user_notifications = [...state.user_notifications];
      const index = user_notifications.findIndex(d=>d.id===action.data.id);
      if(index !== -1) {
        user_notifications[index].status = 1;
      }
      return { ...state, user_notifications: user_notifications};

    case types.READ_FAILED:
      return state;

    default:
      return state;
  }
}

export const actions = {
  loadUserNotificationsRequest: function () {
    return { type: types.LOAD_REQUEST };
  },
  loadUserNotificationsCompleted: function(data) {
    return { type: types.LOAD_COMPLETED, data: data };
  },
  loadUserNotificationsFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },
  readUserNotificationsRequest: function(id) {
    return { type: types.READ_REQUEST, id: id };
  },
  readUserNotificationsCompleted: function(data) {
    return { type: types.READ_COMPLETED, data: data };
  },
  readUserNotificationsFailed: function(error) {
    return { type: types.READ_FAILED, error: error };
  }
}
