import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const FavGroupLoadAPI = () => axios.get(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    }
  }
);
const FavGroupNameEditAPI = (params) => axios.put(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups/${params.group_id}`,
  { group_name: params.group_name },
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const FavGroupDataSortAPI = (params) => axios.put(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups/sort`,
  { sorted_stocks: params.sorted_stocks },
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const FavGroupStockAddAPI = (params) => axios.post(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups/${params.group_id}`,
  { stock_code: params.stock_code },
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const FavGroupStockDeleteAPI = (params) => axios.delete(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups/${params.group_id}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
    data: { stock_code: params.stock_code },
  }
);
const FavGroupAllStocksDeleteAPI = (params) => axios.delete(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups_deleteAll/${params.group_id}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const FavGroupAddAPI = () => axios.post(
  `${config.api.APP_API_DOMAIN}/user/favorite_groups/add_group`,
  {},
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);


export default {
  FavGroupLoadAPI,
  FavGroupNameEditAPI,
  FavGroupDataSortAPI,
  FavGroupStockAddAPI,
  FavGroupStockDeleteAPI,
  FavGroupAllStocksDeleteAPI,
  FavGroupAddAPI
}
