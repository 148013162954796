const COLOUR = {
  arearange: {
    dark: [
      '#fff',
      '#a2cec1',
      '#468086',
      '#ded780',
      '#e48274',
      '#ef5350',
    ],
    light: [
      '#676464',
      '#a2cec1',
      '#468086',
      '#ded780',
      '#e48274',
      '#ef5350',
    ],
    // app: [
    //   '#ffffff99',
    //   '#a2cec1',
    //   '#468086',
    //   '#ded780',
    //   '#e48274',
    //   '#ef5350',
    // ],
  },
  background: {
    dark: '#545454',
    light: '#f7f7f7',
    // app: '#2f3441',
  },
  border: {
    dark: 'rgba(255, 255, 255, 0.4)',
    light: 'rgba(0, 0, 0, 0.4)',
    // app: '#ffffff66',
  },
  button: {
    dark: 'rgba(255, 255, 255, 0.8)',
    light: 'transparent',
  },
  caption: {
    dark: '#b5b5b5',
    light: '#545454',
  },
  fill_opacity_area: {
    dark: 0.1,
    light: 0.2,
    // app: 0.1,
  },
  flags: [
    '#ebd14a',//15. lemon
    '#ffa500',//1. orange
    '#ef3474',//3. magenta

    '#a2cec1',//7. green
    '#468086',//12. teal
    '#1455c2',//14. blue
  ],
  grid: {
    dark: 'rgba(255, 255, 255, 0.1)',
    light: 'rgba(0, 0, 0, 0.2)',
    // app: '#ffffff1a',
  },
  line: {
    dark: '#fff',
    light: '#000',
  },
  pie_data_labels: {
    dark: 'text-shadow: 0px 0px 4px #000',
    light: '',
  },
  series: {
    dark: [
      '#ffa500',// 1. orange
      '#4abf70',// 2. green
      '#ef3474',// 3. magenta
      '#73a2ff',// 4. blue
      '#b5b5b5',// 5. grey
      '#ded780',// 6. lime
      '#a2cec1',// 7. green
      '#e48274',// 8. pink
      '#7e79bb',// 9. purple
      '#e9e9e9',// 10. light-grey
      '#eba483',// 11. orange
      '#468086',// 12. teal
      '#ef5350',// 13. red
      '#1455c2',// 14. blue
      '#ded780',// 15. lemon
      '#40E0d0',// 16. turquoise
    ],
    light: [
      '#ffa500',// 1. orange
      '#4abf70',// 2. green
      '#ef3474',// 3. magenta
      '#73a2ff',// 4. blue
      '#545454',// 5. grey
      '#ded780',// 6. lime
      '#a2cec1',// 7. green
      '#e48274',// 8. pink
      '#7e79bb',// 9. purple
      '#393939',// 10. dark-grey
      '#eba483',// 11. orange
      '#468086',// 12. teal
      '#ef5350',// 13. red
      '#1455c2',// 14. blue
      '#ded780',// 15. lemon
      '#40E0d0',// 16. turquoise
    ]
  },
  text: {
    dark: 'rgba(255, 255, 255, 0.8)',
    light: '#000',
    // app: '#ffffff99',
  },
  title: {
    dark: '#fff',
    light: '#000',
    // app: '#ffffff99',
  },
  tooltip: {
    dark: '#393939',
    light: '#e9e9e9',
    // app: '#1d222a',
  },
}


export { COLOUR }
