import React from 'react'
import { AppStore } from 'appRedux'
import DeviceHandler from './DeviceHandler'


const highlightKeywords = (value = '', searchValue) => {
  if (!searchValue || !value) { return value; }

  const lowerCaseSearchValue = searchValue.toLowerCase();
  const lowerCaseValue = value.toLowerCase();
  const items = [];

  let idxStart = 0;
  let idx;

  while ((idx = lowerCaseValue.indexOf(lowerCaseSearchValue, idxStart)) !== -1) {
    items.push(value.substring(idxStart, idx));
    items.push(
      <span className='keyword-highlight' key={idx}>
        {value.substring(idx, idx + searchValue.length)}
      </span>
    );
    idxStart = idx + searchValue.length;
  }

  items.push(value.substring(idxStart));

  return items;
};
const onTabWeel = (e) => {
	e.stopPropagation();

	const tab = e.target.parentElement.parentElement;
	tab.scrollLeft += e.deltaY;
};

const OSHandler = () => {
	document.body.classList.remove('Mac');
	document.body.classList.remove('Windows');
	document.body.classList.remove('Mobile');
	document.body.classList.remove('Android');

	const isMac = navigator.platform.toUpperCase().includes('MAC')
      || navigator.platform.toUpperCase().includes('IPHONE');

	if (window.innerWidth <= 520 || DeviceHandler.mobileDetect()) {
		document.body.classList.add('Mobile');

		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/android/i.test(userAgent)) { document.body.classList.add('Android'); }
	} else if (isMac) {
		document.body.classList.add('Mac');
	} else {
		document.body.classList.add('Windows');
	}
};
const setTabCSS = (where) => {
	const tab	= document.getElementById('ua-tab');

	// if (!tab) { return; }

	const activeTab = document.querySelector('.ua-tab-item.active');
	const tabContainer = document.getElementById('dashboard-tab-container');

	if (tab) {
		tab.removeEventListener('wheel', onTabWeel);
		tab.addEventListener('wheel', onTabWeel);
	}

	if (activeTab) { tab.scrollLeft = activeTab.offsetLeft - tab.offsetLeft; }

	if (tabContainer && where !== 'lab_tab') {
		const dashboardTitle = document.getElementById('dashboard-title');

		let titleWidth = 0;

		if (dashboardTitle) { titleWidth = dashboardTitle.offsetWidth; }

		const width	= AppStore.getState().Layout.width;

		let tabWidth = '100%';

		if (titleWidth) { tabWidth = `${width - titleWidth - 360}px`; }

		tab.style.width = tabWidth;
	}
};


export default { highlightKeywords, OSHandler, setTabCSS }
