import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const ReflineUpdateAPI = (switcher_id, data) => axios.post(`${config.api.APP_API_DOMAIN}/user/lab_setting/${switcher_id}`, data, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  }),
  ReflineLoadAPI = (stock_code) => axios.get(`${config.api.APP_API_DOMAIN}/user/lab_setting/${stock_code}`, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  })


export default { ReflineUpdateAPI, ReflineLoadAPI }
