import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/Auth'
import AuthRedirect from 'components/AuthRedirect'
import { LogInOutHandler as component } from '../components'


const mapStateToProps = (state) => ({
  isUserInfoLoaded: state.Profile.loaded,
  user_info: state.Profile.user_info,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogout: () => dispatch(actions.logout()),
});
const LogInOutHandler = AuthRedirect.AuthProps(connect(mapStateToProps, mapDispatchToProps)(component));

export { LogInOutHandler }
