import React from 'react'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import classNames from 'classnames'

import { AutosuggestHighlightMatch } from './func'


const SectionTitle = (section) => (<strong>{section.title}</strong>);
const StockSuggestion = (suggestion, { query }) => {
  if (!suggestion || !suggestion.stock_code) { return null; }

  const { stock_code, stock_name } = suggestion;
    // codeToLowerCase                = stock_code.toString().toLowerCase(),
    // queryToLowerCase               = query.toString().toLowerCase(),
    // stockCodeMatches               = AutosuggestHighlightMatch(codeToLowerCase, queryToLowerCase),
    // stockCodeParts                 = AutosuggestHighlightParse(stock_code, stockCodeMatches),
    // stockCodeComponent             = stockCodeParts.map((part, idx) => {
    //   const className = classNames({ 'react-autosuggest__suggestion-match': part.highlight });
    //
    //   return (
    //     <strong className={className} key={idx}>
    //       {part.text}
    //     </strong>
    //   );
    // });
  const stockCodeComponent = <strong>{stock_code}</strong>;

  let stockNameComponent = null;

  if (stock_name) {
    // const nameToLowerCase = stock_name.toString().toLowerCase(),
    //   stockNameMatches    = AutosuggestHighlightMatch(nameToLowerCase, queryToLowerCase),
    //   stockNameParts      = AutosuggestHighlightParse(stock_name, stockNameMatches);

    // stockNameComponent = stockNameParts.map((part, idx) => {
    //   const className = classNames({ 'react-autosuggest__suggestion-match': part.highlight });
    //
    //   return (
    //     <small className={className} key={idx}>
    //       {part.text}
    //     </small>
    //   );
    // });
    stockNameComponent = <small>{stock_name}</small>;
  }

  return (
    <>
      <div className="stock-name">{stockNameComponent}</div>

      <div>{stockCodeComponent}</div>
    </>
  );
};
const SingleSuggestion = (suggestion, { query }) => {
  const { name, available, need_pay } = suggestion;
  const nameToLowerCase = name.toLowerCase();
  const queryToLowerCase = query.toLowerCase();
  const nameMatches = AutosuggestHighlightMatch(
    nameToLowerCase,
    queryToLowerCase
  );
  const nameParts = AutosuggestHighlightParse(name, nameMatches);
  const needPay = (need_pay !== undefined && available !== undefined)
    ?
    need_pay && !available
    :
    false;

  return (
    <span className="single-name">
      {needPay ? <i className="fa fa-lock margin-right-5"/> : null}

      {nameParts.map((part, idx) => {
        const className = classNames({
          'react-autosuggest__suggestion-match': part.highlight
        });

        return (
          <span className={className} key={idx}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
};
const AccountSuggestion = (suggestion, { query }) => {
  const name = suggestion.ChineseAccount;
  const nameMatches = AutosuggestHighlightMatch(name, query);
  const nameParts = AutosuggestHighlightParse(name, nameMatches);

  return (
    <span>
      {nameParts.map((part, idx) => {
        const className = classNames({
          'react-autosuggest__suggestion-match': part.highlight
        });

        return <span className={`${className}`} key={idx}>{part.text}</span>;
      })}
    </span>
  );
};
const SearchSuggestion = (suggestion, { query }) => {
  const searchMatches = AutosuggestHighlightMatch(suggestion, query);
  const searchParts = AutosuggestHighlightParse(suggestion, searchMatches);

  return (
    <span>
      {searchParts.map((part, idx) => {
        const className = classNames({
          'react-autosuggest__suggestion-match': part.highlight
        });

        return <span className={`${className}`} key={idx}>{part.text}</span>;
      })}
    </span>
  );
}


export {
  SectionTitle,
  StockSuggestion,
  SingleSuggestion,
  AccountSuggestion,
  SearchSuggestion,
}
