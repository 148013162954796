import React from 'react'

import { MarkdownText } from 'components'

const QAComponent = ({ item, loading, onQuestionSet }) => {
  const {show, text}  = item;
  let aParsed = loading ? null : text;
  let btn = null;
  if (aParsed) {
    const nextTitle = '您的下一個問題是：';

    aParsed = aParsed.replace(/~~/g, '');
    aParsed = aParsed.split(nextTitle);

    if (aParsed.length > 1) {
      const idx = aParsed[1].indexOf('？') + 1;
      const nextQuestion = aParsed[1].slice(0, idx);

      btn = (
        <div className="btn-container display-flex">
          <span className="margin-left-auto">{nextTitle}</span>

          <span
            className="question-submit ua-txt-btn txt-semibold"
            disabled={loading}
            data-actual={nextQuestion}
            data-show={nextQuestion}
            onClick={onQuestionSet}
          >
            {nextQuestion}
          </span>
        </div>
      );
    }
  }

  return (
    <div className="inputs">
      <div className="txt-orange">
        <span>Q: </span>

        <strong>{show}</strong>
      </div>

      {aParsed && (
        <div className="margin-top-5 margin-bottom-15">
          <MarkdownText children={aParsed[0]} />

          {btn}
        </div>
      )}
    </div>
  );
}


export { QAComponent }
