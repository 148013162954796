import React from 'react'
import validator from 'validator'


export default class Validator extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nullable = false;
		this.is_edited = false;
	}

	checkNull (value) {
		return !value || value == null || value.length === 0;
	}

	checkID = (id) => {
		const city = [ 1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11, 20, 48, 29, 38, 47, 56, 65, 74, 83, 21, 3, 12, 30 ];

  	id = id.toUpperCase();

		if (id.search(/^[A-Z](1|2)\d{8}$/i) === -1) {
			return false;
		} else {
			id = id.split('');

      var total = city[id[0].charCodeAt(0) - 65];

			for (var i = 1; i <= 8; i++) {
				total += parseInt(id[i], 10) * (9 - i);
      }

			total += parseInt(id[9], 10);

			return total % 10 === 0;
    }
  }

	checkCompanyID = (id) => {
		if (!id.match(/\d+/)) { return false; }

		const idArray = id.split('').map((item) => (parseInt(item, 10))),
			factorArray = [1, 2, 1, 2, 1, 2, 4, 1];

    let result = 0;

		for (let i = 0; i < factorArray.length; i++) {
			let multiply = factorArray[i] * idArray[i];

			result += (multiply > 9) ? Math.floor(multiply / 10) + (multiply % 10) : multiply;
		}

    return result % 10 === 0 || ((idArray[6] === 7) && ((result + 1) % 10 === 0));
  }

	getValidator(key, name, value, text, ex_value) {
		// const re = /^[0-9]+(\.[0-9]{1,10})?$/;

		if (this.checkNull(value) && this.nullable) { return true; }

		switch(key) {
			case 'required':
				if (this.checkNull(value)) {
					if (name === 'products' || name === 'single') {
						return `${text}`;
					} else {
						return `請輸入${text}`;
					}
			  }

				return true;

			case 'passwordConfirmation':
				if (ex_value !== value) { return '兩次密碼輸入須相同'; }

				return true;
			case 'resetPassword':
				if (ex_value === value) {
					return '新舊密碼不可相同';
				}

				return true;

			case 'email':
				return validator.isEmail(value) ? true : '請檢查 Email 格式';

			case 'min':
				return value && value.length >= ex_value ? true : `${text}須至少為 ${ex_value} 個字元`;

			case 'max':
				return  value && value.length <= ex_value ? true : `${text}須少於 ${ex_value} 個字元`;

			case 'number':
				return validator.isNumeric(value) && /^\d+$/.test(value) ? true : '請輸入數字';

			case 'float':
				return validator.isFloat(value.toString()) ? true : '請輸入數字';

			case 'nullable':
				return true;

			case 'checked':
				return value ? true : text;

			case 'id_number':
				return this.checkID(value) ? true : `${text} 錯誤`;

			case 'company_id_number':
				return this.checkCompanyID(value) ? true : `${text} 錯誤`;

			case 'phone':
				return /^[\d()[\]#-]+$/.test(value) ? true : `${text} 錯誤`;

			default:
				return 'Error';
		}
	}

	checkValidate() {
		const {
			name,
			value,
			text,
			validate
		} = this.props;

		let	error = false, message = '';

		this.nullable = 'nullable' in validate;

		for (let key in validate) {
			let validateResult = this.getValidator(key, name, value, text, validate[key]);

			if (validateResult !== true) {
				message = validateResult;
				error = true;

				break;
			}
		}

		return { error: error, message: message };
	}

	setValidator() {
		const { name, onErrorSet } = this.props,
			checkValidate = this.checkValidate();

		onErrorSet(name, checkValidate.error ? true : false);
	}

	componentWillUnmount() {
		const { onRemove, name } = this.props;

		onRemove(name);
	}

	componentDidUpdate(prevProps, prevState) {
		const { value, onErrorShow, init } = this.props;

		if (prevProps.value !== value) {
			this.setValidator();

			onErrorShow();
			if (!this.is_edited) {
				this.is_edited = true;
			}
		}

		if (prevProps.init === false && init === true) {
			this.setValidator();
			this.is_edited = false;
		}
	}

	componentDidMount() {
		this.setValidator();
	}

	render() {
		const checkValidate = this.checkValidate();

		if (this.is_edited && this.props.show_error && checkValidate.error) {
			return <em className="invalid">{checkValidate.message}</em>;
		}

		return null;
	}
}
