import { values as _values } from 'lodash'


export const types = {
  DATA_REQUEST: 'singleComparisonChart/DATA_REQUEST',
  DATA_COMPLETED: 'singleComparisonChart/DATA_COMPLETED',
  DATA_FAILED : 'singleComparisonChart/DATA_FAILED',
}

const initialState = { loading: false, stock_data: [] }

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.DATA_REQUEST:
    case types.DATA_FAILED:
      return { ...state, loading: action.type === types.DATA_REQUEST };

    case types.DATA_COMPLETED:
      let stocks = [];
      let stockDataUpdated = action.data;

      const arrKey = Object.keys(stockDataUpdated);

      for (let i = 0; i < arrKey.length; i++) {
        const stockCode = arrKey[i];
        const stockData = stockDataUpdated[stockCode];
        stockData.stock_code = stockCode;
        stockData.Style = 'stackedcolumn';

        stocks.push(stockData);
      }

      return { ...state, loading: false, stock_data: stocks };

    default:
      return state;
  }
}

export const actions = {
  dataRequest: function(model, stocks) {
    return { type: types.DATA_REQUEST, model: model, stocks : stocks };
  },
  dataCompleted: function(data) {
    return { type: types.DATA_COMPLETED, data: data };
  },
  dataFailed: function(err) {
    return { type: types.DATA_FAILED, err: err };
  },
}
