import axios from 'axios'
import { Cookies } from 'utils'


const url = process.env.REACT_APP_API_DOMAIN;
const SingleCustomSettingsLoadAPI = (apiName) => (axios.get(
  `${url}/user/lab_setting/radar/${apiName}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
));
const SingleCustomSettingsUpdateAPI = (apiName, data, stockCode) => (axios.post(
  `${url}/user/lab_setting/radar/${apiName}/${stockCode}`,
  { data: data },
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
));


export default { SingleCustomSettingsLoadAPI, SingleCustomSettingsUpdateAPI }
