export const types = {
  // load questions list
  DATA_LOAD_REQUEST: 'singleAiChat/DATA_LOAD_REQUEST',
  DATA_LOAD_SUCCESS: 'singleAiChat/DATA_LOAD_SUCCESS',
  DATA_LOAD_ERROR: 'singleAiChat/DATA_LOAD_ERROR',

  // get a reply to a specific question
  QUESTION_SET: 'singleAiChat/QUESTION_SET',
  // ANSWERING_STOP: 'singleAiChat/ANSWERING_STOP',

  ANSWER_GET_REQUEST: 'singleAiChat/ANSWER_GET_REQUEST',
  ANSWER_GET_SUCCESS: 'singleAiChat/ANSWER_GET_SUCCESS',
  ANSWER_GET_ERROR: 'singleAiChat/ANSWER_GET_ERROR',

  RESET: 'singleAiChat/RESET',
  CLEAR: 'singleAiChat/CLEAR',
}

const initialState = { loading: {}, qas: {}, qa: {} }

export default function reducer(state = initialState, action = {}) {
  let loadingUpdated = { ...state.loading };
  let qasUpdated = { ...state.qas };
  let qaUpdated = { ...state.qa };

  switch (action.type) {
    case types.DATA_LOAD_REQUEST:
      loadingUpdated[`${action.id}:${action.category}:${action.subcategory}`] = true;

      return { ...state, loading: loadingUpdated };

    case types.DATA_LOAD_SUCCESS:
       loadingUpdated[`${action.id}:${action.category}:${action.subcategory}`] = false;

      const actionData = [ ...action.data.data ];

      let dataUpdated = [];

      if (action.question) {
        dataUpdated = qasUpdated[`${action.id}:${action.category}:${action.subcategory}`];
        dataUpdated[dataUpdated.length] = actionData[0];
        qaUpdated[`${action.id}:${action.category}:${action.subcategory}`] = {...actionData[0]};
      } else {
        dataUpdated = actionData.map((item) => ({
          ...item,
          show: item.prompt,
          actual: item.prompt,
        }));
      }

      qasUpdated[`${action.id}:${action.category}:${action.subcategory}`] = dataUpdated;

      return {
        ...state,
        loading: loadingUpdated,
        qas: qasUpdated,
        qa: qaUpdated,
      };

    case types.DATA_LOAD_ERROR:
      loadingUpdated[`${action.id}:${action.category}:${action.subcategory}`] = false;

      return { ...state, loading: loadingUpdated };

    case types.QUESTION_SET:
      qaUpdated[`${action.id}:${action.category}:${action.subcategory}`] = {...action.data};
      return { ...state, qa: qaUpdated };

    // case types.ANSWERING_STOP:
    //   loadingUpdated[action.qa_type] = false;
    //
    //   return { ...state, qa: {}, loading: loadingUpdated };

    case types.ANSWER_GET_REQUEST:
      loadingUpdated[`${action.id}:${action.category}:${action.subcategory}`] = true;

      return { ...state, loading: loadingUpdated };

    case types.ANSWER_GET_SUCCESS:
    case types.ANSWER_GET_ERROR:
    case types.RESET:
      if (loadingUpdated[`${action.id}:${action.category}:${action.subcategory}`])
        {
          qaUpdated[`${action.id}:${action.category}:${action.subcategory}`] = {
              ...qaUpdated[`${action.id}:${action.category}:${action.subcategory}`],
              text: action.data ? action.data.text : '系統忙碌中，請稍後再問一次'
          };
      } else {
          qaUpdated = {};
      }

      loadingUpdated[`${action.id}:${action.category}:${action.subcategory}`] = false;
      return { ...state, loading: loadingUpdated, qa: qaUpdated };
    case types.CLEAR:
      delete qaUpdated[`${action.id}:${action.category}:${action.subcategory}`];
      return { ...state, qa: {...qaUpdated} };
    default:
      return state;
  }
}

export const actions = {
  loadDataRequest: function (id, category, subcategory, data) {
    return {
      type: types.DATA_LOAD_REQUEST,
      id: id,
      category: category,
      subcategory: subcategory,
      data: data,
    };
  },
  loadDataSuccess: function(id, data, category, subcategory, question = null) {
    return {
      type: types.DATA_LOAD_SUCCESS,
      id:id,
      data: data,
      category: category,
      subcategory: subcategory,
      question: question,
    };
  },
  loadDataError: function(id, category, subcategory) {
    return {
      type: types.DATA_LOAD_ERROR,
      id:id,
      category: category,
      subcategory: subcategory,
    };
  },

  setQuestion: function (id, data, category, subcategory) {
    return {
      type: types.QUESTION_SET,
      id:id,
      data: data,
      category: category,
      subcategory: subcategory,
    };
  },
  // stopAnswering: function (category) {
  //   return { type: types.ANSWERING_STOP };
  // },

  getAnswerRequest: function (id, data, category, subcategory) {
    return {
      type: types.ANSWER_GET_REQUEST,
      id:id,
      data: data,
      category: category,
      subcategory: subcategory,
    };
  },
  getAnswerSuccess: function(id, data, category, subcategory) {
    return {
      type: types.ANSWER_GET_SUCCESS,
      id:id,
      data: data,
      category: category,
      subcategory: subcategory,
    };
  },
  getAnswerError: function(id, category, subcategory) {
    return {
      type: types.ANSWER_GET_ERROR,
      id:id,
      category: category,
      subcategory: subcategory,
    };
  },

  reset: function(id, category, subcategory) {
    return {
      type: types.RESET,
      id:id,
      category: category,
      subcategory: subcategory,
    };
  },

  clear: function(id, category, subcategory) {
    return {
      type: types.CLEAR,
      id:id,
      category: category,
      subcategory: subcategory,
    };
  },
}
