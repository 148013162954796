import { all } from 'redux-saga/effects'
import AnnouncementWatcher from './Announcement'
import DataAssistantWatcher from './AssistantData'
import AuthWatcher from './Auth'
import AvatarWatcher from './Avatar'
import ContactWatcher from './Contact'
import DashboardWatcher from './Dashboard'
import FavoriteWatcher from './Favorite'
import LaboratoryWatcher from './Laboratory'
import LabListSagaWatcher from './LabListSaga'
import OrderWatcher from './Order'
import PasswordWatcher from './Password'
// import PlaygroundWatcher from './Playground'
import PortfolioWatcher from './Portfolio'
import ProductWatcher from './Product'
import ProfileWatcher from './Profile'
import ReflineWatcher from './ReflineSwitcher'
// import SingleWatcher from './Single'
import SingleAiChatWatcher from './SingleAiChat'
import SingleAiConsultantWatcher from './SingleAiConsultant'
import SingleAiPromptWatcher from './SingleAiPrompt'
import SingleAiReportWatcher from './SingleAiReport'
import SingleChartWatcher from './SingleChart'
import SingleComparisonChartWatcher from './SingleComparisonChart'
import SingleCustomSettingsWatcher from './SingleCustomSettings'
// import SingleKeywordWatcher from './SingleKeyword'
import SingleStockComparisonWatcher from './SingleStockComparison'
import StockWatcher from './Stock'
// import Stock5GList from './Stock5GList'
import UserNotificationsWatcher from './UserNotifications'
import UserProductsWatcher from './UserProducts'
import UserOrdersWatcher from './UserOrders'

export default function * rootSaga() {
  yield all([
    ...AnnouncementWatcher,
    ...AuthWatcher,
    ...AvatarWatcher,
    ...ContactWatcher,
    ...DashboardWatcher,
    ...DataAssistantWatcher,
    ...FavoriteWatcher,
    ...LaboratoryWatcher,
    ...LabListSagaWatcher,
    ...OrderWatcher,
    ...PasswordWatcher,
    // ...PlaygroundWatcher,
    ...PortfolioWatcher,
    ...ProductWatcher,
    ...ProfileWatcher,
    ...ReflineWatcher,
    // ...SingleWatcher,
    ...SingleAiChatWatcher,
    ...SingleAiConsultantWatcher,
    ...SingleAiPromptWatcher,
    ...SingleAiReportWatcher,
    ...SingleChartWatcher,
    ...SingleComparisonChartWatcher,
    ...SingleCustomSettingsWatcher,
    // ...SingleKeywordWatcher,
    ...SingleStockComparisonWatcher,
    // ...Stock5GList,
    ...StockWatcher,
    ...UserNotificationsWatcher,
    ...UserProductsWatcher,
    ...UserOrdersWatcher
  ]);
}
