import React from 'react'
import { Cookie } from 'components'
import { Header, Footer } from './components'
import { SideMenu } from './containers'

import { CSSHandler, DeviceHandler } from 'utils'
import 'assets/sass/Layout/layout.scss'


export class Layout extends React.PureComponent {
	updateSize = (isInit = false) => {
		const { onIsMobileSet, onSideMenuHide, onWindowSizeSet } = this.props;
		const isMobile = window.innerWidth <= 520 || DeviceHandler.mobileDetect();

		CSSHandler.OSHandler();
		DeviceHandler.inAppBrowserDetect();

		onWindowSizeSet(window.innerWidth, window.innerHeight);
		onIsMobileSet(isMobile);

		if (isMobile && isInit && typeof isInit === 'boolean') {
			onSideMenuHide(isMobile);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			authenticated,

			isLoginPage,
			colour_theme,
			location,

			onProductListLoadRequest,
			onRedirectPathSet,
		} = this.props;

		if (prevProps.location.pathname !== location.pathname) {
			document.getElementById('main').scrollTop = 0;

			if (!isLoginPage) { onRedirectPathSet(location.pathname); }
		}

		if (prevProps.colour_theme !== colour_theme) {
			document.body.classList.add(colour_theme);
			document.body.classList.remove(prevProps.colour_theme);
		}

		if (prevProps.authenticated !== authenticated) {
			onProductListLoadRequest();

			if (authenticated) { this.updateSize(); }
		}
	}

	componentDidMount() {
		const {
			isLoginPage,
			colour_theme,
			location,
			products_loaded,

			onProductListLoadRequest,
			onRedirectPathSet,
		} = this.props;

		this.updateSize(true);

		window.addEventListener('resize', this.updateSize, true);

		document.body.classList.add(colour_theme);
		document.getElementById('ua-root').classList.remove('ua-before-loading');
		document.getElementById('main').scrollTop = 0;

		if (!isLoginPage) { onRedirectPathSet(location.pathname); }

		if (!products_loaded) { onProductListLoadRequest(); }
	}

	render() {
		const {
				children,
				isLoginPage,
				location,
				is_mobile,
				side_menu_hidden,
				// isSingle,
			} = this.props,
			isDashboard = location.pathname.indexOf('dashboard') > -1;

		return (
			<div className={`ua-app w-100 h-100 margin-0 padding-0
					${side_menu_hidden ? 'menu-hidden' : ''}
					${isLoginPage ? 'login-page' : ''}`}
					//${isSingle ? 'ua-single-page' : ''}
			>

				{/*!isSingle && (*/
					<Header
						isLoginPage={isLoginPage}
						isDashboard={isDashboard}
						isMobile={is_mobile}
					/>
			/*)*/}

				<section className='ua-body h-100'>
					{(!isLoginPage /*&& !isSingle*/) && <SideMenu path={location.pathname} />}

					<section id='main' className='ua-page h-100 padding-top-0'>
						<main className='ua-content w-100'>{children}</main>

						{/*!isSingle &&*/ <Footer isMobile={is_mobile} />}

						<Cookie />
					</section>
				</section>

			</div>
		);
	}
}
