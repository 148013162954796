import validator from 'validator'
import { DataHandler } from 'utils'


const escapeRegExp = (value) => { // $& means the whole matched string
  const str = value.toString();

  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
const parseValue = (value) => {
  const str = value.toString().toLowerCase();

  return str;
};

/*
 search:
 1. stock / stock list
 2. single
 3. account
 4. keyword
 */
// const RECORD_TITLE = '搜尋紀錄';

const getSuggestions = (
  value,
  searchType,
  // focused,
  pool,
  // recordPool
) => {
  if (value.length < 1) { return []; }

  const regex = new RegExp(escapeRegExp(value), 'i');
  const parsedValue = parseValue(value);
  let suggestionsParsed = [], sections = [];

  switch (searchType) {
    case 'stock':
    case 'stock-list':
      // 1. record
      // const stockRecordPoolParsed = recordPool
      //   .map((stockCode) => (pool.find((stock) => (stock.stock_code === stockCode))))
      //   .filter((stock) => (stock !== undefined));
      //
      // if (focused) {
      //   // sections = stockRecordPoolParsed;
      //   sections[sections.length] = {
      //     title: RECORD_TITLE,
      //     suggestions: stockRecordPoolParsed
      //   };
      // } else {
        // 2. suggestions
        const matchStockCodes = pool.filter((stock) => (parseValue(stock.stock_code).startsWith(parsedValue)));
        const parsedStockCodeSuggestions = pool.filter((stock) => (
          regex.test(parseValue(stock.stock_code))
        ));
        const matchStockNames = pool.filter((stock) => (parseValue(stock.stock_name).startsWith(parsedValue)));
        const parsedStockNameSuggestions = pool.filter((stock) => (
          regex.test(parseValue(stock.stock_name))
        ));

        // if (parsedStockCodeSuggestions.length > 0) {
        //   suggestionsParsed = parsedStockCodeSuggestions;
        // } else if (parsedStockNameSuggestions.length > 0) {
        //   suggestionsParsed = parsedStockNameSuggestions;
        // }

        suggestionsParsed = [ 
          ...matchStockCodes, 
          ...matchStockNames, 
          ...parsedStockCodeSuggestions, 
          ...parsedStockNameSuggestions,
        ];
        suggestionsParsed = DataHandler.duplicatesRemover(suggestionsParsed);
        sections = suggestionsParsed;

        // sections[sections.length] = {
        //   title: '',
        //   suggestions: focused ? [] : suggestionsParsed
        // };
      // }
      
      break;

    case 'single':
      // 1. record
      // const singleRecordPoolParsed = recordPool.map((id) => (pool.find((single) => (single.id.toString() === id.toString()))));

      // if (focused) {
      //   sections[sections.length] = {
      //     title: RECORD_TITLE,
      //     suggestions: singleRecordPoolParsed
      //   };
      // } else {
        // 2. suggestions
        const parsedNameSuggestions = pool.filter((single) => (
          regex.test(single.name)
        ));
        const parsedInfoMoreSuggestions = pool.filter((single) => (
          regex.test(single.info_more)
        ));
        const parsedInfoShortSuggestions = pool.filter((single) => (
          regex.test(single.info_short)
        ));

        if (parsedNameSuggestions.length > 0) {
          suggestionsParsed = parsedNameSuggestions;
        } else if (parsedInfoMoreSuggestions.length > 0) {
          suggestionsParsed = parsedInfoMoreSuggestions;
        } else if (parsedInfoShortSuggestions.length > 0) {
          suggestionsParsed = parsedInfoShortSuggestions;
        }

        sections = suggestionsParsed;
        // sections[sections.length] = {
        //   title: '',
        //   suggestions: suggestionsParsed
        // };
      // }

      break;

    case 'account':
      // suggestions
      const parsedCategorySuggestions = pool.filter((account) => (
        account.Category && regex.test(account.Category)
      ));
      const parsedChineseAccountSuggestions = pool.filter((account) => (
        regex.test(account.ChineseAccount)
      ));
      const parsedEnglishAccountSuggestions = pool.filter((account) => (
        regex.test(account.EnglishAccount)
      ));

      if (parsedCategorySuggestions.length > 0) {
        suggestionsParsed = parsedCategorySuggestions;
      } else if (parsedChineseAccountSuggestions.length > 0) {
        suggestionsParsed = parsedChineseAccountSuggestions;
      } else if (parsedEnglishAccountSuggestions.length > 0) {
        suggestionsParsed = parsedEnglishAccountSuggestions;
      }

      sections = /*focused ? [] :*/ suggestionsParsed;

      break;

    default:// keyword
      suggestionsParsed = pool.filter((keyword) => (regex.test(keyword)));
      suggestionsParsed = suggestionsParsed.map((item) => (
        (!item || typeof item === 'object') ? '' : item.toString()
      ));

      // sections[sections.length] = {
      //   title: '',
      //   suggestions: suggestionsParsed
      // };

      sections = /*focused ? [] :*/ suggestionsParsed;
  }

  return sections;
};
const getSuggestionValue = (suggestion) => (
  `_${suggestion.stock_code ? suggestion.stock_code : suggestion}`
);
const getSuggestionStock = (value, stockPool) => {
  let cond = value;
  let cond_2 = null;

  if (typeof cond === 'object') {
    const { stock_code, stock_name } = cond;

    cond = stock_code.toString().toLowerCase();
    cond_2 = stock_name.toString().toLowerCase();
  }

  let stock = false;

  stock = stockPool.find((item) => {
    let match = item.stock_code.toString().toLowerCase() === cond
        || item.stock_name.toLowerCase() === cond;

    if (cond_2) {
      match = item.stock_code.toString().toLowerCase() === cond
          && item.stock_name.toLowerCase() === cond_2
    }

    return match;
  });

  return stock;
}
const getSuggestionSingle = (value, singlePool) => {
  let result = false;

  if (Array.isArray(value)) {
    result = [];

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < singlePool.length; j++) {
        const single = singlePool[j];

        if (value[i].includes(single.name)) {
          result[result.length] = single;
        }
      }
    }
  } else {
    const valueLowerCase = value.toString().toLowerCase();

    result = singlePool.find((item) => (
      item.name.toLowerCase() === valueLowerCase
    ));

    if (!result) {
      result = singlePool.find((item) => {
        const { info_short, info_more } = item;

        return item.name.toLowerCase().includes(valueLowerCase)
          || (info_short && info_short.toLowerCase().includes(valueLowerCase))
          || (info_more && info_more.toLowerCase().includes(valueLowerCase));
      });
    } else if (result.length > 1) {
      console.log(result);
    }
  }

  return result;
}
const getSuggestionAccount = (value, accountPool) => {
  const { ChineseAccount, EnglishAccount } = value;

  if (!ChineseAccount && !EnglishAccount) { return false; }

  const valueChineseAccountLowerCase = ChineseAccount.toLowerCase();
  const valueEnglishAccountLowerCase = EnglishAccount.toLowerCase();

  let account = false;
  account = accountPool.find((item) => {
    return item.ChineseAccount.toLowerCase().includes(valueChineseAccountLowerCase)
        || item.EnglishAccount.toLowerCase().includes(valueEnglishAccountLowerCase);
  });

  return account;
};
const parseDataPool = (data, result, exist) => {
  const arrResult = (result ?? []).map((single) => (single.id));
  const arrExist = (exist ?? []).map((single) => (single.id));
  const dataParsed = data
    .filter((single) => (!arrResult.includes(single.id)
        && !arrExist.includes(single.id)))
    .map((single) => ({
      id: single.id,
      name: single.name,
      model: single.model,
      info_short: single.info_short,
      // info_more: single.info_more,
    }));

  return dataParsed;
};


export {
  getSuggestions,
  getSuggestionValue,
  getSuggestionStock,
  getSuggestionSingle,
  getSuggestionAccount,
  parseDataPool,
}
