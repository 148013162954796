/*! SmartAdmin - v1.5 - 2014-10-16 */
import $ from 'jquery'
const jQuery = $ ;
window.jQuery = window.$ = jQuery;


var ExistMsg = 0;
var SmartMSGboxCount = 0;

const Box = function (a, b) {
    var c, d;

    SmartMSGboxCount += 1;

    if (0 === ExistMsg) {
      	ExistMsg = 1;

      	c = "<div class='message-box-backdrop ua-backdrop animated fadeIn fast' id='MsgBoxBack'></div>";

      	$("body").append(c);
    }

    var g = '';
    var h = 0;
    var j = '';
    var k = 0;

    d = "<div class='message-box-container text-center animated fadeIn fast' id='Msg" + SmartMSGboxCount + "'>";
    d += "<div class='message-box-content'>";
    d += "<span class='meg-title'>" + a.title + "</span class='meg-title'>";
    d += "<p class='p-text'>" + a.content + "</p>"; d += g;
    d += "<div class='btn-section'>";

    void 0 === a.buttons && (a.buttons = "[Accept]");

    a.buttons = $.trim(a.buttons);
    a.buttons = a.buttons.split('');

    for (let i = 0; i <= a.buttons.length - 1; i++) {
    	if ("[" === a.buttons[i]) {
          j = '';
      } else if ("]" === a.buttons[i]) {
      		k += 1;
      		j = j.length ? "<button id='btn" + k + "-Msg" + SmartMSGboxCount + "' class='ua-btn outline btn-tempo'> " + j + "</button>" : "<button class='display-none'></button>";
          d += j;
      } else {
          j += a.buttons[i];
      }
    }

    d += "</div>";
    d += "</div>";
    d += "</div>";

    if (SmartMSGboxCount > 1) {
        $(".message-box-container").hide();
        $(".message-box-container").css("z-index", 99999);
    }

		$(".message-box-backdrop").html(d);

		if (1 === h) {
        $("#txt" + SmartMSGboxCount).focus();
    }

		$(".btn-tempo").hover(
        function () {
            $(this).attr("id");
        },
        function () {
            $(this).attr("id");
        }
    );

    $(".btn-tempo").click(function () {
        var a = $(this).attr("id"),
            c = a.substr(a.indexOf("-") + 1),
            d = $.trim($(this).text());

        if (1 === h) {
            if ("function" === typeof b) {
                var e = c.replace("Msg", ''),
                    f = $("#txt" + e).val();

                b && b(d, f)
            }
        } else {
            "function" === typeof b && b && b(d);
        }

        $("#" + c).addClass("animated fadeOut fast");

        SmartMSGboxCount -= 1;
        SmartMSGboxCount = 0;
        0 === SmartMSGboxCount && $("#MsgBoxBack").removeClass("fadeIn").addClass("fadeOut").delay(300).queue(function () {
            ExistMsg = 0; $(this).remove()
        });
    });
};

var SmallBoxes = 0;
var SmallBoxesAnchos = 0;

const SmallBox = function (a, b) {
    var c;

    SmallBoxes += 1;

    var f = (void 0 === a.iconSmall) ? "<div class='mini-icon-o'></div>" : "<div class='mini-icon-o'><i class='mini-pic " + a.iconSmall + "'></i></div>";
    var g = "smallbox" + SmallBoxes;

    c = (void 0 === a.icon) ? "<div id='smallbox" + SmallBoxes + "' class='small-box animated fadeInRight fast " + a.class + "'><div class='text-area'><span class='title'>" + a.title + "</span><p class='content'>" + a.content + "</p></div>" + f + "</div>" : "<div id='smallbox" + SmallBoxes + "' class='small-box animated fadeInRight fast'><div class='photo'><i class='" + a.icon + "'></i></div><div class='tex-o-photo'><span>" + a.title + "</span><p>" + a.content + "</p></div>" + f + "</div>";

    if (1 === SmallBoxes) {
      	$("#divSmallBoxes").append(c);

        SmallBoxesAnchos = $("#smallbox" + SmallBoxes).height() + 40;
	  } else {
        var h = $(".small-box").length;

        if (0 === h) {
            $("#divSmallBoxes").append(c);

            SmallBoxesAnchos = $("#smallbox" + SmallBoxes).height() + 40;
        } else {
            $("#divSmallBoxes").append(c);
            $("#smallbox" + SmallBoxes).css("top", SmallBoxesAnchos);

            SmallBoxesAnchos = SmallBoxesAnchos + $("#smallbox" + SmallBoxes).height() + 20;

            var i = 0;

            $(".small-box").each(function (a) {
                if (0 === a) {
                    $(this).css("top", 20);

                    i = $(this).height() + 40;
                    SmallBoxesAnchos = $(this).height() + 40;
                } else {
                    $(this).css("top", i);

                    i = i + $(this).height() + 20;
                    SmallBoxesAnchos = SmallBoxesAnchos + $(this).height() + 20;
                }
            });
        }
    }

    var j = $("#smallbox" + SmallBoxes);

    void 0 === a.color ? j.css("background-color", "#ffa500") : j.css("background-color", "#ffa500");

    var k;

    void 0 !== a.timeout && setTimeout(function () {
        clearInterval(k);

        var a = $(this).height() + 20;

        $("#" + g).css("top");

        if (0 !== $("#" + g + ":hover").length) {
            $("#" + g).on("mouseleave", function () {
                SmallBoxesAnchos -= a;

                $("#" + g).remove();

                "function" === typeof b && b && b();

                var d = 0;

              $(".small-box").each(function (a) {
                  if (0 === a) {
                      $(this).animate({ top: 20 }, 300);

                      d = $(this).height() + 40;
                      SmallBoxesAnchos = $(this).height() + 40;
                  } else {
                    $(this).animate({ top: d }, 350);

                    d = d + $(this).height() + 20;
                    SmallBoxesAnchos = SmallBoxesAnchos + $(this).height() + 20;
                }
              });
          });
      } else {
          clearInterval(k);

          SmallBoxesAnchos -= a;
          "function" === typeof b && b && b();

          $("#" + g).removeClass().addClass("small-box").animate({ opacity: 0 }, 300, function () {
              $(this).remove();

              var b = 0;

              $(".small-box").each(function (a) {
                  if (0 === a) {
                      $(this).animate({ top: 20 }, 300);

                      b = $(this).height() + 40;
                      SmallBoxesAnchos = $(this).height() + 40;
                  } else {
                      $(this).animate({ top: b });

                      b = b + $(this).height() + 20;
                      SmallBoxesAnchos = SmallBoxesAnchos + $(this).height() + 20;
                  }
              });
          });
        }
    }, a.timeout);

    $("#smallbox" + SmallBoxes).bind("click", function () {
        clearInterval(k);

        "function" === typeof b && b && b();

        var a = $(this).height() + 20;

        $(this).attr("id"); $(this).css("top");

        SmallBoxesAnchos -= a;

        $(this).removeClass().addClass("small-box").animate({ opacity: 0 }, 300, function () {
            $(this).remove();

            var b = 0;

            $(".small-box").each(function (a) {
                if (0 === a) {
                    $(this).animate({ top: 20 }, 300);

                    b = $(this).height() + 40;
                    SmallBoxesAnchos = $(this).height() + 40;
                } else {
                    $(this).animate({ top: b }, 350);

                    b = b + $(this).height() + 20;
                    SmallBoxesAnchos = SmallBoxesAnchos + $(this).height() + 20;
                }
            });
        });
    });
}


export default { Box, SmallBox }
