export const types = {
    FORGOT_REQUEST         : 'password/FORGOT_REQUEST',
    FORGOT_SUCCESS         : 'password/FORGOT_SUCCESS',
    FORGOT_ERROR           : 'password/FORGOT_ERROR',

    PASSWORD_RESET_REQUEST : 'password/PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS : 'password/PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_ERROR   : 'password/PASSWORD_RESET_ERROR'
}

const initialState = {
    reset_password_mail_send    : false,
    reset_password_mail_sending : false,
    password_reset              : false,
    password_reseting           : false
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.FORGOT_REQUEST:
            return { ...state, reset_password_mail_sending: true };

        case types.FORGOT_SUCCESS:
            return { ...state, reset_password_mail_send: true, reset_password_mail_sending: false };

        case types.FORGOT_ERROR:
        case types.PASSWORD_RESET_ERROR:
            return { ...initialState };

        case types.PASSWORD_RESET_REQUEST:
            return { ...state, password_reseting: true };

        case types.PASSWORD_RESET_SUCCESS:
            return { ...state, password_reset: true, password_reseting: false };

        default:
            return state;
    }
}

export const actions = {
    forgotRequest: function(data) {
        return { type: types.FORGOT_REQUEST, data: data };
    },
    forgotSuccess: function () {
        return { type: types.FORGOT_SUCCESS };
    },
    forgotError: function(error) {
        return { type: types.FORGOT_ERROR, error: error };
    },

    resetPasswordRequest: function(data, noAuth) {
        return { type: types.PASSWORD_RESET_REQUEST, data: data, noAuth: noAuth };
    },
    resetPasswordSuccess: function () {
        return { type: types.PASSWORD_RESET_SUCCESS };
    },
    resetPasswordError: function(error) {
        return { type: types.PASSWORD_RESET_ERROR, error: error };
    }
}
