const duplicatesRemover = (array) => {
  if (!array) { return; }

  if (typeof array[0] === 'object') {
    return (array[0]
      ?
      array.reduce((result, current) => {
        if (result.indexOf(current) < 0) {
          result[result.length] = current;
        }

        return result;
      }, [])
      :
      array.filter((element, idx, self) =>
        idx === self.findIndex((t) => (
          Object.keys(t)[0] === Object.keys(element)[0]
        ))
      )
    );
  } else {
    return array.reduce((result, current) => {
      if (result.indexOf(current) < 0) {
        result[result.length] = current;
      }

      return result;
    }, []);
  }
}


export default { duplicatesRemover }
