const countOcurrences = (str, word) => {
	const regex = new RegExp(word, 'gi');
	const count = (str.match(regex) || []).length;

	return count;
};

const getString = (value) => {
		if (!value || typeof value === 'object') { return ''; }

		if (isNaN(value)) {
			return value.toLowerCase();
		} else {
			return value.toString();
		}
	},
	getTabTitle = (str, idx, type) => {
		let title = str ?? 'new';

		switch (type) {
			case 'lab_edit':
				title = (title === 'new') ? `頁籤 ${(idx > 8) ? '' : '0'}${idx + 1}` : title;

				break;

			case 'my_fav':
				title = (title.length > 0) ? title : `我的最愛 ${(idx > 8) ? '' : '0'}${idx + 1}`;

				break;

			default:

		}

		return title;
	}


export default { countOcurrences, getString, getTabTitle }
