import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const UserProductsLoadAPI = () => axios.get(`${config.api.APP_API_DOMAIN}/user/products`,{
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  }),
  UserProductCancelAPI = (id) => axios.put(`${config.api.APP_API_DOMAIN}/user/products/${id}/credit_period_cancel`, {}, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  })


export default { UserProductsLoadAPI, UserProductCancelAPI }
