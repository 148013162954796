import axios from 'axios'
import { Cookies } from 'utils'


const { REACT_APP_API_DOMAIN, REACT_APP_STOCK_DOMAIN } = process.env;
const StockListLoadAPI = () => axios.get(
  `${REACT_APP_STOCK_DOMAIN}/data_fetch/api/StockPool`
);
const StockOthersListLoadAPI = () => axios.get(
  `${REACT_APP_STOCK_DOMAIN}/data_fetch/api/StockPoolOthers`
);
const StockETFListLoadAPI = () => axios.get(
  `${REACT_APP_STOCK_DOMAIN}/data_fetch/account/ETFStocks/AllStocks`
);
const StockInfoLoadAPI = (stockCode) => axios.get(
  `${REACT_APP_STOCK_DOMAIN}/data_fetch/api/WebStockInfo/${stockCode}`
);

const StockDataFetchAPI = (model, stockCode, params) => axios.get(
  `${REACT_APP_STOCK_DOMAIN}/fetch/${model}${(params && params.type) ? `/${params.type}` : ''}${stockCode ? `/${stockCode}` : ''}${(params && params.user_id) ? `/${params.user_id}` : ''}`
);
const StockCRAWLERDataFetchAPI = (model, stockCode, type) => axios.get(
  `${REACT_APP_STOCK_DOMAIN}/data_fetch/api/${model}${stockCode ? `/${stockCode}` : ''}${type ? `/${type}` : ''}`
);
const StockCRAWLERDataFetchWithUserIDAPI = (model, stockCode, type, userID) =>
    axios.get(`${REACT_APP_STOCK_DOMAIN}/data_fetch/api_user/${model}/${userID}${type ? `/${type}` : ''}${stockCode ? `/${stockCode}` : ''}`);
const StockAPIDataFetchAPI = (model, stockCode) => axios.get(
  `${REACT_APP_API_DOMAIN}/data/fetch/${model}${stockCode ? `/${stockCode}` : ''}`
);

const StockRecordAPI = (stockCode) => axios.post(
  `${REACT_APP_API_DOMAIN}/user/records`,
  { stock_code: stockCode },
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    }
  }
);


export default {
  StockListLoadAPI,
  StockOthersListLoadAPI,
  StockETFListLoadAPI,
  StockInfoLoadAPI,
  StockDataFetchAPI,
  StockCRAWLERDataFetchAPI,
  StockCRAWLERDataFetchWithUserIDAPI,
  StockAPIDataFetchAPI,
  StockRecordAPI,
}
