import React from 'react'


export class MenuToggler extends React.PureComponent {
	menuToggle = (e) => {
		e.preventDefault();

		this.props.onMenuToggle();
	}

	render() {
	  return (
	    <div
				className='menu-toggler header-btn display-inline-block elegant
						elegant-eleganticons-64 cursor-pointer text-center'
				title='隱藏 / 顯示選單'
				onClick={this.menuToggle}
	    />
	  );
	}
}
