const QUESTIONS = {
  'stock': [
    // {
    //   show: '公司做什麼的',
    //   actual: '公司做什麼的'
    // },
    // {
    //   show: '最近財報如何',
    //   actual: '最近財報如何'
    // },
    {
      show: '近況發展',
      actual: '近期發展有哪些，詳細且完整的敍述'
    },
    {
      show: '產業趨勢',
      actual: '未來產業趨勢，詳細且完整的敍述'
    },
    {
      show: '❤️產品線分析',
      actual: '針對各項產品進行詳細且完整的分析，並標示出該產品對公司的重要程度'
    },
    {
      show: '長短期展望',
      actual: '短期展望有哪些，並給一個短期成長性評分；長期展望有哪些，並給一個長期成長性評分，十分制'
    },
    {
      show: '供需分析',
      actual: '列出供給狀況並給一組整體供給評分(例如[[整體供給評分：8.5/10]])，列出需求狀況並給一組整體需求評分，十分制小數點1位(例如7.5/10)，並在最後加二條文字，寫法：**備註**：供給評分高代表供給順暢，低代表吃緊；需求評分高代表強勁，低代表疲弱。換行。**備註**：需求大於供給2分，顯示可能供不應求；供給大於需求1分，顯示可能供過於求。'
    },
    {
      show: '觀察重點',
      actual: '你是一名能明確指出細部原因且條理分明的頂尖產業及證券分析師，請告訴我這間公司最重要的觀察重點'
    },
    {
      show: '利多因素',
      actual: '你是一名能明確指出細部原因且條理分明的頂尖產業及證券分析師，請詳述利多的原因'
    },
    {
      show: '利空因素',
      actual: '你是一名能明確指出細部原因且條理分明的頂尖產業及證券分析師，請詳述利空的原因'
    },
    {
      show: '接單狀況',
      actual: '根據資料告訴我目前接單狀況，詳細且完整的敍述'
    },
    {
      show: '資本支出',
      actual: '詳述資本支出或擴產計劃，包含前因後果、項目、產能、金額、時間點、地點，若無資本支出或擴產，請回答『無資本支出相關資料』'
    },
    {
      show: '新產品',
      actual: '有新產品嗎，進度如何，最後條列出新產品詳細數字'
    },
    {
      show: '時間表',
      actual: '列出時間表(從過去到未來)'
    },
    {
      show: '相關公司',
      actual: '列出資料中提到的所有公司並說明關連性，並且僅根據資料告訴我競爭對手有誰，供應鏈及代理商有誰，若無相關資料就說"查無資料"'
    },
    {
      show: '同業競爭',
      actual: '根據資料告訴我競爭情況及應對策略，若無相關資料就說"查無資料"'
    },
    {
      show: '護城河分析',
      actual: '公司有護城河嗎？公司護城河明顯嗎？'
    },
    {
      show: '併購分析',
      actual: '[[【公司名】與【對象名】併購|收購|入股|策略合作|合資案|投資案|財務投資|換股案|股權交換案|合作備忘錄(MOU)分析]]：動機及對象介紹(意思是為什麼要做這件事的原因和目的)、執行方式及時間點(併購或認列)、預期效益及影響(請詳細分析)、[[點出關鍵重點(這點請你務必詳細分析探究)]]、其他細節。請詳細敍述原因，適時以數據佐證你的邏輯。若有多次併購，請將每併購活動區分開，日期新的放前面，例如A併購案分析12345、B、C......。備註，出售廠房不算併購、擴大產能不算併購。若無併購|收購|入股|策略合作|合資案|投資案|財務投資|換股|股權交換|合作備忘錄(MOU)僅需回答『無相關資料』'
    },
    {
      show: '重要數字',
      actual: '產能，或產能利用率，或滲透率，或市佔率，或公司排名，或(年複合)成長率'
    },
    {
      show: '公司簡介',
      actual: '針對營運模式、產品、客戶、產業、生產基地，生產或銷售過程，銷售地區，寫公司簡介'
    },
    {
      show: '銷售地區',
      actual: '主要銷售地區'
    },
    {
      show: '名詞解釋',
      actual: '你是一名非常了解公司產品及特點的專家，挑選資料中的十個產品名詞進行解釋，回答時不要給前言及最後總結'
    },
    // {
    //   show: '最新營收比重',
    //   actual: '最新營收比重'
    // },
    // {
    //   show: '相關產業訊息',
    //   actual: '相關產業訊息'
    // },
  ],
  'industry': [// default
    { show: '快速了解市場', actual: '快速了解市場' },
    { show: '市場細分 - 按產品', actual: '市場細分-按產品' },
    { show: '市場細分 - 按應用市場', actual: '市場細分-按應用市場' },
    { show: '市場細分 - 按國家', actual: '市場細分-按國家' },
    { show: '上下游關係', actual: '上下游關係' },
  ],
  '再生能源': [
    { show: '全球太陽能市場', actual: '全球太陽能市場' },
    { show: '中國風能市場', actual: '中國風能市場' },
  ],
  '電機設備': [
    { show: '變壓器市場', actual: '變壓器市場' },
    { show: '全球氣體絕緣開關設備', actual: '全球氣體絕緣開關設備' },
    { show: '全球電網基礎建設市場', actual: '全球電網基礎建設市場' },
    { show: '美國電網市場', actual: '美國電網市場' },
  ],
}


export { QUESTIONS }
