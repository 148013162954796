import { SessionStorage } from 'utils'

export const types = {
  LOAD_REQUEST: 'singleCustomSettings/LOAD_REQUEST',
  LOAD_COMPLETED: 'singleCustomSettings/LOAD_COMPLETED',
  LOAD_FAILED: 'singleCustomSettings/LOAD_FAILED',

  UPDATE_REQUEST: 'singleCustomSettings/UPDATE_REQUEST',
  UPDATE_COMPLETED: 'singleCustomSettings/UPDATE_COMPLETED',
  UPDATE_FAILED: 'singleCustomSettings/UPDATE_FAILED',

  CALCMODE_INPUTDATA_SET : 'singleCustomSettings/CALCMODE_INPUTDATA_SET',
  CALCMODE_CURR_SET : 'singleCustomSettings/CALCMODE_CURR_SET',

  RESET: 'singleCustomSettings/RESET',
}

const initialState = {
  single_custom_settings: null,

  calc_mode_input: SessionStorage.get(`DCF_calcMode_inputData`)??{},
  curr_calc_mode: SessionStorage.get(`DCF_currCalcMode`)??{}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
    case types.LOAD_FAILED:
    case types.UPDATE_REQUEST:
    case types.UPDATE_FAILED:
      return { ...state };

    case types.LOAD_COMPLETED:
    case types.UPDATE_COMPLETED:
      const { api, data } = { ...action.data };

      return {
        ...state,
        single_custom_settings: { ...state.single_custom_settings, [api]: data },
      };

        case types.CALCMODE_INPUTDATA_SET:
            SessionStorage.set(`DCF_calcMode_inputData`,
            {...state.calc_mode_input,
                [action.data.model]: {
                    ...state.calc_mode_input[action.data.model],
                    [action.data.stockCode]: {
                        ...state.calc_mode_input[action.data.model]?.[action.data.stockCode],
                        [action.data.calcMode]: action.data.input
                    }
                }
            });

            return {...state,
                calc_mode_input:
                {...state.calc_mode_input,
                    [action.data.model]: {
                        ...state.calc_mode_input[action.data.model],
                        [action.data.stockCode]: {
                            ...state.calc_mode_input[action.data.model]?.[action.data.stockCode],
                            [action.data.calcMode]: action.data.input
                        }
                    }
                }
            };
        case types.CALCMODE_CURR_SET:
            SessionStorage.set(`DCF_currCalcMode`, {...state.curr_calc_mode, [action.data.model]: { curr_calc_mode: action.data.calcMode }});
            return {...state, curr_calc_mode: {...state.curr_calc_mode, [action.data.model]: { curr_calc_mode: action.data.calcMode }}};

    case types.RESET:
      const settingsUpdated = { ...state.single_custom_settings };
      settingsUpdated[action.api_name] = null;

      return { ...state, single_custom_settings: settingsUpdated };

    default:
      return state;
  }
}

export const actions = {
  loadSingleCustomSettingsRequest: function(apiName) {
    return { type: types.LOAD_REQUEST, api_name: apiName };
  },
  loadSingleCustomSettingsCompleted: function(data) {
    return { type: types.LOAD_COMPLETED, data: data };
  },
  loadSingleCustomSettingsFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },

  updateSingleCustomSettingsRequest: function(apiName, data, stockCode = '') {
    return {
      type: types.UPDATE_REQUEST,
      api_name: apiName,
      data: data,
      stock_code: stockCode,
    };
  },
  updateSingleCustomSettingsCompleted: function(data) {
    return { type: types.UPDATE_COMPLETED, data: data };
  },
  updateSingleCustomSettingsFailed: function(error) {
    return { type: types.UPDATE_FAILED, error: error };
  },

    setCalcModeInputData: function(stockCode = '', model, calcMode, input) {
        return { type: types.CALCMODE_INPUTDATA_SET, data: { stockCode, model, calcMode, input }};
    },
    setCurrCalcMode: function(model, calcMode) {
        return { type: types.CALCMODE_CURR_SET, data: { model, calcMode }};
    },

  reset: function(apiName) {
      return { type: types.RESET, api_name: apiName };
  },
}
