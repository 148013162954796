export default {
  CATEGORY: {
    '0': {
      key: 'my-lab',
      title: '自建圖表',
      info: '自建圖表可安裝、歸納分類優分析所有免費與付費的分析套件',
    },
    '1': {
      key: 'my-lab',
      title: '自建圖表',
      info: '自建圖表可安裝、歸納分類優分析所有免費與付費的分析套件',
    },
    '3': {
      key: 'my-collection',
      title: '達人策略',
      info: `達人策略是一套有系統的投資策略，包括「精選股套件」、「個股評分套件」、「股價評估套件」。
  這些套件間具有整合性、相互連動，非常適合正在學習特定投資策略的投資人。
  此外，達人策略也能幫助投資人快速檢測持股，並追蹤財報與價值面的變化，提高分析效率。`,
    },
    '4': {
      key: 'my-collection',
      title: '達人策略',
      info: `達人策略是一套有系統的投資策略，包括「精選股套件」、「個股評分套件」、「股價評估套件」。
  這些套件間具有整合性、相互連動，非常適合正在學習特定投資策略的投資人。
  此外，達人策略也能幫助投資人快速檢測持股，並追蹤財報與價值面的變化，提高分析效率。`,
    },
    '5': {
      key: 'portfolio',
      title: '投資組合',
      info: '投資組合',
    },
    '6': {
      key: 'freebie',
      title: '股票資訊',
      info: '股票資訊',
    },

    custom: {
      key: 'my-lab',
      title: '自建圖表',
      info: '自建圖表可安裝、歸納分類優分析所有免費與付費的分析套件',
    },
    portfolio: {
      key: 'portfolio',
      title: '投資組合',
      info: '投資組合',
    },
  },
}
