export const types = { TOGGLE_MODAL: 'modal/TOGGLE_MODAL' }

const initialState = { open_id: {} }

export default function reducer(state = initialState, action = {}) {
  const { type, id, open } = action;
  
  if (type === types.TOGGLE_MODAL) {
    if (state.open_id[id] !== open) {
      return {
        ...state,
        open_id: { ...state.open_id, [id]: open ?? false },
      };
    }
  }
  return state;
}

export const actions = {
  toggleModal: function(id, open) {
    return { type: types.TOGGLE_MODAL, id: id, open: open };
  },
}
