import { createStore, applyMiddleware, combineReducers } from 'redux'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import * as reducers from './modules'
import { types } from './modules/Auth'


const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
	...reducers,
	routing : routerReducer
});

const rootReducer = (state, action) => {
	if (action.type === types.LOGOUT) {
		state = undefined;
  }

  return appReducer(state, action);
}

const AppStore = createStore(rootReducer, applyMiddleware(sagaMiddleware));

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, AppStore);

sagaMiddleware.run(rootSaga);


export { AppStore, history };
