import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Favorite'
import { FavoriteAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


function * watchFavGroupLoadRequest() {
  yield takeLatest(types.LOAD_REQUEST, favGroupLoadFlow);
}

function * favGroupLoadFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupLoadAPI),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.loadFavGroupDataCompleted(responseData.data));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.loadFavGroupDataFailed(responseData.error));
    }
  } catch(error) {
    yield put(actions.loadFavGroupDataFailed(error));
  }
}

function * watchFavGroupNameEditRequest() {
  yield takeLatest(types.NAME_EDIT_REQUEST, editFavGroupNameFlow);
}

function * editFavGroupNameFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupNameEditAPI, action.data),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.editFavGroupNameCompleted(action.data));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.editFavGroupNameFailed(responseData.error));
    }
  } catch(error) {
    yield put(actions.editFavGroupNameFailed(error));
  }
}

function * watchFavGroupDataSortRequest() {
  yield takeLatest(types.STOCKS_SORT_REQUEST, sortFavGroupStocksFlow);
}

function * sortFavGroupStocksFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupDataSortAPI, action.data),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.sortFavGroupStocksCompleted(action.data));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.sortFavGroupStocksFailed(responseData.error));
    }
  } catch(error) {
    yield put(actions.sortFavGroupStocksFailed(error));
  }
}

function * watchFavGroupStockAddRequest() {
  yield takeLatest(types.STOCK_ADD_REQUEST, addFavGroupStockFlow);
}

function * addFavGroupStockFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupStockAddAPI, action.data),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.addFavGroupStockCompleted(responseData.data));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.addFavGroupStockFailed(responseData.error));
    }
  } catch(error) {
    yield put(actions.addFavGroupStockFailed(error));
  }
}

function * watchFavGroupStockDeleteRequest() {
  yield takeLatest(types.STOCK_DELETE_REQUEST, deleteFavGroupStockFlow);
}

function * deleteFavGroupStockFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupStockDeleteAPI, action.data),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.deleteFavGroupStockCompleted(action.data));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.deleteFavGroupStockFailed(responseData.error));
    }
  }
  catch(error) {
    yield put(actions.deleteFavGroupStockFailed(error));
  }
}

function * watchFavGroupAllStocksDeleteRequest() {
  yield takeLatest(types.ALL_STOCKS_DELETE_REQUEST, deleteFavGroupAllStocksFlow);
}

function * deleteFavGroupAllStocksFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupAllStocksDeleteAPI, action),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.deleteFavGroupAllStocksCompleted(action));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.deleteFavGroupAllStocksFailed(responseData.error));
    }
  }
  catch(error) {
    yield put(actions.deleteFavGroupAllStocksFailed(error));
  }
}

function * watchFavGroupAddRequest() {
  yield takeLatest(types.ADD_REQUEST, addFavGroupFlow);
}

function * addFavGroupFlow(action) {
  try {
    const response = yield call(FavoriteAPIs.FavGroupAddAPI),
      responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.addFavGroupCompleted(responseData.data));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.addFavGroupFailed(responseData.error));
    }
  }
  catch(error) {
    yield put(actions.addFavGroupFailed(error));
  }
}

export default [
  watchFavGroupLoadRequest(),
  watchFavGroupNameEditRequest(),
  watchFavGroupDataSortRequest(),
  watchFavGroupStockAddRequest(),
  watchFavGroupStockDeleteRequest(),
  watchFavGroupAllStocksDeleteRequest(),
  watchFavGroupAddRequest(),
]
