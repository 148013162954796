import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Portfolio'
import { PortfolioAPIs } from 'api'

function * watchPortfolioLoadRequest() {
    yield takeLatest(types.LOAD_REQUEST, portfolioLoadFlow);
}
function responseHandler(response) {
    return response.data;
}

function * portfolioLoadFlow(action) {
    try {
        const response = yield call(PortfolioAPIs.PortfolioLoadAPI, action.id);
        const responseData = responseHandler(response);
        if (responseData.status === 'success') {
            yield put(actions.loadCompleted(responseData.data));
        }else{
            yield put(actions.loadFailed(responseData.error));
        }
    }
    catch(error) {
        yield put(actions.loadFailed(error));
    }
}

function * watchPortfolioCreateRequest() {
    yield takeLatest(types.CREATE_REQUEST, portfolioCreateFlow);
}

function * portfolioCreateFlow(action) {
    try {
        const response = yield call(PortfolioAPIs.PortfolioCreateAPI, action.new_data);
        const responseData = responseHandler(response);
        if (responseData.status === 'success') {
            yield put(actions.createCompleted(responseData.data));
        }else{
            yield put(actions.createFailed(responseData.error));
        }
    }
    catch(error) {
        yield put(actions.createFailed(error));
    }
}

function * watchPortfolioUpdateRequest() {
    yield takeLatest(types.UPDATE_REQUEST, portfolioUpdateFlow);
}

function * portfolioUpdateFlow(action) {
    try {
        const response = yield call(PortfolioAPIs.PortfolioUpdateAPI, action.labID, action.updated_data);
        const responseData = responseHandler(response);
        if (responseData.status === 'success') {
            yield put(actions.updateCompleted(responseData.data));
        }else{
            yield put(actions.updateFailed(responseData.error));
        }
    }
    catch(error) {
        yield put(actions.updateFailed(error));
    }
}

function * watchPortfolioDeleteRequest() {
    yield takeLatest(types.DELETE_REQUEST, portfolioDeleteFlow);
}

function * portfolioDeleteFlow(action) {
    try {
        const response = yield call(PortfolioAPIs.PortfolioDeleteAPI, action.id);
        const responseData = responseHandler(response);
        if (responseData.status === 'success') {
            yield put(actions.deleteCompleted(responseData.data));
        }else{
            yield put(actions.deleteFailed(responseData.error));
        }
    }
    catch(error) {
        yield put(actions.deleteFailed(error));
    }
}

function * watchPortfolioStockRequest() {
    yield takeLatest(types.GET_STOCK_REQUEST, portfolioStockFlow);
}

function * portfolioStockFlow(action) {
    try {
        const response = yield call(PortfolioAPIs.PortfolioStockAPI, action.stocks);
        const responseData = responseHandler(response);
        if (responseData.status === 'OK') {
            yield put(actions.getStockCompleted(responseData.data));
        }else{
            yield put(actions.getStockFailed(responseData.error));
        }
    }
    catch(error) {
        yield put(actions.getStockFailed(error));
    }
}


export default [
    watchPortfolioLoadRequest(),
    watchPortfolioCreateRequest(),
    watchPortfolioUpdateRequest(),
    watchPortfolioDeleteRequest(),
    watchPortfolioStockRequest()
]