import React from 'react'
import { Modal } from 'components'
import Renewbox from './components'
import 'assets/sass/Page/renew.scss'


const MODAL_ID = 'renew';
const Renew = (props) => {
	const {
			className,
			btnName,
			product,
			selected,
			history,
			onPayHandler,
			onPlanIDSet,
			onModalToggle,
		} = props;

	if (product && product.renew_product) {
		const renewProduct = product.renew_product;
		const onModalOpen = () => onModalToggle(MODAL_ID, true);
		const changeSubscriptionState = (e) => {
			e.preventDefault();

			onPayHandler(product, renewProduct.plan_id);
			onPlanIDSet(renewProduct.id);
			onModalToggle(MODAL_ID);

			history.push('/payment');
		};

		return (
			<div className={`${MODAL_ID} ${className || ''}`}>
				<div
					className="ua-btn default renew-btn"
					onClick={onModalOpen}
				>
					{btnName}
				</div>

				<Modal
					id={MODAL_ID}
					customClassName="renew-modal"
					title="續約優惠"
					html={<Renewbox renewProduct={renewProduct} />}
					leaveTxt="取消"
					checkBtn={
						<button
							className="submit renew-submit ua-btn default"
							disabled={selected}
							onClick={changeSubscriptionState}
						>
							{!selected ? '立即續約' : '已續約'}
						</button>
					}
				/>
			</div>
		);
	}

	return null;
}


export { Renew }
