import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Avatar'
import { AvatarAPIs } from 'api'

function * watchAvatarLoadRequest() {
    yield takeLatest(types.LOAD_REQUEST, avatarLoadFlow);
}
function responseHandler(response) {
    return response.data;
}

function * avatarLoadFlow(action) {
    try {
        const response = yield call(AvatarAPIs.AvatarLoadAPI),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadCompleted(responseData.data));
        } else {
            yield put(actions.loadFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadFailed(error));
    }
}

function * watchAvatarAddRequest() {
    yield takeLatest(types.ADD_REQUEST, avatarAddFlow);
}

function * avatarAddFlow(action) {
    try {
        const formData = new FormData();
        formData.append('avatar', action.avatar);

        const response = yield call(AvatarAPIs.AvatarAddAPI, formData),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.addCompleted(responseData.data));
        } else {
            yield put(actions.addFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.addFailed(error));
    }
}

function * watchAvatarUpdateRequest() {
    yield takeLatest(types.UPDATE_REQUEST, avatarUpdateFlow);
}

function * avatarUpdateFlow(action) {
    try {
        const formData = new FormData();
        formData.append('avatar', action.avatar);
        formData.append('_method', 'PUT');

        const response = yield call(AvatarAPIs.AvatarUpdateAPI, formData),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.updateCompleted(responseData.data));
        } else {
            yield put(actions.updateFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.updateFailed(error));
    }
}


export default [
    watchAvatarLoadRequest(),
    watchAvatarAddRequest(),
    watchAvatarUpdateRequest()
]
