import { Message } from 'components'
import { types as typesAuth } from 'appRedux/modules/Auth'
import { types as typesPassword } from 'appRedux/modules/Password'
import { types as typesProfile } from 'appRedux/modules/Profile'


function MessageHandler(message) {
	switch(message) {
        case typesAuth.LOGIN_SUCCESS:
        	return { title: '歡迎回來', content: '' };

        case typesAuth.REGISTER_SUCCESS:
            return { title: '註冊成功', content: '' };

        case typesAuth.VERIFIED_EMAIL_SEND_SUCCESS:
            return { title: '驗證信已寄出', content: '請至註冊信箱查看' };

        case typesPassword.FORGOT_SUCCESS:
            return { title: '重設密碼連結已寄出', content: '請至註冊信箱查看' };

        case typesPassword.PASSWORD_RESET_ERROR:
            return { title: '密碼重設成功', content: '' };

        case typesAuth.PASSWORD_SET_SUCCESS:
            return { title: '密碼設定成功', content: '' };

        case typesProfile.UPDATE_SUCCESS:
            return { title: '資料編輯成功', content: '' };

        default:
						return false;
    }
}


export default function ResponseMessageHandler(message) {
    const MessageData = MessageHandler(message);

    if (MessageData) {
        Message.SmallBox({
						title   : `<i class="fa fa-check-circle txt-white" aria-hidden="true"></i>
${MessageData.title}`,
						content : MessageData.content,
						timeout : 3000
				});
    }

    return message;
}
