import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions } from 'appRedux/modules/Modal'
import { Modal as component } from './Modal'


const mapStateToProps = (state) => ({
	...state.Layout, open_id: state.Modal.open_id,
});
const mapDispatchToProps = (dispatch) => ({
	onModalToggle: (id, open) => dispatch(actions.toggleModal(id, open)),
});
const Modal = withRouter(connect(mapStateToProps, mapDispatchToProps)(component));


export { Modal }
