import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Order'
import { OrderAPIs } from 'api'
import { ResponseErrorHandler} from 'utils'


function * watchConfigRequest() {
    yield takeLatest(types.CONFIG_REQUEST, configFlow);
}

function * configFlow(action) {
    try {
        const response = yield call(OrderAPIs.configAPI),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.configCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.configFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.checkoutFailed(error));
    }
}

function * watchTrialRequest() {
    yield takeLatest(types.TRIAL_REQUEST, trialFlow);
}

function * trialFlow(action) {
    try {
        const response = yield call(OrderAPIs.trialAPI, action.products, action.promocodes, action.modules);
        const responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.trialCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.trialFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.trialFailed(error));
    }
}

function * watchCheckoutRequest() {
    yield takeLatest(types.CHECKOUT_REQUEST, checkoutFlow);
}

function * checkoutFlow(action) {
    try {
        const response = yield call(OrderAPIs.checkoutAPI, action.orderData);
        const responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.checkoutCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.checkoutFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.checkoutFailed(error));
    }
}

export default [
    watchConfigRequest(),
    watchTrialRequest(),
    watchCheckoutRequest()
]
