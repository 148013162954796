import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/DropzoneContainer'
import DropzoneContainer from './DropzoneContainer'


const mapStateToProps = (state) => ({}),
	mapDispatchToProps = (dispatch, ownProps) => ({ onDropGet: (files) => dispatch(actions.getDrop(files)) })


export default connect(mapStateToProps, mapDispatchToProps)(DropzoneContainer)
