import arrayMove from 'array-move'
import { cloneDeep as _cloneDeep } from 'lodash'


export const types = {
  LIST_LOAD_REQUEST          : 'laboratory/LIST_LOAD_REQUEST',
  LIST_LOAD_COMPLETED        : 'laboratory/LIST_LOAD_COMPLETED',
  LIST_LOAD_FAILED           : 'laboratory/LIST_LOAD_FAILED',
  // add lab
  LAB_CREATE_REQUEST         : 'laboratory/LAB_CREATE_REQUEST',
  LAB_CREATE_COMPLETED       : 'laboratory/LAB_CREATE_COMPLETED',
  LAB_CREATE_FAILED          : 'laboratory/LAB_CREATE_FAILED',
  // sort labs
  LIST_SORT                  : 'laboratory/LIST_SORT',
  LIST_SORT_REQUEST          : 'laboratory/LIST_SORT_REQUEST',
  LIST_SORT_COMPLETED        : 'laboratory/LIST_SORT_COMPLETED',
  LIST_SORT_FAILED           : 'laboratory/LIST_SORT_FAILED',
  // delete lab
  LAB_DELETE_SET             : 'laboratory/LAB_DELETE_SET',
  LAB_DELETE_REQUEST         : 'laboratory/LAB_DELETE_REQUEST',
  LAB_DELETE_COMPLETED       : 'laboratory/LAB_DELETE_COMPLETED',
  LAB_DELETE_FAILED          : 'laboratory/LAB_DELETE_FAILED',
  // edit lab singles
  LAB_EDIT_PROCESS_CHANGE    : 'laboratory/LAB_EDIT_PROCESS_CHANGE',

  ALL_SINGLES_LOAD_REQUEST   : 'laboratory/ALL_SINGLES_LOAD_REQUEST',
  ALL_SINGLES_LOAD_COMPLETED : 'laboratory/ALL_SINGLES_LOAD_COMPLETED',
  ALL_SINGLES_LOAD_FAILED    : 'laboratory/ALL_SINGLES_LOAD_FAILED',

  // edit lab singles
  LAB_UPDATE_REQUEST         : 'laboratory/LAB_UPDATE_REQUEST',
  LAB_UPDATE_COMPLETED       : 'laboratory/LAB_UPDATE_COMPLETED',
  LAB_UPDATE_FAILED          : 'laboratory/LAB_UPDATE_FAILED',
  // delete lab single
  SINGLE_DELETE_REQUEST      : 'laboratory/SINGLE_DELETE_REQUEST',
  SINGLE_DELETE_COMPLETED    : 'laboratory/SINGLE_DELETE_COMPLETED',
  SINGLE_DELETE_FAILED       : 'laboratory/SINGLE_DELETE_FAILED',

  SINGLES_CLEAR               : 'laboratory/SINGLES_CLEAR',
  RESET                      : 'laboratory/RESET',
}

const initialState = {
  // data load
  labs             : [],
  loaded           : false,
  failed           : false,
  // lab list
  collection_labs  : [],
  free_labs        : [],
  my_labs          : [],
  portfolio_labs   : [],
  // edit
  process          : 'edit',
  // collections_editable : [],
  singles_editable : null,
  title            : '',
  lab_id_add       : null,
  lab_type_sort    : null,
  lab_id_delete    : null
}

/*
 category
 0, 1 : custom labs
 3, 4 : collections
 5    : portfolios
 6    : freeies
 */
const seperateLabs = (labs, myLabs, collectionLabs, portfolioLabs, freeLabs) => {
  for (let i = 0; i < labs.length; i++) {
    if (labs[i].category === 3 || labs[i].category === 4) {
      collectionLabs.push(labs[i]);
    } else if (labs[i].category === 5) {
      portfolioLabs.push(labs[i]);
    } else if (labs[i].category === 0 /*&& labs[i].customized === 1*/) {
      myLabs.push(labs[i]);
    } else if (labs[i].category === 6) {
      freeLabs.push(labs[i]);
    }
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LIST_LOAD_REQUEST:
      return { ...state, loaded: false, failed: false };

    case types.LIST_LOAD_COMPLETED:
      let data = [...action.data] || [];
      let myLabs = [];
      let collectionLabs = [];
      let portfolioLabs = [];
      let freeLabs = [];

      seperateLabs(data, myLabs, collectionLabs, portfolioLabs, freeLabs);

      return {
        ...state,
        labs: data,
        my_labs: myLabs,
        collection_labs: collectionLabs,
        portfolio_labs: portfolioLabs,
        free_labs: freeLabs,
        loaded: true,
        failed: false,
      };

    case types.LIST_LOAD_FAILED:
      return { ...state, loaded: false, failed: true };

    case types.LAB_CREATE_REQUEST:
    case types.LIST_SORT_REQUEST:
    case types.LAB_UPDATE_REQUEST:
    case types.LAB_DELETE_REQUEST:
    case types.ALL_SINGLES_LOAD_REQUEST:
    case types.ALL_SINGLES_LOAD_FAILED:
    case types.SINGLE_DELETE_REQUEST:
      let stateUpdated = { ...state };

      return stateUpdated;

    case types.LAB_CREATE_COMPLETED:
      return {
        ...state,
        title      : '新增',
        process    : 'success',
        lab_id_add : action.data.id
      };

    case types.LIST_SORT_COMPLETED:
      return {
        ...state,
        title: '編輯',
        process: 'success'
      };

    case types.LAB_UPDATE_COMPLETED:
      return { ...state, title: '編輯', process: 'success' };

    case types.LAB_DELETE_COMPLETED:
      return { ...state, title: '刪除', process: 'success' };

    case types.ALL_SINGLES_LOAD_COMPLETED:
      const { /*collections,*/ singles } = action.data;

      return {
        ...state,
        // collections_editable: collections,
        singles_editable: singles
      };
      // return { ...state, singles_editable: action.data };

    case types.SINGLE_DELETE_COMPLETED:
      return { ...state, title: '刪除', process: 'success' };

    case types.LAB_CREATE_FAILED:
      return { ...initialState, title: '新增', process: 'failed' };

    case types.LIST_SORT_FAILED:
      return { ...initialState };

    case types.LAB_DELETE_FAILED:
      return { ...initialState, process: 'failed' };

    case types.LIST_SORT:
      let labSorted = arrayMove(action.labs, action.idx_old, action.idx_new);
      labSorted = _cloneDeep(labSorted);

      return {
        ...state,
        lab_type_sort   : action.sort_type,
        my_labs         : (action.sort_type === 'custom') ? labSorted : state.my_labs,
        collection_labs : (action.sort_type === 'master') ? labSorted : state.collection_labs,
        portfolio_labs  : (action.sort_type === 'portfolio') ? labSorted : state.portfolio_labs
      };

    case types.LAB_EDIT_PROCESS_CHANGE:
      return { ...state, process: action.process };

    case types.LAB_UPDATE_FAILED:
      return { ...initialState, title: '編輯', process: 'failed' };

    case types.LAB_DELETE_SET:
      return { ...state, lab_id_delete: action.labID };

    case types.SINGLE_DELETE_FAILED:
      return { ...initialState, title: '刪除', process: 'failed' };

    case types.RESET:
      return {
        ...state,
        // loaded           : false,
        failed           : false,
        process          : 'edit',
        singles_editable : null,
        title            : '',
        lab_id_add       : null,
        lab_type_sort    : null,
        lab_id_delete    : null
     };

    default:
      return state;
  }
}

export const actions = {
  loadLabListRequest: function () {
    return { type: types.LIST_LOAD_REQUEST };
  },
  loadLabListCompleted: function(data) {
    return { type: types.LIST_LOAD_COMPLETED, data: data };
  },
  loadLabListFailed: function(error) {
    return { type: types.LIST_LOAD_FAILED, error: error };
  },

  createLabRequest: function(newLab) {
    return { type: types.LAB_CREATE_REQUEST, newLab: newLab };
  },
  createLabCompleted: function(data) {
    return { type: types.LAB_CREATE_COMPLETED, data: data };
  },
  createLabFailed: function(error) {
    return { type: types.LAB_CREATE_FAILED, error: error };
  },

  sortLabList: function(labs, oldIndex, newIndex, type) {
    return {
      type: types.LIST_SORT,
      labs: labs,
      idx_old: oldIndex,
      idx_new: newIndex,
      sort_type: type
    };
  },
  sortLabListRequest: function(sortedLabs) {
    return { type: types.LIST_SORT_REQUEST, sortedLabs: sortedLabs };
  },
  sortLabListCompleted: function(data) {
    return { type: types.LIST_SORT_COMPLETED, data: data };
  },
  sortLabListFailed: function(error) {
    return { type: types.LIST_SORT_FAILED, error: error };
  },

  setDeleteLab: function(labID) {
    return { type: types.LAB_DELETE_SET, labID: labID };
  },
  deleteLabRequest: function(labID) {
    return { type: types.LAB_DELETE_REQUEST, labID: labID };
  },
  deleteLabCompleted: function(data) {
    return { type: types.LAB_DELETE_COMPLETED, data: data };
  },
  deleteLabFailed: function(error) {
    return { type: types.LAB_DELETE_FAILED, error: error };
  },

  changeLabProcess: function(process) {
    return { type: types.LAB_EDIT_PROCESS_CHANGE, process: process };
  },

  loadAllSinglesRequest: function(id) {
    return { type: types.ALL_SINGLES_LOAD_REQUEST, id: id };
  },
  loadAllSinglesCompleted: function(data) {
    return { type: types.ALL_SINGLES_LOAD_COMPLETED, data: data };
  },
  loadAllSinglesFailed: function(error) {
    return { type: types.ALL_SINGLES_LOAD_FAILED, error: error };
  },

  updateLabRequest: function(labID, updatedLab) {
    return { type: types.LAB_UPDATE_REQUEST, labID: labID, updatedLab: updatedLab };
  },
  updateLabCompleted: function(data) {
    return { type: types.LAB_UPDATE_COMPLETED, data: data };
  },
  updateLabFailed: function(error) {
    return { type: types.LAB_UPDATE_FAILED, error: error };
  },

  deleteSingleRequest: function(labID,products) {
    return { type: types.SINGLE_DELETE_REQUEST, labID: labID, products: products };
  },
  deleteSingleCompleted: function(data) {
    return { type: types.SINGLE_DELETE_COMPLETED, data: data };
  },
  deleteSingleFailed: function(error) {
    return { type: types.SINGLE_DELETE_FAILED, error: error };
  },

  clearSingles: function () {
    return { type: types.SINGLES_CLEAR };
  },
  reset: function () {
    return { type: types.RESET };
  }
}
