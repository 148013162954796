export const types = {
  LOAD_REQUEST     : 'avatar/LOAD_REQUEST',
  LOAD_COMPLETED   : 'avatar/LOAD_COMPLETED',
  LOAD_FAILED      : 'avatar/LOAD_FAILED',
  ADD_REQUEST      : 'avatar/ADD_REQUEST',
  ADD_COMPLETED    : 'avatar/ADD_COMPLETED',
  ADD_FAILED       : 'avatar/ADD_FAILED',
  UPDATE_REQUEST   : 'avatar/UPDATE_REQUEST',
  UPDATE_COMPLETED : 'avatar/UPDATE_COMPLETED',
  UPDATE_FAILED    : 'avatar/UPDATE_FAILED',
  RESET            : 'avatar/RESET',
}

const initialState = {
  user_avatar: `${process.env.REACT_APP_DOMAIN}/assets/img/default/default-user.png`,
  loaded: false,
  edit_avatar_process: 0,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
      return { ...state, loaded: false };

    case types.LOAD_COMPLETED:
      return {
        ...state,
        user_avatar: action.avatar.length ? action.avatar[0].url
            : initialState.user_avatar,
        loaded: true,
      };

    case types.LOAD_FAILED:
      return { ...initialState };

    case types.ADD_REQUEST:
    case types.UPDATE_REQUEST:
      return { ...state, avatar: action.avatar };

    case types.ADD_COMPLETED:
    case types.UPDATE_COMPLETED:
      return { ...state, user_avatar: action.avatar.url, edit_avatar_process: 3 };

    case types.ADD_FAILED:
    case types.UPDATE_FAILED:
      return { ...state, edit_avatar_process: 4 };

    case types.RESET:
      return { ...state, edit_avatar_process: action.process };

    default:
      return state;
  }
}

export const actions = {
  loadRequest: function () {
    return { type: types.LOAD_REQUEST };
  },
  loadCompleted: function(avatar) {
    return { type: types.LOAD_COMPLETED, avatar: avatar };
  },
  loadFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },
  addRequest: function(avatar) {
    return { type: types.ADD_REQUEST, avatar: avatar };
  },
  addCompleted: function(avatar) {
    return { type: types.ADD_COMPLETED, avatar: avatar };
  },
  addFailed: function(error) {
    return { type: types.ADD_FAILED, error: error };
  },
  updateRequest: function(avatar) {
    return { type: types.UPDATE_REQUEST, avatar: avatar };
  },
  updateCompleted: function(avatar) {
    return { type: types.UPDATE_COMPLETED, avatar: avatar };
  },
  updateFailed: function(error) {
    return { type: types.UPDATE_FAILED, error: error };
  },
  reset: function(process) {
    return { type: types.RESET, process: process };
  },
}
