export const types = {
  DROP: 'dropzoneContainer/DROP',
  RESET: 'dropzoneContainer/RESET',
}

const initialState = { files: [] }

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.DROP:
      return { ...state, files: action.files };

    case types.RESET:
      return { ...state, files: [] };

    default:
      return state;
  }
}

export const actions = {
  getDrop: function(files) {
    return { type: types.DROP, files: files };
  },
  resetFile: function () {
    return { type: types.RESET };
  },
}
