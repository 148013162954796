import { call, put, takeLatest } from 'redux-saga/effects'
import { LaboratoryAPIs } from 'api'
import { types, actions } from '../modules/Laboratory'


function * watchLabListLoadRequest() {
    yield takeLatest(types.LIST_LOAD_REQUEST, loadLabListFlow);
}

function responseHandler(response) {
    return response.data.data;
}

function * loadLabListFlow(action) {
    try {
        const response = yield call(LaboratoryAPIs.LabListLoadAPI, action.data);
        const responseData = responseHandler(response);

        yield put(actions.loadLabListCompleted(responseData));
    } catch(error) {
        yield put(actions.loadLabListFailed(error));
    }
}

function * watchAllSinglesLoadRequest() {
    yield takeLatest(types.ALL_SINGLES_LOAD_REQUEST, loadAllSinglesFlow);
}

function * loadAllSinglesFlow(action) {
    try {
        const response = yield call(LaboratoryAPIs.AllSinglesLoadAPI, action.id),
            responseData = responseHandler(response);

        yield put(actions.loadAllSinglesCompleted(responseData));
    } catch(error) {
        yield put(actions.loadAllSinglesFailed(error));
    }
}

function * watchLabCreateRequest() {
    yield takeLatest(types.LAB_CREATE_REQUEST, createLabFlow);
}

function * createLabFlow(action) {
  try {
    const response = yield call(LaboratoryAPIs.LabCreateAPI, action.newLab);
    const responseData = responseHandler(response);

    if (responseData) {
      yield put(actions.createLabCompleted(responseData));
    } else {
      yield put(actions.createLabFailed(response.data.error.message));
    }
  } catch(error) {
    yield put(actions.createLabFailed(error));
  }
}

function * watchLabListSortRequest() {
    yield takeLatest(types.LIST_SORT_REQUEST, sortLabListFlow);
}

function * sortLabListFlow(action) {
    try {
        const response = yield call(LaboratoryAPIs.LabListSortAPI, action.sortedLabs),
            responseData = responseHandler(response);

        yield put(actions.sortLabListCompleted(responseData));
    } catch(error) {
        yield put(actions.sortLabListFailed(error));
    }
}

function * watchLabUpdateRequest() {
    yield takeLatest(types.LAB_UPDATE_REQUEST, updateLabFlow);
}

function * updateLabFlow(action) {
  try {
    const response = yield call(
      LaboratoryAPIs.LabUpdateAPI,
      action.labID,
      action.updatedLab
    );
    const responseData = responseHandler(response);

    if (responseData) {
      yield put(actions.updateLabCompleted(responseData));
    } else {
      yield put(actions.updateLabFailed(response.data.error.message));
    }
  } catch(error) {
    yield put(actions.updateLabFailed(error));
  }
}

function * watchSingleDeleteRequest() {
    yield takeLatest(types.LAB_DELETE_REQUEST, deleteLabFlow);
}

function * deleteLabFlow(action) {
    try {
        const response = yield call(LaboratoryAPIs.LabDeleteAPI, action.labID),
            responseData = responseHandler(response);

        yield put(actions.deleteLabCompleted(responseData));
    } catch(error) {
        yield put(actions.deleteLabFailed(error));
    }
}

function * watchLabDeleteRequest() {
    yield takeLatest(types.SINGLE_DELETE_REQUEST, deleteSingleFlow);
}

function * deleteSingleFlow(action) {
    try {
        const response = yield call(LaboratoryAPIs.LabProductDeleteAPI, action.labID, action.products),
            responseData = responseHandler(response);

        yield put(actions.deleteSingleCompleted(responseData));
    } catch(error) {
        yield put(actions.deleteSingleFailed(error));
    }
}


export default [
    watchLabListLoadRequest(),
    watchAllSinglesLoadRequest(),
    watchLabCreateRequest(),
    watchLabListSortRequest(),
    watchLabUpdateRequest(),
    watchSingleDeleteRequest(),
    watchLabDeleteRequest()
]
