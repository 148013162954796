import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/SingleAiChat'
import AiChat from './AiChat'
import { QUESTIONS } from './data'


const mapStateToProps = (state, ownProps) => {
	const { option, stock, id } = ownProps;
	const { industry_classification, single_data_type } = option;
	const category = single_data_type ?? 'stock';
	const { loading, qas, qa } = state.SingleAiChat;

	let subcategory = category;
	let qasData = [];

	if (industry_classification) {
		subcategory = industry_classification;
	} else {
		subcategory = stock.stock_code;
	}

	if (option.questions) {
		qasData = option.questions.map((d) => ({
			show: d.show??d.label, actual: d.actual??d.value
		}));
	} else {
		switch (category) {
			case 'AnnualReport':
				if (`${id}:${category}:${subcategory}` in qas) {
					qasData = qas[`${id}:${category}:${subcategory}`];
				}

				break;

			case 'industry':
				qasData = (subcategory in QUESTIONS)
					? QUESTIONS[subcategory]
					:
					QUESTIONS[category];

				break;

			case 'stock':
				qasData = QUESTIONS[category];

				break;

			default:

		}
	}

	const loadingData = (`${id}:${category}:${subcategory}` in loading)
		?
		loading[`${id}:${category}:${subcategory}`]
		:
		false;
	const qaData = (`${id}:${category}:${subcategory}` in qa)
		?
		qa[`${id}:${category}:${subcategory}`]
		:
		{};

	return {
		category: category,
		ai_chat_loading: loadingData,
		qas: qasData,
		qa: qaData,
	};
};
const mapDispatchToProps = (dispatch, ownProps) => {
	const { option, stock, id } = ownProps;
	const { industry_classification, single_data_type } = option;
	const category = single_data_type ?? 'stock';

	let subcategory = category;

	if (industry_classification) {
		subcategory = industry_classification;
	} else {
		subcategory = stock.stock_code;
	}

	return {
		onQADataLoadRequest: (data) => dispatch(
			actions.loadDataRequest(id, category, subcategory, data)
		),

		onQuestionSet: (data) => dispatch(
			actions.setQuestion(id, data, category, subcategory)
		),

		onQAAnswerGetRequest: (data) => dispatch(
			actions.getAnswerRequest(id,data, category, subcategory)
		),

		onReset: () => dispatch(actions.reset(id, category, subcategory)),
		onClear: () => dispatch(actions.clear(id, category, subcategory)),
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(AiChat)
