export default function UrlQuery(name, url = false) {
  if (!url) { url = window.location.href; }

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) { return null; }

  if (!results[2]) { return ''; }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
