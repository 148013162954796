import { cloneDeep as _cloneDeep, values as _values } from 'lodash'
import moment from 'moment'
import config from 'config'


const REUTERS = [ 'revenue', 'eps', 'gm', 'opm' ];
const types = {
  LIST_SET: 'stockComparison/LIST_SET',

  LOAD_REQUEST: 'stockComparison/LOAD_REQUEST',
  LOAD_COMPLETED: 'stockComparison/LOAD_COMPLETED',
  LOAD_FAILED: 'stockComparison/LOAD_FAILED',

  LOAD_BUBBLE_DATA_REQUEST: 'stockComparison/LOAD_BUBBLE_DATA_REQUEST',
  LOAD_BUBBLE_DATA_COMPLETED: 'stockComparison/LOAD_BUBBLE_DATA_COMPLETED',
  LOAD_BUBBLE_DATA_FAILED: 'stockComparison/LOAD_BUBBLE_DATA_FAILED',

  DATA_SET: 'stockComparison/DATA_SET',

  DATA_CLEAR: 'stockComparison/DATA_CLEAR',
}
const initialState = {
  bubble_data: {},
  bubble_cache: {},
  stock_list: [],
  stock_data: [],
  stock_cache: {},
  stock_error: false,
};

for (let i = 0; i < REUTERS.length; i++) {
  types[`LOAD_REUTERS_ESTIMATE_${REUTERS[i].toUpperCase()}_REQUEST`] = `stockComparison/LOAD_REUTERS_ESTIMATE_${REUTERS[i].toUpperCase()}_REQUEST`;
  types[`LOAD_REUTERS_ESTIMATE_${REUTERS[i].toUpperCase()}_COMPLETED`] = `stockComparison/LOAD_REUTERS_ESTIMATE_${REUTERS[i].toUpperCase()}_COMPLETED`;
  types[`LOAD_REUTERS_ESTIMATE_${REUTERS[i].toUpperCase()}_FAILED`] = `stockComparison/LOAD_REUTERS_ESTIMATE_${REUTERS[i].toUpperCase()}_FAILED`;
  initialState[`reuters_estimate_${REUTERS[i]}_data`] = {};
  initialState[`reuters_estimate_${REUTERS[i]}_cache`] = {};
}

export default function reducer(state = initialState, action = {}) {
  const EXPIRED = config.stock.cache_expired;
  const dataLoading = { stock_code: action.stock_code, data: 'loading' };

  switch (action.type) {
    case types.LIST_SET:
      const stockCode = action.stock_code;
      const checked = action.checked ?? false;

      let listUpdated = _cloneDeep(state.stock_list);

      const idxActive = listUpdated.indexOf(listUpdated.find((item) => (
        item.id === stockCode
      )));

      if (idxActive === -1) {
        listUpdated = [ ...listUpdated, { id: stockCode, value: checked } ];

        return { ...state, stock_list: listUpdated };
      } else {
        listUpdated[idxActive].value = checked

        return { ...state, stock_list: listUpdated, [stockCode]: checked };
      }

    case types.LOAD_REQUEST:
      let stockDataUnupdated = [ ...state.stock_data ];

      if (stockDataUnupdated.filter((item) => (
        item.stock_code === action.stock_code
      )).length > 0) {
        for (let i = 0; i < stockDataUnupdated.length; i++) {
          const temp = stockDataUnupdated[i];

          if (temp.stock_code === action.stock_code) {
            stockDataUnupdated[i] = dataLoading;
          }
        }
      } else {
        stockDataUnupdated.push(dataLoading);
      }

      return { ...state, stock_data: stockDataUnupdated };

    case types.LOAD_COMPLETED:
      const dataUpdated = action.data;
      const cache = {
        ...state.stock_cache,
        [action.stock_code]: {
          data: dataUpdated,
          time: moment().add(EXPIRED, 'minutes').format(),
        }
      };

      let stockDataUpdated = [ ...state.stock_data ];

      for (let i = 0; i < stockDataUpdated.length; i++) {
        const temp = stockDataUpdated[i];

        if (temp.stock_code === action.stock_code) {
          stockDataUpdated[i] = dataUpdated;
        }
      }

      return { ...state, stock_data: stockDataUpdated, stock_cache: cache };

    case types.LOAD_FAILED:
      console.log(types.LOAD_FAILED, action);

      let dataFailed = [ ...state.stock_data ];

      for (let i = 0; i < dataFailed.length; i++) {
        const temp = dataFailed[i];

        if (temp.stock_code !== action.stock_code) { continue; }

        dataFailed[i].data = false;
      }

      return { ...state, stock_data: dataFailed, stock_error: action.error };

    case types.LOAD_BUBBLE_DATA_REQUEST:
      dataLoading.Data = 'loading';

      let bubbleDataUnupdated = { ...state.bubble_data };

      if (!bubbleDataUnupdated[action.stock_code]) {
        bubbleDataUnupdated[action.stock_code] = dataLoading;
      }

      return { ...state, bubble_data: bubbleDataUnupdated };

    case types.LOAD_BUBBLE_DATA_COMPLETED:
      const bubbleData = action.data?.display;

      for (const key in bubbleData) {
        const bubble = bubbleData[key];
        bubble.key = key;
      }

      const bubbleUpdated = {
        stock_code: action.data?.stock_code,
        stock_name: action.data?.stock_name,
        Data: bubbleData,
        country: action.data?.country,
      };
      const bubbleCache = {
        ...state.bubble_cache,
        [action.stock_code]: {
          data: bubbleUpdated,
          time: moment().add(EXPIRED, 'minutes').format(),
        }
      };

      let bubbleDataUpdated = { ...state.bubble_data };

      for (let i = 0; i < state.stock_list.length; i++) {
        const temp = state.stock_list[i];

        if (temp.id === action.stock_code) {
          bubbleDataUpdated[action.stock_code] = bubbleUpdated;
        }
      }

      return {
        ...state,
        bubble_data: bubbleDataUpdated,
        bubble_cache: bubbleCache,
      };

    case types.LOAD_BUBBLE_DATA_FAILED:
      const bubbleFailed = { ...state.bubble_data };
      bubbleFailed[action.stock_code] = false;

      return { ...state, bubble_data: dataFailed, stock_error: action.error };

    case types.DATA_SET:
      const dataKey = action.key;
      const dataSet = action.data;
      const dataStock = dataSet.stock_code;

      let dataSetUpdated = null;

      if (dataKey === 'stock_data') {
        dataSetUpdated = [ ...state.stock_data ];

        if (dataSetUpdated.filter((item) => (item.stock_code
            === dataStock)).length > 0) {
          for (let i = 0; i < dataSetUpdated.length; i++) {
            const temp = dataSetUpdated[i];

            if (temp.stock_code === action.stock_code) {
              dataSetUpdated[i] = dataSet;
            }
          }
        } else {
          dataSetUpdated.push(dataSet);
        }
      } else {
        dataSetUpdated = { ...state[dataKey] };
        dataSetUpdated[dataStock] = dataSet;
      }

      return { ...state, [dataKey]: dataSetUpdated };

    case types.DATA_CLEAR:
      return {
        ...state,
        stock_list: [],
        stock_data: [],
        reuters_estimate_revenue_data: {},
        reuters_estimate_eps_data: {},
        stock_error: false,
      };

    default:
      // reuters
      if (action.type.includes('REUTERS') && action.type.includes('REQUEST')) {
        const reutersEstimateLoading = {
          stock_code: action.stock_code,
          YearData: 'loading',
          PeriodData: 'loading',
        };

        let reutersEstimateLoadingKey = action.api.toLowerCase();
        reutersEstimateLoadingKey = `reuters_estimate_${reutersEstimateLoadingKey}_data`;

        let reutersEstimateUnupdated = { ...state[reutersEstimateLoadingKey] };

        if (!reutersEstimateUnupdated[action.stock_code]) {
          reutersEstimateUnupdated[action.stock_code] = reutersEstimateLoading;
        }

        return {
          ...state,
          [reutersEstimateLoadingKey]: reutersEstimateUnupdated,
        };
      } else if (action.type.includes('REUTERS') && action.type.includes('COMPLETED')) {
        const { country, data, stock_name } = action.data;
        const dataLoadKey = action.api.toLowerCase();
        const reutersEstimateDataKey = `reuters_estimate_${dataLoadKey}_data`;
        const reutersEstimateCacheKey = `reuters_estimate_${dataLoadKey}_cache`;

        let reutersEstimate = _values(data)[0];
        reutersEstimate = {
          ...reutersEstimate,
          ChineseAccount: stock_name,
          country: country,
          EnglishAccount: `${reutersEstimate.EnglishAccount}_${action.stock_code}`,
          stock_code: action.stock_code,
        };

        const reutersEstimateCache = {
          ...state[reutersEstimateCacheKey],
          [action.stock_code]: {
            data: reutersEstimate,
            time: moment().add(EXPIRED, 'minutes').format(),
          }
        };

        let reutersEstimateUpdated = { ...state[reutersEstimateDataKey] };

        for (let i = 0; i < state.stock_list.length; i++) {
          const temp = state.stock_list[i];

          if (temp.id === action.stock_code) {
            reutersEstimateUpdated[action.stock_code] = {
              ...reutersEstimate,
              Order: i + 1,
            };
          }
        }

        return {
          ...state,
          [reutersEstimateDataKey]: reutersEstimateUpdated,
          [reutersEstimateCacheKey]: reutersEstimateCache,
        };
      } else if (action.type.includes('REUTERS') && action.type.includes('FAILED')) {
        let keyFailed = action.api.toLowerCase();
        keyFailed = `reuters_estimate_${keyFailed}_data`;

        const dataReutersFailed = { ...state[keyFailed] };
        dataReutersFailed[action.stock_code] = false;

        return {
          ...state,
          [keyFailed]: dataReutersFailed,
          stock_error: action.error,
        };
      }

      return state;
  }
}

const actions = {
  setStockComparisonList: (stockCode, checked) => {
    return { type: types.LIST_SET, stock_code: stockCode, checked: checked };
  },
  loadStockComparisonRequest: function(stockCode) {
    return { type: types.LOAD_REQUEST, stock_code: stockCode };
  },
  loadStockComparisonCompleted: function(data, stockCode) {
    return { type: types.LOAD_COMPLETED, data: data, stock_code: stockCode };
  },
  loadStockComparisonFailed: function(err, stockCode) {
    return { type: types.LOAD_FAILED, stock_error: err, stock_code: stockCode };
  },
  loadReutersEstimateRequest: function(api, stockCode) {
    return {
      type: types[`LOAD_REUTERS_ESTIMATE_${api.toUpperCase()}_REQUEST`],
      api: api,
      stock_code: stockCode,
    };
  },
  loadReutersEstimateCompleted: function(data, api, stockCode) {
    return {
      type: types[`LOAD_REUTERS_ESTIMATE_${api.toUpperCase()}_COMPLETED`],
      data: data,
      api: api,
      stock_code: stockCode,
    };
  },
  loadReutersEstimateFailed: function(err, api, stockCode) {
    return {
      type: types[`LOAD_REUTERS_ESTIMATE_${api.toUpperCase()}_FAILED`],
      stock_error: err,
      api: api,
      stock_code: stockCode,
    };
  },
  loadBubbleDataRequest: function(stockCode) {
    return { type: types.LOAD_BUBBLE_DATA_REQUEST, stock_code: stockCode };
  },
  loadBubbleDataCompleted: function(data, stockCode) {
    return {
      type: types.LOAD_BUBBLE_DATA_COMPLETED,
      data: data,
      stock_code: stockCode,
    };
  },
  loadBubbleDataFailed: function(err, stockCode) {
    return {
      type: types.LOAD_BUBBLE_DATA_FAILED,
      stock_error: err,
      stock_code: stockCode,
    };
  },

  setStockComparisonData: (key, data) => {
    return { type: types.DATA_SET, key: key, data: data };
  },
  
  clearStockComparisonData: function() { return { type: types.DATA_CLEAR }; },
};


export { types, actions }
