import { call, put, takeLatest } from 'redux-saga/effects'
import { LaboratoryAPIs } from 'api'
import { types, actions } from '../modules/ListLab'


function * watchLabListRequest() {
    yield takeLatest(types.REQUEST, LabListFlow);
}

function * LabListFlow(action) {
    try {
        const response = yield call(LaboratoryAPIs.AllSinglesLoadAPI, action.labID),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.getSuccess(responseData.data));
        } else {
            yield put(actions.getError(responseData.error));
        }
    } catch (err) {
        yield put(actions.getError(err));
    }
}


export default [ watchLabListRequest() ]
