export const types = {
  LOAD_REQUEST   : 'announcement/LOAD_REQUEST',
  LOAD_COMPLETED : 'announcement/LOAD_COMPLETED',
  LOAD_FAILED    : 'announcement/LOAD_FAILED',
}

const initialState = { announcements: [] }

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
    case types.LOAD_FAILED:
      return { ...initialState };

    case types.LOAD_COMPLETED:
      return { ...state, announcements: action.data };

    default:
      return state;
  }
}

export const actions = {
  loadRequest: function () {
    return { type: types.LOAD_REQUEST };
  },
  loadCompleted: function(data) {
    return { type: types.LOAD_COMPLETED, data: data };
  },
  loadFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },
}
