import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const LabListLoadAPI = () => axios.get(
  `${config.api.APP_API_DOMAIN}/user/customized_laboratories`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const AllSinglesLoadAPI = (labID) => axios.get(
  `${config.api.APP_API_DOMAIN}/user/collection_singles/${labID}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const LabCreateAPI = (newLab) => axios.post(
  `${config.api.APP_API_DOMAIN}/user/laboratories`, newLab,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const LabListSortAPI = (sortedLabs) => axios.put(
  `${config.api.APP_API_DOMAIN}/user/laboratories/sort`, sortedLabs,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const LabUpdateAPI = (labID, updateLab) => axios.put(
  `${config.api.APP_API_DOMAIN}/user/laboratories/${labID}`, updateLab,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const LabDeleteAPI = (labID) => axios.delete(
  `${config.api.APP_API_DOMAIN}/user/laboratories/${labID}`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const LabProductDeleteAPI = (labID, products) => axios.delete(
  `${config.api.APP_API_DOMAIN}/user/laboratories/${labID}/products`,
  {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
    data: products,
  }
);


export default {
  LabListLoadAPI,

  AllSinglesLoadAPI,
  LabCreateAPI,
  LabListSortAPI,
  LabUpdateAPI,
  LabDeleteAPI,
  LabProductDeleteAPI,
}
