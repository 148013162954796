import React from 'react'
import AuthRedirect from 'components/AuthRedirect';
import {
	ColourThemeHandler,
	LogInOutHandler,
	Menu,
	UserInfo as UserInfoContainer
} from './containers'
import { WindowHandler } from 'utils'
import 'assets/sass/Layout/side-menu.scss'


const UserInfo = AuthRedirect.isAuthShow(UserInfoContainer);

export class SideMenu extends React.PureComponent {
	componentDidUpdate(prevProps, prevState) {
    const {
			authenticated,
			isUserAvatarLoaded,
			isUserInfoLoaded,

      onAvatarGetRequest,
      onUserLoad,
    } = this.props;

		if (!authenticated) { return; }

		if (prevProps.isUserAvatarLoaded && !isUserAvatarLoaded) {
			onAvatarGetRequest();
		}

    if (prevProps.isUserInfoLoaded && !isUserInfoLoaded) { onUserLoad(); }
  }

	componentDidMount() {
		const {
			authenticated,

			onAvatarGetRequest,
			onLabListLoadRequest,
			onUserLoad,
			onUserNotificationsRequest,
		} = this.props;

		if (authenticated) {
			onAvatarGetRequest();
			onLabListLoadRequest();
			onUserLoad();
			onUserNotificationsRequest();
		}

		setTimeout(() => { WindowHandler.resize() }, 300);
	}

	render() {
		const { authenticated, isUserInfoLoaded, user_info, path } = this.props;

		return (
			<aside className='side-menu bg-white'>

				{(authenticated && isUserInfoLoaded && user_info && user_info.nickname)
					?
					<UserInfo path={path} />
					:
					<LogInOutHandler />}
				{/* colour theme */}
				<ColourThemeHandler />
				{/* content */}
				<Menu />

			</aside>
		);
	}
}
