import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/Laboratory'
import { actions as actionsSideMenu } from 'appRedux/modules/SideMenu'
import AuthRedirect from 'components/AuthRedirect'
import { Menu as component } from '../components'


const mapStateToProps = (state) => ({
	menu					  : state.SideMenu.menu,
	collection_labs : state.Laboratory.collection_labs,
	free_labs				: state.Laboratory.free_labs,
	my_labs					: state.Laboratory.my_labs,
	portfolio_labs	: state.Laboratory.portfolio_labs,
	labsLoaded 		  : state.Laboratory.loaded,
	labEditProcess  : state.Laboratory.process,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onLabListLoadRequest: () => dispatch(actions.loadLabListRequest()),
	onSideMenuSet: (menu) => dispatch(actionsSideMenu.setSideMenu(menu)),
});
const Menu = AuthRedirect.AuthProps(connect(mapStateToProps, mapDispatchToProps)(component));


export { Menu }
