import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Dashboard'
import { DashboardAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


function * watchLabDataLoadRequest() {
    yield takeLatest(types.LAB_LOAD_REQUEST, labDataLoadFlow);
}

function * labDataLoadFlow(action) {
    try {
        let response;

        if (action.noAuth) {
            response = yield call(DashboardAPIs.FreeLabDataLoadAPI, action.lab);
        } else {
            response = yield call(DashboardAPIs.LabDataLoadAPI, action.lab);
        }

        const responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.loadLabDataCompleted(responseData.data, action.lab));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadLabDataFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loadLabDataFailed(error));
    }
}

function * watchCurrentLabDataLoadRequest() {
    yield takeLatest(types.CURRENT_LAB_LOAD_REQUEST, currentLabDataLoadFlow);
}

function * currentLabDataLoadFlow(action) {
    try {
        const response = yield call(DashboardAPIs.LabAffiliatedDataLoadAPI, action.lab, action.affiliated),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.loadCurrentLabDataCompleted(responseData.data, action.affiliated));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadCurrentLabDataFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loadCurrentLabDataFailed(error));
    }
}

export default [
    watchLabDataLoadRequest(),
    watchCurrentLabDataLoadRequest()
]
