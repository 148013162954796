import React from 'react'
import { Redirect } from 'react-router-dom'
import hoistNonReactStatic from 'hoist-non-react-statics'


const isAuthenticated = (WrappedComponent) => {
				const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component' ;

				class AuthWrapped extends React.Component {
						static displayName = `AuthWrapper(${displayName})`;

						componentDidUpdate(prevProps, prevStates) {
								const { location, accessToken, onRedirectPathSet } = this.props;

								if (prevProps.location.pathname !== location.pathname) {
										onRedirectPathSet(location.pathname);
								}

								if (prevProps.accessToken && accessToken && prevProps.accessToken !== accessToken) {
										window.location.reload();
								}
						}

						componentDidMount() {
								const { location, onRedirectPathSet } = this.props;

								onRedirectPathSet(location.pathname);
						}

						render() {
								return this.props.authenticated ? <WrappedComponent {...this.props} /> : <Redirect to='/login-page' />;
						}
				}

				return hoistNonReactStatic(AuthWrapped, WrappedComponent);
		},
		isNotAuthenticated = (WrappedComponent) => {
				const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component' ;

				class AuthWrapped extends React.Component {
						static displayName = `AuthWrapper(${displayName})`;

						render() {
								const { authenticated, redirectPath, setPassword } = this.props;

								return authenticated
										?
										!setPassword
												?
												<Redirect to="/set-password" />
												:
												<Redirect to={redirectPath ? redirectPath : '/'} />
										:
										<WrappedComponent {...this.props} />
						}
				}

				return hoistNonReactStatic(AuthWrapped, WrappedComponent);
		},
		isAuthenticatedShow = (WrappedComponent) => {
				const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component' ;

				class AuthWrapped extends React.Component {
						static displayName = `AuthWrapper(${displayName})`;

						render() {
								return this.props.authenticated ? <WrappedComponent {...this.props} /> : null;
						}
				}

				return hoistNonReactStatic(AuthWrapped, WrappedComponent);
		};


export {
		isAuthenticated,
		isNotAuthenticated,
		isAuthenticatedShow
}
