import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/TabToggler'
import { TabToggler as Tabs } from './TabToggler'


const mapStateToProps = (state, ownProps) => ({
	active_tab           : state.TabToggler.active_tab[ownProps.type],

	new_notification     : state.UserNotifications.new_notification,
	need_updated_product : state.UserProducts.need_updated_product,
	need_pay_order       : state.UserOrders.need_pay_order,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onTabChange: (tab) => dispatch(actions.changeTab(ownProps.type, tab)),
})
const TabToggler = connect(mapStateToProps, mapDispatchToProps)(Tabs);


export { TabToggler }
