import React from 'react'
import ReactTable from 'react-table'
import { Pagination } from 'components'
import { getColumnArray, getColumnWidth } from './func'
import 'assets/sass/utils/table.scss'


export class Table extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { sorted: [] }
  }

  componentDidMount() {
    const { sorted, type, model } = this.props;

    if (sorted || type !== 'user') {
      let id = sorted ? sorted : null;
      let sort = 'esc';

      switch (model) {
        case 'Consensus_EPS_Estimatetable_R0001':
          id = 'date';
          sort = 'desc';

          break;

        case 'RadarSelection':
          id = 'ua120022_cp';

          break;

        case 'LatestFinancialResultRankings':
        case 'OrderVisibilitySingleRankings':
          id = 'ua60261_cp';

          break;

        case 'PublishedMonthlyRevenueSingleRankings':
        case 'RevenueMonthlyYTDExceedingAnalystExpectationsSingleRankings':
          id = 'ua70269_cp';

          break;

        default:
      }

      if (id) {
        this.setState({ sorted: [{ id: id, [sort]: true }] });
      }
    }
  }

  render() {
    const {
      header,
      data,
      accessor,
      type,
      cell,
      filtered,
      minWidth,
      showPagination,
      pageSize,
      sortCallback,
      singleKey,
      model,
      is_mobile,
      pager,
    } = this.props;
    const columns = getColumnArray(
      type,
      singleKey,
      data,
      model,

      header,
      accessor,
      cell,
      minWidth
    );
    const enableSorting = type !== 'user' && model !== 'ValuationSheet_Three_Haoway';

    let size = pageSize;

    if (type === 'account_single' || data.length < 10 || !showPagination) {
      size = data.length;
    }

    return (
      <ReactTable
        className={`${(type === 'user') ? 'user-table'
            : 'table-component ua-table'} ${(type === 'account_single')
            ? 'padding-top-15' : ''} notice`}
        columns={columns}
        data={data}

        initial={0}
        minRows={0}

        showPagination={!(data.length < 10 || !showPagination)}
        PaginationComponent={Pagination}
        pagerID={singleKey}
        pageSize={size}
        page={(data.length <= size) ? 0 : pager[singleKey]}

        NoDataComponent={() => null}

        enableSorting={enableSorting}
        multiSort={true}
        sorted={enableSorting ? this.state.sorted : []}

        filtered={filtered ? filtered : []}
        onFilteredChange={() => filtered ? filtered : []}

        // enableRowSelection={true}
        // enableMultiRowSelection={true}
        // onRowSelectionChange={({ row }) => ({
        //     onClick: () => {
        //       console.log('onClick: ', row);
        //     }
        // })}

        getProps={() => ({ id: singleKey ? `table_${singleKey}` : '' })}
        getTheadThProps={(props, some, column) => ({
          onClick: (e) => {
            if (!enableSorting || !column.sortable) { return; }

            const tableSort = props.sorted;

            this.setState(
              {
                sorted: [{
                  id: column.id,
                  desc: (tableSort.length > 0 && tableSort[0].id === column.id)
                      ? !tableSort[0].desc : true
                }],
              },
              () => { if (sortCallback) { sortCallback(); }}
            );
          },
          style: {
            minWidth: minWidth ? minWidth : getColumnWidth(
              props.pageRows,
              column.id,
              header[accessor.indexOf(column.id)],
              is_mobile
            ),
            // marginLeft : type !== 'user' && accessor.indexOf(column.id) === 1 ? getColumnWidth(props.pageRows, accessor[0], header[0], is_mobile) : '',
            transform: is_mobile ? 'translate3d(0, 0, 0)' : '',
            lineHeight: 2.4,
          },
        })}
        getTrProps={(_, row) => ({
                    // onClick: () => {
                      // console.log('onClick: ', row);
                    // }
        })}
        getTdProps={(props, rowInfo, column) => ({
          style: {
            minWidth   : minWidth ? minWidth : getColumnWidth(
              props.pageRows,
              column.id,
              header[accessor.indexOf(column.id)],
              is_mobile
            ),
            // marginLeft : type !== 'user' && accessor.indexOf(column.id) === 1 ? getColumnWidth(props.pageRows, accessor[0], header[0], is_mobile) : '',
            transform  : is_mobile ? 'translate3d(0, 0, 0)' : '',
            lineHeight : (column.id === 'stock_title' || type === 'user') ? ''
                : 2.4
          },
                    // onClick: () => {
                    //     console.log('onClick: ', column);
                    // }
        })}
      />
    );
  }
}
