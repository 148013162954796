import React from 'react'

import 'assets/sass/utils/modal.scss'

class Modal extends React.PureComponent {
  onModalClose = (closeID) => {
    const callback = this.props.callback;
    const modal = document.getElementsByClassName(`${closeID} modal`)[0];
    const modalBody = document.getElementById(`modal-body-${closeID}`);
    const fullHeightElem = document.getElementById(
      `modal-body-full-height-${closeID}`
    );

    if (modalBody) {
      modalBody.scrollTop = 0;
      modal.classList.remove('fadeInDown');
      modal.classList.add('fadeOutUp');
      document.body.classList.remove('modal-open');
    } else if (fullHeightElem) { // fullHeight
      fullHeightElem.scrollTop = 0;
    }

    setTimeout(() => {
      if (modal) {
        modal.classList.remove('show');
        modal.scrollTop = 0;
      }
    }, callback ? 1 : 300);

    if (callback) { callback(); }
  }

  onAllModalsClose = () => {
    const arrActiveID = this.props.open_id;

    for (let id in arrActiveID) {
      this.onModalClose(id);
      this.props.onModalToggle(id);
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    const { id, open_id, leaveTxt, secondBtn, history, height, is_mobile } = this.props;
    const fullHeightElem = document.getElementById(
      `modal-body-full-height-${id}`
    );

    if (prevProps.id !== id && fullHeightElem) { // fullHeight
      fullHeightElem.style.height = (height - ((leaveTxt || secondBtn)
          ? 120 : 0)) + 'px';
    }

    if (prevProps.history.location.pathname !== history.location.pathname /*&& modal.classList.value.includes('show')*/) {
      this.onAllModalsClose();
    }

    if (prevProps.open_id[id] !== open_id[id]) {
      const arrID = Object.keys(open_id);

      for (let i = 0; i < arrID.length; i++) {
        const toggleID = arrID[i];
        const toggleModal = document.getElementsByClassName(
          `${toggleID} modal`
        )[0];
        const isFullPage = document.getElementById(
          `modal-body-full-height-${toggleID}`
        );

        if (!toggleModal) { continue; }

        if (open_id[toggleID]) {
          if (!isFullPage) {
            toggleModal.classList.remove('fadeOutUp');
            toggleModal.classList.add('fadeInDown');
          }
          
          if (!toggleModal.classList.contains('show')) {
            toggleModal.classList.add('show');
            document.body.classList.add('modal-open');

            if (is_mobile) { history.push(history.location.pathname); }
          }
        } else {
          this.onModalClose(toggleID);
        }
      }
    }
  }

  componentDidMount() {
    const { id, leaveTxt, secondBtn, height } = this.props;
    const fullHeightElem = document.getElementById(
      `modal-body-full-height-${id}`
    );

    // fullHeight
    if (fullHeightElem) {
      fullHeightElem.style.height = (height - ((leaveTxt || secondBtn)
          ? 120 : 0)) + 'px';
    }
  }

  render() {
    const {
      id,
      leaveTxt,
      checkBtn,
      secondBtn,
      title,
      html,
      customClassName = '',
      fullHeight,
      fullPage,
      noPageScrollTop,
      hideCloseBtn,
      is_mobile,

      onModalToggle,
    } = this.props;
    const articleArrow = customClassName.includes('article-modal');
    const showDefaultCloseBtn = !hideCloseBtn && !articleArrow;
    const onSelfModalClose = (e) => {
      e.preventDefault();
      e.stopPropagation();

      onModalToggle(id);

      this.onModalClose(id);
    };

    return (
      <div
        className={`${id} modal animated ${customClassName}
        ${(fullHeight || fullPage)
          ?
          `full-${fullPage ? 'page full-height' : 'height'}`
          :
          (is_mobile && (!customClassName
              || customClassName.includes('mobile-height')))
              ? 'mobile-height' : ''}
        ${noPageScrollTop ? 'no-page-scroll-top' : ''}`}
      >

        <div
          className='modal-backdrop ua-backdrop'
          onClick={!hideCloseBtn ? onSelfModalClose : () => {}}
        />

        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              {articleArrow && (
                <button
                  type='button'
                  className={`${customClassName} close`}
                  onClick={onSelfModalClose}
                >
                  <i className='fa fa-chevron-left'/>
                </button>
              )}

              <h4 className='modal-title'>{title}</h4>

              {showDefaultCloseBtn && (
                <button
                  type='button'
                  className='close'
                  onClick={onSelfModalClose}
                >
                  <i className='fa fa-times'/>
                </button>
              )}
            </div>

            <div
              id={`modal-body-${(fullHeight || fullPage) ? 'full-height-' : ''}${id}`}
              className='modal-body'
            >
              {html}
            </div>

            {(leaveTxt || secondBtn) && (
              <div className='modal-footer'>
                <div className='botton-action margin-auto'>
                  {leaveTxt
                    ?
                    <button
                      type='button'
                      className={`got-it ua-btn ${checkBtn
                          ? 'outline' : 'default'}`}
                      onClick={onSelfModalClose}
                    >
                      {leaveTxt}
                    </button>
                    :
                    secondBtn}

                  {checkBtn}
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
    );
  }
}

export { Modal }
