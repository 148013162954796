export const types = {
  TOGGLE: 'tooltips/TOGGLE',
  TOGGLE_SET: 'tooltips/TOGGLE_SET'
}

const initialState = { tooltips: {} }

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.TOGGLE:
      return {
        ...state,
        tooltips: {
          // ...state.tooltips,
          [action.active_id]: !state.tooltips[action.active_id]
        }
      };

    case types.TOGGLE_SET:
      return {
        ...state,
        tooltips: {
          ...state.tooltips,
          [action.id]: false
        }
      };

    default:
      return state;
  }
}

export const actions = {
  toggle: function(active_id) {
    return { type: types.TOGGLE, active_id: active_id };
  },
  setToggle: function(id) {
    return { type: types.TOGGLE_SET, id: id };
  }
}
