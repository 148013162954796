import React from 'react'
import * as d3 from 'd3'
import { assignIn as _assignIn } from 'lodash'
import { RADAR_OPTIONS_DEFAULT, RADAR_COLOURS } from '../config'


const drag = (value, idx, nodes, radar) => {
  nodes[idx].parentNode.appendChild(nodes[idx]);

  const dragTarget = d3.select(nodes[idx]);
  const cx = parseFloat(dragTarget.attr('cx'));
  const cy = parseFloat(dragTarget.attr('cy'));
  const dx = d3.event.dx;
  const dy = d3.event.dy;
  const maxX = radar.maxAxisValues[idx].x;
  const maxY = radar.maxAxisValues[idx].y;
  const minX = radar.minAxisValues[idx].x;
  const minY = radar.minAxisValues[idx].y;

  let newX = cx;
  let newY = 0;
  let newValue = 0;

  if (idx !== 0) { newX += dx; }

  switch (idx) {
    case 1:
    case 2:
      newX = (newX > maxX)
        ?
        maxX
        :
        (newX < minX)
          ?
          minX
          :
          newX;

      break;

    case 3:
    case 4:
      newX = (newX < maxX)
        ?
        maxX
        :
        (newX > minX)
          ?
          minX
          :
          newX;

          break;

    default:

  }

  const slope = (maxY - minY) / (maxX - minX);

  newY = (idx === 0) ? -(Math.abs(cy) - dy) : cy + slope * (newX - cx);

  switch (idx) {
    case 0:
    case 1:
    case 4:
      newY = (newY < maxY)
      ?
      maxY
      :
      (newY > minY)
        ?
        minY
        :
        newY;

      break;

    case 2:
    case 3:
      newY = (newY > maxY)
      ?
      maxY
      :
      (newY < minY)
        ?
        minY
        :
        newY;

      break;

    default:

  }

  const temp = newY / Math.sin(radar.options.radians / radar.options.categories.length * idx  - Math.PI / 2);

  newValue = radar.rScale.invert(temp);
  newValue = Math.round(newValue / 5) * 5;
  radar.options.data[idx].y = newValue;

  dragTarget.attr('cx', newX).attr('cy', newY)

  // redraw
  radar.polygonDrawer();
};
const pentagon = {
  width         : 0,
  height        : 0,
  isMobile      : false,
  svgGroup      : null,
  axisGrid      : null,
  rScale        : null,
  options       : {},
  minAxisValues : [],
  maxAxisValues : [],
  // funcs
  optionsSetter: (options) => {
    pentagon.options = _assignIn(RADAR_OPTIONS_DEFAULT, options);
  },
  init: (props) => {
    let {
      id,
      options,
      is_mobile,
      width,
      callback,
    } = props;

    pentagon.optionsSetter(options);

    const margin = pentagon.options.margin;
    const marginTop  = margin.top;
    const svgCanvas  = d3.select(`#${id}`);

    let chartWidth = pentagon.options.w;
    let chartHeight = pentagon.options.h;
    let marginLeft = margin.left;

    if (is_mobile) {
      chartWidth = width * 0.7;
      chartHeight = chartWidth;
      marginLeft = 30;
    }

    pentagon.width = chartWidth;
    pentagon.height = chartHeight;
    pentagon.isMobile = is_mobile;

    svgCanvas.select('svg').remove();
    svgCanvas.append('svg').attr('class', id)
        .attr('width', chartWidth + marginLeft * 2)
        .attr('height', chartHeight + marginTop * 2);

    pentagon.svgGroup = svgCanvas.select('svg').append('g')
        .attr(
          'transform',
          `translate(${chartWidth / 2 + marginLeft},${chartHeight / 2 + marginTop})`
        );
    pentagon.rScale = d3.scaleLinear().range([ 0, chartWidth / 2 ])
        .domain([ -15, pentagon.options.maxValue ]);

      pentagon.gridDrawer();
      pentagon.axisDrawer();
      pentagon.polygonDrawer(true);
      pentagon.nodeDrawer(callback);
    },
    gridDrawer: () => {
      const levels = pentagon.options.levels;
      const chartRadius = pentagon.width / 2;
      const format = d3.format('');
      const theme = pentagon.options.theme;

      pentagon.axisGrid = pentagon.svgGroup.append('g')
          .attr('class', 'grid-wrapper');

      pentagon.axisGrid.selectAll('.levels')
        .data(d3.range(1, (levels + 1)).reverse()).enter().append('circle')
      	.attr('class', 'grid-circle')
      	.attr('r', (value, idx) => (chartRadius / levels * value))
      	.style('fill', 'none')
      	.style('stroke', RADAR_COLOURS[theme].stroke);
      // labels
      pentagon.axisGrid.selectAll('.axis-label')
        .data(d3.range(1, (levels + 1)).reverse()).enter().append('text')
        .attr('class', 'axis-label')
        .attr('x', 4)
        .attr('y', function(d) { return -d * chartRadius / levels; })
        .attr('dy', '0.4em')
        .style('font-size', '10px')
        .attr('fill', RADAR_COLOURS[theme].fill)
        .text((value) => (format(pentagon.options.maxValue * value / levels)));
    },
    axisDrawer: () => {
      const isMobile = pentagon.isMobile;
      const radians = pentagon.options.radians;
      const minValue = pentagon.options.minValue;
      const maxValue = pentagon.options.maxValue;
      const categories = pentagon.options.categories;
      const factorLegend = pentagon.options.factorLegend;
      const rScale = pentagon.rScale;
      const axis = pentagon.axisGrid.selectAll('.axis').data(categories).enter()
          .append('g').attr('class', 'axis g');
      const theme = pentagon.options.theme;

      for (let i = 0; i < categories.length; i++) {
        pentagon.minAxisValues[i] = {
          axis_x: rScale(-3) * Math.cos(radians / categories.length * i  - Math.PI / 2),
          axis_y: rScale(-3) * Math.sin(radians / categories.length * i  - Math.PI / 2),
          x: rScale(minValue) * Math.cos(radians / categories.length * i  - Math.PI / 2),
          y: rScale(minValue) * Math.sin(radians / categories.length * i  - Math.PI / 2),
        };
        pentagon.maxAxisValues[i] = {
          x: rScale(maxValue) * Math.cos(radians / categories.length * i  - Math.PI / 2),
          y: rScale(maxValue) * Math.sin(radians / categories.length * i - Math.PI / 2),
        };
      }

      axis.append('line')
        .attr('x1', (value, idx) => (pentagon.minAxisValues[idx].axis_x))
        .attr('y1', (value, idx) => (pentagon.minAxisValues[idx].axis_y))
        .attr('x2', (value, idx) => (pentagon.maxAxisValues[idx].x))
        .attr('y2', (value, idx) => (pentagon.maxAxisValues[idx].y))
        .attr('class', 'grid-line')
        .style('stroke-width', '1px')
        .style('stroke', RADAR_COLOURS[theme].stroke);
      axis.append('text')
        .attr('class', (value, idx) => {
          let className = 'legend legend-';

          if (idx === 0 || isMobile) {
            className += 'middle';
          } else {
            const p = 0.5 * pentagon.maxAxisValues[idx].x;

            className += (p < 0.4) ? 'right' : (p > 0.6) ? 'left' : 'middle';
          }

          return className;
        })
        .attr('dy', (value, idx) => {
          let y = '0';

          if (idx !== 0 && !isMobile) {
            const p = 0.5 * pentagon.maxAxisValues[idx].y;

            y = (p < 0.1) ? '1em' : ((p > 0.9) ? '0' : '0.5em');
          }

          return y;
        })
        .text((value, idx) => (categories[idx].name))
        .attr('x', (value, idx) => (rScale(maxValue * factorLegend) * Math.cos(radians / categories.length * idx  - Math.PI / 2)))
        .attr('y', (value, idx) => (rScale(maxValue * factorLegend) * Math.sin(radians / categories.length * idx  - Math.PI / 2)))
        .attr('fill', RADAR_COLOURS[theme].fill);
    },
    polygonDrawer: (isInit = false) => {
      const { options, rScale } = pentagon;
      const {
        radians,
        categories,
        colour,
        opacityArea,
        stroke,
        data,
      } = options;
      const dataValues = data.map((value) => (value.y));

      if (isInit) {
        pentagon.svgGroup.selectAll('area').data([dataValues]).enter()
            .append('polygon').attr('class', 'polygon').style('stroke', colour)
            .style('stroke-width', stroke)
            .style('fill', colour)
            .style('fill-opacity', opacityArea);
      }

      pentagon.svgGroup.selectAll('area').data([dataValues]).enter()
        .select('.polygon').attr('points', (value) => {
          let str = '';

          for (let i = 0; i < value.length; i++) {
            const x = rScale(value[i]) * Math.cos(radians / categories.length * i  - Math.PI / 2),
              y = rScale(value[i]) * Math.sin(radians / categories.length * i  - Math.PI / 2);

            str = str + x + ',' + y + ' ';
          }

          return str;
        });
    },
    nodeDrawer: (callback) => {
      const radians = pentagon.options.radians;
      const radius = pentagon.options.radius;
      const categories = pentagon.options.categories;
      const colour = pentagon.options.colour;
      const stroke = pentagon.options.stroke;
      const rScale = pentagon.rScale;
      const nodes = pentagon.svgGroup.selectAll('.nodes')
          .data(pentagon.options.data).enter();
      const labels = pentagon.svgGroup.selectAll('.pentagon-label')
          .data(pentagon.options.data).enter();
      const theme = pentagon.options.theme;

      nodes.append('circle')
        .attr('class', 'pentagon-circle')
        .attr('r', radius)
        .attr('alt', (value) => (Math.max(value.y, 0)))
        .attr('cx', (value, idx) => (rScale(value.y) * Math.cos(radians / categories.length * idx  - Math.PI / 2)))
        .attr('cy', (value, idx) => (rScale(value.y) * Math.sin(radians / categories.length * idx  - Math.PI / 2)))
        .attr('data-id', (value, idx) => (categories[idx].name))
        .style('fill', 'rgba(255, 255, 255, 0.9)')
        .style('stroke', colour)
        .style('stroke-width', stroke)
        .on('mouseenter', (value, idx, nodes) => {
          d3.select(nodes[idx])
            .style('cursor', 'grab')
            .attr('r', pentagon.options.radiusHover)
            .style('stroke-width', pentagon.options.strokeHover);
        })
        .on('mousedown', (value, idx, nodes) => { d3.event.stopPropagation(); })
        .on('mouseout', (value, idx, nodes) => {
          d3.select(nodes[idx])
            .attr('r', radius)
            .style('cursor', 'default')
            .style('stroke-width', stroke)
        })
        .on('touchstart', (value, idx, nodes) => {
          const element = d3.select(nodes[idx]);
          const lbl = labels.selectAll('.pentagon-label').filter((v, i) => (
            i === idx
          ));

          element.attr('r', pentagon.options.radiusHover)
              .style('stroke-width', pentagon.options.strokeHover);

          lbl.text((value, idx) => (value.y)).style('display', 'block');

          d3.event.stopPropagation();
        })
        .on('touchend', (value, idx, nodes) => {
          d3.select(nodes[idx])
            .attr('r', radius)
            .style('stroke-width', stroke)
        })
        .call(d3.drag()
          .on('start', (value, idx, nodes) => {
            const lbl = labels.selectAll('.pentagon-label').filter((v, i) => (
              i === idx
            ));
            lbl.style('display', 'none');

            d3.event.sourceEvent.stopPropagation();
          })
          .on('drag', (value, idx, nodes) => {
            const element = d3.select(nodes[idx]);
            const x = element.attr('cx');
            const y = element.attr('cy');
            const lbl = labels.selectAll('.pentagon-label').filter((v, i) => (
              i === idx
            ));

            lbl.text((value, idx) => (value.y)).attr('x', x).attr('y', y - 30)
                .style('display', 'block');

            d3.event.sourceEvent.stopPropagation();

            drag(value, idx, nodes, pentagon);
          })
          .on('end', (value, idx, nodes) => { callback(idx, value.y); })
        );

      labels.append('text')
        .attr('class', 'pentagon-label')
        .text((value, idx) => (value.y))
        .attr('x', (value, idx) => (rScale(value.y) * Math.cos(radians / categories.length * idx  - Math.PI / 2)))
        .attr('y', (value, idx) => (rScale(value.y) * Math.sin(radians / categories.length * idx  - Math.PI / 2) - 30))
        .style('fill', colour)
        .style('text-shadow', `-1.5px -1.5px 0 ${RADAR_COLOURS[theme].text_shadow}, 1.5px -1.5px 0 ${RADAR_COLOURS[theme].text_shadow}, -1.5px 1.5px 0 ${RADAR_COLOURS[theme].text_shadow}, 1.5px 1.5px 0 ${RADAR_COLOURS[theme].text_shadow}`)
        .attr('font-weight', '700')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'central');
    }
  };


export class Pentagon extends React.PureComponent {

  componentDidUpdate(prevProps, prevState) {
    const { width, options } = this.props;

    if (prevProps.width !== width || prevProps.options.theme !== options.theme) {
      pentagon.init(this.props);
    }
  }

  componentDidMount() {
    pentagon.init(this.props);
  }

  render() {
    return <div id={this.props.id} className='radar-chart'/>
  }
}
