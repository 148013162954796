import { connect } from 'react-redux'
import AuthRedirect from '../AuthRedirect'
import { actions } from 'appRedux/modules/Stock'
import { actions as actionsLaboratory } from 'appRedux/modules/Laboratory'
import { ArrayHandler } from 'utils'
import { SearchInput as Search } from './SearchInput'


const mapStateToProps = (state, ownProps) => {
	const {
		stock_pool,
		stock_others_pool,
		stock_tw_pool,
		stock_etf_pool,
		search_record,
		select_stock,
	} = state.Stock;
	const { isTaiwanOnly, isWorldwide, withoutETF } = ownProps;
	const { current_group, favs }	= state.Favorite;
	const fav = (favs.length > 0) ? favs[current_group].stocks : [];

	let stockPool = stock_pool;

	if (isTaiwanOnly) {
		stockPool = stock_tw_pool;
	} else if (isWorldwide) {
		stockPool = ArrayHandler.sorter(
			[ ...stock_pool, ...stock_others_pool ],
			'stock_code'
		);
	} else if (withoutETF) {
		stockPool = stock_pool.filter((stock) => (!stock_etf_pool.includes(
			stock.stock_code
		)));
	}

	// let singlePool = state.Laboratory.singles_editable;
	//
	// if (singlePool && singlePool.length > 0) {
	// 	singlePool = singlePool[0].singles.filter((single) => (
	// 		single.name.includes('產業助理')
	// 	));
	// 	singlePool = ArrayHandler.sorter(singlePool, 'id', false);
	// }

	return {
		fav: fav,

		stock_pool: stockPool,
		stock_others_pool: stock_others_pool,
		stock_etf_pool: stock_etf_pool,

		stock_search_record: search_record,

		// single_pool: singlePool,
		single_search_record: state.Laboratory.search_record,
		stock: select_stock,

		width: state.Layout.width,
	}
};
const mapDispatchToProps = (dispatch, ownProps) => ({
	onStockListLoad: () => dispatch(actions.loadStockListRequest()),
	onOthersStockListLoad: () => dispatch(actions.loadOthersStockListRequest()),
	onETFStockListLoad: () => dispatch(actions.loadETFStockListRequest()),
	// onStockRecordLoadRequest	  : () => dispatch(actions.loadStockRecordRequest()),
	// onStockRecordUpdateRequest  : (stockCode) => dispatch(actions.updateStockRecordRequest(stockCode)),

	onAllSinglesLoadRequest: (labID) => dispatch(
		actionsLaboratory.loadAllSinglesRequest(labID)
	),
	// onSingleRecordLoadRequest   : () => dispatch(actionsLaboratory.loadSingleRecordRequest()),
	// onSingleRecordUpdateRequest : (single) => dispatch(actionsLaboratory.updateSingleRecordRequest(single))
});
const SearchInput = AuthRedirect.AuthProps(connect(mapStateToProps, mapDispatchToProps)(Search));


export { SearchInput }
