import axios from 'axios'


const stockDomain = process.env.REACT_APP_STOCK_DOMAIN;
const AnswerGetAPI = (data = null) => axios.post(
  `${stockDomain}/data_fetch/ai_prompt`,
  data,
  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
);


export default { AnswerGetAPI }
