import { call, put, takeLatest } from 'redux-saga/effects'
import { ProfileAPIs } from 'api'
import { ResponseErrorHandler, ResponseMessageHandler } from 'utils'
import { types, actions } from '../modules/Profile'


function * watchUserDataRequest() {
    yield takeLatest(types.LOAD_REQUEST, userDataFlow);
}

function * userDataFlow(action) {
    try {
        const response = yield call(ProfileAPIs.UserDataAPI, action.data);
        const responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.loadUserCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadUserFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadUserFailed(error));
    }
}

function * watchUserDataUpdateRequest() {
    yield takeLatest(types.UPDATE_REQUEST, userDataUpdateFlow);
}

function * userDataUpdateFlow(action) {
    try {
        const response = yield call(ProfileAPIs.UserDataUpdateAPI, action.data);
        const responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.UPDATE_SUCCESS);

            yield put(actions.updateUserSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.updateUserError(responseData.error));
        }
    } catch (error) {
        yield put(actions.updateUserError(error));
    }
}

function * watchUserMailSubscriptRequest() {
    yield takeLatest(types.SUBSCRIPT_REQUEST, userMailSubscriptFlow);
}

function * userMailSubscriptFlow() {
    try {
        const formData = new FormData();

        formData.append('_method', 'put');

        const response = yield call(ProfileAPIs.UserMailSubscriptAPI, formData);
        const responseData = response.data;

        yield put(actions.subscriptSuccess(responseData.data));
    } catch(error) {
        yield put(actions.subscripteError(error));
    }
}

function * watchUserMailSubscriptCancelRequest() {
    yield takeLatest(types.SUBSCRIPT_CANCEL_REQUEST, userMailSubscriptCancelFlow);
}

function * userMailSubscriptCancelFlow(action) {
    try {
        const formData = new FormData();

        formData.append('_method', 'put');

        const response = yield call(ProfileAPIs.UserMailSubscriptCancelAPI, formData);
        const responseData = response.data;

        yield put(actions.cancelSubscriptSuccess(responseData.data));
    } catch (error) {
        yield put(actions.cancelSubscriptError(error));
    }
}

export default [
    watchUserDataRequest(),
    watchUserDataUpdateRequest(),
    watchUserMailSubscriptRequest(),
    watchUserMailSubscriptCancelRequest()
]
