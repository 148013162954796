import React from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'

import RemarkColorWord from './RemarkColorWord'


const MarkdownText = ({ children }) => (
  <Markdown
    children={children}
    linkTarget='_blank'
    rehypePlugins={[ rehypeKatex ]}
    remarkPlugins={[
      RemarkColorWord,
      [ remarkGfm, { singleTilde: false } ],
      [ remarkMath, { singleDollarTextMath: false } ],
    ]}
    remarkRehypeOptions={{ handlers: { element: (h, node) => node } }}
    components={{
      a: ({ node, children, ...props }) => {
        const linkProps = props;

        if (props.target === '_blank') {
          linkProps.rel = 'nofollow noopener noreferrer';
        }

        return <a {...linkProps}>{children}</a>
      },
    }}
  />
);


export { MarkdownText }
