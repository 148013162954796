import axios from 'axios'


const SingleComparisonChartDataLoadAPI = (model, stocks) => axios.post(
  `${process.env.REACT_APP_STOCK_DOMAIN}/data_fetch/stocks/RKD/${model}`, 
  stocks,
  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
)


export default { SingleComparisonChartDataLoadAPI }
