import React from 'react'
import { ChatInput, Loading } from 'components'
import 'assets/sass/utils/dropdown.scss'

const DropdownItem = ({ item, onClick }) => (
	<div
		className='dashboard-dropdown-item cursor-pointer text-center'
		onClick={onClick}
	>
		{item}
	</div>
)

export default class Dropdown extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = { active: false, filtered_items: [] };
		this.ref = React.createRef();
		this.togglerRef = React.createRef();
	}

	onItemClick = (e) => this.setState({ active: false })

	handleClickOutside = (e) => {
		if (this.togglerRef.current && this.togglerRef.current.contains(e.target)) {
			this.setState((state) => ({ active: !state.active }));
		} else if (this.ref.current && !this.ref.current.contains(e.target)) {
			this.setState({ active: false });
		} else{
			this.setState({ active: true });
		}
	}

	onChange = (value) => {
		const { items, filter } = this.props;
		const itemFilter = filter(value);

		let filterArr = items.filter((item) => {
			const title = item.props.children[0];
			return value === '' || (title && title.includes(value) && value !== '') || (itemFilter && itemFilter.includes(title));
		});

		this.setState({ filtered_items: filterArr });
	}

	onSearch = (value) => {
		const { onChatInputSearch } = this.props;

		if (onChatInputSearch) {
			onChatInputSearch(value);
		}
	}

	componentWillUnmount = () => {
		this.setState({ filtered_items: [] });
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.active !== this.state.active && this.state.active === false) {
			this.setState({ filtered_items: [] })
		}
	}

	componentDidMount = () => {
		document.addEventListener('click', this.handleClickOutside, true);
	}

	render() {
		const { active, filtered_items } = this.state;
		const {
			id,
			title,
			items,
			key,
			onChatInputSearch,
			isSearching = false,
			searchInput = false,
			labBtn,
			menuRef
		} = this.props;
		const filteredItems = filtered_items.length ? filtered_items : items;

		return	(
			<div
				id={id}
				className={`${id} dashboard-dropdown cursor-pointer ${active ? 'active'
						: ''} text-center`}
				key={key}
			>
				<div className='dashboard-dropdown-btn'
					data-id={id}
					ref={this.togglerRef}
				>
					<span className='dashboard-dropdown-btn-title'>{title}</span>

					{(id !== 'lab-tab-list') && (
						<div className={`ua-triangle ${active ? 'active' : ''}`}/>
					)}
				</div>

				{(items && items.length > 0) && (
					<div
						className='dashboard-dropdown-menu-container margin-top-10'
						ref={menuRef}
						onClick={(e) => e.stopPropagation()}
					>
						<div className='ua-box' ref={this.ref}>
							{searchInput && (
								<div className='dashboard-dropdown-search'>
									<ChatInput
										actived={active}
										disabled={isSearching}
										onChange={this.onChange}
										onSearch={onChatInputSearch ? this.onSearch : null}
									/>
								</div>
							)}
							<div className='dashboard-dropdown-menu'>
								{isSearching
									?
									<>
										<div className='display-inline-block search-box'>
											<Loading/>
										</div>

										<hr/>
									</>
									:
									(this.props.filterItems !== null
											&& typeof this.props.filterItems !== 'undefined') && (
										(this.props.filterItems?.length > 0)
											?
											<>
												<div className='search-box__text'>為您推薦的研究室：</div>

												{items
													.filter((item) => (this.props.filterItems.includes(
														item.props.children
													)))
													.map((item, idx) => (
														<DropdownItem
															item={item}
															onClick={this.onItemClick}
															key={idx}
														/>
													))}

												<hr/>
											</>
											:
											null
									)
								}

								{filteredItems.map((item, idx) => (
									<DropdownItem
										item={item}
										onClick={this.onItemClick}
										key={idx}
									/>
								))}
							</div>

							<div className='dashboard-dropdown-labBtn'>{labBtn && labBtn}</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}
