import React from 'react'


class HTMLRenderer extends React.Component {
	RawMarkupHandler() {
		return { __html: this.props.html };
	}

	shouldComponentUpdate(nextProps, nextState) {
		const classNameChanged = this.props.className !== nextProps.className,
			htmlChanged          = this.props.html !== nextProps.html,
			typeChanged          = this.props.htmlType !== nextProps.htmlType;

		return classNameChanged || htmlChanged || typeChanged;
	}

	render() {
		let { className, htmlType, html } = this.props;

		var component = null;
			if (html) {
				switch (htmlType) {
					case 'p':
						component = <p className={className} dangerouslySetInnerHTML={this.RawMarkupHandler()}/>;

						break;

					case 'h3':
						component = <h3 className={className} dangerouslySetInnerHTML={this.RawMarkupHandler()}/>;

						break;

					default:
						component = <div className={className} dangerouslySetInnerHTML={this.RawMarkupHandler()}/>;
				}
			}

		setTimeout(() => {}, 300);

		return component;
	}
}

const getProductID = (product) => {
		if (product) {
			return product.pathname ? product.pathname : product.id;
		}

		return false;
	}


export default { HTMLRenderer, getProductID }
