import React from 'react'
import CookieConsent from 'react-cookie-consent'


const Cookie = () => (
  <CookieConsent
      cookieName='UAProCookieName'
      location='bottom'
      contentClasses='cookie-flex'
      expires={150}
      buttonText='我知道了'
      buttonStyle={{
        margin          : (window.screen.width > 520) ? 'auto 15px' : '0px auto 10px',
        backgroundColor : '#ffa500',
        borderColor     : '#ffa500',
        borderRadius    : '3px',
        color           : '#fff',
        fontWeight      : '400'
      }}
      style={{ backgroundColor: 'rgba(0, 0, 0, .6)' }}
  >
      本網站使用 Cookie 技術，於您的電腦中存取某些資訊，以輔助本網站進行資料之彙集或分析，並提供更好的服務，無侵犯個人隱私之意圖。Cookie 是網站伺服器與使用者瀏覽器溝通的技術，若不願意開放此項功能，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie 的寫入，但可能會導致本網站之部分或全部功能無法正常執行。
  </CookieConsent>
)


export default Cookie
