import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const SendForgotEmailAPI = (data) => axios.post(`${config.api.APP_API_DOMAIN}/password/email`, data, {
    headers: {
      Accept        : 'application/json'
    }
  }),
  ResetPasswordAPI = (data) => axios.post(`${config.api.APP_API_DOMAIN}/password/reset`, data, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  }),
  SetPasswordAPI = (data) => axios.post(`${config.api.APP_API_DOMAIN}/password/set`, data, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  })


export default { SendForgotEmailAPI, ResetPasswordAPI, SetPasswordAPI }
