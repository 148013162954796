import * as d3 from 'd3'


const COLOUR = '#ffa500';
// radar
const RADAR_OPTIONS_DEFAULT = {
  // width of the circle
  w: 350,
  // height of the circle
  h: 350,
  // the margins of the SVG
  margin: { top: 50, left: 150 },

  // frame
  factor: 1,
  // how many levels or inner circles should there be drawn
  levels: 10,

  // radar
  colour: d3.rgb(COLOUR),
  opacityArea: 0.1,
  radians: 2 * Math.PI,
  // how much farther than the radius of the outer circle should the labels be placed
  factorLegend: 1.1,
  factorTooltip: 1,
  // the number of pixels after which a label needs to be given a new line
  wrapWidth: 60,
  
  // handle
  radius: 10,
  radiusHover: 15,
  stroke: '2px',
  strokeHover: '3px',

  minValue: 0,
  maxValue: 100,
};
const RADAR_COLOURS = {
  dark: {
    fill: 'rgba(255, 255, 255, .8)',
    stroke: 'rgba(255, 255, 255, .2)',
    text_shadow: '#545454',
  },
  light: {
    fill: 'rgba(0, 0, 0, .8)',
    stroke: 'rgba(0, 0, 0, .2)',
    text_shadow: '#fafafa',
  }
};


export { RADAR_OPTIONS_DEFAULT, RADAR_COLOURS }
