export const types = {
    SET              : 'reflineSwitcher/SET',
    RATE             : 'reflineSwitcher/RATE',

    LOAD_REQUEST     : 'reflineSwitcher/LOAD_REQUEST',
    LOAD_COMPLETED   : 'reflineSwitcher/LOAD_COMPLETED',
    LOAD_FAILED      : 'reflineSwitcher/LOAD_FAILED',

    UPDATE_REQUEST   : 'reflineSwitcher/UPDATE_REQUEST',
    UPDATE_COMPLETED : 'reflineSwitcher/UPDATE_COMPLETED',
    UPDATE_FAILED    : 'reflineSwitcher/UPDATE_FAILED'
}

const initialState = {
    active_ref_line_type: {},
    initial_ref_line_type: {}
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
       	case types.SET:
            return {
                ...state,
                active_ref_line_type: {
                    ...state.active_ref_line_type,
                    [action.id]: action.refline
                }
            };

        case types.RATE:
            let tempReflineKeyArray   = action.rate[0].key.split('_'),
                tempReflineType       = tempReflineKeyArray[0] + '_' + tempReflineKeyArray[1],
                tempReflineRate       = action.rate[0].rate,
                initial_ref_line_type = {};

            if (tempReflineRate && tempReflineRate.length) {
                if (tempReflineRate[0].indexOf('x') !== -1) {
                    initial_ref_line_type[tempReflineType] = { up: 'x6', bottom: 'x3' };
                } else if (tempReflineRate[0].indexOf('p') !== -1) {
                    initial_ref_line_type[tempReflineType] = { up: 'p6', bottom: 'p3' };
                }
            } else {
                const defaultLineType = action.default_line ? action.default_line : [];

                for (let i = 0; i < defaultLineType.length; i++) {
                  initial_ref_line_type[defaultLineType[i]] = { up: null, bottom: null };
                }

                initial_ref_line_type[tempReflineType] = { up: null, bottom: null };
            }

            return {
                ...state,
                initial_ref_line_type: {
                    ...state.initial_ref_line_type,
                    [action.id]: initial_ref_line_type
                }
            };

        case types.LOAD_REQUEST:
        case types.LOAD_FAILED:
        case types.UPDATE_REQUEST:
        case types.UPDATE_COMPLETED:
            return { ...state };

        case types.LOAD_COMPLETED:
            let new_ref_line_type = {},
                data              = {},
                initial           = state.initial_ref_line_type,
                keys              = Object.keys(initial);

            if (action.data.length > 0) {
                action.data.forEach((element) => {
                    data = { ...data, ...element };
                });

                if (keys.length > 0) {
                    keys.forEach((key) => {
                        new_ref_line_type = (key in data) ? { ...initial, ...data } : { ...initial };
                    });
                } else {
                    new_ref_line_type = { ...data };
                }
            } else {
                new_ref_line_type = { ...initial };
            }

            return {
              ...state,
              active_ref_line_type: {
                ...state.active_ref_line_type,
                ...new_ref_line_type
              }
            };

        case types.UPDATE_FAILED:
            return { ...initialState };

        default:
            return state;
    }
}

export const actions = {
    reflineSet: function(id, refline) {
        return { type: types.SET, id: id, refline: refline };
    },
    reflineRate: function(id, rate, defaultLine) {
        return {
          type         : types.RATE,
          id           : id,
          rate         : rate,
          default_line : defaultLine
        };
    },

    loadReflineRequest: function(stock_code) {
        return { type: types.LOAD_REQUEST, stock_code: stock_code };
    },
    loadReflineCompleted: function(data) {
        return { type: types.LOAD_COMPLETED, data: data };
    },
    loadReflineFailed: function(error) {
        return { type: types.LOAD_FAILED, error: error };
    },

    updateReflineRequest: function(switcher_id, data) {
        return { type: types.UPDATE_REQUEST, switcher_id: switcher_id, data: data };
    },
    updateReflineCompleted: function(data) {
        return { type: types.UPDATE_COMPLETED, data: data };
    },
    updateReflineFailed: function(error) {
        return { type: types.UPDATE_FAILED, error: error };
    }
}
