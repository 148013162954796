import { connect } from 'react-redux'
import { Octagon as component } from '../components'


const mapStateToProps = (state, ownProps) => ({
  is_mobile: state.Layout.is_mobile,
  width: state.Layout.width,
});
const Octagon = connect(mapStateToProps)(component);


export { Octagon }
