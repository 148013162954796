import React from 'react'


export class ColourThemeHandler extends React.PureComponent {
  handler = (e) => {
    const { colour_theme, onColourThemeSet } = this.props;

    let oldTheme = '';
    let newTheme = '';

    if (colour_theme === 'dark') {
      oldTheme = 'dark';
      newTheme = 'light';
    } else {
      oldTheme = 'light';
      newTheme = 'dark';
    }

    onColourThemeSet(oldTheme, newTheme)
  }

  render() {
    const colourTheme = this.props.colour_theme;

    return (
      <div className='menu-item colour-theme'>
        <section className='menu-item-link'>
          <i className='fa-lg fa-fw fas fa-adjust'/>

          <span className='menu-item-parent-title margin-right-auto'>深色主題</span>

          <label className={`ua-switch ${(colourTheme === 'dark') ? 'on' : 'off'}
              margin-bottom-0`}>
            <input
              type='checkbox'
              onChange={this.handler}
              defaultChecked={(colourTheme === 'dark')}
            />

            <span className='slider cursor-pointer'/>
          </label>
        </section>
      </div>
    );
  }
}
