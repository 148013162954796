const AutosuggestHighlightMatch = (suggestion, input) => {
  const arrSuggestion = suggestion.split(''),
    arrInput = input.split('');

  let arr = [];

  for (let i = 0; i < arrInput.length; i++) {
    const temp = arrInput[i],
      idx = arrSuggestion.indexOf(temp);

    if (idx > -1) {
      if (arr.length === 0) {
        arr[arr.length] = idx;
      }

      if (i === arrInput.length - 1 && idx < suggestion.length - 1) {
        arr[arr.length] = idx + 1;
      }
    }
  }

  return arr.length > 0 ? [ arr ] : arr;
};


export { AutosuggestHighlightMatch }
