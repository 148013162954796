import axios from 'axios'
import config from 'config'


const AnnouncementsLoadAPI = () => axios.get(
  `${config.api.APP_API_DOMAIN}/announcements`,
  { headers: { Accept: 'application/json' } }
)


export default { AnnouncementsLoadAPI }
