import { size as _size } from 'lodash'


export const types = {
  QUESTION_HANDLE_REQUEST: 'SingleAiPrompt/QUESTION_HANDLE_REQUEST',
  QUESTION_HANDLE_SUCCESS: 'SingleAiPrompt/QUESTION_HANDLE_SUCCESS',
  QUESTION_HANDLE_ERROR: 'SingleAiPrompt/QUESTION_HANDLE_ERROR',

  PROMPT_SET: 'SingleAiPrompt/PROMPT_SET',
  PROMPT_RESET: 'SingleAiPrompt/PROMPT_RESET',

  PROMPT_LIST_SET: 'SingleAiPrompt/PROMPT_LIST_SET',
}

const initialState = {
  ai_loading: false,
  ai_status: { prompt: null, get: null, store: null },
  prompt_data: null,
  prompt_btn_list: [],
  prompt_list: [],
  request_type: null,
};
const parseList = (list) => {
  let listUpdated = [];

  for (let i = 0; i < list.length; i++) {
    const listSubAccount = list[i].sub_account;

    for (let j = 0; j < listSubAccount.length; j++) {
      listUpdated[listUpdated.length] = listSubAccount[j];
    }
  }

  return listUpdated;
}
const setStatus = (dataType = '') => {
  const DATA_TYPE = [ 'prompt', 'get', 'store' ];
  const listFiltered = DATA_TYPE.filter((item) => (item !== dataType));
  const stateUpdate = {};

  for (let i = 0; i < listFiltered.length; i++) {
    stateUpdate[listFiltered[i]] = null;
  }

  return stateUpdate;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.QUESTION_HANDLE_REQUEST:
      const typeRequest = action.data_type;
      const stateRequest = {
        ...state,
        ai_loading: typeRequest === 'prompt',
        request_type: typeRequest,
      };
      const statusRequest = setStatus();
      stateRequest.ai_status = statusRequest;

      return { ...stateRequest };

    case types.QUESTION_HANDLE_SUCCESS:
      const typeSuccess = action.data_type;
      const stateSuccess = { ...state, ai_loading: false, request_type: null };
      const statusSuccess = setStatus(typeSuccess);
      statusSuccess[typeSuccess] = true;
      stateSuccess.ai_status = statusSuccess;

      let data = action.data;

      switch (typeSuccess) {
        case 'prompt':
          if (!data || _size(data) === 0) {
            data = { answer: '找不到相關訊息' };
          }

          if (data.answer === "''" || data.answer === 'N/A') {
            data.answer = '找不到相關訊息';
          }

          if (stateSuccess.prompt_data) {
            delete stateSuccess.prompt_data.saved;
          }

          stateSuccess.prompt_data = { ...stateSuccess.prompt_data, ...data };

          break;

        case 'get':
          stateSuccess.prompt_btn_list = data;

          const list = parseList(data);

          stateSuccess.prompt_list = list;

          break;

        default:

      }

      return { ...stateSuccess };

    case types.QUESTION_HANDLE_ERROR:
      const typeErr = action.data_type;
      const stateErr = { ...state, ai_loading: false, request_type: null };
      const statusErr = setStatus(typeErr);
      statusErr[typeErr] = false;
      stateErr.ai_status = statusErr;


      if (typeErr === 'prompt') {
        stateErr.prompt_data = { answer: '暫無訊息' };
      }

      return { ...stateErr };

    case types.PROMPT_SET:
      const dataUpdated = action.data;
      const promptDataUpdated = { ...state.prompt_data, ...dataUpdated };

      return { ...state, prompt_data: promptDataUpdated };

    case types.PROMPT_LIST_SET:
      const listBtn = [ ...action.list ];
      const listUpdated = parseList(listBtn);

      return { ...state, prompt_btn_list: listBtn, prompt_list: listUpdated };

    case types.PROMPT_RESET:
      return { ...state, prompt_data: null };

    default:
      return state;
  }
}

export const actions = {
  handleQuestionRequest: function (dataType, data) {
    return {
      type: types.QUESTION_HANDLE_REQUEST,
      data_type: dataType,
      data: data,
    };
  },
  handleQuestionSuccess: function(dataType, data) {
    return {
      type: types.QUESTION_HANDLE_SUCCESS,
      data_type: dataType,
      data: data,
    };
  },
  handleQuestionError: function(dataType, err) {
    return {
      type: types.QUESTION_HANDLE_ERROR,
      data_type: dataType,
      err: err,
    };
  },

  setPrompt: function(data) {
    return { type: types.PROMPT_SET, data: data };
  },
  setPromptList: function(list) {
    return { type: types.PROMPT_LIST_SET, list: list };
  },

  resetPrompt: function() {
    return { type: types.PROMPT_RESET };
  },
}
