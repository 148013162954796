import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/Tooltip';
import { Tooltip as component } from './Tooltip'


const mapStateToProps = (state, ownProps) => ({
	tooltips: state.Tooltip.tooltips,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onTooltipsToggle: (active_id) => dispatch(actions.toggle(active_id)),
	onTooltipsHide: (id) => dispatch(actions.setToggle(id)),
});
const Tooltip = connect(mapStateToProps, mapDispatchToProps)(component);


export { Tooltip }
