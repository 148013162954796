const getActiveLabIndex = (currentLabID, lab) => {
  const numCurrentLabID = isNaN(currentLabID)
    ?
    currentLabID
    :
    Number(currentLabID);

  if (numCurrentLabID === getLabID(lab)) { return 0; }

  if (!lab.customized) {
    const arrID = lab.master.affiliated_products.map((affiliated) => (
      getLabID(affiliated)
    ));

    for (let i = 0; i < arrID.length; i++) {
      if (arrID[i] === numCurrentLabID) { return i + 1; }
    }
  }
};
const getLabID = (lab) => (lab ? lab.pathname ? lab.pathname : lab.id : false);


export default { getActiveLabIndex, getLabID }
