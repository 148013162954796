export const types = {
  SET : 'AssistantData/SET',
  REQUEST : 'AssistantData/REQUEST',
  SUCCESS : 'AssistantData/SUCCESS',
  ERROR : 'AssistantData/ERROR',
  RESET : 'AssistantData/RESET',
  CREATEDATAREQUEST : 'AssistantData/CREATEDATAREQUEST',
  LOADDATAREQUEST : 'AssistantData/LOADDATAREQUEST',
  SEARCHREQUEST : 'AssistantData/SEARCHREQUEST',
  SEARCHSUCCESS : 'AssistantData/SEARCHSUCCESS',
}

const initialState = {
  data:[],
  searchResult:null,
  is_loading:false
}

export default function reducer(state = initialState, action = {}) {

  switch (action.type) {
    case types.SET:
      return { ...state };
    case types.REQUEST:
      return { ...state, is_loading: true };
    case types.ERROR:
      return { ...state, is_loading: false };
    case types.SUCCESS:
      return { ...state, data: [...state.data, { ...action.data }], is_loading: false };
    case types.RESET:
      return { ...initialState };
    case types.CREATEDATAREQUEST:
      return { ...state, data: [...state.data, { text:action.data.q, type:'text'}], is_loading: true };
    case types.SEARCHREQUEST:
      return { ...state, searchResult:[], is_loading: true };
    case types.SEARCHSUCCESS:
      return { ...state, searchResult: [...action.data.data], is_loading: false };
    default:
      return state;
  }
}

export const actions = {
  getRequest: function (data) {
    return { type: types.REQUEST, data: data };
  },
  getSuccess: function(data) {
    return { type: types.SUCCESS, data: data };
  },
  getSearchSuccess: function(data) {
    return { type: types.SEARCHSUCCESS, data: data };
  },
  getError: function(err) {
    return { type: types.ERROR, err: err };
  },
  reset: function() {
    return { type: types.RESET };
  },
  createDataRequest: function(data) {
    return { type: types.CREATEDATAREQUEST, data: data };
  },
  searchRequest: function(data) {
    return { type: types.SEARCHREQUEST, data: data };
  },
}