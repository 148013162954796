export const types = {
    DATA_REQUEST   : 'singleChart/DATA_REQUEST',
    DATA_COMPLETED : 'singleChart/DATA_COMPLETED',
    DATA_FAILED    : 'singleChart/DATA_FAILED',
}

const initialState = { multi_stocks_data: [] }

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.DATA_REQUEST:
        case types.DATA_FAILED:
            return { ...state };

        case types.DATA_COMPLETED:
            let stocks = [ ...state.multi_stocks_data ];

            if (action.data) {
                stocks[stocks.length] = action.data;
            }

            return {
                ...state,
                multi_stocks_data: stocks
            };

        default:
            return state;
    }
}

export const actions = {
    dataRequest: function(model, stockCode) {
        return {
            type       : types.DATA_REQUEST,
            model      : model,
            stock_code : stockCode,
        };
    },
    dataCompleted: function(data) {
        return {
            type: types.DATA_COMPLETED,
            data: data,
        };
    },
    dataFailed: function(err) {
        return {
            type: types.DATA_FAILED,
            err: err
        };
    }
}
