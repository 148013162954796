import moment from 'moment'
import config from 'config'


export const types = {
  LAB_LOAD_REQUEST           : 'dashboard/LAB_LOAD_REQUEST',
  LAB_LOAD_COMPLETED         : 'dashboard/LAB_LOAD_COMPLETED',
  LAB_LOAD_FAILED            : 'dashboard/LAB_LOAD_FAILED',

  CURRENT_LAB_LOAD_REQUEST   : 'dashboard/CURRENT_LAB_LOAD_REQUEST',
  CURRENT_LAB_LOAD_COMPLETED : 'dashboard/CURRENT_LAB_LOAD_COMPLETED',
  CURRENT_LAB_LOAD_FAILED    : 'dashboard/CURRENT_LAB_LOAD_FAILED',

  CACHE_CLEAR                : 'dashboard/CACHE_CLEAR',
  RESET                      : 'dashboard/RESET',
}

const initialState = { loading: false, failed: false, cache: {} };
const cacheExpired = config.dashboard.cache_expired;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LAB_LOAD_REQUEST:
      return { ...state, loading: true, failed: false };

    case types.LAB_LOAD_COMPLETED:
      let labCache = {
        ...state.cache,
        [action.labID]: {
          data: {
            id: action.data.id,
            available: action.data.available,
            master: action.data.master,
          },
          time: moment().add(cacheExpired, 'minutes').format(),
        },
      };

      if (action.data.available === 1) {
        labCache = {
          ...labCache,
          [action.labID]: { ...labCache[action.labID], data: action.data },
        };
      }

      return { ...state, loading: false, failed: false, cache: labCache };

    case types.LAB_LOAD_FAILED:
      return { ...state, loading: false, failed: true };

    case types.CURRENT_LAB_LOAD_REQUEST:
      return { ...state, loading: true };

    case types.CURRENT_LAB_LOAD_COMPLETED:
      let currentLabCache = { ...state.cache };

      if (action.data.available === 1) {
        currentLabCache = {
          ...state.cache,
          [action.labID]: {
            data: action.data,
            time: moment().add(cacheExpired, 'minutes').format(),
          }
        };
      }

      return { ...state, loading: false, cache: currentLabCache };

    case types.CURRENT_LAB_LOAD_FAILED:
      return { ...state, loading: false };

    case types.CACHE_CLEAR:
      return { ...state, cache: { ...state.cache, [action.labID]: false } };

    case types.RESET:
      return { ...state, loading: false, failed: false };

    default:
      return state;
  }
}

export const actions = {
  loadLabDataRequest: function(lab, noAuth = false) {
    return {
      type   : types.LAB_LOAD_REQUEST,
      lab    : lab,
      noAuth : noAuth,
    };
  },
  loadLabDataCompleted: function(data, labID) {
    return { type: types.LAB_LOAD_COMPLETED, data: data, labID: labID };
  },
  loadLabDataFailed: function(error) {
    return { type: types.LAB_LOAD_FAILED, error: error };
  },

  loadCurrentLabDataRequest: function(lab, affiliated, noAuth = false) {
    return {
      type       : types.CURRENT_LAB_LOAD_REQUEST,
      lab        : lab,
      affiliated : affiliated,
      noAuth     : noAuth,
    };
  },
  loadCurrentLabDataCompleted: function(data, labID) {
    return { type: types.CURRENT_LAB_LOAD_COMPLETED, data: data, labID: labID };
  },
  loadCurrentLabDataFailed: function(error) {
    return { type: types.CURRENT_LAB_LOAD_FAILED, error: error };
  },

  clearLabCache: function(labID) {
    return { type: types.CACHE_CLEAR, labID: labID };
  },
  reset: function () {
    return { type: types.RESET };
  },
}
