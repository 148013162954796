import _ from 'lodash'


export const types = {
  TOGGLE         : 'sideMenu/TOGGLE',
  HIDDEN_SET     : 'sideMenu/HIDDEN_SET',
  SECTION_TOGGLE : 'sideMenu/SECTION_TOGGLE',
  SECTION_OPEN   : 'sideMenu/SECTION_OPEN',
  SET            : 'sideMenu/SET'
}

const initialState = {
  side_menu_hidden: false,
  menu : [
    {
      title          : '最新公告',
      section        : 'announcement',
      url            : 'announcement',
      path           : '/announcement',
      is_auth_needed : false,
      uncollapse     : undefined,
      no_select      : true,
      icon           : 'fas fa-bell',
      items          : [],
    },
    {
      title          : '股票資訊',
      section        : 'freebie',
      url            : 'lab',
      path           : '/lab/freebie/stockbasicinfo-stocksummary',
      is_auth_needed : true,
      uncollapse     : false,
      no_select      : false,
      icon           : 'fas fa-chart-area',
      items          : [],
    },
    {
      title          : '我的訂閱',
      section        : 'my-collection',
      url            : 'lab',
      path           : '',
      is_auth_needed : true,
      uncollapse     : false,
      no_select      : false,
      customized     : false,
      icon           : 'fas fa-th-large',
      items          : [],
    },
    // {
    //   title          : '塗鴉板',
    //   section        : 'playground',
    //   url            : 'playground',
    //   path           : '/playground',
    //   uncollapse     : undefined,
    //   no_select      : true,
    //   icon           : 'fas fa-pencil-ruler',
    //   items          : [],
    // },
    {
      title          : '自建圖表',
      section        : 'my-lab',
      url            : 'lab',
      path           : '',
      is_auth_needed : true,
      uncollapse     : false,
      no_select      : false,
      customized     : true,
      icon           : 'fas fa-flask',
      items          : [],
    },
    {
      title          : '投資組合',
      section        : 'portfolio',
      url            : 'lab',
      path           : '',
      is_auth_needed : true,
      uncollapse     : false,
      no_select      : false,
      customized     : true,
      icon           : 'fas fa-box',
      items          : [],
    },
    {
      title          : '優分析產業新聞',
      section        : 'uanalyze-news',
      url            : 'uanalyze-news',
      path           : false,
      is_auth_needed : false,
      uncollapse     : undefined,
      no_select      : true,
      icon           : 'fas fa-book-open',
      href           : 'https://uanalyze.com.tw/',
      items          : [],
    },
    {
      title          : '商城',
      section        : 'e-com',
      url            : 'e-com',
      path           : '/e-com/product-view/collection',
      is_auth_needed : false,
      uncollapse     : undefined,
      no_select      : true,
      icon           : 'fas fa-shopping-cart',
      items          : [],
    },
  ],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.TOGGLE:
      return { ...state, side_menu_hidden: !state.side_menu_hidden };

    case types.HIDDEN_SET:
      return { ...state, side_menu_hidden: action.side_menu_hidden };

    case types.SECTION_TOGGLE:
      let menuUncollapse = state.menu;

      for (let i = 0; i < menuUncollapse.length; i++) {
        let parentUpdated = menuUncollapse[i];

        if (parentUpdated.section && parentUpdated.section === action.section) {
          parentUpdated.uncollapse = !parentUpdated.uncollapse;
          menuUncollapse[i] = parentUpdated;

          break;
        }
      }

      return { ...state, menu: _.cloneDeep(menuUncollapse) };

    case types.SECTION_OPEN:
      const menuOpen = state.menu.map((item) => {
        item.uncollapse = (item.section === action.section) ? true : item.uncollapse;

        return item;
      });

      return { ...state, menu: _.cloneDeep(menuOpen) };

    case types.SET:
      return { ...state, menu: action.menu };

    default:
      return state;
  }
}

export const actions = {
  toggleSideMenu: function () {
    return { type: types.TOGGLE };
  },
  hideSideMenu: function(sideMenuHidden) {
    return { type: types.HIDDEN_SET, side_menu_hidden: sideMenuHidden };
  },
  toggleSection: function(section) {
    return { type: types.SECTION_TOGGLE, section: section };
  },
  openSection: function(section) {
    return { type: types.SECTION_OPEN, section: section };
  },
  setSideMenu: function(menu) {
    return { type: types.SET, menu: menu };
  }
}
