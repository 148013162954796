import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const AvatarLoadAPI = () => axios.get(
  `${config.api.APP_API_DOMAIN}/user/avatar`,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const AvatarAddAPI = (formData) => axios.post(
  `${config.api.APP_API_DOMAIN}/user/avatar`,
  formData,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const AvatarUpdateAPI = (formData) => axios.post(
  `${config.api.APP_API_DOMAIN}/user/avatar`,
  formData,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
)


export default { AvatarLoadAPI, AvatarAddAPI, AvatarUpdateAPI }
