import React from "react";
import { Modal } from 'components';
import func from './func';

const keyLabel = i => {
    const { modifierkey, primaryKey } = func.parseKeyCombination(i);
    const isCombo = modifierkey !== "F";

    return (
        <span className={`shortcutKey-k--${func.getModifierkeyName(modifierkey)} shortcut-list`}>
            {isCombo && func.getModifierkeyName(modifierkey)}{isCombo && "+"}{primaryKey}
        </span>
    );
}

const keyList = keys => {
    return keys.map((i, k) => <span key={k}>{keyLabel(i)}{k !== keys.length - 1 && "、"}</span>);
};

const listMap = (item, key) => (
    <div className="lab-btn-modal-shortcut-chunks-list" key={key}>{item.title}：{keyList(item.key)}</div>
)

const chunksMap = (item, key) => (
    <div className="lab-btn-modal-shortcut-chunks" key={key}>{item.map(listMap)}</div>
)

const ShortcutListModal = ({ data }) => {
    const splitDataIntoChunks = (data, chunkNum) => {
        let chunks = [];
        for (let i=0; i<data.length; i+=chunkNum) {
            chunks.push(data.slice(i, i+chunkNum));
        }
        return chunks;
    }

    return(
        <Modal
            id="shortcut-list"
            title="快捷鍵列表"
            html={
                <div className="lab-btn-modal-wrap">
                    <div className="lab-btn-modal-shortcut">{splitDataIntoChunks(data, 5).map(chunksMap)}</div>
                </div>
            }
            leaveTxt = "我知道了"
        />
    )
}


export default ShortcutListModal;