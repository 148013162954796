import { connect } from 'react-redux'
import { Loading as component } from './Loading'


const mapStateToProps = (state, ownProps) => ({
  width: state.Layout.width,
  height: state.Layout.height,
  colour_theme: state.ColourTheme.colour_theme,
});
const Loading = connect(mapStateToProps)(component);


export { Loading } 
