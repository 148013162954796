export default {
  PRODUCT_TYPE: {
    collection   : {
      title: '策略包',
      info: `策略包是一套有系統的投資策略，包括「精選股套件」、「個股評分套件」、「股價評估套件」。
  這些套件間具有整合性、相互連動，非常適合正在學習特定投資策略的投資人。
  此外，策略包也能幫助投資人快速檢測持股，並追蹤財報與價值面的變化，提高分析效率。`,
    },
    single       : {
      title: '數據庫套件',
      info: `數據庫套件是一些財務指標套件、個股公開資訊套件等特定功能套件。
  彈性、多元、豐富的功能，應有盡有。
  這些套件能夠幫助投資人歸納分類，建立屬於自己的數據庫。`,
    },
    multi_single : {
      title: '數據庫',
      info: '可安裝優分析所有免費與付費的分析套件',
    },
  },
}
