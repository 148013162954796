export default {
	cache_expired				 : 10,
	arr_model_check 		 : [
		'A0015Rankings',
		'A0015Score',
		'A0015_1Rankings',
		'A0015_1Score',
		'A0003',
		'A0003Rankings',
		'A0003Score',
		'A0003_2',
		'A0003_2Rankings',
		'A0003_2Score',
		'A0003_5',
		'A0003_5Rankings',
		'A0003_5Score',
		'A0004',
		'A0004Rankings',
		'A0004Score',
		'A0004_1',
		'A0004_1Rankings',
		'A0004_1Score',
		'A0004_3',
		'A0004_3Rankings',
		'A0004_3Score',
		'A0006',
		'A0006Rankings',
		'A0006Score',
		'A0009',
		'A0009Rankings',
		'A0009Score',
		'BuffettTestRankings',
		'BuffettScore',
		'E0001',
		'E0001Rankings',
		'E0001Score',
		'MyValuationRecord',
		'NCAV',
		'NCAVRankings',
		'NCAVScore',
		'PortfolioReturnTemplate',
	],
}
