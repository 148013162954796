import { ArrayHandler } from 'utils'
import _ from 'lodash'


export const types = {
  // load
  LOAD_REQUEST                : 'favorite/LOAD_REQUEST',
  LOAD_COMPLETED              : 'favorite/LOAD_COMPLETED',
  LOAD_FAILED                 : 'favorite/LOAD_FAILED',
  // switch
  SWITCH                      : 'favorite/SWITCH',
  // edit
  NAME_EDIT_REQUEST           : 'favorite/NAME_EDIT_REQUEST',
  NAME_EDIT_COMPLETED         : 'favorite/NAME_EDIT_COMPLETED',
  NAME_EDIT_FAILED            : 'favorite/NAME_EDIT_FAILED',
  // add group
  ADD_REQUEST                 : 'favorite/ADD_REQUEST',
  ADD_COMPLETED               : 'favorite/ADD_COMPLETED',
  ADD_FAILED                  : 'favorite/ADD_FAILED',
  // sort
  STOCKS_SORT                 : 'favorite/STOCKS_SORT',
  STOCKS_SORT_REQUEST         : 'favorite/STOCKS_SORT_REQUEST',
  STOCKS_SORT_COMPLETED       : 'favorite/STOCKS_SORT_COMPLETED',
  STOCKS_SORT_FAILED          : 'favorite/STOCKS_SORT_FAILED',
  // add stock
  STOCK_ADD_REQUEST           : 'favorite/STOCK_ADD_REQUEST',
  STOCK_ADD_COMPLETED         : 'favorite/STOCK_ADD_COMPLETED',
  STOCK_ADD_FAILED            : 'favorite/STOCK_ADD_FAILED',
  // delete stock
  STOCK_DELETE_REQUEST        : 'favorite/STOCK_DELETE_REQUEST',
  STOCK_DELETE_COMPLETED      : 'favorite/STOCK_DELETE_COMPLETED',
  STOCK_DELETE_FAILED         : 'favorite/STOCK_DELETE_FAILED',
  // delete all stocks
  ALL_STOCKS_DELETE_REQUEST   : 'favorite/ALL_STOCKS_DELETE_REQUEST',
  ALL_STOCKS_DELETE_COMPLETED : 'favorite/ALL_STOCKS_DELETE_COMPLETED',
  ALL_STOCKS_DELETE_FAILED    : 'favorite/ALL_STOCKS_DELETE_FAILED',
}

const initialState = {
  loading          : false,
  current_group    : 0,
  favs             : [],
  is_reload_needed : false,
  is_delete_all    : false,
}
/*
 group: 12
 stock: 50 per group
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOAD_REQUEST:
    case types.NAME_EDIT_REQUEST:
    case types.ADD_REQUEST:
    case types.STOCKS_SORT_REQUEST:
    case types.STOCK_ADD_REQUEST:
    case types.STOCK_DELETE_REQUEST:
    case types.ALL_STOCKS_DELETE_REQUEST:
      return { ...state, loading: true };

    case types.LOAD_COMPLETED:
      let favs = action.data;

      for (let i = 0; i < favs.length; i++) {
        let stocks = favs[i].stocks;

        if (stocks.length > 0) {
          stocks = ArrayHandler.sorter(stocks, 'sort');
          favs[i].stocks = stocks;
        }
      }

      return {
        ...state,
        loading: false,
        favs: [ ...action.data ],
        is_reload_needed: false,
        is_delete_all: false,
      };

    case types.SWITCH:
      return { ...state, current_group: action.idx };

    case types.STOCKS_SORT_COMPLETED:
    case types.LOAD_FAILED:
    case types.NAME_EDIT_FAILED:
    case types.ADD_FAILED:
    case types.STOCKS_SORT_FAILED:
    case types.STOCK_ADD_FAILED:
    case types.STOCK_DELETE_FAILED:
    case types.ALL_STOCKS_DELETE_FAILED:
      return { ...state, loading: false };

    case types.NAME_EDIT_COMPLETED:
      let favEdited = [ ...state.favs ];
      favEdited[action.data.group_id - 1].group_name = action.data.group_name;

      return { ...state, loading: false, favs: favEdited };

    case types.ADD_COMPLETED:
      return { ...state, loading: false, is_reload_needed: true };

    case types.STOCKS_SORT:
      let favSorted = [ ...state.favs ],
        stocksSorted = favSorted[action.data.current_group].stocks;
      stocksSorted = action.data.temp;
      favSorted[action.data.current_group].stocks = stocksSorted;

      return { ...state, favs: favSorted };

    case types.STOCK_ADD_COMPLETED:
      let favAdded = [ ...state.favs ];
      favAdded[action.data[0].favorite_group_id - 1].stocks = action.data;

      return { ...state, loading: false, favs: favAdded };

    case types.STOCK_DELETE_COMPLETED:
      let favDeleted = _.cloneDeep(state.favs),
        stocksDeleted = favDeleted[action.data.group_id - 1].stocks;
      stocksDeleted = stocksDeleted.filter((item) => (item.stock_code !== action.data.stock_code));
      favDeleted[action.data.group_id - 1].stocks = [ ...stocksDeleted ];

      return { ...state, loading: false, favs: favDeleted };

    case types.ALL_STOCKS_DELETE_COMPLETED:
      return { ...state, loading: false, is_delete_all: true };

    default:
      return state;
  }
}

export const actions = {
  loadFavGroupDataRequest: function () {
    return { type: types.LOAD_REQUEST };
  },
  loadFavGroupDataCompleted: function(data) {
    return { type: types.LOAD_COMPLETED, data: data };
  },
  loadFavGroupDataFailed: function(error) {
    return { type: types.LOAD_FAILED, error: error };
  },

  switchFavGroup: function(idx) {
    return { type: types.SWITCH, idx: idx };
  },

  editFavGroupNameRequest: function(data) {
    return { type: types.NAME_EDIT_REQUEST, data: data };
  },
  editFavGroupNameCompleted: function(data) {
    return { type: types.NAME_EDIT_COMPLETED, data: data }
  },
  editFavGroupNameFailed: function(error) {
    return { type: types.NAME_EDIT_FAILED, error: error };
  },

  addFavGroupRequest: function () {
    return { type: types.ADD_REQUEST };
  },
  addFavGroupCompleted: function(data) {
    return { type: types.ADD_COMPLETED, data: data };
  },
  addFavGroupFailed: function(error) {
    return { type: types.ADD_FAILED, error: error };
  },

  sortFavGroupStocks: function(temp) {
    return { type: types.STOCKS_SORT, data: temp };
  },
  sortFavGroupStocksRequest: function(data) {
    return { type: types.STOCKS_SORT_REQUEST, data: data };
  },
  sortFavGroupStocksCompleted: function(data) {
    return { type: types.STOCKS_SORT_COMPLETED, data: data };
  },
  sortFavGroupStocksFailed: function(error) {
    return { type: types.STOCKS_SORT_FAILED, error: error };
  },

  addFavGroupStockRequest: function(data) {
    return { type: types.STOCK_ADD_REQUEST, data: data };
  },
  addFavGroupStockCompleted: function(data) {
    return { type: types.STOCK_ADD_COMPLETED, data: data };
  },
  addFavGroupStockFailed: function(error) {
    return { type: types.STOCK_ADD_FAILED, error: error };
  },
  deleteFavGroupStockRequest: function(data) {
    return { type: types.STOCK_DELETE_REQUEST, data: data };
  },
  deleteFavGroupStockCompleted: function(data) {
    return { type: types.STOCK_DELETE_COMPLETED, data: data };
  },
  deleteFavGroupStockFailed: function(error) {
    return { type: types.STOCK_DELETE_FAILED, error: error };
  },

  deleteFavGroupAllStocksRequest: function(id) {
    return { type: types.ALL_STOCKS_DELETE_REQUEST, group_id: id };
  },
  deleteFavGroupAllStocksCompleted: function(data) {
    return { type: types.ALL_STOCKS_DELETE_COMPLETED, data: data };
  },
  deleteFavGroupAllStocksFailed: function(error) {
    return { type: types.ALL_STOCKS_DELETE_FAILED, error: error };
  }
}
