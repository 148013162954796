export const types = { CHANGE: 'tabs/CHANGE' }

const initialState = { active_tab: {} }

export default function reducer(state = initialState, action = {}) {
  return (action.type === types.CHANGE)
    ?
    {
      ...state,
      active_tab: {
        ...state.active_tab,
        [action.id]: action.active_tab
      }
    }
    :
    state;
}

export const actions = {
  changeTab: function(id, tab) {
    return {
      type       : types.CHANGE,
      id         : id,
      active_tab : tab
    };
  }
}
