import _ from 'lodash'


export const types = {
    SET    : 'validator/SET',
    REMOVE : 'validator/REMOVE',
    SHOW   : 'validator/SHOW',
    RESET  : 'validator/RESET',
    CLEAR  : 'validator/CLEAR'
}

const initialState = {
    init        : true,
    show        : false,
    form_error  : true,
    input_error : {}
}

export default function reducer(state = {}, action = {}) {
    let formState = { ...state };

    switch (action.type) {
        case types.SET:
            if (!(action.form_id in formState)) {
                formState[action.form_id] = { ...initialState };
            }

            let input_error = {
              ...formState[action.form_id].input_error,
              [action.key]: action.value
            };

            formState[action.form_id].input_error = { ...input_error };

            const form_error = _.values(input_error).reduce((a, b) => (a || b), false);

            formState[action.form_id].form_error = form_error;
            formState[action.form_id].init = false;

            return formState;

        case types.REMOVE:
            if (action.form_id in formState) {
                delete formState[action.form_id].input_error[action.key];

                const input_error = formState[action.form_id].input_error,
                    form_error = _.values(input_error).reduce((a, b) => (a || b), false);

                formState[action.form_id].form_error = form_error;
            }

            return formState;

        case types.RESET:
            delete formState[action.form_id];

            return formState;

        case types.SHOW:
            formState[action.form_id].show = true;

            return formState;
        case types.CLEAR:
            formState[action.form_id] = { ...initialState };

            return formState;

        default:
            return state;
    }
}

export const actions = {
    set: function(form_id, key, value) {
        return { type: types.SET, form_id: form_id, key: key, value: value };
    },
    remove: function(form_id, key) {
        return { type: types.SET, form_id: form_id, key: key };
    },
    show: function(form_id) {
        return { type: types.SHOW, form_id: form_id };
    },
    clear: function(form_id) {
        return { type: types.CLEAR, form_id: form_id };
    },
    reset: function(form_id) {
        return { type: types.RESET, form_id: form_id };
    }
}
