import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/SideMenu'
import { MenuToggler as component } from	'../components'


const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onMenuToggle: () => dispatch(actions.toggleSideMenu()),
})
const MenuToggler = connect(mapStateToProps, mapDispatchToProps)(component);

export { MenuToggler } 
