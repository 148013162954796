const parseKeyCombination = key => {
    const isCombo = key.split('+');
    const isF = key.startsWith("F");
    let modifierkey;
    let primaryKey;

    if(isCombo){
        modifierkey = isCombo?.[0];
        primaryKey = isCombo?.[1];
    }
    if(isF){
        modifierkey = "F";
        primaryKey = key;
    }

    return { modifierkey, primaryKey };
}

const getModifierkeyName = modifier => {
    switch(modifier){
        case "A": return "ALT";
        case "C": return "CTRL";
        case "F": return "F";
        default: return;
    }
}

export default { parseKeyCombination, getModifierkeyName };