import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Contact'
import { ContactAPIs } from 'api'

function * watchContactRequest() {
    yield takeLatest(types.REQUEST, contactFlow);
}

function * contactFlow(action) {
    try {
        const response = yield call(ContactAPIs.ContactSubmitAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.sendSuccess(responseData.data));
        } else {
            yield put(actions.sendError(responseData.error));
        }
    } catch(error) {
        yield put(actions.sendError(error));
    }
}

export default [ watchContactRequest() ]
