import { Cookies } from 'utils'


export const types = {
   CONFIG_REQUEST     : 'payment/CONFIG_REQUEST',
   CONFIG_COMPLETED   : 'payment/CONFIG_COMPLETED',
   CONFIG_FAILED      : 'payment/CONFIG_FAILED',

   TRIAL_REQUEST      : 'payment/TRIAL_REQUEST',
   TRIAL_COMPLETED    : 'payment/TRIAL_COMPLETED',
   TRIAL_FAILED       : 'payment/TRIAL_FAILED',

   PRODUCT_PLANS_SET  : 'payment/PRODUCT_PLANS_SET',

   CHECKOUT_REQUEST   : 'payment/CHECKOUT_REQUEST',
   CHECKOUT_COMPLETED : 'payment/CHECKOUT_COMPLETED',
   CHECKOUT_FAILED    : 'payment/CHECKOUT_FAILED',
}

const initialState = {
  credit_info   : { credit_type: 'credit' },
  origin_price  : 0,
  total_price   : 0,
  promocodes    : {},
  product_plans : {},
  add_products  : {},
  order         : null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CONFIG_REQUEST:
    case types.TRIAL_REQUEST:
    case types.TRIAL_FAILED:
        return { ...state };

    case types.CONFIG_COMPLETED:
      return {
        ...state,
        credit_info: action.data ? { ...action.data } : { ...state.credit_info },
      };

    case types.CONFIG_FAILED:
      return { ...state, credit_info: { credit_type: 'credit' } };

    case types.TRIAL_COMPLETED:
      const { origin_price, total_price } = action.data;
      const removeParams = { path: '/' };
      const setParams = {
        path     : '/',
        maxAge   : 60 * 60 * 24 * 1,
        sameSite : 'Lax',
        secure   : true,
      };

      Cookies.remove('origin_price', removeParams);
      Cookies.set('origin_price', origin_price, setParams);
      Cookies.remove('total_price', removeParams);
      Cookies.set('total_price', total_price, setParams);

      return { ...state, ...action.data };

    case types.PRODUCT_PLANS_SET:
      return { ...state, product_plans: action.plans };

    case types.CHECKOUT_REQUEST:
    case types.CHECKOUT_FAILED:
      return { ...state, order: null };

    case types.CHECKOUT_COMPLETED: return { ...state, order: { ...action.data } };

    default: return state;
  }
}

export const actions = {
  configRequest: () => ({ type: types.CONFIG_REQUEST }),
  configCompleted: (data) => ({
      type: types.CONFIG_COMPLETED,
      data: data
  }),
  configFailed: (error) => ({
      type: types.CONFIG_FAILED,
      error: error
  }),

  trialRequest: (products, promocodes, modules) => ({
      type       : types.TRIAL_REQUEST,
      products   : products,
      promocodes : promocodes,
      modules    : modules
  }),
  trialCompleted: (data) => ({
      type: types.TRIAL_COMPLETED,
      data: data
  }),
  trialFailed: (error) => ({
      type: types.TRIAL_FAILED,
      error: error
  }),

  setProductPlans: (plans) => ({
      type: types.PRODUCT_PLANS_SET,
      plans: plans
  }),

  checkoutRequest: (orderData) => ({
      type: types.CHECKOUT_REQUEST,
      orderData: orderData
  }),
  checkoutCompleted: (data) => ({
      type: types.CHECKOUT_COMPLETED,
      data: data
  }),
  checkoutFailed: (error) => ({
      type: types.CHECKOUT_FAILED,
      error: error
  })
}
