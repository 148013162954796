export const types = {
    XAXIS_RESET     : 'chartFauntion/XAXIS_RESET',
    SHOW            : 'chartFauntion/SHOW',
    VBP_SHOW        : 'chartFauntion/VBP_SHOW',
    FIBONACCI_RESET : 'chartFauntion/FIBONACCI_RESET'
}

const initialState = { fibonacci_setting: {} }

export const initialFibonacciSetting = {
    x_axis          : { start: 0, end: 0 },
    show            : 'steady',
    vbp_show        : true,
    fibonacci_value : []
}

export default function reducer(state = initialState, action = {}) {
    let fibonacci_setting;

    if (!(action.singleKey in state.fibonacci_setting)) {
        fibonacci_setting = { ...initialFibonacciSetting };
    } else {
        fibonacci_setting = { ...state.fibonacci_setting[action.singleKey] };
    }

    switch (action.type) {
      case types.XAXIS_RESET:
          let period = { start: action.start, end: action.end };

          fibonacci_setting.x_axis = period;

          return { ...state, fibonacci_setting: { ...state.fibonacci_setting, [action.singleKey]: fibonacci_setting }};

      case types.SHOW:
          fibonacci_setting.show = action.show_type;

          return { ...state, fibonacci_setting: { ...state.fibonacci_setting, [action.singleKey]: fibonacci_setting }};

      case types.VBP_SHOW:
          fibonacci_setting.vbp_show = !fibonacci_setting.vbp_show;

          return { ...state, fibonacci_setting: { ...state.fibonacci_setting, [action.singleKey]: fibonacci_setting }};

      case types.FIBONACCI_RESET:
          fibonacci_setting.fibonacci_value = action.value.reverse();

          return { ...state, fibonacci_setting: { ...state.fibonacci_setting, [action.singleKey]: fibonacci_setting }};

      default:
          return state;
    }
  }

  export const actions = {
    resetXAxis: function(start, end, singleKey) {
        return { type: types.XAXIS_RESET, start: start, end: end, singleKey: singleKey };
    },
    show: function(singleKey, type) {
        return { type: types.SHOW, singleKey: singleKey, show_type: type };
    },
    showVBP: function(singleKey) {
        return { type: types.VBP_SHOW, singleKey: singleKey };
    },
    setFibonacci: function(value, singleKey) {
        return { type: types.FIBONACCI_RESET, value: value, singleKey: singleKey };
    }
  }
