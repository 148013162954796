import React from 'react'
import { Link } from 'react-router-dom'
import { isEqual as _isEqual } from 'lodash'

import { Message, Modal } from 'components'
import { MenuChildren } from '../MenuChildren'
import {
  MenuContainer,
  SortableMenuContainer,
  SortableMenuItem,
} from './components'


const getPath = (parent, authenticated) => {
  let path = '/login-page';

  if (authenticated) {
    path = (parent.items.length > 0)
      ?
      `/lab/dashboard/${parent.items[0][(parent.items[0].pathname)
          ? 'pathname' : 'id']}`
      :
      '/e-com/product-view/collection';
  }

  return path;
}

export class MenuParents extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // edit & sort
      is_editing : false,

      // sort
      is_sorted  : false,

      // delete
      is_enabled : true,
    };
  }

  onOpen = (e) => {
    e.stopPropagation();

    const { is_mobile, parent, onSectionOpen, onSideMenuHide, } = this.props;

    if (!parent.no_select) {
      onSectionOpen(parent.section);
    } else if (parent.href) {
      window.open(parent.href, 'new');
    }

    onSideMenuHide(is_mobile);
  }

  onOpenByIcon = (e) => {
    e.stopPropagation();

    const { parent, onSectionToggle } = this.props;

    onSectionToggle(parent.section);
  }

  onSectionOpen = (e) => {
    const { location, parent, onSectionOpen } = this.props;

    let section = false;

    if (!parent.no_select) {
      if (parent.path.length > 0) {
        section = (parent.path === location.pathname) ? parent.section : false;
      } else {
        section = parent.items.find((item) => {
            const labID = item.pathname ? item.pathname : item.id;
            const arrPathname = location.pathname.split('/');

            return arrPathname[arrPathname.length - 1] === labID.toString();
          })
          ?
          parent.section
          :
          false;
      }
    }

    if (section) { onSectionOpen(section); }
  }

  onAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { parent, history } = this.props;

    history.push(parent.customized
      ?
      `/lab/${parent.section}/create`
      :
      '/e-com/product-view/collection');

    this.setState({ is_editing: false });
  }

  onEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { is_editing, is_sorted } = this.state;
    const { parent, onSectionOpen, onLabListSortRequest } = this.props;

    if (!is_editing && !parent.uncollapse) {
      onSectionOpen(parent.section);
    }

    if (is_sorted) {
      const labsSorted = parent.items.map((item) => (item.id));

      onLabListSortRequest({ sorted_laboratories: labsSorted });
    }

    this.setState({ is_editing: !is_editing, is_sorted: false });
  }

  onSortStart = ({ node, index, collection, isKeySorting }, e) => {
    document.getElementById('side-menu-nav').style.overflowY = 'hidden';
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    document.getElementById('side-menu-nav').style.overflowY = 'auto';

    const { parent, onLabListSort } = this.props;
    const { section, items } = parent;

    let sortType = null;

    switch (section) {
      case 'my-collection':
        sortType = 'master';

        break;

      case 'my-lab':
        sortType = 'custom';

        break;

      case 'portfolio':
        sortType = 'portfolio';

        break;

      default:

    }

    this.setState(
      { is_sorted: true },
      () => onLabListSort(items, oldIndex, newIndex, sortType)
    );
  }

  onRemoveModalShow = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { id, section } = e.currentTarget.dataset;
    const { onLabDeleteSet, onModalToggle } = this.props;

    onLabDeleteSet(id);
    onModalToggle(`delete-lab-${section}`, true);
  }

  onRemove = (e) => {
    const { id, section } = e.currentTarget.dataset;
    const { onLabProcessChange, onLabDeleteRequest, onModalToggle } = this.props;

    onLabProcessChange('delete');
    onLabDeleteRequest(id);
    onModalToggle(`delete-lab-${section}`);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      parent,
      title,
      lab_id_add,
      lab_type_sort,
      lab_id_delete,
      location,
      history,
      onReset,
    } = this.props;
    const { path, items, no_select, uncollapse } = parent;
    const pathname = location.pathname;

    if ((pathname !== path && !no_select // edited
        && prevProps.parent.items.length !== items.length)
        || (!uncollapse && prevProps.pathname !== pathname
        && (pathname.indexOf('dashboard') !== -1
        || pathname.indexOf('freebie') !== -1))) {
      this.onSectionOpen();
    }

    if (!_isEqual(prevProps.parent, parent) && (lab_id_add || lab_type_sort
        || lab_id_delete) && !this.state.is_sorted) {
      Message.SmallBox({
        title   : `<i class='fa fa-check-circle txt-white' aria-hidden='true'></i> ${title}成功`,
        content : '',
        timeout : 3000,
      });

      if (lab_id_add) { history.push(`/lab/dashboard/${lab_id_add}`); }

      if (lab_id_delete) {
        const pathnameUpdated = items[0]
          ?
          `/lab/dashboard/${items[0][(items[0].pathname) ? 'pathname' : 'id']}`
          :
          '/';

        history.replace(pathnameUpdated);
      }

      onReset();
    }
  }

  componentDidMount() {
    this.onSectionOpen();
  }

  render() {
    const { is_editing, is_enabled } = this.state;
    const {
      authenticated,
      idxParent,
      lab_id_delete,
      location,
      parent,
    } = this.props;
    const {
      title,
      section,
      path,
      items,
      icon,
      uncollapse,
    } = parent;
    const pathUpdated = (path.length === 0)
      ?
      getPath(parent, authenticated)
      :
      path;

    let btnAdd = null;
    let btnEdit = null;
    let MenuList = (
      <MenuContainer>
        {items.map((child, idx) => (
          <MenuChildren
            child={child}
            idxParent={idxParent}
            isEditing={is_editing}
            isEnabled={is_enabled}
            parent={parent}
            // pathname={pathname}
            onRemove={this.onRemoveModalShow}
            key={idx}
          />
        ))}
      </MenuContainer>
    );
    let modal = null;

    if (path.length === 0 && authenticated) {
      if (is_editing) {
        btnAdd = (
          <span
            className='btn-add margin-left-auto cursor-pointer'
            onClick={this.onAdd}
          >
            <i className='fas fa-plus'/>
          </span>
        );
        btnEdit = (
          <strong
            className='btn-end-edit margin-left-10 margin-right-30 cursor-pointer'
            onClick={this.onEdit}
          >
            確定
          </strong>
        );
        MenuList = (
          <SortableMenuContainer
            helperClass='dragging-helper dragging-helper-menu'
            axis='y'
            lockAxis='y'
            distance={2}

            onSortStart={this.onSortStart}
            onSortEnd={this.onSortEnd}
          >
            {items.map((child, idx) => (
              <SortableMenuItem
                child={child}
                idxParent={idxParent}
                index={idx}
                isEditing={is_editing}
                isEnabled={is_enabled}
                parent={parent}
                // props={this.props}
                onRemove={this.onRemoveModalShow}
                key={idx}
              />
            ))}
          </SortableMenuContainer>
        );
      } else {
        btnEdit = (
          <span
            className='btn-edit margin-left-auto margin-right-30 cursor-pointer'
            onClick={this.onEdit}
          >
            <i className='fa fa-pen'/>
          </span>
        );
      }

      modal = (
        <Modal
          id={`delete-lab-${section}`}
          customClassName='mobile-height'
          title={`刪除${title}`}
          html={
            <div className='expired-content margin-top-10vh text-center'>
              <i className='txt-5em elegant elegant-eleganticons-119'/>

              <div className='margin-top-20'>是否刪除{title}？</div>
            </div>
          }
          leaveTxt='取消'
          checkBtn={
            <button
              className='submit ua-btn default margin-left-15'
              data-id={lab_id_delete}
              data-section={section}
              onClick={this.onRemove}
            >
              確定
            </button>
          }
        />
      );
    }

    return (
      <div className='menu-item cursor-pointer'>
        <Link
          className={`menu-item-link ${uncollapse
              || (pathUpdated === location.pathname) ? 'open' : ''}`}
          to={pathUpdated ? pathUpdated :'#'}
          onClick={this.onOpen}
        >
          <span><i className={`fa-lg fa-fw ${icon}`}/></span>

          <span className='menu-item-parent-title'>{title}</span>

          {btnAdd}

          {btnEdit}
        </Link>

        {(section && items.length > 0) && (
          <b className='collapse-sign' onClick={this.onOpenByIcon} >
            <i
              className={`far fa-${uncollapse? 'minus' : 'plus'}-square`}
              aria-hidden='true'
            />
          </b>
        )}

        {(items.length > 0 && uncollapse) && MenuList}

        {modal}
      </div>
    );
  }
}
