import { connect } from 'react-redux'
import Case from './Case'


const mapStateToProps = (state, ownProps) => ({
  selected: state.Renew.plan_id === ownProps.id,
  is_mobile: state.Layout.is_mobile,
})


export default connect(mapStateToProps)(Case)
