import axios from 'axios'


const { REACT_APP_AI_HOST, REACT_APP_STOCK_DOMAIN } = process.env;
const QuestionsGetAPI = (subcategory) => axios.get(
  `${REACT_APP_AI_HOST}/api/questions?name=${encodeURIComponent(subcategory)}`,
  { headers: { Accept: 'application/json' } }
);
const AnswerGetAPI = (question, subcategory) => axios.get(
  `${REACT_APP_AI_HOST}/api/ai/chat/?name=${encodeURIComponent(subcategory)}&q=${encodeURIComponent(question)}`,
  { headers: { Accept: 'application/json' } }
);
const SourceGetAPI = (data) => axios.post(
  `${REACT_APP_STOCK_DOMAIN}/data_fetch/api/ai_source`,
  data,
  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
);


export default { QuestionsGetAPI, AnswerGetAPI, SourceGetAPI }
