import React from 'react'


const QuestionBtn = ({ item, currShow, btnText, onClick, disabled }) => (
  <button
    type='button'
    className={`question-submit submit ua-btn outline round${(item === currShow)
        ? ` enabled-show` : ''}`}
    onClick={onClick}
    disabled={disabled}
  >
    {btnText}
  </button>
);


export default QuestionBtn
