import React from 'react'
import queryString from 'query-string'
import { debounce as _debounce, isEqual as _isEqual, size as _size } from 'lodash'

import 'assets/sass/Single/ai-chat.scss'
import 'assets/sass/utils/ai.scss'
import { QAComponent } from './components'
import { QuestionBtn } from 'components'


export default class AiChat extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = { input: '' };
	}

	onChange = (e) => {
		e.preventDefault();

		const { name, value } = e.target;

		this.setState({ [name]: value });
	}

	onQuestionSet = (q) => (e) => {
		const input = this.state.input;
		const prompt = q?.actual ?? input;
		const show = q?.show ?? input;

		if (!prompt && prompt.length === 0) { return; }

		this.props.onQuestionSet({ show: show, prompt: prompt });
	}

	onKeyUp = (e) => {
		if (e.which !== 13) { return; }

		this.onQuestionSet(null)(e);
	}

	onSubmit = () => {
		const { qa, ai_chat_loading, onQAAnswerGetRequest } = this.props;

		if (ai_chat_loading) { return; }

		const inputRequest = queryString.stringify(qa, { arrayFormat: 'bracket' });

		onQAAnswerGetRequest(inputRequest);

		this.setState({ input: '' });
	}

	onClear = (e) => {
		this.props.onClear();
	}

	componentDidUpdate(prevProps, prevState) {
		const { category, qas, qa, stock, onQADataLoadRequest, onReset } = this.props;

		if (!_isEqual(prevProps.qa, qa) && _size(qa) && !qa.text) { this.onSubmit(); }

		if ((!_isEqual(prevProps.qas, qas) && !_size(qas))
				|| prevProps.stock.stock_code !== stock.stock_code) {
			onReset();

			if (category === 'AnnualReport') { onQADataLoadRequest(); }
		}
	}

	componentDidMount() {
		const { category, onQADataLoadRequest } = this.props;

		if (category === 'AnnualReport') { onQADataLoadRequest(); }
	}

  render() {
    const { option, category, ai_chat_loading, qas, qa } = this.props;

		let notice = null;
		let btnTitle = 'paper-plane';
		let component = null;

		if (!option.questions) {
			notice = (
				<label className='margin-top-10'>
					警語：本套件功能為輔助蒐集資料。資料來源：法說會、公司管理階層、業內資訊、產業機構報告、優分析供應鏈調查、年報、財報， 不代表任何建議，也不保證資料正確性
				</label>
			);
		}

		const isThereQuestion = _size(qa) > 0;
		const btnDisabled = this.state.input.length === 0 || ai_chat_loading;
		const onClick = (q)=> _debounce(
			this.onQuestionSet(q),
			300,
			{ leading: true, trailing: false }
		);
		const onKeyUp = _debounce(
			this.onKeyUp,
			100,
			{ leading: true, trailing: false }
		);

		if (isThereQuestion) {
			component = (
				<QAComponent
					item={qa}
					loading={ai_chat_loading}
					onQuestionSet={onClick}
				/>
			);
		}

    return (
			<div className='ai-chat'>
				<form className='ua-form' name='ai-form'>
					<fieldset className='questions'>
						<section className='btn-container text-center'>
							{notice}

							{qas.map((d, idx) => {
								const { show } = d;

								return (show
									?
									<QuestionBtn
										key={idx}
										item={qa?.show}
										onClick={onClick(d)}
										currShow={show}
										btnText={show}
										disabled={ai_chat_loading}
									/>
									:
									null
								);
							})}

							{(isThereQuestion && !ai_chat_loading) && (
								<div className='questions-clearBtn' onClick={this.onClear}>
									清除回答
								</div>
							)}
						</section>
					</fieldset>

					{(category === 'AnnualReport') && (
						<fieldset className='question-inputs'>
							<section className='inputs display-flex'>
								<input
									type='text'
									name='input'
									className='question-input ua-input'
									value={this.state.input}
									placeholder='請直接提問'
									onChange={this.onChange}
									onKeyUp={onKeyUp}
								/>

								<button
									type='submit'
									className={`question-submit submit ua-btn default
											${btnDisabled ? 'disabled' : ''}`}
									disabled={btnDisabled}
									onClick={onClick}
								>
									<i className={`fa fa-${btnTitle}`}/>
								</button>
							</section>
						</fieldset>
					)}

					{isThereQuestion && (
						<fieldset className='answers'>
							{component}

							{ai_chat_loading && (
								<div>
									回答中...

									<div className='content-loader'/>
								</div>
							)}
						</fieldset>
					)}
				</form>
			</div>
    );
  }
}
