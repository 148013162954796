import React from 'react'
import Autosuggest from 'react-autosuggest'
import ReactPixel from 'react-facebook-pixel'
import { debounce as _debounce, isEqual as _isEqual } from 'lodash'

import { Message } from 'components'
import {
  StockSuggestion,
  AccountSuggestion,
  SearchSuggestion,
  SuggestionList,
} from './components'

import { StringHandler } from 'utils'
import {
	getSuggestions,
  getSuggestionValue,
  getSuggestionStock,
  getSuggestionAccount,
} from './func'

import 'assets/sass/utils/search.scss'


// user input to search a stock or data
export class SearchInput extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			search_input_value: props.defaultValue ?? '',
			search_input_error: false,

      suggestions: [],
      highlightedSuggestion: null,

      // focused              : {},

      // // search record
      // stock_record_update  : false,

      reset_input: props.reset ?? false,
		};
    this.ref = React.createRef();
    // this.inputRef = React.createRef();
	}

  // // search record
  // onFocus = () => {
  //   const searchType = this.props.searchType;
	//
  //   this.setState({ focused: { [searchType]: true } });
  // }

	// onBlur = (e) => {
	// 	// e.preventDefault();
	//
  //   const searchType = this.props.searchType;
	//
  //   this.setState({ focused: { [searchType]: false } });
	//
	// 	// this.handleSubmit();
	// }

  // suggestions
  getSuggestions = () => {
    const searchInputValue = this.state.search_input_value;
    const {
			// lab,
			searchType,
			searchPool,

			stock_pool,
      stock_others_pool,
      stock_etf_pool,

			// stock_search_record,

      isWorldwide,
      withoutETF,

			onStockListLoad,
      onOthersStockListLoad,
      onETFStockListLoad,
			// onStockRecordLoadRequest,
			// onAllSinglesLoadRequest,
			// onSingleRecordLoadRequest,
		} = this.props;

    let suggestions = [];

    if (searchPool) {
      suggestions = getSuggestions(
        searchInputValue,
        searchType,
        // focused[searchType],
        searchPool,
        // recordPool
      );
    } else {
      switch (searchType) {
        case 'stock':
        case 'stock-list':
          if (!stock_pool || stock_pool.length === 0) {
            onStockListLoad();
          } else {
            if (isWorldwide && stock_others_pool.length === 0) {
              onOthersStockListLoad();
            }

            if (withoutETF && stock_etf_pool.length === 0) {
              onETFStockListLoad();
            }

            suggestions = getSuggestions(
              searchInputValue,
              searchType,
              // focused[searchType],
              stock_pool,
              // recordPool
            );
          }

          // if (!stock_search_record) {
          // 	onStockRecordLoadRequest();
          // }

          break;

        default: // keyword

      }
    }

    this.setState({
      suggestions: suggestions,
      highlightedSuggestion: suggestions[0],
    });
  }

  getSectionSuggestions = (section) => (section.suggestions)
  onSuggestionsFetchRequested = ({ value }) => {}
  onSuggestionsClearRequested = () => {};
  renderSuggestionsContainer = (renderSuggestion) => ({ containerProps, children, query }) => {
    const { suggestions, highlightedSuggestion } = this.state;
    const { searchType } = this.props;
    return (children
      ?
      <div {...containerProps}>
        <SuggestionList
          suggestions={suggestions}
          getItemProps={children.props.itemProps}
          renderSuggestion={renderSuggestion}
          query={query}
          highlightedSuggestion={highlightedSuggestion}
          searchType={searchType}
        />
      </div>
      :
      null
    );
  }

  onChange = (e, selectedProps) => {
		const { name, value } = e.target;

    if (selectedProps?.method==='click' && !value) { return ; }

		const {
      searchType,
      searchPool,
      showAutosuggest,
      callback,
      onChange,
    } = this.props;

    if (!name || name === 'search') { // search
  		this.setState({ search_input_value: value });

      if (onChange) { onChange(value); }

  		if (showAutosuggest !== false) {
  			this.setState({ search_input_error: false });
  		} else {
  			callback(value);
  		}

  		if (searchPool && searchType !== 'account' && (!value
          || value.length === 0)) { callback(''); }
    }

    this.setState({ reset_input: false });
	}

	onKeyUp = (e) => {
		if (e.which === 13 && !e.nativeEvent.isComposing) { this.handleSubmit(); }
	}
  
	onSuggestionSelected = (e, { suggestion }) => {
		e.preventDefault();

		const searchType = this.props.searchType;

		let inputUpdated = suggestion;

		switch (searchType) {
			case 'stock':
      case 'stock-list':
				inputUpdated = suggestion;

				break;

			case 'account':
				inputUpdated = suggestion.ChineseAccount;

				break;

			default: // keyword

		}

		this.setState(
			{ search_input_value: inputUpdated, highlightedSuggestion: suggestion },
			this.handleSubmit
		);


    if (this.ref.current && this.ref.current.input) { this.ref.current.input.blur(); }
	}

  onSuggestionHighlighted = ({ suggestion }) => {
    const { suggestions } = this.state;

    if (!suggestion && suggestions.length > 0) {
      this.setState({ highlightedSuggestion: suggestions[0] });
    } else {
      this.setState({ highlightedSuggestion: suggestion });
    }
  };


	onSubmit = (e) => {
		e.preventDefault();

    if (!this.state.answering) { this.handleSubmit(); }
	}

	handleSubmit = () => {
    const { highlightedSuggestion } = this.state;
    const { inputEmptyToFalse=true } = this.props;
		const searchInputValue = highlightedSuggestion ?? this.state.search_input_value;

    if (inputEmptyToFalse) { if (!searchInputValue) { return false; } }

		const suggestions = this.state.suggestions;
    const {
      type,
      searchType,
      searchPool,
      existList,
      isIncludes,
      showAutosuggest,
      callback,
      stock,

      // // search record
      // onStockRecordUpdateRequest,
    } = this.props;

		if (showAutosuggest === false) {
      callback(searchInputValue);

			return;
		}

		let searchInputError = false;
		let selectedStockCode = null;
		let inputUpdated = '';
    
		if (searchPool) {
      let result = '';

      if (searchType === 'account') {
        result = getSuggestionAccount(searchInputValue, suggestions);
        searchInputError = result === false;

        if (existList) {
          searchInputError = existList.filter((item) => (
            item?.ChineseAccount === result?.ChineseAccount)
          ).length > 0;

          if (searchInputError) {
            searchInputError = existList.filter((item) => (
              item.stock_code === result.stock_code
            )).length > 0;
          }

          if (searchInputError) {
            searchInputError = '輸入科目已在比較列表中';
            result = false;
          }
        }
      } else {
        const arrFiltered = searchPool.filter((item) => {
  				const str = StringHandler.getString(item);
  				const strInput = StringHandler.getString(searchInputValue);

  				return (isIncludes && item) ? str.includes(strInput) : str === strInput;
  			});

        searchInputError = arrFiltered.length === 0;
        inputUpdated = searchInputValue;

        if (!searchInputError) {
          result = isIncludes ? arrFiltered : arrFiltered[0];
        }
      }

			callback(result);
		} else {
			switch (searchType) {
				case 'stock':
        case 'stock-list':
          const selectedStock = getSuggestionStock(searchInputValue, suggestions);

					if (selectedStock) {
            const { stock_code, stock_name } = selectedStock;

            selectedStockCode = selectedStock.stock_code;

						if (existList) {
              const existed = existList.find((item) => (
                item.stock_code === stock_code && item.stock_name === stock_name
              ));
              // existList.includes(selectedStockCode)

              if (existed) {
                searchInputError = '輸入股票已在比較列表中';
              }

              let num = null;

              if (type.includes('stock-compare') || type === 'template') {
                const arr = type.split('-');

                num = (type === 'template') ? 20 : Number(arr[arr.length - 1]);

  							if (existList.length === num) { searchInputError = `一次最多比較 ${num} 支股票`; }
  						}
						}

						if (!searchInputError && (searchType === 'stock-list'
                || type === 'chart-stock-compare-10'
                || selectedStockCode !== stock.stock_code)) {
							const check = (type === 'portfolio') ? selectedStock === false : null;

              // onStockRecordUpdateRequest(selectedStockCode);
							callback(selectedStock, check);

              // this.setState({ stock_record_update: true });
						}
					} else {
						searchInputError = '輸入的名稱或代號不存在';
					}

					break;

				default: // keyword
          callback(searchInputValue);

          inputUpdated = searchInputValue;
			}
		}

		if (type === 'portfolio' && selectedStockCode) { inputUpdated = selectedStockCode; }

		if (inputUpdated !== searchInputValue) { this.setState({ search_input_value: inputUpdated }); }

    if (searchInputError && typeof searchInputError !== 'boolean') {
      this.setState({ search_input_error: searchInputError });
    }

		ReactPixel.track(
      'Search',
      { content_category: type, search_string: searchInputValue }
    );

    this.setState({ suggestions: [], highlightedSuggestion: null });
	}

	componentDidUpdate(prevProps, prevState) {
		const {
      reset_input,
      search_input_value,
      search_input_error,
      /*stock_record_update*/
    } = this.state;
    const {
      reset,
      searchPool,
      stock_pool,
      // stock_record_updated,
      // onStockRecordLoadRequest,
    } = this.props;

    if (prevState.reset_input !== reset_input && reset_input && this.ref.current) {
      this.setState({ search_input_value: '' });
      this.ref.current.value = '';
    }

    if (prevState.search_input_value !== search_input_value
        || (!_isEqual(prevProps.stock_pool, stock_pool)
        && stock_pool && stock_pool.length > 0)) { this.getSuggestions(); }

    if (prevProps.reset !== reset) { this.setState({ reset_input: true }); }

    if(!_isEqual(prevProps.searchPool, searchPool)
    && searchPool && searchPool.length > 0){
      this.handleSubmit();
    }

		if (prevState.search_input_error !== search_input_error && search_input_error) {
			Message.SmallBox({
				title   : `<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> ${search_input_error}`,
				content : '',
				timeout : 2000
			});

      this.setState({ search_input_error: false });
		}

    // // search record
    // if (prevState.stock_record_update !== stock_record_update && stock_record_update) {
    //   onStockRecordLoadRequest();
		//
    //   this.setState({ stock_record_update: false });
    // }

    // if (!_isEqual(prevProps.stock, stock) && this.ref.current
    //     && this.ref.current.input) {
    //   this.ref.current.input.blur();
    // }
	}

  componentDidMount() {
    if (this.props.searchType?.includes('stock')) { this.getSuggestions(); }
  }

	render() {
		const {
      suggestions,
      search_input_value,
      answering,
    } = this.state;
		const {
      customClassName,
      type,
      searchType,
      placeholder,
      model,

      showAutosuggest,
    } = this.props;
    let inputUpdated = search_input_value;
    let placeholderUpdated = placeholder;
		let renderSuggestion = null;
    let searchComponent = null;
    let btnTitle = 'search';
    let listComponent = null;

    if (typeof inputUpdated === 'object') {
      inputUpdated = inputUpdated.stock_code;
    }

    switch (searchType) {
      case 'stock':
      case 'stock-list':
        placeholderUpdated = placeholderUpdated || '股票代碼或名稱';
        renderSuggestion = StockSuggestion;

        break;

      case 'account':
        placeholderUpdated = placeholderUpdated || '請輸入關鍵字';
        renderSuggestion = AccountSuggestion;

        break;

      default: // w/ searchPool
        renderSuggestion = SearchSuggestion;
    }

    const onSubmit = _debounce(
      this.onSubmit,
      300,
      { leading: true, trailing: false }
    );
    const onKeyUp = _debounce(
      this.onKeyUp,
      100,
      { leading: true, trailing: false }
    );
    const btn = (
      <button
        className={`bg-${(type === 'portfolio')
          ? 'orange' : 'transparent'}`}
        disabled={searchType === 'single' && answering}
        onClick={onSubmit}
      >
        <i className={`fa fa-${btnTitle}`}/>
      </button>
    );

    if (showAutosuggest === false) {
      searchComponent = (
        <div className='react-autosuggest__container'>
          <input
            type='text'
            name='search'
            className='react-autosuggest__input'
            value={inputUpdated ?? ''}
            placeholder={placeholderUpdated}
            onChange={this.onChange}
            onKeyUp={onKeyUp}
          />
        </div>
      );
    } else {
      const inputProps = {
        placeholder : placeholderUpdated,
        value				: inputUpdated,
        onChange    : this.onChange,
        onKeyUp     : this.onKeyUp,
      };

      searchComponent = (
        <Autosuggest
          ref={this.ref}
          id={model ?? type}
          inputProps={inputProps}
          suggestions={suggestions}

          // alwaysRenderSuggestions={true}

          focusInputOnSuggestionClick={false}

          multiSection={false}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={
            this.renderSuggestionsContainer(renderSuggestion)
          }
          /*
           implement it to teach Autosuggest what should be the input value
           when suggestion is clicked
           */
          getSuggestionValue={getSuggestionValue}
          getSectionSuggestions={this.getSectionSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionHighlighted={this.onSuggestionHighlighted}
        />
      );
    }

    const searchInputValue = this.state.search_input_value;

		return (
			<div
        className={`search-input-box ${searchType || ''} ${customClassName || ''}`}
      >

				<div className={`search-input${searchInputValue ? ` enabled-input` : ''}
            ${(type === 'data-table') ? 'display-inline-block' : ''}`}>
          {searchComponent}

          {btn}
				</div>

        {listComponent}

			</div>
		);
	}
}
