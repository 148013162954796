const resize = () => {
	var el = document,
		event = document.createEvent('HTMLEvents');

	event.initEvent('resize', true, false);
	el.dispatchEvent(event);
}


export default { resize };
