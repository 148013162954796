export const types = {
    SET : 'ListLab/SET',
    REQUEST : 'ListLab/REQUEST',
    SUCCESS : 'ListLab/SUCCESS',
    ERROR : 'ListLab/ERROR',
    RESET : 'ListLab/RESET',
    LOADDATAREQUEST : 'ListLab/LOADDATAREQUEST'
  }
  
  const initialState = {
    data:[],
  }
  
  export default function reducer(state = initialState, action = {}) {
  
    switch (action.type) {
      case types.SET:
        return { ...state, };
      case types.REQUEST:
        return { ...state, };
      case types.ERROR:
          return { ...state, };
      case types.SUCCESS:
        return { ...state, data: [...state.data, { ...action.data }] };
      case types.RESET:
        return { ...initialState };
      default:
        return state;
    }
  }
  
  export const actions = {
    getRequest: function (labID, data) {
      return { type: types.REQUEST, labID: labID, data: data };
    },
    getSuccess: function(data) {
      return { type: types.SUCCESS, data: data };
    },
    getError: function(err) {
      return { type: types.ERROR, err: err };
    },
    reset: function() {
      return { type: types.RESET };
    },
  }