import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SingleAiChatAPIs } from 'api'
import { types, actions } from '../modules/SingleAiChat'


const { DataLoadAPI, AnswerGetAPI } = SingleAiChatAPIs;

function* watchAiChatDataLoadRequest() {
  yield takeLatest(types.DATA_LOAD_REQUEST, loadAiChatDataFlow);
}

function* loadAiChatDataFlow(action) {
  const { id, category, subcategory, data } = action;

  try {
    const response = yield call(DataLoadAPI, category, subcategory, data);
    const responseData = response.data;

    if (responseData.status === 'OK') {
      yield put(actions.loadDataSuccess(
        id,
        responseData.data,
        category,
        subcategory,
        data
      ));
    } else {
      console.log(
        'loadAiChatDataFlow response error: ',
        responseData.error ?? 'ERROE'
      );

      yield put(actions.loadDataError(id, category, subcategory));
    }
  } catch (err) {
    console.log('loadAiChatDataFlow catch error: ', err);

    yield put(actions.loadDataError(id, category, subcategory));
  }
}

function* watchAiChatAnswerGetRequest() {
  yield takeEvery(types.ANSWER_GET_REQUEST, getAiChatAnswerFlow);
}

function* getAiChatAnswerFlow(action) {
  const { id, category, data, subcategory } = action;

  try {
    const response = yield call(AnswerGetAPI, data, subcategory);
    const responseData = response.data;

    if (responseData.status === 'OK') {
      yield put(actions.getAnswerSuccess(id, responseData.data, category, subcategory));
    } else {
      console.log('getAiChatAnswer response error: ', responseData.error);

      yield put(actions.getAnswerError(id, category, subcategory));
    }
  } catch (err) {
    console.log('getAiChatAnswer catch error: ', err);

    yield put(actions.getAnswerError(id, category, subcategory));
  }
}


export default [ watchAiChatDataLoadRequest(), watchAiChatAnswerGetRequest() ]
