import React from 'react'
import { isEqual as _isEqual } from 'lodash'

import {
  TabItem,
  TabContainer,
  SortableTabContainer,
  SortableTabItem
} from './components'

import { CSSHandler } from 'utils'


export class TabToggler extends React.PureComponent {
	constructor(props) {
		super(props);

		const activeTab = props.active_tab;

		this.state = { active_tab: (activeTab !== undefined) ? activeTab : 0 };
	}

	onTabChange = (e) => {
		e.stopPropagation();

		const idx = parseInt(e.currentTarget.dataset.idx);

		this.props.onTabChange(idx);
		this.setState({ active_tab: idx });
	}

	componentDidUpdate(prevProps, prevState) {
		const { type, active_tab } = this.props;

		if (!_isEqual(prevProps.active_tab, active_tab)) {
      this.setState({ active_tab: (active_tab !== undefined) ? active_tab : 0 });

      CSSHandler.setTabCSS(type);
			// this.onTabCSSSet();
		}
	}

	componentDidMount() {
		CSSHandler.setTabCSS(this.props.type);
	}

	render() {
		const { labels, horizontal, onAdd, onSort } = this.props;
		// add
		const onTabAdd = (e) => {
      if (onAdd) {
        onAdd(e);

        this.setState({ active_tab: labels.length });
      }
    };

		return (
			<div id='tab-container' className='tab-container'>

				{onSort
					?
					<SortableTabContainer
						helperClass='dragging-helper dragging-helper-tab'
						axis='x'
						lockAxis='x'
						distance={2}
            lockToContainerEdges={true}
            lockOffset={10}

						customClassName={`ua-${horizontal ? 'horizontal' : 'vertical'}-tab
                ${onAdd ? 'with-add' : ''}`}

						onSortEnd={onSort}
					>
						{labels.map((item, idx) => (
					    <SortableTabItem
					      index={idx}
					      item={item}
					      state={this.state}
					      props={this.props}
								onTabChange={this.onTabChange}
					      key={idx}
					    />
					  ))}
					</SortableTabContainer>
					:
          <TabContainer
            customClassName={`ua-${horizontal ? 'horizontal' : 'vertical'}-tab
                ${onAdd ? 'with-add' : ''}`}
          >
            {labels.map((item, idx) => (
              <TabItem
                item={item}
                state={this.state}
                props={this.props}
                onTabChange={this.onTabChange}
                key={idx}
              />
            ))}
          </TabContainer>}

				{onAdd && (
					<div className='add-item-btn ua-tab-item'>
						<span
							className='ua-tab-title'
							data-idx={labels.length}
							onClick={onTabAdd}
						>
							<i className='fas fa-plus'/>
						</span>
					</div>
				)}

			</div>
		);
	}
}
