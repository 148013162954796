import axios from 'axios'
import config from 'config'


const ContactSubmitAPI = (data) => axios.post(
  `${config.api.APP_API_DOMAIN}/messages`,
  data
)


export default { ContactSubmitAPI }
