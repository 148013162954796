
import { SessionStorage } from 'utils'


export const types = {
    FILTER_GET: 'draggable/FILTER_GET',
    FILTER_SET: 'draggable/FILTER_SET',
    Y_AXIS_GET: 'draggable/Y_AXIS_GET',
    Y_AXIS_SET: 'draggable/Y_AXIS_SET',
}

const initialState = { 
    filtered: SessionStorage.get(`FilterDraggable_filtered`) ?? {},
    yAxis: SessionStorage.get(`Draggable_yAxis`) ?? {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.FILTER_SET:
            const newFiltered = { ...state.filtered, [action.data.key]: action.data.value };
            
            SessionStorage.set(`FilterDraggable_filtered`, newFiltered);

            return { ...state, filtered: newFiltered };

        case types.Y_AXIS_SET:
            const newYAxis = { ...state.yAxis, [action.data.key]: action.data.value };
            
            SessionStorage.set(`Draggable_yAxis`, newYAxis);
            
            return { ...state, yAxis: newYAxis };
              
        default: return state
    }
}

export const actions = {
    setFilterd: function(key, value) {
        return { type: types.FILTER_SET, data:{ key, value }};
    },
    setYAxis: function(key, value) {
        return { type: types.Y_AXIS_SET, data:{ key, value }};
    },
}
