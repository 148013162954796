import moment from 'moment'
import config from 'config'
import { Cookies } from 'utils'


export const types = {
  LIST_LOAD_REQUEST: 'stock/LIST_LOAD_REQUEST',
  LIST_LOAD_COMPLETED: 'stock/LIST_LOAD_COMPLETED',
  LIST_LOAD_FAILED: 'stock/LIST_LOAD_FAILED',

  OTHERS_LIST_LOAD_REQUEST: 'stock/OTHERS_LIST_LOAD_REQUEST',
  OTHERS_LIST_LOAD_COMPLETED: 'stock/OTHERS_LIST_LOAD_COMPLETED',
  OTHERS_LIST_LOAD_FAILED: 'stock/OTHERS_LIST_LOAD_FAILED',

  ETF_LIST_LOAD_REQUEST: 'stock/ETF_LIST_LOAD_REQUEST',
  ETF_LIST_LOAD_COMPLETED: 'stock/ETF_LIST_LOAD_COMPLETED',
  ETF_LIST_LOAD_FAILED: 'stock/ETF_LIST_LOAD_FAILED',

  SET: 'stock/SET',

  INFO_LOAD_REQUEST: 'stock/INFO_LOAD_REQUEST',
  INFO_LOAD_COMPLETED: 'stock/INFO_LOAD_COMPLETED',
  INFO_LOAD_FAILED: 'stock/INFO_LOAD_FAILED',

  DATA_FETCH_REQUEST: 'stock/DATA_FETCH_REQUEST',
  DATA_FETCH_COMPLETED: 'stock/DATA_FETCH_COMPLETED',
  DATA_FETCH_FAILED: 'stock/DATA_FETCH_FAILED',

  RECORD_REQUEST: 'stock/RECORD_REQUEST',
  RECORD_COMPLETED: 'stock/RECORD_COMPLETED',
  RECORD_FAILED: 'stock/RECORD_FAILED',

  // DATA_CLEAR: 'stock/DATA_CLEAR'
}

const getStockCookie = () => ({
  stock_name : (typeof Cookies.get('stock_name') !== 'undefined')
      ? Cookies.get('stock_name') : '台泥',
  stock_code : (typeof Cookies.get('stock_code') !== 'undefined')
      ? Cookies.get('stock_code') : '1101'
});
const setStockCookie = (stockData) => {
  const setParams = {
    path: '/',
    maxAge: 60 * 60 * 24 * 1,
    sameSite: 'Lax',
    secure: true,
  };

  Cookies.set('stock_code', stockData.stock_code, setParams);
  Cookies.set('stock_name', stockData.stock_name, setParams);
};
const cacheExpired = config.stock.cache_expired;
const initialState = {
  stock_pool: [],
  stock_others_pool: [],
  stock_tw_pool: [],
  stock_etf_pool: [],
  select_stock: getStockCookie(),
  stock_info: {},
  fetch_data: {},
  cache: {},
  loading: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LIST_LOAD_REQUEST:
    case types.LIST_LOAD_FAILED:
    case types.OTHERS_LIST_LOAD_REQUEST:
    case types.ETF_LIST_LOAD_REQUEST:
    case types.OTHERS_LIST_LOAD_FAILED:
    case types.ETF_LIST_LOAD_FAILED:
    case types.INFO_LOAD_REQUEST:
    case types.INFO_LOAD_FAILED:
    case types.RECORD_REQUEST:
    case types.RECORD_COMPLETED:
    case types.RECORD_FAILED:
      return { ...state };

    case types.LIST_LOAD_COMPLETED:
      const list = [ ...action.data ];
      const listTW = list.filter((stock) => (stock.country === 'TW'));

      return { ...state, stock_pool: list, stock_tw_pool: listTW };

    case types.OTHERS_LIST_LOAD_COMPLETED:
      return { ...state, stock_others_pool: action.data };

    case types.ETF_LIST_LOAD_COMPLETED:
      return { ...state, stock_etf_pool: action.data.AllStocks.ETFStocks };

    case types.INFO_LOAD_COMPLETED:
      const info = {
        data: action.data.data,
        time: moment().add(cacheExpired, 'minutes').format(),
      };
      const stock = { ...state.select_stock, info: info };
      const stockInfo = {
        ...state.stock_info,
        [state.select_stock.stock_code]: info,
      };

      return { ...state, select_stock: stock, stock_info: stockInfo };

    case types.SET:
      const code = action.stock.stock_code;
      const infoState = state.stock_info[code] ? state.stock_info[code] : null;
      const infoUpdated = infoState
        ?
        /*moment().isAfter(moment(infoState.time)) ? {} :*/ infoState
        :
        null;

        setStockCookie(action.stock);

        return {
          ...state,
          select_stock: { ...action.stock, info: infoUpdated },
        };

      case types.DATA_FETCH_REQUEST:
        return {
          ...state,
          loading: {
            ...state.loading,
            [action.params ? action.params.model : action.model]: true,
          },
        };

      case types.DATA_FETCH_COMPLETED:
      case types.DATA_FETCH_FAILED:
        const { model, data_type, data } = action;
        const loadingData = { ...state.loading[model], [data_type]: false };
        const time = moment()
          .add(cacheExpired, 'minutes')
          .format();
        const cacheData = {
          ...state.cache[model],
          [data_type]: { data: data, time: time },
        };
        const cache = { ...state.cache };
        cache[model] = cacheData;

        const loading = { ...state.loading };
        loading[model] = loadingData;

        return { ...state, cache: cache, loading: loading };

      default:
        return state;
  }
}

export const actions = {
    loadStockListRequest: function () {
        return { type: types.LIST_LOAD_REQUEST };
    },
    loadStockListCompleted: function(data) {
        return { type: types.LIST_LOAD_COMPLETED, data: data };
    },
    loadStockListFailed: function(error) {
        return { type: types.LIST_LOAD_FAILED, error: error };
    },
    loadOthersStockListRequest: function () {
        return { type: types.OTHERS_LIST_LOAD_REQUEST };
    },
    loadOthersStockListCompleted: function(data) {
        return { type: types.OTHERS_LIST_LOAD_COMPLETED, data: data };
    },
    loadOthersStockListFailed: function(error) {
        return { type: types.OTHERS_LIST_LOAD_FAILED, error: error };
    },
    loadETFStockListRequest: function () {
        return { type: types.ETF_LIST_LOAD_REQUEST };
    },
    loadETFStockListCompleted: function(data) {
        return { type: types.ETF_LIST_LOAD_COMPLETED, data: data };
    },
    loadETFStockListFailed: function(error) {
        return { type: types.ETF_LIST_LOAD_FAILED, error: error };
    },
    loadStockInfoRequest: function(stockCode) {
        return { type: types.INFO_LOAD_REQUEST, stockCode: stockCode };
    },
    loadStockInfoCompleted: function(data) {
        return { type: types.INFO_LOAD_COMPLETED, data: data };
    },
    loadStockInfoFailed: function(error) {
        return { type: types.INFO_LOAD_FAILED, error: error };
    },
    loadStockDataRequest: function(
      model,
      stockCode,
      apiHost,
      crawlerApi,
      params
    ) {
        return {
          type      : types.DATA_FETCH_REQUEST,
          model     : model,
          stock_code : stockCode,
          api_host   : apiHost,
          crawler_api : crawlerApi,
          params    : params,
        };
    },
    fetchStockDataRequest: function(
      stockCode,
      apiHost,
      crawlerApi,
      params
    ) {
        return {
            type        : types.DATA_FETCH_REQUEST,
            stock_code  : stockCode,
            api_host    : apiHost,
            crawler_api : crawlerApi,
            params      : params,
        };
    },
    fetchStockDataCompleted: function(model, dataType, data) {
        return {
          type: types.DATA_FETCH_COMPLETED,
          model: model,
          data_type: dataType,
          data: data,
        };
    },
    fetchStockDataFailed: function(model, dataType) {
        return {
          type: types.DATA_FETCH_FAILED,
          model: model,
          data_type: dataType,
        };
    },
    setStock: function(stock) {
        return { type: types.SET, stock: stock };
    },
    recordStockRequest: function(stockCode) {
        return { type: types.RECORD_REQUEST, stockCode: stockCode };
    },
    recordStockCompleted: function(data) {
        return { type: types.RECORD_COMPLETED, data: data };
    },
    recordStockFailed: function(error) {
        return { type: types.RECORD_FAILED, error: error };
    },
    // clearStockData: function(model) {
    //   return { type: types.DATA_CLEAR, model: model };
    // }
}
