import { call, put, takeLatest } from 'redux-saga/effects'
import { AnnouncementAPIs } from 'api'
import { types, actions } from '../modules/Announcement'


function * watchAnnouncementLoadRequest() {
    yield takeLatest(types.LOAD_REQUEST, announcementFlow);
}

function * announcementFlow(action) {
    try {
        const response = yield call(AnnouncementAPIs.AnnouncementsLoadAPI),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.loadCompleted(responseData.data));
        } else {
            yield put(actions.loadFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadFailed(error));
    }
}


export default [ watchAnnouncementLoadRequest() ]
