export const types = {
    WINDOW_SIZE_SET: 'auth/WINDOW_SIZE_SET',
    MOBILE_SET: 'auth/MOBILE_SET'
}

const initialState = {
    is_mobile : false,
    width     : window.screen.width,
    height    : window.screen.height
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.WINDOW_SIZE_SET:
            return { ...state, width: action.width_size, height: action.height_size };

        case types.MOBILE_SET:
            return { ...state, is_mobile: action.isMobile };

        default:
            return state;
    }
}

export const actions = {
    setWindowSize: function(width_size, height_size) {
        return { type: types.WINDOW_SIZE_SET, width_size: width_size, height_size: height_size };
    },
    setMobile: function(isMobile) {
        return { type: types.MOBILE_SET, isMobile: isMobile };
    }
}
