import { call, put, takeLatest } from 'redux-saga/effects'
import { SingleChartDataLoadAPIs } from 'api'
import { types, actions } from '../modules/SingleChart'


function * watchChartDataLoadRequest() {
    yield takeLatest(types.DATA_REQUEST, chatFlow);
}

function * chatFlow(action) {
    try {
        const response = yield call(SingleChartDataLoadAPIs.SingleChartDataLoadAPI, action.model, action.stock_code),
            responseData = response.data;

        if (responseData.status === 'OK') {
            yield put(actions.dataCompleted(responseData.data));
        } else {
            yield put(actions.dataFailed(responseData.error));
        }
    } catch (err) {
        yield put(actions.dataFailed(err));
    }
}


export default [ watchChartDataLoadRequest() ]
