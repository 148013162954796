export const types = {
  SET: 'singleWeightedGrowthRate/SET',
  RESET: 'singleWeightedGrowthRate/RESET'
}

export const initialSettings = {
  weighted: {
    past: {
      name: 'past',
      title: '情境一',
      columns: [
        { name: 'estimate', value: [], unit: '%' },
        { name: 'probability', value: [], unit: '%' },
      ],
    },
    external: {
      name: 'external',
      title: '情境二',
      columns: [
        { name: 'estimate', value: [], unit: '%' },
        { name: 'probability', value: [], unit: '%' },
      ],
    },
    internal   : {
      name: 'internal',
      title: '情境三',
      columns: [
        { name: 'estimate', value: [], unit: '%' },
        { name: 'probability', value: [], unit: '%' },
      ],
    },
  },
  price: {
    first: {
      name: 'first',
      title: '情境一',
      columns: [
        { name: 'price', value: [], unit: '元' },
        { name: 'probability', value: [], unit: '%' },
      ],
    },
    second: {
      name: 'second',
      title: '情境二',
      columns: [
        { name: 'price', value: [], unit: '元' },
        { name: 'probability', value: [], unit: '%' },
      ],
    },
    third: {
      name: 'third',
      title: '情境三',
      columns: [
        { name: 'price', value: [], unit: '元' },
        { name: 'probability', value: [], unit: '%' },
      ],
    },
  },
};

const initialState = {
  inputs: { weighted: {}, price: {} },
  is_initial : { weighted: {}, price: {} },
};

export default function reducer(state = initialState, action = {}) {
  const inputs = { ...state.inputs };
  const isInitial = { ...state.is_initial };
  const { stock_code, data, model } = action;

  switch (action.type) {
    case types.SET:
      isInitial[model][stock_code] = inputs[model][stock_code] === undefined;
      inputs[model][stock_code] = isInitial[model][stock_code]
          ? initialSettings[model] : inputs[model][stock_code];

      if (data) { inputs[model][stock_code] = data; }

      return { ...state, inputs: inputs, is_initial: isInitial };

    case types.RESET:
      inputs[model][stock_code] = initialSettings[model];
      isInitial[model][stock_code] = true;

      return { ...state, inputs: inputs, is_initial: isInitial };

    default:
      return state;
  }
}


export const actions = {
  set: function (stockCode, data, model) {
    return { type: types.SET, stock_code: stockCode, data: data, model: model };
  },
  reset: function (stockCode, model) {
    return { type: types.RESET, stock_code: stockCode, model: model };
  }
}
