import React from 'react';
import { judgmentMonth } from 'components/Renew/func';


export default class Case extends React.Component {
    render() {
        const { renewProduct, selected, is_mobile } = this.props,
            noticeSplit = renewProduct.notice.split('\n'),
            deadline = renewProduct.renew_deadline.split(' ')[0].replaceAll('-', '/');

        return (
            <section className="case-list">
                <div className={`case-item margin-10 margin-horizontal-0 ${selected ? 'inactive' : ''}`}>
                    <h3 className="case-title padding-10 txt-white" style={{ backgroundColor: renewProduct.ad_bg }}>
                        {deadline} 前{renewProduct.ad}
                    </h3>

                    <div className="case-content padding-10">
                        <div className="case-content-discount">
                            <span className="discount-text margin-bottom-5">優惠價：</span>

                            <span className="margin-right-5 txt-orange txt-bold">
                                ${renewProduct.price} / {judgmentMonth(renewProduct.quantity)}
                            </span>

                            <small className="txt-grey">(原價 ${renewProduct.origin_price})</small>
                        </div>

                        <div className="case-content-bonus">
                            {(renewProduct.giveaway !== null || renewProduct.given_product.length !== 0) && <span className="bonus-text">加贈：</span>}

                            <div className="bonus-list margin-bottom-5">
                                {(renewProduct.giveaway !== null) && (<span>{renewProduct.giveaway}</span>)}

                                {(renewProduct.given_product.length > 0) && (
                                  renewProduct.given_product.map((item, idx) => (
                                      <p className="margin-bottom-0" key={idx}>
                                          <span className="margin-right-5 txt-orange">{item.product_name} / {judgmentMonth(item.expiration)}</span>

                                          <small className="txt-grey">(原價 ${item.price})</small>
                                      </p>
                                  ))
                                )}
                            </div>
                        </div>

                        <div className="case-content-pic margin-top-5">
                            {<img src={renewProduct[`${is_mobile ? 'mobile' : 'web'}_image`]} alt="case-pic"/>}
                        </div>
                    </div>
                </div>

                <div className="precautions txt-grey">注意事項：</div>

                <ul className="note-list padding-0 txt-grey list-unstyled">{noticeSplit.map((item, idx) => <li key={idx}>{item}</li>)}</ul>
            </section>
        );
    }
}
