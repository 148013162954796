import React from 'react'
import { Link } from 'react-router-dom'
import { MenuToggler } from './containers'
import 'assets/sass/Layout/header.scss'


export class Header extends React.PureComponent {
	render() {
		const { isMobile, isLoginPage, sideMenuHidden } = this.props;

		return(
			<header
				id='ua-header'
				className={`page-header diplay-block
						${isLoginPage ? 'white-btn' : ''}
						${!isLoginPage && !isMobile  ? 'logged-in-header' : ''}`}
			>
				{(isMobile && sideMenuHidden && !isLoginPage)
					?
					<MenuToggler />
					:
					<div className='logo-container pull-left display-inline-block'>
						{!isLoginPage && <MenuToggler />}

						<Link to='/'>
							<div id='logo' className='logo display-inline-block margin-left-0'/>
						</Link>
					</div>}

				{isLoginPage && (
					<div className='top-bar'>
						<Link
							className='header-btn padding-right-15 bg-transparent cursor-pointer'
							to='/e-com/product-view/collection'
						>
							商城
						</Link>

						<a
							className='header-btn padding-right-15 bg-transparent cursor-pointer'
							href='https://forum.uanalyze.com.tw/forum'
						>
							討論區
						</a>
					</div>
				)}
			</header>
		);
	}
}
