import { connect } from 'react-redux'
import { actions as actionsAvatar } from 'appRedux/modules/Avatar'
import { actions as actionsLaboratory } from 'appRedux/modules/Laboratory'
import { actions as actionsProfile } from 'appRedux/modules/Profile'
import { actions as actionsNotice } from 'appRedux/modules/UserNotifications'
import { SideMenu as component } from '../components'


const mapStateToProps = (state) => ({
	authenticated: state.Auth.authenticated,
	isUserAvatarLoaded: state.Avatar.loaded,
	isUserInfoLoaded: state.Profile.loaded,
	user_info: state.Profile.user_info,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onAvatarGetRequest: () => dispatch(actionsAvatar.loadRequest()),
	onLabListLoadRequest: () => dispatch(actionsLaboratory.loadLabListRequest()),
	onUserLoad: () => dispatch(actionsProfile.loadUserRequest()),
	onUserNotificationsRequest : () =>
			dispatch(actionsNotice.loadUserNotificationsRequest()),
})
const SideMenu = connect(mapStateToProps, mapDispatchToProps)(component);


export { SideMenu }
