import React from 'react'
import 'assets/sass/utils/loading.scss'
import logoLightNoBorder from 'assets/img/logo/logo_light_no_border.svg'
import logoColourNoBorder from 'assets/img/logo/logo_colour_no_border.svg'


export class Loading extends React.PureComponent {
	render() {
		const { customWidth, customHeight, colour_theme } = this.props;
		const width = customWidth ?? '100';
		const height = customHeight ?? '100';
		const isDarkTheme = colour_theme === 'dark';

		return (
			<div className={`page-loading w-${width} h-${height}`}>

				<div className="circle-container w-100 h-100 margin-auto">
					<div
						className="logo-loading w-100 h-100 no-border"
						style={{ backgroundImage: `url(${isDarkTheme
							?
							logoLightNoBorder
							:
							logoColourNoBorder})`
						}}
					/>

					<div className="wrapper">
						<div className="half-clip right">
							<div
								className="clipped half-circle"
								style={{
									borderTopColor: isDarkTheme ? '#fff' : '#ffa500',
									borderLeftColor: isDarkTheme ? '#fff' : '#ffa500'
								}}
							/>
						</div>

						<div className="half-clip left">
							<div
								className="clipped half-circle"
								style={{
									borderTopColor: isDarkTheme ? '#fff' : '#393939',
									borderLeftColor: isDarkTheme ? '#fff' : '#393939',
								}}
							/>
						</div>
					</div>
				</div>

			</div>
		);
	}
}
