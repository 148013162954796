import { connect } from 'react-redux'
import Dropdown from './Dropdown'


const mapStateToProps = (state) => ({
	isSearching: state.AssistantData.is_loading,
});


export default connect(mapStateToProps)(Dropdown)
