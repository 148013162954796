import React from 'react'
import { Helmet } from 'react-helmet-async'


const HelmetComponent = ({
  title = '',
  description = '',
  img = null,
  href,
  robots = true,
}) => {
  const url = process.env.REACT_APP_DOMAIN;

  return (
    <Helmet prioritizeSeoTags>
        <title>{title}</title>

        <meta name="description" content={description}/>

        {!robots && (
          <meta
            name="robots"
            content="none, noimageindex, noarchive, nocache, nosnippet"
          />
        )}

        <meta property="og:title" content={title}/>

        <meta property="og:description" content={description}/>

        <meta
          property="og:image"
          content={img ? img : `${url}/assets/img/obimg.png`}
        />

        <meta property="og:url" content={`${url}${href}`}/>

        <link rel="canonical" href={href} />
    </Helmet>
  );
}


export { HelmetComponent }
