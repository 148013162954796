export const types = {
    SET: 'singleFunction/SET',
    RESET: 'singleFunction/RESET'
}

export const initialFuncChartState = {
    func_chart_type : 'per',
    range           : 5,

    price_key       : null,// ua70001_cp

    //left
    left_key        : null,
    lower_bound     : 0,
    upper_bound     : 0,

    // right
    right_key       : null,
    t               : null,
    t1              : null,
    t_year          : null,
    t1_year         : null
}

const initialState = { functions: {} }

export default function reducer(state = initialState, action = {}) {
    let functions = { ...state.functions };

    switch (action.type) {
        case types.SET :
            if (!(action.id in functions)) {
                functions[action.id] = { ...initialFuncChartState };
            }

            functions[action.id] = { ...functions[action.id], ...action.data };
            
            return { ...state, functions: { ...functions } };

         case types.RESET :
            if (action.id in functions) {
                functions[action.id] = { ...initialFuncChartState };
            }

            return { ...state, functions:{ ...functions } };

        default:
            return state;
    }
}

export const actions = {
    set: function(id, data) {
        return { type: types.SET, id: id, data: data };
    },
    reset: function(id) {
        return { type: types.RESET, id: id };
    }
}
