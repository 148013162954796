const getDate = (dateKey) => {
  const date = dateKey.toString();

  if (date.length > 6) {
    return date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2);
  } else if (date.length > 4 && date.length < 7) {
    return date.substr(0, 4) + '/' + date.substr(4, 2)
  }

  return date;
};
const getDateTime = (dateKey) => {
  let moQtr = dateKey.substr(4, 2),
    lastDate;

  if (dateKey.length > 6) {
    lastDate = new Date(
      dateKey.substr(0, 4),
      parseInt(moQtr, 10) - 1,
      dateKey.substr(6, 2)
    );
  } else if (dateKey.length > 4) {
    if (moQtr.includes('Q')) {
      switch (moQtr) {
        case 'Q1':
          moQtr = '01';

          break;

        case 'Q2':
          moQtr = '04';

          break;

        case 'Q3':
          moQtr = '07';

          break;

        case 'Q4':
          moQtr = '10';

          break;

        default:

      }
    }

    lastDate = new Date(dateKey.substr(0, 4), parseInt(moQtr, 10), 0);
  } else {
    lastDate = new Date(dateKey.substr(0, 4), 11, 31, 0);
  }

  return lastDate.getTime();
};
const getDateTime_5G = (dateKey) => {
  let moQtr = dateKey.substr(4, 2),
    lastDate;

  if (dateKey.length > 6) {
    lastDate = new Date(
      dateKey.substr(0, 4),
      parseInt(moQtr, 10) - 1,
      dateKey.substr(6, 2)
    );
  } else if (dateKey.length > 4) {
    lastDate = new Date(dateKey.substr(0, 4), parseInt(moQtr, 10) - 1);
  } else {
    lastDate = new Date(dateKey.substr(0, 4), 11, 31, 0);
  }

  return lastDate.getTime();
};
const getDayType = (days) => {
  if (days <= 1) { return 'day'; }

  if (days >= 4 && days <= 7) { return 'week'; }

  if (days >= 20 && days <= 30) { return 'month'; }
};
const getType = (date) => {
  if (date.length > 6) {
    return 'day';
  } else if (date.length > 4) {
    if (date.indexOf('Q') !== -1 || date.indexOf('q') !== -1
      || date.indexOf('S') !== -1 || date.indexOf('s') !== -1) {
      return 'quarter';
    }

    return 'month';
  }

  return 'year';
};
const getQuarter = (month) => (Math.ceil(parseInt(month, 10) / 3));

const microSToYMD = (ms) =>{
  const date = new Date(ms);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}
export default {
  getDate,
  getDateTime,
  getDateTime_5G,
  getDayType,
  getType,
  getQuarter,
  microSToYMD
}
