import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'
import queryString from 'query-string'


const PortfolioLoadAPI = (id) => axios.get(`${config.api.APP_API_DOMAIN}/user/portfolios?${queryString.stringify(id, { arrayFormat: 'bracket' })}`, {
  headers: {
    Accept        : 'application/json',
    Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
  }
});
const PortfolioCreateAPI = (new_data) => axios.post(`${config.api.APP_API_DOMAIN}/user/portfolios`, new_data, {
  headers: {
    Accept        : 'application/json',
    Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
  }
});
const PortfolioUpdateAPI = (labID, updated_data) => axios.put(`${config.api.APP_API_DOMAIN}/user/portfolios/${labID}`, updated_data, {
  headers: {
    Accept        : 'application/json',
    Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
  }
});
const PortfolioDeleteAPI = (id) => axios.delete(`${config.api.APP_API_DOMAIN}/user/portfolios/${id}`, {
  headers: {
    Accept        : 'application/json',
    Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
  }
});
const PortfolioStockAPI = (stocks) => axios.post(`${config.api.DATA_API_DOMAIN}/data_fetch/api/portfolio`, stocks, {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})


export default {
  PortfolioLoadAPI,
  PortfolioCreateAPI,
  PortfolioUpdateAPI,
  PortfolioDeleteAPI,
  PortfolioStockAPI,
}
