import { connect } from 'react-redux'
import { Header as component } from './Header'


const mapStateToProps = (state) => ({
  sideMenuHidden : state.SideMenu.side_menu_hidden,
})
const Header = connect(mapStateToProps)(component);

export { Header } 
