import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const ProductListLoadAPI = () => axios.get(`${config.api.APP_API_DOMAIN}/products`, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
    }
  }),
  ProductDetailLoadAPI = (id) => axios.get(`${config.api.APP_API_DOMAIN}/products/${id}`, {
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get('token_type')} ${Cookies.get('access_token')}`
    }
  })


export default { ProductListLoadAPI, ProductDetailLoadAPI }
