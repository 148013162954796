import React from 'react'
import 'assets/sass/utils/chatInput.scss'

export default class ChatInput extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
            debouncedValue: "",
        };

        this.timerRef = React.createRef();
        this.inputRef = React.createRef();
    }

    onInputChange = (e) => {
        const { value } = e.target;
        const { disableDebounce } =  this.props;
        if(!disableDebounce){
            this.setState({ debouncedValue: value });
        }else{
            this.setState({ searchValue: value });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        const { debouncedValue, searchValue } = this.state;
        const { onChange, disableDebounce } =  this.props;
        if (prevState.debouncedValue !== debouncedValue) {
            clearTimeout(this.timerRef.current);
            this.timerRef.current = setTimeout(() => {
                this.setState((state) => ({
                        ...state,
                        searchValue:debouncedValue
                    }));
            }, 100);
        }

        if(!disableDebounce){
            if (prevState.searchValue !== searchValue) {
                onChange(searchValue);
            }
        }

        if(this.props.location?.pathname !== prevProps.location?.pathname){
            if (this.inputRef.current) {
                this.inputRef.current.value = "";
            }
            this.setState({ searchValue: "", debouncedValue:"" });
        }
        if(this.props.actived !== prevProps.actived && this.props.actived === false){
            if (this.inputRef.current) {
                this.inputRef.current.value = "";
            }
            this.setState({ searchValue: "", debouncedValue:""  });
        }
    }

    componentWillUnmount = () => {
        if (this.inputRef.current) {
            this.inputRef.current.value = "";
        }
    }

    onSearch = (e) => {
        const {onSearch} = this.props;
        const target = this.inputRef.current
        if(onSearch && target){
            onSearch(target.value);
        }
    }

    render() {
        const { onSearch, disabled=false} = this.props;
        const { placeholder="你想找什麼？" } =  this.props;

        return (
            <div className="chat-input">
                <form className="chat-input-form ua-form" onSubmit={this.onSubmit}>
                    <fieldset>
                        <section className="inputs display-flex">
                            <input
                                type="text"
                                name="searchValue"
                                className="ua-input"
                                placeholder={placeholder}
                                onChange={this.onInputChange}
                                ref={this.inputRef}
                            />
                            {onSearch !== null && <button
                                type="button"
                                className="scores-settings ua-btn default"
                                onClick={this.onSearch}
                                disabled={disabled}
                            >
                                送出
                            </button>}
                        </section>
                    </fieldset>
                </form>
            </div>
        );
    }
}
