export const types = {
    PERIOD_SET : 'rangeSwitcher/PERIOD_SET',
    MODE_SET   : 'rangeSwitcher/MODE_SET',
    RANGE_SET  : 'rangeSwitcher/RANGE_SET'
}

export const initialRangeState = { start: false, end: false, };

const initialState = { period: {}, mode: {}, range: {}, };

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.PERIOD_SET :
            return { ...state, period: { ...state.period, [action.id]: action.period } };

        case types.MODE_SET :
            return { ...state, mode: { ...state.mode, [action.id]: action.mode } };

        case types.RANGE_SET :
            let range = { ...state.range };
            
            if (!(action.id in range)) {
                range = { ...range, [action.id]: initialRangeState };
            }

            return { ...state, range: { ...range, [action.id]: { ...range[action.id], [action.range_type]: action.value } } };

        default:
            return state;
    }
}

export const actions = {
    setPeriod: function(id, period) {
        return { type: types.PERIOD_SET, id: id, period: period };
    },
    setMode: function(id, mode) {
        return { type: types.MODE_SET, id: id, mode: mode };
    },
    setRange: function(id, type, value) {
        return { type: types.RANGE_SET, id: id, range_type: type, value: value };
    }
}
