export const types = {
    SET   : 'form/SET',
    RESET : 'form/RESET',
    CLEAR : 'form/CLEAR'
}

const initialState = { form_data: {} }

export default function reducer(state = {}, action = {}) {
    let formState = { ...state };

    switch (action.type) {
        case types.SET:
            if (!(action.form_id in formState)) {
                formState[action.form_id] = { ...initialState };
            }

            let form_data = { ...formState[action.form_id].form_data, [action.key]: action.value };
            formState[action.form_id].form_data = { ...form_data };

            return formState;

        case types.RESET:
            return {};

        case types.CLEAR:
            delete formState[action.form_id];

            return formState;

        default:
            return state;
    }
}

export const actions = {
    set: function(form_id, key, value) {
        return { type: types.SET, form_id: form_id, key: key, value: value };
    },
    clear: function(form_id) {
        return { type: types.CLEAR, form_id: form_id };
    },
    reset: function () {
        return { type: types.RESET };
    }
}
