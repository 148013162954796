import { Cookies } from 'utils'


export const types = {
    PRODUCT_SET        : 'payment/PRODUCT_SET',
    PLAN_SET           : 'payment/PLAN_SET',
    ERROR_SET          : 'payment/ERROR_SET',
    USE_POINT_SET      : 'payment/USE_POINT_SET',
    UPDATE_PROFILE_SET : 'payment/UPDATE_PROFILE_SET'
}

const initialState = {
    product           : null,
    plan_id           : null,
    error             : false,
    is_use_point      : false,
    is_update_profile : false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.PRODUCT_SET:
      const { product, plan_id } = action;
      const removeParams = { path: '/' };
      const setParams = {
        path     : '/',
        maxAge   : 60 * 60 * 24 * 1,
        sameSite : 'Lax',
        secure   : true,
      };

      Cookies.remove('product', removeParams);
      Cookies.set('product', product.id, setParams);
      Cookies.remove('plan_id', removeParams);
      Cookies.set('plan_id', plan_id, setParams);

      return {
        ...state,
        product           : { ...product },
        plan_id           : plan_id,
        error             : false,
        is_update_profile : false,
      };

    case types.PLAN_SET:
      return {
        ...state,
        plan_id           : action.plan_id,
        error             : false,
        is_update_profile : false,
      };

    case types.ERROR_SET:
      return { ...state, error: action.error };

    case types.USE_POINT_SET:
      return { ...state, is_use_point: action.isUsePiont };

    case types.UPDATE_PROFILE_SET:
      return { ...state, is_update_profile: action.is_update_profile };

    default: return state;
  }
}

export const actions = {
  setProduct: (product, id) => ({
    type    : types.PRODUCT_SET,
    product : product,
    plan_id : id,
  }),
  setPlan: (id) => ({ type: types.PLAN_SET, plan_id: id }),
  setError: (error) => ({ type: types.ERROR_SET, error: error }),
  setUsePoint: (isUsePiont) => ({
    type: types.USE_POINT_SET,
    isUsePiont: isUsePiont,
  }),
  setUpdateProfile: (is_update_profile) => ({
    type: types.UPDATE_PROFILE_SET,
    is_update_profile: is_update_profile,
  }),
}
