import { call, put, takeEvery } from 'redux-saga/effects'
import { types, actions } from '../modules/SingleStockComparison'
import { SingleStockComparisonAPIs } from 'api'


const  {
  BubbleDataLoadAPI,
  ReutersEstimateAPI,
  SingleStockComparisonLoadAPI,
} = SingleStockComparisonAPIs;

function* watchStockComparisonLoadRequest() {
  yield takeEvery(types.LOAD_REQUEST, stockComparisonFlow);
}

function* stockComparisonFlow(action) {
  try {
    const response = yield call(SingleStockComparisonLoadAPI, action.stock_code);
    const responseData = response.data;

    if (responseData.status === 'OK') {
      yield put(actions.loadStockComparisonCompleted(responseData.data,
          action.stock_code));
    } else {
      yield put(actions.loadStockComparisonFailed(responseData.error,
          action.stock_code));
    }
  } catch(error) {
    yield put(actions.loadStockComparisonFailed(error, action.stock_code));
  }
}

const fs = [
  'REVENUE',
  'EPS',
  'GM',
  'OPM',
].map((key) => {
  const foo = function* () {
    yield takeEvery(types[`LOAD_REUTERS_ESTIMATE_${key}_REQUEST`], reutersEstimateFlow);
  };

  return foo();
});

function* reutersEstimateFlow(action) {
  try {
    const response = yield call(ReutersEstimateAPI, action.api, action.stock_code);
    const responseData = response.data;

    if (responseData.status === 'OK') {
      yield put(actions.loadReutersEstimateCompleted(responseData.data,
          action.api, action.stock_code));
    } else {
      yield put(actions.loadReutersEstimateFailed(responseData.error,
          action.api, action.stock_code));
    }
  } catch(error) {
    yield put(actions.loadReutersEstimateFailed(error, action.api,
        action.stock_code));
  }
}

function* watchBubbleDataLoadRequest() {
  yield takeEvery(types.LOAD_BUBBLE_DATA_REQUEST, bubbleDataLoadFlow);
}

function* bubbleDataLoadFlow(action) {
  try {
    const response = yield call(BubbleDataLoadAPI, action.stock_code);
    const responseData = response.data;
    
    if (responseData.status === 'OK') {
      yield put(actions.loadBubbleDataCompleted(responseData.data,
          action.stock_code));
    } else {
      yield put(actions.loadBubbleDataFailed(responseData.error,
          action.stock_code));
    }
  } catch(error) {
    yield put(actions.loadBubbleDataFailed(error, action.stock_code));
  }
}


export default [
  watchStockComparisonLoadRequest(),
  ...fs,
  watchBubbleDataLoadRequest(),
]
