import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/UserOrders'
import { UserOrdersAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


function responseHandler(response) {
    return response.data;
}

function * watchUserOrdersLoadRequest() {
    yield takeLatest(types.ORDERS_LOAD_REQUEST, loadUserOrdersFlow);
}

function * loadUserOrdersFlow(action) {
    try {
        const response = yield call(UserOrdersAPIs.UserOrdersLoadAPI, action.data),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadUserOrdersCompleted(responseData.data));
        } else {
            yield put(actions.loadUserOrdersFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadUserOrdersFailed(error));
    }
}

function * watchUserOrdersAddRequest() {
    yield takeLatest(types.ORDERS_ADD_REQUEST, addUserOrderFlow);
}

function * addUserOrderFlow(action) {
    try {
        const response = yield call(UserOrdersAPIs.UserOrdersAddAPI, action.list),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.addUserOrderCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.addUserOrderFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.addUserOrderFailed(error));
    }
}

function * watchInvoiceDataLoadRequest() {
    yield takeLatest(types.INVOICE_LOAD_REQUEST, loadInvoiceDataFlow);
}

function * loadInvoiceDataFlow(action) {
    try {
        const response = yield call(UserOrdersAPIs.InvoiceDataLoadAPI, action.orderID),
            responseData = responseHandler(response);


        if (responseData.status === 'success') {
            yield put(actions.loadInvoiceDataCompleted(responseData.data));
        } else {
            yield put(actions.loadInvoiceDataFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadInvoiceDataFailed(error));
    }
}

export default [
    watchUserOrdersLoadRequest(),
    watchUserOrdersAddRequest(),
    watchInvoiceDataLoadRequest()
]
