export default {
    "lynch-tengrower": [
        { id: 24910, title: '成長股篩選', key: ['A+`'] },
        { id: 24800, title: '公司簡介', key: ['A+Z'] }, 
        { id: 38364, title: '小助理', key: ['F1', 'C+Q']  },
        { id: 33165, title: '折現模型', key: ['F2', 'C+S'] } ,
        { id: 32845, title: '法人預估', key: ['A+X'] }, 
        { id: 40338, title: 'IBES預估', key: ['A+C'] }, 
        { id: 41616, title: '追蹤成長數據', key: ['F5',  'A+Q'] },
        { id: 41647, title: '作弊工具', key: ['A+3'] }, 
        { id: 41648, title: '訂單能見度', key: ['A+4'] } ,
        { id: 41663, title: 'ROIC選股', key: ['A+5'] }, 
        { id: 33767, title: '崩盤漲跌統計', key: ['A+6']  },
        { id: 41615, title: '未來展望', key: ['F3', 'C+E'] } ,
        { id: 41642, title: '短期動能', key: ['F4', 'C+D'] }, 
        { id: 41645, title: '淡旺季', key: ['A+W'] }, 
        { id: 23368, title: '月營收', key: ['A+E'] }, 
        { id: 38908, title: '財報熱圖比較', key: ['A+A'] },
        { id: 27004, title: '同業比較', key: ['A+S'] }, 
        { id: 41644, title: '營收快報', key: ['A+1'] }, 
        { id: 42223, title: '財報搶先看', key: ['A+2'] } ,
        { id: 33750, title: '業外分析', key: ['A+D'] }, 
        { id: 24005, title: '股價K線圖', key: ['A+K'] }, 
        { id: 23372, title: '股票評價', key: ['A+F'] }, 
        { id: 23376, title: '損益表', key: ['F6'] }, 
        { id: 23375, title: '資產負債表', key: ['F7']  },
        { id: 23374, title: '現金流量表', key: ['F8'] }, 
        { id: 27403, title: '財務比率', key: ['F9'] }, 
    ]
}