const judgmentMonth = (month) => {
    switch(month) {
        case 12:
            return '1 年';

        case 6 :
            return '半年';

        default:
            return `${month} 個月`;
    }
}


export{ judgmentMonth }
