export default {
	GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,

	GOOGLE_GTM_ID: process.env.REACT_APP_GOOGLE_GTM_ID,
	GOOGLE_GA_ID: process.env.REACT_APP_GOOGLE_GA_ID,
	GOOGLE_GA_MEASUREMENT_ID: process.env.REACT_APP_GOOGLE_GA_MEASUREMENT_ID,

	GOOGLE_FIREBASE_APIKEY: process.env.REACT_APP_GOOGLE_FIREBASE_APIKEY,
	GOOGLE_FIREBASE_AUTHDOMAIN: process.env.REACT_APP_GOOGLE_FIREBASE_AUTHDOMAIN,
	GOOGLE_FIREBASE_DATABASEURL: process.env.REACT_APP_GOOGLE_FIREBASE_DATABASEURL,
	GOOGLE_FIREBASE_PROJECTID: process.env.REACT_APP_GOOGLE_FIREBASE_PROJECTID,
	GOOGLE_FIREBASE_STORAGEBUCKET: process.env.REACT_APP_GOOGLE_FIREBASE_STORAGEBUCKET,
	GOOGLE_FIREBASE_MESSAGINGSENDERID: process.env.REACT_APP_GOOGLE_FIREBASE_MESSAGINGSENDERID,
	GOOGLE_FIREBASE_APPID: process.env.REACT_APP_GOOGLE_FIREBASE_APPID,
	GOOGLE_FIREBASE_MEASUREMENTID: process.env.REACT_APP_GOOGLE_FIREBASE_MEASUREMENTID,
	GOOGLE_FIREBASE_PUSHKEY: process.env.REACT_APP_GOOGLE_FIREBASE_PUSHKEY,
};
