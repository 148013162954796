import { connect } from 'react-redux'
import AuthRedirect from 'components/AuthRedirect'
import { UserInfo as component } from '../components'


const mapStateToProps = (state) => ({
	userAvatar: state.Avatar.user_avatar,
	user_info: state.Profile.user_info,
	notifications: state.UserNotifications.user_notifications,
});
const UserInfo = AuthRedirect.AuthProps(connect(mapStateToProps)(component));

export { UserInfo } 
