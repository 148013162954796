import axios from 'axios'


const stockDomain = process.env.REACT_APP_STOCK_DOMAIN;
// load questions list
const DataLoadAPI = (category, subcategory, data = null) => axios.get(
  `${stockDomain}/data_fetch/api/UanalyzeAI/${category}_${subcategory}${data ? `_${data}` : ''}`
);
// get a reply to a specific question
const AnswerGetAPI = (data, subcategory) => axios.post(
  `${stockDomain}/fetch/AiChat/${subcategory}`,
  data,
  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
)


export default { DataLoadAPI, AnswerGetAPI }
