import React from 'react'


const IDX_SECTION = 1;
const MenuChildren = ({
  child,
  history,
  idxParent,
  isEditing,
  isEnabled,
  is_mobile,
  location,
  parent,
  // pathname,
  onRemove,
  onSideMenuHide,
}) => {
  const [active, setActive] = React.useState(false);
  const { id, name, category } = child;
  const childPathname = child.pathname;
  const { url, section } = parent;
  const historyHandler = (e) => {
    onSideMenuHide(is_mobile);
    setActive(true);

    const linkTo = (section === 'freebie')
      ?
      `/${url}/${section}/${childPathname}`
      :
      `/${url}/dashboard/${(childPathname) ? childPathname : id}`;

    history.push(linkTo);
  };

  React.useEffect(() => {
    const arrPathname = location.pathname.split('/');
    const idxID = arrPathname.length - ((arrPathname.length === 4) ? 1 : 2);
    const activeUpdated = arrPathname[IDX_SECTION] === url
        && arrPathname[idxID] === (childPathname ? childPathname : id).toString();

    setActive(activeUpdated);
  }, [ childPathname, id, location.pathname, url ]);

  React.useEffect(() => {
    if (active) {
      const elem = document.getElementsByClassName(`menu-item-children ${id}`)[0];
      const nav = document.getElementById('side-menu-nav');
      const elemParent = document.getElementsByClassName('menu-item')[idxParent];
      const top = elem.offsetTop + 33 * (idxParent - 1) + ((idxParent < 4) ? 0
          : elemParent.offsetTop);

      nav.scrollTo({ top: top, behavior: 'smooth' });
    }
  }, [ active, id, idxParent ]);

  return (
    <div
      className={`menu-item-children ${id} ${active ? 'active' : ''}
          ${isEditing ? 'is-editing' : ''}`}
      onClick={historyHandler}
    >
      <div className='ua-triangle-after'>
        {isEditing && (
          <span className='mover margin-right-5 cursor-move'>
            <i className='txt-medium elegant elegant-eleganticons-64'/>
          </span>
        )}

        <span className='menu-item-children-title margin-right-auto'>
          {name}
        </span>

        {(isEditing && category === 0)
          ?
          <span
            className='remover margin-right-5 pull-right cursor-pointer'
            data-id={id}
            data-section={section}
            onClick={isEnabled ? onRemove : null}
          >
            <i className='txt-medium elegant elegant-eleganticons-44'/>
          </span>
          :
          null}
      </div>
    </div>
  );
}


export { MenuChildren }
