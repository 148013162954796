import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { types, actions } from '../modules/Stock'
import { StockAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


const {
  StockListLoadAPI,
  StockOthersListLoadAPI,
  StockETFListLoadAPI,
  StockInfoLoadAPI,
  StockDataFetchAPI,
  StockCRAWLERDataFetchAPI,
  StockCRAWLERDataFetchWithUserIDAPI,
  StockAPIDataFetchAPI,
  StockRecordAPI,
} = StockAPIs

function* watchStockListLoadRequest() {
    yield takeLatest(types.LIST_LOAD_REQUEST, loadStockListFlow);
}

function* loadStockListFlow(action) {
    try {
        const response = yield call(StockListLoadAPI);
        const responseData = response.data;

        if (responseData.status === 'OK') {
            yield put(actions.loadStockListCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadStockListFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadStockListFailed(error));
    }
}

function* watchStockOthersListLoadRequest() {
    yield takeLatest(types.OTHERS_LIST_LOAD_REQUEST, loadStockOthersListFlow);
}

function* loadStockOthersListFlow(action) {
    try {
        const response = yield call(StockOthersListLoadAPI);
        const responseData = response.data;

        if (responseData.status === 'OK') {
            yield put(actions.loadOthersStockListCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadOthersStockListFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadStockListFailed(error));
    }
}

function* watchStockETFListLoadRequest() {
    yield takeLatest(types.ETF_LIST_LOAD_REQUEST, loadStockETFListFlow);
}

function* loadStockETFListFlow(action) {
    try {
        const response = yield call(StockETFListLoadAPI);
        const responseData = response.data;

        if (responseData.status === 'OK') {
            yield put(actions.loadETFStockListCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadETFStockListFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadStockListFailed(error));
    }
}

function* watchStockInfoLoadRequest() {
    yield takeEvery(types.INFO_LOAD_REQUEST, loadStockInfoFlow);
}

function* loadStockInfoFlow(action) {
    try {
        const response = yield call(StockInfoLoadAPI, action.stockCode);
        const responseData = response.data;

        if (responseData.status === 'OK') {
            yield put(actions.loadStockInfoCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadStockInfoFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadStockInfoFailed(error));
    }
}

function* watchStockDataFetchRequest() {
  yield takeEvery(types.DATA_FETCH_REQUEST, fetchStockDataFlow);
}

function* fetchStockDataFlow(action) {
  const {
    stock_code,
    api_host,
    crawler_api,
    params,
  } = action;
  const { user_id, type } = params;
  const dataModel = params.model ? params.model : action.model;
  const dataType = type ? type : (stock_code || 'noStock');

  let api = StockDataFetchAPI;

  if (api_host) {
    api = StockAPIDataFetchAPI;
  } else if (crawler_api) {
    api = user_id ? StockCRAWLERDataFetchWithUserIDAPI : StockCRAWLERDataFetchAPI;
  }

  try {
    const response = yield call(
      api,
      (dataModel === 'research/chart/PackageMarket')
          ?
          `${dataModel}${stock_code}ChartString`
          :
          dataModel,
      stock_code,
      type,
      user_id,
    );
    const responseData = response.data;

    if (responseData.status === 'OK' || responseData.status === 'success') {
      yield put(actions.fetchStockDataCompleted(
        dataModel,
        dataType,
        responseData.data
      ));
    } else {
      ResponseErrorHandler(action.type, responseData.error);

      yield put(actions.fetchStockDataFailed(dataModel, dataType, 'error'));
    }
  } catch(error) {
    yield put(actions.fetchStockDataFailed(dataModel, dataType, 'error'));
  }
}

function* watchStockRecordRequest() {
  yield takeEvery(types.RECORD_REQUEST, recordStockFlow);
}

function* recordStockFlow(action) {
  try {
    const response = yield call(StockRecordAPI, action.stockCode);
    const responseData = response.data;

      if (responseData.status === 'success') {
        yield put(actions.recordStockCompleted(responseData.data));
      } else {
        ResponseErrorHandler(action.type, responseData.error);

        yield put(actions.recordStockFailed(responseData.error));
      }
  } catch(error) {
    yield put(actions.recordStockFailed(error));
  }
}


export default [
    watchStockListLoadRequest(),
    watchStockOthersListLoadRequest(),
    watchStockETFListLoadRequest(),
    watchStockInfoLoadRequest(),
    watchStockDataFetchRequest(),
    watchStockRecordRequest()
]
