import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/Pagination'
import { Pagination as component } from './Pagination'


const mapStateToProps = (state, ownProps) => {
	const pagerID = ownProps.pagerID;

	return pagerID
		?
		{
			page: pagerID in state.Pagination.pager ? state.Pagination.pager[pagerID] : 0,
			pagerID: pagerID,
		}
		:
		{ pager: state.Pagination.pager }
};
const mapDispatchToProps = (dispatch, ownProps) => ({
	onPagerSet: (currentPage) =>
			dispatch(actions.setPager(ownProps.pagerID, currentPage)),
});
const Pagination = connect(mapStateToProps, mapDispatchToProps)(component);


export { Pagination }
