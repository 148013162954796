import { call, put, takeEvery } from 'redux-saga/effects'
import { SingleAiReportAPIs } from 'api'
import { types, actions } from '../modules/SingleAiReport'


const { QuestionsGetAPI, AnswerGetAPI, SourceGetAPI } = SingleAiReportAPIs;

function* watchAiReportQuestionsGetRequest() {
  yield takeEvery(types.QUESTIONS_GET_REQUEST, getAiReportQuestionsFlow);
}

function* getAiReportQuestionsFlow(action) {
  const { subcategory } = action;

  try {
    const response = yield call(QuestionsGetAPI, subcategory);
    const responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.getQuestionsSuccess(responseData.data, subcategory));
    } else {
      console.log('getAiReportQuestions response error: ', responseData.error);

      yield put(actions.getQuestionsError(subcategory));
    }
  } catch (err) {
    console.log('getAiReportQuestions catch error: ', err);

    yield put(actions.getQuestionsError(subcategory));
  }
}

function* watchAiReportAnswerGetRequest() {
  yield takeEvery(types.ANSWER_GET_REQUEST, getAiReportAnswerFlow);
}

function* getAiReportAnswerFlow(action) {
  const { data, subcategory } = action;

  try {
    const response = yield call(AnswerGetAPI, data, subcategory);
    const responseData = response.data;

    if (responseData.status === 'success') {
      yield put(actions.getAnswerSuccess(
        responseData.data.text,
        data,
        subcategory
      ));
    } else {
      console.log('getAiReportAnswer response error: ', responseData.error);

      yield put(actions.getAnswerError(subcategory));
    }
  } catch (err) {
    console.log('getAiReportAnswer catch error: ', err);

    yield put(actions.getAnswerError(subcategory));
  }
}

function* watchAiReportSourceGetRequest() {
  yield takeEvery(types.SOURCE_GET_REQUEST, getAiReportSourceFlow);
}

function* getAiReportSourceFlow(action) {
  try {
    const response = yield call(SourceGetAPI, action.data);
    const responseData = response.data;

    if (responseData.status === 'OK') {
      yield put(actions.getSourceSuccess(responseData.data.data));
    } else {
      console.log('getAiReportSource response error: ', responseData.error);

      yield put(actions.getSourceError());
    }
  } catch (err) {
    console.log('getAiReportSource catch error: ', err);

    yield put(actions.getSourceError());
  }
}


export default [
  watchAiReportQuestionsGetRequest(),
  watchAiReportAnswerGetRequest(),
  watchAiReportSourceGetRequest(),
]
