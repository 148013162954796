import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/ReflineSwitcher'
import { ReflineAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'

function responseHandler(response) {
    return response.data;
}

function * watchReflineLoadRequest() {
    yield takeLatest(types.LOAD_REQUEST, loadReflineFlow);
}

function * loadReflineFlow(action) {
    try {
        const response = yield call(ReflineAPIs.ReflineLoadAPI, action.stock_code);
        const responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadReflineCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);
            yield put(actions.loadReflineFailed(responseData.error));
        }
    }
    catch(error) {
        ResponseErrorHandler(action.type, error);
        yield put(actions.loadReflineFailed(error));
    }
}

function * watchReflineUpdateRequest() {
    yield takeLatest(types.UPDATE_REQUEST, updateReflineFlow);
}

function * updateReflineFlow(action) {
    try {
        const response = yield call(ReflineAPIs.ReflineUpdateAPI, action.switcher_id, action.data);
        const responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.updateReflineCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);
            yield put(actions.updateReflineFailed(responseData.error));
        }
    }
    catch(error) {
        ResponseErrorHandler(action.type, error);
        yield put(actions.updateReflineFailed(error));
    }
}

export default [
    watchReflineLoadRequest(),
    watchReflineUpdateRequest()
]
