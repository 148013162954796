export default {
  APP_API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  DATA_API_DOMAIN: process.env.REACT_APP_STOCK_DOMAIN,
  // add other API-related configuration here
  endpoints: {
    auth: '/auth',
    user: '/user',
    stock: '/stock'
  }
}
