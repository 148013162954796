import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import ReactPixel from 'react-facebook-pixel'
import { HelmetProvider } from 'react-helmet-async'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { AppStore } from './appRedux'
import * as serviceWorker from './serviceWorker'

import App from './App'

import 'react-table/react-table.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'jquery/dist/jquery.min.js'

import 'assets/sass/animation.scss'
import 'assets/sass/style.scss'
import 'assets/sass/ua-element.scss'
import 'assets/sass/ua-theme.scss'


const rootElement = document.getElementById('ua-root');
const appElement = (
	<HelmetProvider>
		<CookiesProvider>
			<Provider store={AppStore}>
				<BrowserRouter>
					<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
						<App />
					</GoogleOAuthProvider>
				</BrowserRouter>
			</Provider>
		</CookiesProvider>
	</HelmetProvider>
);

if ('serviceWorker' in navigator && rootElement.hasChildNodes()) {
	ReactPixel.init(
		process.env.REACT_APP_FACEBOOK_PIXEL_ID,
		/*
		 optional, more info:
		 https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
		 */
		{},
		{
			/*
			 set pixel's autoConfig. More info:
			 https://developers.facebook.com/docs/facebook-pixel/advanced/
			 */
			autoConfig: true,
			// enable logs
			debug: false,
		}
	);
	hydrate(appElement, rootElement);
} else {
	render(appElement, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
