import { Cookies } from 'utils'


export const types = {
    RESET: 'renew/RESET',
    SET: 'renew/SET'
}

const initState = { plan_id: null }

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.SET:
            Cookies.set(
                'renew_plan_id',
                action.plan_id ,
                {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 1,
                    sameSite : 'Lax',
                    secure   : true
                }
            );

            return { ...state, plan_id: action.plan_id };

        case types.RESET:
            Cookies.remove('renew_plan_id', { path: '/' });

            return { ...state, plan_id: null };

        default:
            return state
    }
}

export default reducer

export const actions = {
    reset: () => ({ type: types.RESET }),
    set: (id) => ({ type: types.SET, plan_id: id })
}
