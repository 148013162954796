import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Password'
import { PasswordAPIs } from 'api'
import { ResponseErrorHandler, ResponseMessageHandler } from 'utils'


function responseHandler(response) {
    return response.data;
}

function * watchForgotRequest() {
    yield takeLatest(types.FORGOT_REQUEST, forgotFlow);
}

function * forgotFlow(action) {
    try {
        const response = yield call(PasswordAPIs.SendForgotEmailAPI, action.data),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            if (responseData.data.sent === 1) {
                ResponseMessageHandler(types.FORGOT_SUCCESS);

                yield put(actions.forgotSuccess(responseData.data));
            } else {
                ResponseErrorHandler(action.type, { code: '1', message: responseData.data.message });

                yield put(actions.forgotError(responseData.data.message));
            }
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.forgotError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.forgotError(error));
    }
}

function * watchRepasswordSetRequest() {
    yield takeLatest(types.PASSWORD_RESET_REQUEST, resetPasswordFlow);
}

function * resetPasswordFlow(action) {
    try {
        const response = yield call(
                PasswordAPIs.ResetPasswordAPI,
                action.noAuth ? action.data : { ...action.data, _method: 'put' }
            ),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.PASSWORD_RESET_ERROR);

            yield put(actions.resetPasswordSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.resetPasswordError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.resetPasswordError(error));
    }
}


export default [
    watchForgotRequest(),
    watchRepasswordSetRequest()
]
