import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const UserNotificationsLoadAPI = () => axios.get(`${config.api.APP_API_DOMAIN}/user/notifications`,{
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  }),
  UserNotificationsReadAPI = (notificationID, formData) => axios.post(`${config.api.APP_API_DOMAIN}/user/notifications/${notificationID}`, formData,{
    headers: {
      Accept        : 'application/json',
      Authorization : `${Cookies.get("token_type")} ${Cookies.get("access_token")}`
    }
  })


export default { UserNotificationsLoadAPI, UserNotificationsReadAPI }
