import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import { HelmetComponent, Layout, Loading } from 'components'
import config from 'config'

/*
 side menu
 1. announcement: /announcement
 2. freebie: /lab/freebie/:labID([0-9]+|[a-zA-Z0-9\-_]+)
 3. portfolio: /lab/dashboard/:labID([0-9]+|[a-zA-Z0-9\-_]+)
 4. my-collection: /lab/dashboard/:labID([0-9]+|[a-zA-Z0-9\-_]+)
 									 /lab/dashboard/:labID([0-9]+|[a-zA-Z0-9\-_]+)/:affiliatedID([0-9]+|[a-zA-Z0-9\-_]+)
 5. my-lab: /lab/dashboard/:labID([0-9]+|[a-zA-Z0-9\-_]+)
 6. e-com: /e-com/product-view/:category
 */
const inLayout = (ModuleComponent) => <Layout>{ModuleComponent}</Layout>;
const inAuthLayout = (ModuleComponent) =>
		<Layout isLoginPage={true}>{ModuleComponent}</Layout>;
// const inSingleLayout = (ModuleComponent) =>
// 		<Layout isSingle={true}>{ModuleComponent}</Layout>;
const maintainMode = process.env.REACT_APP_MAINTAIN_MODE;
const { lab, product } = config;
const labCategories	= lab.CATEGORY;
const	productTypes = product.PRODUCT_TYPE;
const Announcement = lazy(() =>
		import(/* webpackChunkName: "Announcement" */'./modules/Announcement'));
const Forgot = lazy(() =>
		import(/* webpackChunkName: "Forgot" */'./modules/Auth/Forgot'));
const Login = lazy(() =>
		import(/* webpackChunkName: "Login" */'./modules/Auth/Login'));
const Register = lazy(() =>
		import(/* webpackChunkName: "Register" */'./modules/Auth/Register'));
const ResetPassword = lazy(() =>
		import(/* webpackChunkName: "ResetPassword" */'./modules/Auth/ResetPassword'));
const SetPassword	= lazy(() =>
		import(/* webpackChunkName: "SetPassword" */'./modules/Auth/SetPassword'));
const Contact	= lazy(() =>
		import(/* webpackChunkName: "Contact" */'./modules/Contact'));
const Dashboard	= lazy(() =>
		import(/* webpackChunkName: "Dashboard" */'./modules/Dashboard/Dashboard'));
// const Playground = lazy(() =>
// 		import(/* webpackChunkName: "Playground" */'./modules/Dashboard/Playground'));
const CheckoutFeedback = lazy(() =>
		import(/* webpackChunkName: "CheckoutFeedback" */'./modules/ECommerce/CheckoutFeedback'));
const Payment	= lazy(() =>
		import(/* webpackChunkName: "Payment" */'./modules/ECommerce/Payment'));
const ProductDetail	= lazy(() =>
		import(/* webpackChunkName: "ProductDetail" */'./modules/ECommerce/ProductDetail'));
const ProductList	= lazy(() =>
		import(/* webpackChunkName: "ProductList" */'./modules/ECommerce/ProductList'));
const LabSetting	= lazy(() =>
		import(/* webpackChunkName: "LabSetting" */'./modules/Laboratory/LabSetting'));
const LabUpdate	= lazy(() =>
		import(/* webpackChunkName: "LabUpdate" */'./modules/Laboratory/LabUpdate'));
const Disclaimer = lazy(() =>
		import(/* webpackChunkName: "Disclaimer" */'./modules/Page/Disclaimer'));
const ErrorPage = lazy(() =>
		import(/* webpackChunkName: "ErrorPage" */'./modules/Page/ErrorPage'));
const MaintainPage = lazy(() =>
		import(/* webpackChunkName: "MaintainPage" */'./modules/Page/MaintainPage'));
const PrivacyPolicy	= lazy(() =>
		import(/* webpackChunkName: "PrivacyPolicy" */'./modules/Page/PrivacyPolicy'));
const PrivacyPolicyApp = lazy(() =>
		import(/* webpackChunkName: "PrivacyPolicyApp" */'./modules/Page/PrivacyPolicyApp'));
const PrivacyPolicyAppStockoldbull = lazy(() =>
		import(/* webpackChunkName: "PrivacyPolicyAppStockoldbull" */'./modules/Page/PrivacyPolicyAppStockoldbull'));
const PrivacyPolicyApp888	= lazy(() =>
		import(/* webpackChunkName: "PrivacyPolicyApp888" */'./modules/Page/PrivacyPolicyApp888'));
const Service	= lazy(() =>
		import(/* webpackChunkName: "Service" */'./modules/Page/Service'));
const ServiceApp = lazy(() =>
		import(/* webpackChunkName: "ServiceApp" */'./modules/Page/ServiceApp'));
const ServiceAppStockoldbull = lazy(() =>
		import(/* webpackChunkName: "ServiceAppStockoldbull" */'./modules/Page/ServiceAppStockoldbull'));
const ServiceApp888 = lazy(() =>
		import(/* webpackChunkName: "ServiceApp888" */'./modules/Page/ServiceApp888'));
const Profile	= lazy(() =>
		import(/* webpackChunkName: "Profile" */'./modules/Profile'));
// const Single = lazy(() =>
// 		import(/* webpackChunkName: "Single" */'./modules/Dashboard/Single'));
const RootRoutes = () => ((!maintainMode || maintainMode === 'false')
	?
	<Switch>
		{/* home */}
		<Route
			path="/"
			exact
			render={(props) => {
				const category = 'collection';
				const { title, info } = productTypes[category];

				return inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title={`優分析 UAnalyze－${title}`}
										description={info}
										href="/"
								/>

								<ProductList {...props} category={category} />
						</Suspense>
				);
			}}
		/>

		{/* auth */}
		<Route
				path="/login-page"
				render={(props) => inAuthLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－登入"
										description="優分析 UAnalyze－登入"
										href="/login-page"
								/>

								<Login {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/register"
				render={(props) => inAuthLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－免費註冊"
										description="立即註冊會員，獲得專屬分析師！"
										href="/register"
								/>

								<Register {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/forgot"
				render={(props) => inAuthLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－忘記密碼"
										description="忘記密碼了嗎？輸入 Email 重新設定！"
										href="/forgot"
								/>

								<Forgot {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/reset-password"
				render={(props) => inAuthLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－重設密碼"
										description="輸入新密碼，以重新登入"
										href="/reset-password"
								/>

								<ResetPassword {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/set-password"
				render={(props) => inAuthLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－設定密碼"
										description="設定完之後就能使用此密碼直接從網站登入"
										href="/set-password"
								/>

								<SetPassword {...props} />
						</Suspense>
				)}
		/>

		{/* terms & privacy */}
		<Route
				path="/disclaimer"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－免責聲明"
										description="優分析 UAnalyze－免責聲明"
										href="/disclaimer"
								/>

								<Disclaimer {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/privacypolicy"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－隱私權政策"
										description="優分析 UAnalyze－隱私權政策"
										href="/privacypolicy"
								/>

								<PrivacyPolicy {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/service"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－服務條款"
										description="優分析 UAnalyze－服務條款"
										href="/service"
								/>

								<Service {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/privacypolicy-app"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="股魚－極速成長股掃描器 APP 隱私權政策"
										description="股魚－極速成長股掃描器 APP 隱私權政策"
										href="/privacypolicy-app"
								/>

								<PrivacyPolicyApp {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/privacypolicy-app-2"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="股海老牛－專挑抱緊股 APP 隱私權政策"
										description="股海老牛－專挑抱緊股 APP 隱私權政策"
										href="/privacypolicy-app-2"
								/>

								<PrivacyPolicyAppStockoldbull {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/privacypolicy-app-3"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="888選股機器人 APP 隱私權政策"
										description="888選股機器人 APP 隱私權政策"
										href="/privacypolicy-app-3"
								/>

								<PrivacyPolicyApp888 {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/service-app"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="股魚－極速成長股掃描器 APP 服務條款"
										description="股魚－極速成長股掃描器 APP 服務條款"
										href="/service-app"
								/>

								<ServiceApp {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/service-app-2"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="股海老牛－專挑抱緊股 APP 服務條款"
										description="股海老牛－專挑抱緊股 APP 服務條款"
										href="/service-app-2"
								/>

								<ServiceAppStockoldbull {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/service-app-3"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="888選股機器人 APP 服務條款"
										description="888選股機器人 APP 服務條款"
										href="/service-app-3"
								/>

								<ServiceApp888 {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/contact-us"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－聯絡我們"
										description="優分析 UAnalyze－聯絡我們"
										href="/contact-us"
								/>

								<Contact {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/announcement"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－公告訊息"
										description="優分析 UAnalyze－公告訊息"
										href="/announcement"
								/>

								<Announcement {...props} />
						</Suspense>
				)}
		/>

		{/* ecom */}
		<Route
				path="/e-com/product-view/:category"
				render={(props) => {
						const { location, match } = props,
								category = match.params.category;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<HelmetComponent
												title={`優分析 UAnalyze 商城－${productTypes[category].title}`}
												description={productTypes[category].info}
												href={location.pathname}
										/>

										<ProductList {...props} category={category} />
								</Suspense>
						);
				}}
		/>

		<Route
				path="/e-com/product-detail/:productID([0-9]+|[a-zA-Z0-9\-_]+)"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<ProductDetail {...props} productID={props.match.params.productID} />
						</Suspense>
				)}
		/>

		<Route
				path="/payment"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－結帳"
										description="優分析 UAnalyze－結帳"
										href="/payment"
										robots={false}
								/>

								<Payment {...props} />
						</Suspense>
				)}
		/>

		<Route
				path="/payment/feedback"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－付款成功"
										description="優分析 UAnalyze－付款成功"
										href="/payment/feedback"
								/>

								<CheckoutFeedback {...props} />
						</Suspense>
				)}
		/>

		{/* user */}
		<Route
				path="/profile"
				render={(props) => inLayout(
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－我的帳戶"
										description="優分析 UAnalyze－我的帳戶"
										href="/profile"
										robots={false}
								/>

								<Profile {...props} />
						</Suspense>
				)}
		/>

		{/* labs */}
		<Route
				path="/lab/my-lab/setting/:labID([0-9]+|[a-zA-Z0-9\-_]+)"
				render={(props) => {
						const { location, match } = props,
								labID = match.params.labID,
								type = 'custom',
								title = labCategories[type].title;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<HelmetComponent
												title={`優分析 UAnalyze－編輯$${title}`}
												description={`優分析 UAnalyze－編輯$${title}`}
												href={location.pathname}
												robots={false}
										/>

										<LabUpdate
												{...props}
												id={labID}
												type={type}
												status="update"
										/>
								</Suspense>
						);
				}}
		/>

		<Route
				path="/lab/my-lab/create"
				render={(props) => {
						const type = 'custom',
								title = labCategories[type].title;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<HelmetComponent
												title={`優分析 UAnalyze－新增$${title}`}
												description={`優分析 UAnalyze－新增${title}`}
												href={props.location.pathname}
												robots={false}
										/>

										<LabSetting
												{...props}
												type={type}
												status="create"
										/>
								</Suspense>
						);
				}}
		/>

		{/* dashboard */}
		<Route
				path="/lab/dashboard/:labID([0-9]+|[a-zA-Z0-9\-_]+)"
				exact
				render={(props) => {
						const labID = props.match.params.labID;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<Dashboard {...props} id={labID} />
								</Suspense>
						);
				}}
		/>

		<Route
				path="/lab/dashboard/:labID([0-9]+|[a-zA-Z0-9\-_]+)/:affiliatedID([0-9]+|[a-zA-Z0-9\-_]+)"
				render={(props) => {
						const { labID, affiliatedID } = props.match.params;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<Dashboard
												{...props}
												id={labID}
												affiliatedID={affiliatedID}
										/>
								</Suspense>
						);
				}}
		/>

		<Route
				path="/lab/freebie/:labID([0-9]+|[a-zA-Z0-9\-_]+)"
				render={(props) => {
						const labID = props.match.params.labID;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<Dashboard {...props} id={labID} />
								</Suspense>
						);
				}}
		/>

		<Route
				path="/lab/portfolio/create"
				render={(props) => {
						const type = 'portfolio',
								title = labCategories[type].title;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<HelmetComponent
												title={`優分析 UAnalyze－新增$${title}`}
												description={`優分析 UAnalyze－新增${title}`}
												href={props.location.pathname}
												robots={false}
										/>

										<LabSetting
												{...props}
												type={type}
												status="create"
										/>
								</Suspense>
						);
				}}
		/>

		{/* playground */}
		{/*<Route
				path="/playground"
				render={(props) => {
						const { location, match } = props,
								singleID = match.params.singleID;

						return inLayout(
								<Suspense fallback={<Loading />}>
										<HelmetComponent
												title="優分析 UAnalyze－塗鴉板"
												description="優分析 UAnalyze－塗鴉板"
												href={location.pathname}
												robots={false}
										/>

										<Playground {...props} id={singleID} />
								</Suspense>
						);
				}}
		/>*/}

		{/*<Route
			path="/singles/:singleID([0-9]+|[a-zA-Z0-9\-_]+)?"
			render={(props) => inSingleLayout(<Single {...props} />)}
		/>*/}

		{/* files */}
		<Route
			path="/robots.txt"
			onEnter={() => window.location.reload()}
		/>

		<Route
			path="/assets/documents/修正式價值投資策略操作手冊.pdf"
			onEnter={() => window.location.reload()}
		/>

		<Route
			path="/assets/documents/股海老牛使用教學.pdf"
			onEnter={() => window.location.reload()}
		/>

		<Route
			path="/assets/documents/股魚價值K線使用手冊.pdf"
			onEnter={() => window.location.reload()}
		/>

		<Route
			path="/assets/documents/陳喬泓翻倍成長選股策略使用介紹篇.pdf"
			onEnter={() => window.location.reload()}
		/>

		<Route
				path="*"
				render={(props) => (
						<Suspense fallback={<Loading />}>
								<HelmetComponent
										title="優分析 UAnalyze－404"
										description="Oops！頁面不存在喔！"
										href={props.location.pathname}
								/>

								<ErrorPage {...props} isMaintaining={maintainMode === "true"} />
						</Suspense>
				)}
		/>
	</Switch>
	:
	<Switch>
			<Route
					render={(props) => (
							<Suspense fallback={<Loading />}>
									<MaintainPage {...props} />
							</Suspense>
					)}
			/>
	</Switch>
);


export default RootRoutes
