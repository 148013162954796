import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as actionsModal  } from 'appRedux/modules/Modal'
import { actions as actionsPayment } from 'appRedux/modules/Payment'
import { actions as actionsRenew } from 'appRedux/modules/Renew'
import { Renew } from './Renew'


const mapStateToProps = (state, ownProps) => ({
  selected: state.Renew.plan_id === ownProps.id,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onPayHandler: (product, planID) =>
      dispatch(actionsPayment.setProduct(product, planID)),
  onPlanIDSet: (planID) => dispatch(actionsRenew.set(planID)),
  onModalToggle: (modalID, open) =>
      dispatch(actionsModal.toggleModal(modalID, open)),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Renew))
