import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/SideMenu'
import { actions as actionsLaboratory } from 'appRedux/modules/Laboratory'
import { actions as actionsModal } from 'appRedux/modules/Modal'
import { MenuParents as Menu } from './MenuParents'


const mapStateToProps = (state) => ({
	authenticated: state.Auth.authenticated,

	title: state.Laboratory.title,
	lab_id_add: state.Laboratory.lab_id_add,
	lab_type_sort: state.Laboratory.lab_type_sort,
	lab_id_delete: state.Laboratory.lab_id_delete,

	is_mobile: state.Layout.is_mobile,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onSectionToggle: (section) => dispatch(actions.toggleSection(section)),
	onSectionOpen: (section) => dispatch(actions.openSection(section)),
	onMenuToggle: () => dispatch(actions.toggleSideMenu()),
	onSideMenuHide: (sideMenuHidden) =>
			dispatch(actions.hideSideMenu(sideMenuHidden)),

	onLabProcessChange: (process) =>
			dispatch(actionsLaboratory.changeLabProcess(process)),
	onLabCreateRequest: (newLab) =>
			dispatch(actionsLaboratory.createLabRequest(newLab)),
	onLabUpdateRequest: (labID, updatedLab) =>
			dispatch(actionsLaboratory.updateLabRequest(labID, updatedLab)),
	onLabDeleteSet: (labID) =>
			dispatch(actionsLaboratory.setDeleteLab(labID)),
	onLabDeleteRequest: (labID) =>
			dispatch(actionsLaboratory.deleteLabRequest(labID)),
	onLabListSort: (labs, idxOld, idxNew, type) =>
			dispatch(actionsLaboratory.sortLabList(labs, idxOld, idxNew, type)),
	onLabListSortRequest: (sortedLabs) =>
			dispatch(actionsLaboratory.sortLabListRequest(sortedLabs)),
	onReset: () => dispatch(actionsLaboratory.reset()),

	onModalToggle: (id, open) => dispatch(actionsModal.toggleModal(id, open)),
});
const MenuParents = withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu))


export { MenuParents }
