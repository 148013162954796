import React from 'react'
import 'assets/sass/utils/pagination.scss'


export class Pagination extends React.Component {
  static defaultProps = { pageWindowSize: 5 }

  firstPage = () => this.props.onPagerSet(0);

  lastPage = () => {
    const { pages, onPagerSet } = this.props;

    onPagerSet(pages - 1);
  }

  nextPage = () => {
    const { page, pages, onPagerSet } = this.props;

    onPagerSet((page !== pages - 1) ? page + 1 : page);
  }

  prevPage = () => {
    const { page, onPagerSet } = this.props;

    onPagerSet(page ? page - 1 :  page);
  }

  setPage = (e) => {
    const pageNumber = e.target.dataset.pageNumber;

    this.props.onPagerSet(parseInt(pageNumber, 10));
  }

  pageItemList = () => {
    const { page, pages, pageWindowSize } = this.props;
    const pageWindowSizeStartFactor = Math.ceil((pageWindowSize + 1.0) / 2.0);
    const pageWindowSizeEndFactor = Math.floor((pageWindowSize + 1.0) / 2.0);

    let startPage = 0;
    let endPage = pages;
    let pagerItems = [];

    if (pages > pageWindowSize) {
      startPage = (page > (pageWindowSizeStartFactor - 1))
          ? (page > pages - pageWindowSizeStartFactor ? (pages - pageWindowSize)
          : (page - (pageWindowSizeStartFactor - 1))) : startPage;
      endPage = (page < (pages - (pageWindowSizeEndFactor - 1)))
          ? (page < pageWindowSizeEndFactor - 1 ? pageWindowSize
            : (page + pageWindowSizeEndFactor)) : endPage;
    }

    for (let i = startPage; i < endPage; i++) {
      pagerItems.push(
        <li className={`pagination-item ${(i === page) ? 'active' : ''}`} key={i}>
          <span
            className='ua-txt-btn'
            data-page-number={i}
            onClick={this.setPage}
          >
            {i + 1}
           </span>
        </li>
      );
    }

    return pagerItems;
  }


  render() {
    const { customClassName, page, pages } = this.props;

    return (
      <ul className={`pagination ${customClassName || ''}`}>
        <li className={`pagination-item ${(page === 0) ? 'disabled' : ''}`}>
          <span className='ua-txt-btn' onClick={this.firstPage}>{'|<'}</span>
        </li>

        <li className={`pagination-item ${(page === 0) ? 'disabled' : ''}`}>
          <span className='ua-txt-btn' onClick={this.prevPage}>{'<'}</span>
        </li>

        {this.pageItemList()}

        <li className={`pagination-item ${(page + 1 === pages) ? 'disabled' : ''}`}>
          <span className='ua-txt-btn' onClick={this.nextPage}>{'>'}</span>
        </li>

        <li className={`pagination-item ${(page + 1 === pages) ? 'disabled' : ''}`}>
          <span className='ua-txt-btn' onClick={this.lastPage}>{'>|'}</span>
        </li>
      </ul>
    );
  }
}
