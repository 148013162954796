import { connect } from 'react-redux'
import { actions as actionsRenew } from 'appRedux/modules/Renew'
import { actions as actionsPayment } from 'appRedux/modules/Payment'
import { actions as actionsProduct } from 'appRedux/modules/Product'
import Renewbox from './Renewbox'


const mapStateToProps = (state, ownProps) => ({
    ...state.Renew,
    current_product: state.Product.current_product
  }),
  mapDispatchToProps = (dispatch, ownProps) => ({
    onPayHandler        : (product, planID) => dispatch(actionsPayment.setProduct(product, planID)),
    onPlanSet           : (planID) => dispatch(actionsPayment.setPlan(planID)),
    onReset             : () => dispatch(actionsRenew.reset()),
    onProductDetailLoad : (productID) => dispatch(actionsProduct.loadDetailRequest(productID))
  })


export default connect(mapStateToProps, mapDispatchToProps)(Renewbox)
