import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/UserNotifications'
import { UserNotificationsAPIs } from 'api'


function responseHandler(response) {
    return response.data;
}

function * watchUserNotificationsLoadRequest() {
    yield takeLatest(types.LOAD_REQUEST, loadUserNotificationsFlow);
}

function * loadUserNotificationsFlow(action) {
    try {
        const response = yield call(UserNotificationsAPIs.UserNotificationsLoadAPI, action.data),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadUserNotificationsCompleted(responseData.data));
        } else {
            yield put(actions.loadUserNotificationsFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.loadUserNotificationsFailed(error));
    }
}

function * watchUserNotificationsReadRequest() {
    yield takeLatest(types.READ_REQUEST, readUserNotificationsFlow);
}

function * readUserNotificationsFlow(action) {
    try {
        const formData = new FormData();
        formData.append('_method', 'put');

        const response = yield call(UserNotificationsAPIs.UserNotificationsReadAPI, action.id, formData),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.readUserNotificationsCompleted(responseData.data));
        } else {
            yield put(actions.readUserNotificationsFailed(responseData.error));
        }
    } catch(error) {
        yield put(actions.readUserNotificationsFailed(error));
    }
}

export default [
    watchUserNotificationsLoadRequest(),
    watchUserNotificationsReadRequest()
]
