import { Message } from 'components'
import { AppStore } from 'appRedux'
import { actions } from 'appRedux/modules/Auth'
import { actions as actionsPayment } from 'appRedux/modules/Payment'
import moment from 'moment'


function ErrorMessageHandler(error) {
  const { code, message } = error;

  if (!code || !message) { return false; }

  switch (code) {
    case 'E400001': return false;// { title: '登入失敗', content: '請重新登入' };

    case 'E400002': return {
      title: '網路錯誤',
      content: '請稍後再試一次，或聯絡 優分析 UAnalyze 客服人員',
    };

    case 'E400003': return { title: '權限錯誤', content: '沒有存取權限' };

    case 'E400004': return { title: '存取錯誤', content: '沒有找到資源' };

    case 'E400005': return { title: '存取錯誤', content: '無此請求方法' };

    case 'E400009':
    case 'E400007':
      return { title: '登入錯誤', content: '請檢查 Email 或 密碼' };

    case 'E400101': return { title: '輸入錯誤', content: '必須填寫 Email' };

    case 'E400102': return {
      title: '註冊錯誤',
      content: '此 Email 已被註冊，請使用其他信箱',
    };

    case 'E400103': return {
      title: '輸入錯誤',
      content: 'Email 格式錯誤，請重新輸入',
    };

    case 'E400104':
    case 'E400105':
    case 'E400106':
    case 'E400107':
    case 'E400108':
    case 'E400109':
    case 'E400110':
    case 'E400111':
    case 'E400112':
    case 'E400114':
    case 'E400119':
    case 'E400120':
    case 'E400121':
    case 'E400123':
    case 'E400124':
    case 'E400126':
    case 'E400127':
    case 'E400129':
    case 'E400138':
    case 'E400139':
    case 'E400141':
    case 'E400142':
    case 'E400143':
    case 'E400144':
    case 'E400145':
    case 'E400146':
    case 'E400148':
    case 'E400149':
    case 'E400150':
    case 'E400158': return { title: '發生錯誤', content: message };

    case 'E400113': return {
      title: '登入錯誤',
      content: 'Refresh token 欄位必須輸入',
    };

    case 'E400115': return { title: '登入錯誤', content: 'Facebook 登入沒有成功' };

    case 'E400116': return { title: '註冊錯誤', content: '此 Email 沒有註冊過喔' };

    case 'E400117': return { title: '輸入錯誤', content: 'Token 欄位必須輸入' };

    case 'E400118': return {
      title: '重設密碼錯誤',
      content: '密碼重設連結已失效，請點選「忘記密碼」重新寄送',
    };

    case 'E400122': return { title: '權限錯誤', content: '尚未擁有' };

    case 'E400130': return {
      title: '策略包 / 數據庫錯誤',
      content: '無效策略包 / 數據庫',
    };

    case 'E400131': return { title: '輸入錯誤', content: '股票代碼欄位必須填寫' };

    case 'E400132': return { title: '輸入錯誤', content: '股票代碼已經存在' };

    case 'E400133': return { title: '輸入錯誤', content: '股票代碼不存在' };

    case 'E400134': return { title: '訂單錯誤', content: '訂單無產品' };

    case 'E400135': return { title: '訂單錯誤', content: '產品購買方案不存在' };

    case 'E400136': return { title: '訂單錯誤', content: '付款方式必須填寫' };

    case 'E400137': return { title: '發生錯誤', content: '付款方式無效' };

    case 'E400140': return { title: '產品錯誤', content: '無此方案' };

    case 'E400147': return { title: '商品錯誤', content: '請重新選擇商品' };

    case 'E400151': return { title: '權限錯誤', content: '信箱尚未驗證' };

    case 'E400152': return { title: '登入錯誤', content: '此信箱已使用' };

    case 'E400153': return { title: '登入錯誤', content: 'Facebook登入發生錯誤' };

    case 'E400154': return { title: '登入錯誤', content: 'Google登入發生錯誤' };

    default: return {
      title: '系統作業沒有成功',
      content: message ?? '請稍後再試一次，或聯絡 優分析 UAnalyze 客服人員 ( ' + code + ' )',
    };
  }
}

class ErrorHandler {
  constructor(type, errCode) {
    this.type = type;
    this.error_code = errCode;
    // this.refreshToken = AppStore.getState().Auth.refresh_token;
  }

  handleError() {
    const {
      authenticating,
      expires_in,
      refresh_token,
    } = AppStore.getState().Auth;

    switch (this.error_code) {
      case 'E400001':
      case 'E400113':
      case 'E400114':
        if (this.error_code === 'E400001') {
          if (moment().isSameOrAfter(expires_in)) {
            if (!authenticating /*&& this.refreshToken !== refresh_token*/) {
              AppStore.dispatch(actions.refreshTokenRequest({
                refresh_token: refresh_token
              }));
            }
          } else {
            Message.Box(
              {
                title : '頁面閒置過久，或從另一裝置登入',
                content : '目前已自動登出，點擊「確定」以回到登入頁面重新登入',
                buttons : '[確定]',
              },
              (ButtonPressed) => { AppStore.dispatch(actions.logout()); }
            );
          }

          // if (this.refreshToken === refresh_token) {
          //   console.log('this.refreshToken === refresh_token', this.type);
          // }
        } else {// E400113, E400114
          Message.Box(
            {
              title : '頁面閒置過久，或從另一裝置登入',
              content : '目前已自動登出，點擊「確定」以回到登入頁面重新登入',
              buttons : '[確定]',
            },
            (ButtonPressed) => { AppStore.dispatch(actions.logout()); }
          );
        }

        // return this.error_code !== 'E400114';
        return false;

      case 'E400151':
        Message.Box(
          {
            title: '此 Email 尚未驗證',
            content: '是否重新寄送驗證信？',
            buttons: '[否][是]'
          },
          (ButtonPressed) => {
            if (ButtonPressed === '是') {
              AppStore.dispatch(actions.sendVerifiedEmail());
            }

            AppStore.dispatch(actions.logout());
          }
        );

        return false;

      case 'E400140':
        AppStore.dispatch(actionsPayment.setError(true));

        return false;

      default:
        return true;
    }

        // if (this.error_code === 'E400001' || this.error_code === 'E400113' || this.error_code === 'E400114') {
        //     if (this.error_code === 'E400001' && moment().isSameOrAfter(expires_in) && this.refreshToken === refresh_token) {
        //         AppStore.dispatch(actions.refreshTokenRequest({ refresh_token: refresh_token }));
        //     } else {// E400113, E400114
        //         Message.Box(
        //             {
        //                 title : '頁面閒置過久，或從另一裝置登入',
        //                 content : '目前已自動登出，點擊「確定」以回到登入頁面重新登入',
        //                 buttons : '[確定]'
        //             },
        //             (ButtonPressed) => {
        //                 this.authenticating = false;
        //
        //                 AppStore.dispatch(actions.logout());
        //             }
        //         );
        //     }
        //
        //     return this.error_code !== 'E400114';
        // } else if (this.error_code === 'E400151') {
        //     Message.Box(
        //         {
        //             title: '此 Email 尚未驗證',
        //             content: '是否重新寄送驗證信？',
        //             buttons: '[否][是]'
        //         },
        //         (ButtonPressed) => {
        //             if (ButtonPressed === '是') {
        //                 AppStore.dispatch(actions.sendVerifiedEmail());
        //             }
        //
        //             AppStore.dispatch(actions.logout());
        //         }
        //     );
        // } else if (this.error_code === 'E400140') {
        //     AppStore.dispatch(actionsPayment.setError(true));
        //
        //     return false;
        // }
        //
        // return true;
  }
}

export default function ResponseErrorHandler(type, err = false) {
  let isThereErr = false;

  if (err.code) {
    const error = new ErrorHandler(type, err.code);

    isThereErr = error.handleError();
  }

  if (isThereErr) {
    const errorMessage = ErrorMessageHandler(err);

    if (errorMessage) {
      Message.SmallBox({
        title: `<i class="fa fa-exclamation-triangle txt-white" aria-hidden="true"></i>&nbsp;${errorMessage.title}`,
        content: errorMessage.content,
        timeout : 5000,
      });
    }
  }

  return err;
}
