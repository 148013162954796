import Highcharts from 'highcharts/highstock'
import { findIndex as _findIndex, mergeWith as _mergeWith } from 'lodash'
import { COLOUR } from './config'
import { getColumnName, getFormatter, getXAxisFormat, setColour } from './func'
import { NumberHandler } from 'utils'

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/volume-by-price')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/boost')(Highcharts);
require('highcharts/modules/boost-canvas')(Highcharts);
require('highcharts/modules/draggable-points')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/networkgraph')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/treemap')(Highcharts);
require('highcharts/modules/variwide')(Highcharts);


Highcharts.dateFormats = {
  q: function (timestamp) {
    const date = new Date(timestamp);
    const quarter = (Math.ceil(date.getUTCMonth() / 3));

    return quarter;
  }
};

const UAHighcharts = (
  container,
  options            = {},
  dateType           = 'day',
  isStockChart       = false,
  isYAxisOpposite    = true,
  isNavigatorEnabled = false,
  isLegendShown      = false,
  theme
) => {
  const colourOptions = setColour(theme);
  const optionsUpdated = mergeOption(
    options,
    dateType,
    isStockChart,
    isYAxisOpposite,
    isNavigatorEnabled,
    isLegendShown,
    theme
  );
  Highcharts.setOptions(colourOptions);

  return isStockChart ? Highcharts.stockChart(container, optionsUpdated)
      : Highcharts.chart(container, optionsUpdated);
};
const getHighchartStock = (
  isKLine = true,
  dateType = 'day',
  isYAxisOpposite = true,
  isNavigatorEnabled = true,
  isLegendShown = false,
  theme
) => {
  let options = {
    chart: {
      spacing: [ 10, 20, 25, 20 ],
      height: 400,
    },
    xAxis: {
      crosshair: {
        color: COLOUR.border[theme],
      },
      type: 'datetime',
      labels: {
        formatter: function(d) {
          const date = getXAxisFormat(this.value, dateType);

          return date;
        },
        align: 'right',
        x: 5,
        y: 23,
      },
      offset: 24,
      ordinal: true,
      tickPositioner: function () {
        const ext = this.getExtremes();
        const xMax = Math.round(ext.max);
        const xMin = Math.round(ext.min);
        const unitWith = 100;
        const dataLen = (xMax - xMin + 1);
        const labelWidth = (this.width / unitWith);
        const xWidth = Math.ceil(dataLen / labelWidth);

        let positions = [];
        let index = xMax;

        for (; index > xMin; index -= xWidth) { positions.push(index); }

        if (xMax !== xMin) {
          if ((index+xWidth/2) < xMin) { positions.pop(); }

          positions.push(xMin);
        }

        return positions;
      },
    },
    yAxis: [{
      height: isNavigatorEnabled ? '85%' : '100%',
      title: {
        text: null,
        x: 5,
      },
      text: null,
      labels: {
        x: 5,
        align: 'left',
        formatter: function(d) {
          return `<span style='color: ${COLOUR.text[theme]}'>${this.value}</span>`;
        },
      },
      lineWidth: 0,
      gridLineColor: COLOUR.grid[theme],
      maxPadding: 0,
      offset: isYAxisOpposite ? 0 : 35,
      opposite: isYAxisOpposite,
      startOnTick: false,
      endOnTick: false,
      tickInterval: 1,
      tickPosition: 'inside',
    }],
    plotOptions: {
      arearange: {
        fillOpacity: 0.2,
        tooltip: {
          valueDecimals: 2,
          xDateFormat: getFormatter(dateType),
          pointFormatter: function () {
            const x = this.x;

            let tooltip = `<br/>${this.series.name.replace('[買進]', '[便宜價]')
                .replace('[賣出]', '[昂貴價]')}: `;
            let idx = null;

            this.series.processedXData.forEach(function(k, point) {
              if (k === x) { idx = point; }
            });

            const y = this.series.processedYData[idx];

            tooltip += (typeof y === 'object') ? `${y[0]} - ${y[1]}` : y;

            return this.series.name.includes('布林') ? '' : tooltip;
          },
        },
        mark: {
          enabled: true,
          symbol: 'circle',
        },
      },
      candlestick: {
        color: '#4abf70',
        upColor: '#ef5350',
        lineColor: COLOUR.border[theme],
        tooltip: {
          valueDecimals: 2,
          xDateFormat: getFormatter(dateType),
          pointFormatter: function () {
            let tooltip = Highcharts.dateFormat(`<span>${getFormatter(dateType)}</span>`, this.x);
            tooltip += `<br/>${getColumnName('open')}: ${this.open}`;
            tooltip += `<br/>${getColumnName('high')}: ${this.high}`;
            tooltip += `<br/>${getColumnName('low')}: ${this.low}`;
            tooltip += `<br/>${getColumnName('close')}: ${this.close}`;

            return tooltip;
          },
        },
      },
      column: {
        color: COLOUR.border[theme],
        dataGrouping: {
          units: [
            [ 'day',[1] ],  // unit name,
            [ 'week', [1] ],
            [ 'month', [1] ],
            [ 'year', [1] ],
          ],
        },
        tooltip: {
          valueDecimals: 2,
          xDateFormat: getFormatter(dateType),
          pointFormatter: function () {
            return `<br/>${this.series.name}: ${NumberHandler.comma(this.y)}`;
          },
        },
      },
      flags: {
        allowOverlapX: true,
        clip: false,
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      line: {
        tooltip: {
          valueDecimals: 2,
          xDateFormat: getFormatter(dateType),
          pointFormatter: function () {
            return `<br/>${this.series.name.replace('[買進]', '[便宜價]')
                .replace('[賣出]', '[昂貴價]')}: ${this.y}`;
          },
        },
      },
      series: {
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: isNavigatorEnabled,
    },
    scrollbar: {
      enabled: true,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      split: false,
      valueDecimals: 2,
      headerFormat: '',
      xDateFormat: getFormatter(dateType),
      dateTimeLabelFormats: {
        day: '%Y/%m/%d',
        month: '%Y/%m',
      },
      formatter: undefined,
    },
    legend: {
      enabled: isLegendShown,
    },
    series: [],
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
        },
      },
    },
  };

  if (isKLine) {
    options.yAxis.push({
      top: '90%',
      height: '15%',
      title: {
        text: null,
        x: 5,
      },
      text: '成交量',
      labels: {
        x: 5,
        align: 'left',
        formatter: function(d) {
          return `<span style='color: ${COLOUR.text[theme]}'>${this.value}</span>`;
        },
      },
      endOnTick: false,
      maxPadding: 0,
      gridLineColor: COLOUR.grid[theme],
      lineWidth: 0,
      offset: 0,
      opposite: isYAxisOpposite,
      startOnTick: false,
    });
  }

  return options;
};
const updateChart = (
  chart,
  options,
  dateType,
  isStockChart = false,
  isYAxisOpposite = true,
  isNavigatorEnabled = false,
  isLegendShown = false,
  theme,
  updateSetting = false
) => {
  const colourOptions = setColour(theme);

  let newOptions = mergeOption(
    options,
    dateType,
    isStockChart,
    isYAxisOpposite,
    isNavigatorEnabled,
    isLegendShown,
    theme
  );
  newOptions = Highcharts.merge(colourOptions, newOptions);

  if (options.yAxis && options.yAxis.length > 0) {
    for (let i = 0; i < newOptions.yAxis.length; i++) {
      newOptions.yAxis[i] = _mergeWith(
        options.yAxis[i],
        colourOptions.yAxis, () => {}
      );

      const idx = _findIndex(
        newOptions.yAxis[i].plotLines,
        (item) => (item.value === 0)
      );

      if (idx > 0 && newOptions.yAxis[i].plotLines) {
        newOptions.yAxis[i].plotLines[idx] = [{
          value: 0,
          color: COLOUR.title[theme],
          width: 1.5,
        }];
      }
    }

    if (options.yAxis[0].min !== undefined) {
      newOptions.yAxis = [{ ...options.yAxis[0], ...newOptions.yAxis[0] }];

      if (options.yAxis.length > 1) { newOptions.yAxis[1] = options.yAxis[1]; }
    }
  }

  if (updateSetting) {
    chart.update(newOptions, true, true);
  } else {
    chart.update(newOptions);
    chart.redraw();
  }
};

const mergeOption = (
  options,
  dateType,
  isStockChart       = false,
  isYAxisOpposite    = true,
  isNavigatorEnabled = false,
  isLegendShown      = false,
  theme
) => {
  if (isStockChart) {
    const isKLine = options.series[0].type === 'candlestick';
    const defaultOption = getHighchartStock(
      isKLine,
      dateType,
      isYAxisOpposite,
      isNavigatorEnabled,
      isLegendShown,
      theme
    );

    let stockOptions =  Highcharts.merge(defaultOption, options);

    if (options.yAxis && options.yAxis[0]) {
      if (options.yAxis[0].min !== undefined) {
        stockOptions.yAxis = [{ ...options.yAxis[0], ...defaultOption.yAxis[0] }];

        if (options.yAxis.length > 1) {
          stockOptions.yAxis[1] = options.yAxis[1];
        }

        if (defaultOption.yAxis.length > 1) {
            stockOptions.yAxis[1] = defaultOption.yAxis[1];
        }
      }
    }

    return stockOptions;
  }

  return options;
};


export { UAHighcharts, updateChart }
