import Highcharts from 'highcharts/highstock'
import { COLOUR } from './config'
import { NumberHandler } from 'utils'


// column
const columnName = {
    open  : '開盤價(元)',
    high  : '最高價(元)',
    low   : '最低價(元)',
    close : '收盤價(元)'
  };

const addEvent = (eventType, func) => {
  Highcharts.addEvent(Highcharts.Series, eventType, func);
};
const getColumnName = (key) => (columnName[key]);
  // date format
const getFormatter = (type) => {
  switch (type) {
    case 'day': return '%Y/%m/%d';

    case 'month': return '%Y/%m';

    case 'quarter': return '%Y%q';

    default: return '%Y';
  }
};
const getXAxisFormat = (data, dateType) => {
  let strParsed = Highcharts.dateFormat(getFormatter(dateType), data);

  if (dateType === 'quarter') {
    const q = strParsed[strParsed.length - 1];

    strParsed = strParsed.slice(0, -1);
    strParsed += `Q${q}`;
  }

  return strParsed;
};
const getYAxis = (d, that) => {
  const options = d.axis.userOptions;

  let value       = that.value;
  let valueAbs    = Math.abs(value);
  let title       = '';
  let unitUpdated = '';

  // check unit include '千' '百萬' '億'
  if (options.title && options.title.text) {
    title = options.title.text;

    if (title.indexOf('千') !== -1) {
      valueAbs *= Math.pow(10, 3);
    } else if (title.indexOf('百萬') !== -1) {
      valueAbs *= Math.pow(10, 6);
    } else if (title.indexOf('億') !== -1) {
      valueAbs *= Math.pow(10, (title.indexOf('十億') !== -1) ? 9 : 8);
    }
  }

  if (valueAbs !== 0) {
    if (valueAbs > 999 && valueAbs < 1000000) {
      valueAbs /= Math.pow(10, 3);
      unitUpdated = '千';
    } else if (valueAbs > 999999 && valueAbs < 100000000) {
      valueAbs /= Math.pow(10, 6);
      unitUpdated = '百萬';
    } else if (valueAbs > 99999999 && valueAbs < 1000000000000) {
      valueAbs /= Math.pow(10, 8);
      unitUpdated = '億';
    } else if (valueAbs > 999999999999) {
      valueAbs /= Math.pow(10, 12);
      unitUpdated = '兆';
    }
  }

  return `${(value < 0) ? '-' : ''}${valueAbs}${unitUpdated}`;
};

// options
const setColour = (colourTheme) => ({
  colors: COLOUR.series[colourTheme],
  chart: {
    height: 400,
    backgroundColor: COLOUR.background[colourTheme],
    alignThresholds: true,
    zoomType: 'x',
    style: {
      fontFamily: 'Source Han Sans TC, source-han-sans-tc, Noto Sans TC, Open Sans, sans-serif',
    },
    resetZoomButton: {
      position: {
        align: 'left',
        y: 0,
        x: 0,
      },
    },
  },
  lang: {
    contextButtonTitle: '更多功能',
    drillUpText: '回上層',
    exitFullscreen: '退出全螢幕',
    lang: { thousandsSep: ',' },
    // otherwise by default: [ 'k', 'M', 'G', 'T', 'P', 'E' ]
    numericSymbols: null,
    rangeSelectorZoom: '',
    resetZoom: '重設',
  },
  global: {
    useUTC: false, // use local timezone instead
  },
  title: {
    style: {
      display: 'block',
      color: COLOUR.text[colourTheme],
      fontSize: '16px',
      fontWeight: '400',
    },
  },
  xAxis: {
    title: {
      style: {
        color: COLOUR.text[colourTheme],
      },
    },
    labels: {
      style: {
        color: COLOUR.text[colourTheme],
      },
    },
    gridLineColor: COLOUR.grid[colourTheme],
    tickmarkPlacement: 'on',
    gridLineWidth: 1,
  },
  yAxis: {
    title: {
      style: {
        display: 'none',
        color: COLOUR.text[colourTheme],
      },
    },
    labels: {
      style: {
        color: COLOUR.text[colourTheme],
      },
      formatter: function(d) {
        return getYAxis(d, this);
      },
    },
    stackLabels: {
      style: {
        color: COLOUR.text[colourTheme],
        fontSize: '12px',
      },
    },
    gridLineColor: COLOUR.grid[colourTheme],
    alignTicks: true,
  },
  pane: {
    startAngle: 0,
    endAngle: 360,
  },
  plotOptions: {
    series: {
      animation: false,
      borderColor: 'transparent',
      cropThreshold: 10000,
      dataLabels: {
        allowOverlap: true,
        align: 'center',
        backgroundColor: 'transparent',
        padding: 0,
        useHTML: true,
        style: {
          color: COLOUR.text[colourTheme],
          fontSize: '12px',
          fontWeight: 'normal',
          textOutline: '1px contrast',
          textShadow: (colourTheme === 'dark') ? '0px 1px 5px #000' : '',
        },
        y: -5,
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
      states: {
        inactive: {
          opacity: 0.4,
        },
      },
      turboThreshold: 0,
    },
    area: {
      fillOpacity: COLOUR.fill_opacity_area[colourTheme],
    },
    arearange: {
      animation: false,
      fillOpacity: 0.6,
      lineWidth: 0,
    },
    areaspline: {
      fillOpacity: 0.3,
    },
    bubble: {
      colorByPoint: true,
      dataLabels: {
        align: 'center',
        format: '{point.name}',
      },
      marker: {
        states: {
          select: {
            enabled: false,
          },
        },
      },
      maxSize: 140,
      minSize: 40,
      showInLegend: false,
    },
    column: {
      dataLabels: {
        enabled: false,
        formatter: function () {
          const { series, y } = this;

          let value = NumberHandler.comma(NumberHandler.getUnitNumber(y));
          value = (value === '0') ? '' : value + (series.options.unit ?? '');

          return value;
        },
      },
    },
    networkgraph: {
      dataLabels: {
        enabled: true,
        style: {
          color: '#b5b5b5',
          fontSize: '11px',
          fontWeight: 'normal',
          textOutline: '1px transparent',
        }
      },
      keys: [ 'from', 'to' ],
      layoutAlgorithm: {
        enableSimulation: true,
        friction: -0.9,
        gravitationalConstant: 0.001,
        initialPositionRadius: 10,
      },
    },
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        useHTML: true,
        formatter : function () {
          const { name, percentage } = this.point;

          if (name) {
            return `<div
              class='pie-data-label'
              style='${COLOUR.pie_data_labels[colourTheme]}'
            >
              ${name} ${Highcharts.numberFormat(percentage, 1, '.', ',')}%
            </div>`;
          }

          return '';
        },
      },
      showInLegend: true,
      size: '75%',
    },
    scatter: {
      states: {
        hover: {
          marker: {
            enabled: false,
          },
        },
      },
    },
    solidgauge: {
      dataLabels: {
        enabled: false,
      },
      stickyTracking: false,
      rounded: true,
    },
    treemap: {
      accessibility: {
        exposeAsGroupOnly: true,
      },
      allowTraversingTree: true,
      animationLimit: 1000,
      levels: [
        {
          level: 1,
          dataLabels: {
            enabled: true,
            allowOverlap: false
          },
          borderColor: COLOUR.border[colourTheme],
          borderWidth: 3,
          levelIsConstant: false,
        },
        {
          level: 2,
          borderColor: COLOUR.border[colourTheme],
          borderWidth: 1,
        }
      ],
      layoutAlgorithm: 'squarified',
    },
  },
  navigator: {
    enabled: false,
    height: 20,
    handles: {
      backgroundColor: COLOUR.border[colourTheme],
      borderColor: COLOUR.border[colourTheme],
    },
    maskFill: 'rgba(255, 165, 0, .15)',
    outlineColor: COLOUR.title[colourTheme],
    series: {
      fillOpacity: 0,
      lineColor: '#ffa500',
    },
  },
  scrollbar: {
    height: 8,
    barBackgroundColor: COLOUR.border[colourTheme],
    barBorderColor: 'none',
    barBorderWidth: 0,
    barBorderRadius: 4,
    buttonBackgroundColor: 'none',
    buttonBorderColor: 'none',
    buttonBorderWidth: 0,
    buttonArrowColor: 'none',
    rifleColor: 'none',
    trackBackgroundColor: COLOUR.background[colourTheme],
    trackBorderColor: COLOUR.border[colourTheme],
  },
  tooltip: {
    useHTML: true,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderColor: COLOUR.grid[colourTheme],
    formatter: function () {
      let tooltip = this.x ? `<span>${this.x}</span>` : '';
      
      if (this.points) {
        for (var i = 0; i < this.points.length; i++) {
          if (!this.points[i].series.options.noShowInTooltips) {
            tooltip += '<br/>';

            if (this.points[i].series.type==='arearange') {
              tooltip += `<span
                id=${this.points[i].series.chart.container.id}-tooltips-${this.points[i].series.index}
                class='tooltips-row'
              >
                <span style='color: ${this.points[i].color}'>\u25CF</span>
                <b>
                  ${this.points[i].series.name}: ${Highcharts.numberFormat(this.points[i].point.low, 2, '.', ',')}${this.points[i].series.userOptions.unit ?? ''} - ${Highcharts.numberFormat(this.points[i].point.high, 2, '.', ',')}${this.points[i].series.userOptions.unit ?? ''}
                </b>
              </span>`;
            } else if (this.points[i].series.userOptions.type === 'column' && this.points[i].series.userOptions.stacking) {
              tooltip += `<span>
                <span style='color: ${this.points[i].color}'>\u25CF</span>
                <b>
                  ${this.points[i].series.name}: ${NumberHandler.comma(NumberHandler.getUnitNumber(this.points[i].y))}${this.points[i].series.userOptions.unit ?? ''}
                </b>
              </span>`;
            } else {
              tooltip += `<span>
                <span style='color: ${this.points[i].color}'>\u25CF</span>
                <b>
                  ${this.points[i].series.name}: ${NumberHandler.comma(NumberHandler.floatFormatter(this.points[i].y, 2))}${this.points[i].series.userOptions.unit ?? ''}
                </b>
              </span>`;
            }
          }
        }
      } else {
        tooltip += `<span>
          <span style='color: ${this.point.color}'>\u25CF</span>
          <b>
            ${this.point.series.name}: ${Highcharts.numberFormat((this.point.series.userOptions.unit ? this.point.y : this.point.y * 100 / (this.total ?? 1)), (this.point.series.userOptions.unit ? 0 : 2), '.', ',')}${this.point.series.userOptions.unit ? `（${this.point.series.userOptions.unit}）` : ''}
          </b>
        </span>`;
      }

      return tooltip;
    },
    xDateFormat: '%Y/%m/%d',
  },
  legend: {
    itemStyle: {
      color: COLOUR.text[colourTheme],
      fontWeight: 'light',
      textDecoration: 'none',
    },
    itemHiddenStyle: {
      textDecoration: 'line-through',
    },
    itemHoverStyle: {
      color: COLOUR.title[colourTheme],
    },
    navigation: {
      activeColor: COLOUR.series[colourTheme][0],
      inactiveColor: COLOUR.border[colourTheme],
      style: {
        color: COLOUR.text[colourTheme],
      },
    },
    verticalAlign: 'top',
  },
  navigation: {
    buttonOptions: {
      theme: {
        fill: COLOUR.button[colourTheme],
        stroke: 'transparent',
      },
    },
  },
  drilldown: {
    allowPointDrilldown: true,
    activeAxisLabelStyle: {
      color: '#ffa500',
      textDecoration: 'none',
    },
    activeDataLabelStyle: {
      color: COLOUR.title[colourTheme],
      textDecoration: 'none',
    },
    drillUpButton: {
      relativeTo: 'spacingBox',
      position: {
        y: -100,
        x: 0,
      },
    },
  },
  caption: {
    align: 'center',
    verticalAlign: 'top',
    useHTML: true,
    style: {
      left: '15px',
      margin: '15px',
      color: COLOUR.caption[colourTheme],
      fontSize: '16px',
      whiteSpace: 'normal',
    },
  },
  exporting: {
    allowHTML: true,
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Microsoft JhengHei, Arial',
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
        },
      },
    },
    menuItemDefinitions: {
      downloadCSV: {
        text: '下載為 CSV 檔',
        onclick: function () {
          this.downloadCSV();
        }
      },
      downloadJPEG: { text: '下載為 JPEG 檔' },
      downloadPDF: { text: '下載為 PDF' },
      downloadPNG: {
        text: '下載為圖檔',
        // onclick: function () {
        //   let xAxis = this.xAxis;
        //
        //   if (xAxis[0].plotLinesAndBands) {
        //     let arrPlotLinesAndBands = xAxis[0].plotLinesAndBands;
        //
        //     for (let i = 0; i < arrPlotLinesAndBands.length; i++) {
        //       let plotBand = arrPlotLinesAndBands[i].options;
        //
        //       if (plotBand.id === 'plot-band') {
        //         plotBand.label = '';
        //
        //         arrPlotLinesAndBands = arrPlotLinesAndBands.map((item, idx) => ((idx === i) ? plotBand : item.options));
        //         xAxis = xAxis.map((item) => (item.userOptions));
        //         xAxis[0].plotBands = arrPlotLinesAndBands;
        //
        //         break;
        //       }
        //     }
        //   }
        //
        //   this.update({ xAxis: xAxis });
        //   this.exportChart({ type: 'image/png' });
        // }
      },
      downloadSVG: { text: '下載為 SVG 向量檔' },
      printChart: { text: '列印圖表' },
      viewFullscreen: { text: '展開全螢幕' },

      addCaption: { text: '加入註解' },
      clear: { text: '清除重算' },
      drawHorizLine: { text: '繪製水平線' },
      drawDiagLine: { text: '繪製斜線' },
      reset: { text: '結束計算' },
      showLbl: { text: '顯示數值' },
      showMaxMin: { text: '顯示高低值' },

      // chart
      calcAverage: { text: '計算平均值' },
      calcCompoundAnnualGrowthRate: { text: '計算年複合成長率' },
      calcGrowthRate: { text: '計算累積成長率' },
      calcGrowthValue: { text: '計算增減' },
      calcProportion: { text: '計算比重' },
      calcSD: { text: '計算標準差' },
      calcSum: { text: '計算合計值' },
      // k line
      calcKLineAverage: { text: '計算均價' },
      calcKLineGrowthRate: { text: '計算漲跌幅' },
      // reuters
      combine: { text: '產業合併' },
      deselectAll: { text: '全不選' },
      editStocks: { text: '編輯股票' },
      selectAll: { text: '全選' },
      showAllData: { text: '顯示全部資料' },
      switchChartType: { text: '切換圖表類型' },
    },
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'separator',
          'downloadPNG',
          // 'downloadCSV'
        ],
      },
    },
  },
  credits: {
    text: '資料來源: 優分析',
    href: 'https://pro.uanalyze.com.tw',
    position: {
      y: -2,
    },
    style: {
      fontSize: '11px',
      color: COLOUR.title[colourTheme],
    },
  },
});


export { addEvent, getColumnName, getFormatter, getXAxisFormat, setColour }
