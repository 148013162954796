import moment from 'moment'


export const types = {
  PRODUCTS_LOAD_REQUEST     : 'userProducts/LOAD_REQUEST',
  PRODUCTS_LOAD_COMPLETED   : 'userProducts/LOAD_COMPLETED',
  PRODUCTS_LOAD_FAILED      : 'userProducts/LOAD_FAILED',

  PRODUCTS_CANCEL_REQUEST   : 'userProducts/CANCEL_REQUEST',
  PRODUCTS_CANCEL_COMPLETED : 'userProducts/CANCEL_COMPLETED',
  PRODUCTS_CANCEL_FAILED    : 'userProducts/CANCEL_FAILED',
}

const initialState = {
  user_products        : null,
  collections          : null,
  singles              : null,
  need_updated_product : false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.PRODUCTS_LOAD_REQUEST:
    case types.PRODUCTS_LOAD_FAILED:
      return { ...initialState };

    case types.PRODUCTS_LOAD_COMPLETED:
      let userProducts = [ ...action.data ] || [],
          collections  = [],
          multiSingles = [],
          singles      = [];

      for (let i = 0; i < userProducts.length; i++) {
        if (userProducts[i].deadline) {
          if (moment().isAfter(userProducts[i].deadline)) {
            userProducts[i].status = 2;
          }

          userProducts[i].deadline = userProducts[i].deadline.slice(0, -9);
        } else {
          userProducts[i].deadline = '無期限';
        }

        if (userProducts[i].type === 'multi_single') {
          multiSingles.push(userProducts[i]);
        } else if (userProducts[i].type === 'single') {
          singles.push(userProducts[i]);
        } else {
          collections.push(userProducts[i]);
        }
      }

      return {
        ...state,
        user_products        : userProducts,
        need_updated_product : false,
        collections          : collections,
        multi_single         : multiSingles,
        singles              : singles
      };

    case types.PRODUCTS_CANCEL_REQUEST:
    case types.PRODUCTS_CANCEL_FAILED:
      return { ...state };

    case types.PRODUCTS_CANCEL_COMPLETED:
      return {
        ...state,
        need_updated_product: true
      };

    default:
      return state;
  }
}

export const actions = {
  loadUserProductsRequest: function () {
    return { type: types.PRODUCTS_LOAD_REQUEST };
  },
  loadUserProductsCompleted: function(data) {
    return {
      type: types.PRODUCTS_LOAD_COMPLETED,
      data: data
    };
  },
  loadUserProductsFailed: function(error) {
    return {
      type: types.PRODUCTS_LOAD_FAILED,
      error: error
    };
  },

  cancelUserProductRequest: (id) => ({
    type: types.PRODUCTS_CANCEL_REQUEST,
    id: id
  }),
  cancelUserProductCompleted: (data) => ({
    type: types.PRODUCTS_CANCEL_COMPLETED,
    data: data
  }),
  cancelUserProductFailed: (error) => ({
    type: types.PRODUCTS_CANCEL_FAILED,
    error: error
  })
}
