import { call, put, takeEvery } from 'redux-saga/effects'
import { SingleComparisonChartDataLoadAPIs } from 'api'
import { types, actions } from '../modules/SingleComparisonChart'


function * watchComparisonChartDataLoadRequest() {
    yield takeEvery(types.DATA_REQUEST, chatFlow);
}

function * chatFlow(action) {
    try {
        const response = yield call(
          SingleComparisonChartDataLoadAPIs.SingleComparisonChartDataLoadAPI,
          action.model,
          action.stocks
        );
        const responseData = response.data;

        if (responseData.status === 'OK') {
          yield put(actions.dataCompleted(responseData.data));
        } else {
          yield put(actions.dataFailed(responseData.error));
        }
    } catch (err) {
      yield put(actions.dataFailed(err));
    }
}


export default [ watchComparisonChartDataLoadRequest() ]
