import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'


const TabItem = ({ item, state, props, onTabChange }) => {
  const activeTab	= state.active_tab;
  const {
    type,
    labels,

    new_notification,
    need_updated_product,
    need_pay_order,

    onSort,
    onDelete,
  }	= props;
  const idx = labels.indexOf(item);
  const isThereNewItems = type === 'announcement' || (type === 'profile'
      && ((idx === 0 && new_notification) || (idx === 1 && need_updated_product)
      || (idx === 2 && need_pay_order)));

  return (
    <li
      className={`ua-horizontal-tab-item ua-tab-item ${(idx === activeTab)
          ? 'active' : ''}`}
      data-idx={idx}
      onClick={onTabChange}
    >
      {isThereNewItems && <div className='ua-circle'/>}

      <span	className='ua-tab-title'>
        {onSort && (
          <span className='mover margin-right-5 cursor-move'>
            <i className='txt-medium elegant elegant-eleganticons-64'/>
          </span>
        )}

        <span>{item}</span>

        {onDelete && (
          <i
            className='delete-item-btn cursor-pointer txt-medium fa fa-times-circle'
            data-idx={idx}
            onClick={onDelete}
          />
        )}
      </span>

    </li>
  );
};
const TabContainer = ({ children, customClassName }) => (
  <ul id='ua-tab' className={`ua-tab ${customClassName}`}>{children}</ul>
);
const SortableTabContainer = SortableContainer(({ children, customClassName }) => (
  <TabContainer customClassName={customClassName}>{children}</TabContainer>
));
const SortableTabItem = SortableElement(TabItem);


export {
  TabItem,
  TabContainer,
  SortableTabContainer,
  SortableTabItem,
}
