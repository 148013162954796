import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/SideMenu'
import { MenuChildren as Menu } from './MenuChildren'


const mapStateToProps = (state, ownProps) => ({
	is_mobile: state.Layout.is_mobile,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	onSectionOpen: (section) => dispatch(actions.openSection(section)),
	onMenuToggle: () => dispatch(actions.toggleSideMenu()),
	onSideMenuHide: (sideMenuHidden) => dispatch(
		actions.hideSideMenu(sideMenuHidden)
	),
});
const MenuChildren = withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));


export { MenuChildren }
