import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Product'
import { ProductAPIs } from 'api'
import { ResponseErrorHandler } from 'utils'


function responseHandler(response) {
    return response.data;
}

function * watchListLoadRequest() {
    yield takeLatest(types.LIST_LOAD_REQUEST, loadListFlow);
}

function * loadListFlow(action) {
    try {
        const response = yield call(ProductAPIs.ProductListLoadAPI),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadListCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadListFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loadListFailed(error));
    }
}

function * watchDetailLoadRequest() {
    yield takeLatest(types.DETAIL_LOAD_REQUEST, loadDetailFlow);
}

function * loadDetailFlow(action) {
    try {
        const response = yield call(ProductAPIs.ProductDetailLoadAPI, action.id),
            responseData = responseHandler(response);

        if (responseData.status === 'success') {
            yield put(actions.loadDetailCompleted(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loadDetailFailed(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loadDetailFailed(error));
    }
}


export default [
    watchListLoadRequest(),
    watchDetailLoadRequest()
]
