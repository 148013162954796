import { connect } from 'react-redux'
import { actions } from 'appRedux/modules/SideMenu'
import { UserInfo as component } from './UserInfo'


const mapStateToProps = (state) => ({ is_mobile: state.Layout.is_mobile });
const mapDispatchToProps = (dispatch, ownProps) => ({
	onSideMenuHide : (sideMenuHidden) =>
			dispatch(actions.hideSideMenu(sideMenuHidden)),
});
const UserInfo = connect(mapStateToProps, mapDispatchToProps)(component);


export { UserInfo }
