const mergeBy = (a, b, condition) => {
		for (let i = 0; i < a.length; i++) {
			const row = b.find((item) => (item[condition] === a[i][condition]));

			if (row) {
				a[i][2] = row[1];
			}
		}

		return a;
	},
	sortBy = (array, sortArray, key) => ([ ...array ].sort((a, b) => sortArray.indexOf(a[key]) - sortArray.indexOf(b[key]))),
	sorter = (data, key, isASC = true) => {
    const result = data.sort((a, b) => {
      let comparison = 0;

			if (a[key] === null) {
				comparison = (b[key] === null) ? 0 : -1;
			} else if (a[key] > b[key]) {
        comparison = isASC ? 1 : -1;
      } else if (a[key] < b[key]) {
        comparison = isASC ? -1 : 1;
      }

      return comparison;
    });

    return result;
  }


export default { mergeBy, sortBy, sorter }
