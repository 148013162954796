import React from 'react'
import { SortableElement } from 'react-sortable-hoc'


const SingleItem = ({ item }) => {
  const { id, name, onRemove } = item;

  return (
    <div className="sort-box">
      <div>
          <i className="margin-right-5 cursor-move fa fa-arrows-alt"/>

          <strong className="margin-right-auto cursor-move">{name}</strong>

          <i
            className="cursor-pointer fa fa-times"
            data-id={id}
            onClick={onRemove}
          />
      </div>
    </div>
  );
};
const SortableSingleItem = SortableElement(SingleItem);


export { SortableSingleItem }
