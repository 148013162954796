import { call, put, takeLatest } from 'redux-saga/effects'
import { DataAssistantAPIs } from 'api'
import { types, actions } from '../modules/AssistantData'


function * watchDataAssistantRequest() {
    yield takeLatest(types.CREATEDATAREQUEST, DataAssistantFlow);
}

function * DataAssistantFlow(action) {
    try {
        const response = yield call(DataAssistantAPIs.DataAssistantGetAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.getSuccess(responseData.data));
        } else {
            yield put(actions.getError(responseData.error));
        }
    } catch (err) {
        yield put(actions.getError(err));
    }
}

function * watchDataSearchRequest() {
    yield takeLatest(types.SEARCHREQUEST, DataSearchFlow);
}

function * DataSearchFlow(action) {
    try {
        const response = yield call(DataAssistantAPIs.DataSearchAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            yield put(actions.getSearchSuccess(responseData.data));
        } else {
            yield put(actions.getError(responseData.error));
        }
    } catch (err) {
        yield put(actions.getError(err));
    }
}


export default [ watchDataAssistantRequest(), watchDataSearchRequest() ]
