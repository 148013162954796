import React from 'react'
import Case from './components/index'
import { judgmentMonth } from 'components/Renew/func'


const Renewbox = ({ renewProduct }) => {
	const newDeadline = renewProduct.deadline.split(' ')[0].replaceAll('-', '/');

	return (
		<div className="renew-content">
			<p>到期日：{newDeadline}</p>

			<p>前次訂閱紀錄：${renewProduct.last_price} / {judgmentMonth(renewProduct.quantity)}</p>
			
			<p>目前可用優惠：</p>

			<Case renewProduct={renewProduct} />
		</div>
	)
}


export default Renewbox
