import axios from 'axios'
import config from 'config'
import { Cookies } from 'utils'


const configAPI = () => axios.get(`${config.api.APP_API_DOMAIN}/configs`, {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const trialAPI = (products, promocodes, modules) => axios.post(
  `${config.api.APP_API_DOMAIN}/user/orders/trial`,
  { products: products, promocodes: promocodes, modules: modules },
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);
const checkoutAPI = (orderData) => axios.post(
  `${config.api.APP_API_DOMAIN}/user/orders`,
  orderData,
  {
    headers: {
      Accept: 'application/json',
      Authorization: `${Cookies.get('token_type')} ${Cookies.get('access_token')}`,
    },
  }
);


export default { configAPI, trialAPI, checkoutAPI }
