export const types = {
  ANSWER_GET_REQUEST: 'singleAiConsultant/ANSWER_GET_REQUEST',
  ANSWER_GET_SUCCESS: 'singleAiConsultant/ANSWER_GET_SUCCESS',
  ANSWER_GET_ERROR: 'singleAiConsultant/ANSWER_GET_ERROR',
  RESET: 'singleAiConsultant/RESET',
}

const KEYS = [ 'display', 'question', 'highchart' ];
const initialState = { answering: false, qa: {} };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.ANSWER_GET_REQUEST:
      return { ...state, answering: true };

    case types.ANSWER_GET_SUCCESS:
    case types.ANSWER_GET_ERROR:
      const { data, question } = action;

      let text = '暫無訊息';

      if (data) {
        const arr = data.split('\n\n');

        text = '找不到相關訊息';

        if (!data.includes('找不到相關訊息')) {
          text = {
            conclusion: [],
            display: [],
            highchart: [],
            question: [],
          };

          for (let i = 0; i < arr.length; i++) {
            let temp = arr[i];

            for (let j = 0; j < KEYS.length; j++) {
              const key = KEYS[j];

              if (!temp.includes(key)) { continue; }

              temp = temp.replaceAll('```', '');

              let idxStart = temp.indexOf('[');
              let idxEnd = temp.indexOf(']');

              if (temp.includes('highchart')) {
                idxStart = temp.indexOf('highchart') + 9;
                idxEnd = temp.length - 1;
              }

              temp = temp.substr(idxStart, idxEnd);
              temp = JSON.parse(temp);
              if(key === 'highchart'){
                text[key].push(temp);
              }else{
                text[key] = temp;
              }
            }
          }

          text.conclusion = arr.filter((item) => {
            let isConclusion = true;

            for (let i = 0; i < KEYS.length; i++) {
              isConclusion = !item.includes(KEYS[i]);

              if (!isConclusion) { return isConclusion; }
            }

            return isConclusion;
          });
        }
      }

      return {
        ...state,
        answering: false,
        qa: { prompt: question, text: text },
      };

    case types.RESET:
      return { ...state, answering: false, qa: {} };

    default:
      return state;
  }
}

export const actions = {
  getAnswerRequest: function (question, date, providers) {
    return { type: types.ANSWER_GET_REQUEST, question: question, date, providers };
  },
  getAnswerSuccess: function(data, question) {
    return { type: types.ANSWER_GET_SUCCESS, data: data, question: question };
  },
  getAnswerError: function() {
    return { type: types.ANSWER_GET_ERROR};
  },
  reset: function() {
    return { type: types.RESET };
  },
}
