
import { SessionStorage } from 'utils'

export const types = {
    FILTER_GET: 'singleFilterTable/FILTER_GET',
    FILTER_SET: 'singleFilterTable/FILTER_SET',
    SEARCH_GET: 'singleFilterTable/SEARCH_GET',
    SEARCH_SET: 'singleFilterTable/SEARCH_SET',
}
const initialState = { 
    filtered:SessionStorage.get(`FilterTable_filtered`) ?? {},
    search_text:SessionStorage.get(`FilterTable_search`) ?? {}
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.FILTER_SET:
            SessionStorage.set(`FilterTable_filtered`, {...state.filtered, [action.data.key]:action.data.value});
            return {...state, filtered:{...state.filtered, [action.data.key]:action.data.value}}
        case types.SEARCH_SET:
            SessionStorage.set(`FilterTable_search`, {...state.search_text, [action.data.key]:action.data.value});
            return {...state, search_text:{...state.search_text, [action.data.key]:action.data.value}}
        default:
            return state
    }
}

export const actions = {
    setFilterd: function(key, value) {
        return { type: types.FILTER_SET, data:{key, value}};
    },
    setSearch: function(key, value) {
        return { type: types.SEARCH_SET,  data:{key, value}};
    },
}
