
const SessionStorage = {
	get:(key)=>{
		const v = localStorage.getItem(key);
		try {
			return v ? JSON.parse(v): null;
		} catch (e) {
			return null;
		}
	},
	set:(key, value)=>{
		if(value!=='' && value){
			const v = JSON.stringify(value);
			localStorage.setItem(key, v);
		}else{
			localStorage.removeItem(key)
		}
	}
};

export default SessionStorage;