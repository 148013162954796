import { connect } from 'react-redux'
import { Table as component } from './Table'


const mapStateToProps = (state, ownProps) => ({
	is_mobile: state.Layout.is_mobile,
	pager: state.Pagination.pager,
});
const Table = connect(mapStateToProps)(component)


export { Table }
