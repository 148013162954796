import { call, put, takeLatest } from 'redux-saga/effects'
import { types, actions } from '../modules/Auth'
import { AuthAPIs, PasswordAPIs } from 'api'
import { ResponseErrorHandler, ResponseMessageHandler } from 'utils'


// account password login
function* watchLoginRequest() {
    yield takeLatest(types.LOGIN_REQUEST, loginFlow);
}

function* loginFlow(action) {
    try {
        const response = yield call(AuthAPIs.LoginAPI, action.data);
        const responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.LOGIN_SUCCESS);

            yield put(actions.loginSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loginError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loginError(error));
    }
}

// Apple login
function* watchAppleLoginRequest() {
    yield takeLatest(types.APPLE_LOGIN_REQUEST, AppleLoginFlow);
}

function* AppleLoginFlow(action) {
    try {
        const response = yield call(AuthAPIs.AppleLoginAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.LOGIN_SUCCESS);

            yield put(actions.loginSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loginError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loginError(error));
    }
}

// Facebook login
function* watchFacebookLoginRequest() {
    yield takeLatest(types.FACEBOOK_LOGIN_REQUEST, FacebookLoginFlow);
}

function* FacebookLoginFlow(action) {
    try {
        const response = yield call(AuthAPIs.FacebookLoginAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.LOGIN_SUCCESS);

            yield put(actions.loginSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loginError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loginError(error));
    }
}

// Google login
function* watchGoogleLoginRequest() {
    yield takeLatest(types.GOOGLE_LOGIN_REQUEST, GoogleLoginFlow);
}

function* GoogleLoginFlow(action) {
    try {
        const response = yield call(AuthAPIs.GoogleLoginAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.LOGIN_SUCCESS);

            yield put(actions.loginSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.loginError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.loginError(error));
    }
}

// account register
function* watchRegisterRequest() {
    yield takeLatest(types.REGISTER_REQUEST, registerFlow);
}

function* registerFlow(action) {
    try {
        const response = yield call(AuthAPIs.RegisterAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.REGISTER_SUCCESS);

            // register success -> send verify email
            yield put(actions.registerSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.registerError(responseData.error));
        }
    } catch(error) {
        yield put(actions.registerError(error));
    }
}

// register success
function* watchRegisterSuccessRequest() {
    yield takeLatest(types.REGISTER_SUCCESS, registerSuccessFlow);
}

function* registerSuccessFlow() {
    yield put(actions.sendVerifiedEmail());
}

// send verify email
function* watchSendVerifiedEmailRequest() {
    yield takeLatest(types.VERIFIED_EMAIL_SEND_REQUEST, sendVerifiedEmailFlow);
}

function* sendVerifiedEmailFlow() {
    try {
        const response = yield call(AuthAPIs.SendVerifiedEmailAPI),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.VERIFIED_EMAIL_SEND_SUCCESS);

            yield put(actions.sendVerifiedEmailSuccess(responseData.data));
        } else {
            ResponseErrorHandler('auth/VERIFIED_EMAIL_SEND_REQUEST', responseData.error);

            yield put(actions.sendVerifiedEmailError(responseData.error));
        }
    } catch(error) {
        yield put(actions.sendVerifiedEmailError(error));
    }
}

// refresh
function* watchRefreshTokenRequest() {
    yield takeLatest(types.REFRESH_TOKEN_REQUEST, refreshTokenFlow);
}

function* refreshTokenFlow(action) {
    try {
        const response = yield call(AuthAPIs.RefreshTokenAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.REFRESH_TOKEN_SUCCESS);

            yield put(actions.refreshTokenSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.refreshTokenError(responseData.error));
        }
    } catch(error) {
        yield put(actions.refreshTokenError(error));
    }
}

// set password (social login)
function* watchSetPasswordRequest() {
    yield takeLatest(types.PASSWORD_SET_REQUEST, setPasswordFlow);
}

function* setPasswordFlow(action) {
    try {
        const response = yield call(PasswordAPIs.SetPasswordAPI, action.data),
            responseData = response.data;

        if (responseData.status === 'success') {
            ResponseMessageHandler(types.PASSWORD_SET_SUCCESS);

            yield put(actions.setPasswordSuccess(responseData.data));
        } else {
            ResponseErrorHandler(action.type, responseData.error);

            yield put(actions.setPasswordError(responseData.error));
        }
    } catch(error) {
        ResponseErrorHandler(action.type, error);

        yield put(actions.setPasswordError(error));
    }
}


export default [
	  watchLoginRequest(),
    watchAppleLoginRequest(),
    watchFacebookLoginRequest(),
    watchGoogleLoginRequest(),
	  watchRegisterRequest(),
    watchRegisterSuccessRequest(),
    watchSendVerifiedEmailRequest(),
    watchRefreshTokenRequest(),
    watchSetPasswordRequest()
]
